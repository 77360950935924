//if agency logged in for the first time it will show this page that says:
//update ur agency info so you can add new events

import FeedBack from "../../../generic-components/GenericFeedback";
import "../../../style/firstTimeRegistered.scss";

const FirstTimeAgency = () => {

    return (
        <FeedBack
            image={"/icons/ticket.svg"}
            firstLine={<div >ברוכים הבאים לאנטר!</div>}
            secondLine={""}
            lastLine={<div className="no-new-events-text" >
                <div>כדי שתוכלו להתחיל ולהוסיף אירועים</div>
                <div>תצטרכו לעדכן את פרופיל מוסד התרבות שלכם בעוד כמה פרטים</div>
            </div>}

            button={<div>לעדכון הפרופיל</div>}
            agencyHome={true}
            history={"agency-profile"}
            isAgency={true}
        />
    )

}

export default FirstTimeAgency;