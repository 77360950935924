import { FC } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";

import { BenefitEventProps } from "../../../consts/interfaces/BenefitsPage.interface";
import { TICKETS } from "../../../consts/hebrew";
import './style/benefitEvent.scss';

const BenefitEvent: FC<BenefitEventProps> = ({ details }) => {

    const history = useHistory()
    const { coverImage, eventName, ticketsNum, id } = details;

    const handleClickCard = () => {
        history.push(`benefit/${id}`)
    }

    return (
        <div className="card" onClick={handleClickCard}>
            <div className='card-img-container'>
                <img className="card-img" src={coverImage} alt={eventName} />
            </div>
            <div className="card-bio">
                <Typography className="right-bio" variant="cardBio" children={eventName} />
                <div className="left-bio">
                    <img className="ticket-img" src="/icons/ticket.svg" alt='ticket' />
                    <Typography variant="cardBio" children={`${ticketsNum} ${TICKETS}`} />
                </div>
            </div>
        </div>
    )
}

export default BenefitEvent