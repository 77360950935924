import { useState } from "react";
import clsx from "clsx";
import GenericInputProps from "../consts/interfaces/GenericInputProps";
import { VALID_NUM } from "../consts/regexes";
import "../style/genericInput.scss";

const GenericInput: React.FC<GenericInputProps> = (props) => {
    const {
        error,
        value,
        name,
        onBlur,
        onChange,
        disabled,
        type,
        inputMode,
        autoComplete,
        className,
        placeholder,
        characterLimit,
        hideCharacterLimit,
        timeInput,
        textarea,
        inputClassName,
        phoneInput,
        secondaryTitle,
        title,
        inputRef,
    } = props

    const [limitReached, setLimitReached] = useState(false);

    const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        if (characterLimit && e.target.value.length > characterLimit) {
            handleLimitReached()
            return;
        }

        if (phoneInput) {
            if (!VALID_NUM.test(e.target.value)) return;
        }

        if (timeInput) {
            e = handleTimeInput(e as React.ChangeEvent<HTMLInputElement>)
        }

        e.target.value = e.target.value.replace(/ +/g, ' ');
        onChange?.(e);
    }

    const handleTimeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        if (inputValue.at(-1) === ":") {
            inputValue = inputValue[0];
        }
        else if (inputValue.length === 2 && /\d/.test(inputValue)) {
            inputValue = `${inputValue[0]}:${inputValue[1]}`;
            if (Number(inputValue[2]) > 5) {
                inputValue = `${inputValue.slice(0, 2)}0${Number(inputValue[2])}`
            }
        }
        else if (inputValue.length > 4) {
            inputValue = inputValue.slice(0, 4);
        }
        else if (/[^\d:]/.test(inputValue)) {
            inputValue = inputValue.replace(/[^\d:]/g, '');
        }
        event.target.value = inputValue
        return event
    };

    const handleKeyDown = (event: any) => {//focuses on the input and the press it on key press
        const form = event.target.form;
        const index = [...form].indexOf(event.target);
        if (event.key.toLowerCase() === "enter" || event.key.toLowerCase() === "arrowdown") {
            if (form.elements[index + 1].className !== "") {

                event.preventDefault();
                form.elements[index + 1].focus();
            } else {
                form.elements[index + 2].focus()
                event.preventDefault();
            }
        }
        else if (event.key.toLowerCase() === "arrowup" && index !== 0 && form.elements[index - 1].className !== "") {
            event.preventDefault();
            form.elements[index - 1].focus();
        }
    }

    const handleLimitReached = () => {
        setLimitReached(true)
        setTimeout(() => {
            setLimitReached(false)
        }, 1000)
    }

    return (
        <div className={className || ""}>
            <div className="input-container">
                <div className="input-title" ref={inputRef}>
                    {title?.split("\n").map((text, i) =>
                        <div key={i}>{text}</div>
                    )}
                    {secondaryTitle &&
                        <div className="secondary-title">{secondaryTitle}</div>
                    }
                </div>

                {textarea ?
                    <textarea
                        className={clsx('input-textarea', inputClassName)}
                        placeholder={placeholder}
                        name={name}
                        value={value}
                        onBlur={onBlur}
                        onChange={handleChange}
                        disabled={disabled}
                        maxLength={characterLimit}
                    /> :
                    <input
                        onKeyDown={handleKeyDown}
                        type={type}
                        name={name}
                        className={clsx('input', inputClassName)}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onChange={props.value !== undefined ? handleChange : undefined}
                        value={props.value !== undefined ? value : undefined} // this is for supporting uncontrolled inputs
                        disabled={disabled}
                        autoComplete={autoComplete}
                        maxLength={characterLimit}
                        inputMode={inputMode}
                    />
                }
                <div className="bottom-text-container">
                    <div className='input-error'>
                        {error}
                    </div>

                    {(characterLimit && !hideCharacterLimit) &&
                        <div className={clsx('input-character-limit', { 'limit-reached': limitReached })}>
                            {(typeof value === "string" && value.length) || 0}/{characterLimit}
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default GenericInput;