import { useContext, useEffect, useRef, useState } from "react";
import { Area } from "react-easy-crop";
import { AddEntranceTicketsFields, CONTINUE, PHOTO_OR_GRAPHICS_OF_PLACE } from "../../../consts/hebrew";
import { EntranceTicketsContextInterface } from "../../../consts/interfaces/AddEntranceTicketsContext.interface";
import { AgeRangeArrayItem } from "../../../consts/interfaces/AgeRange.interface";
import { CategoriesArray } from "../../../consts/interfaces/Categories.interface";
import { PopulationArray } from "../../../consts/interfaces/PopulationArray.interface";
import { _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import { AddEntranceTicketsContext } from "../../../context/AddEntranceTicketsContext";
import { fileValidation, longTextValidation, selectAtLeastOneValidator } from "../../../functions/validations";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import GenericOvalClick from "../../../generic-components/GenericOvalClick";
import AgeRangeCheckboxes from "../../../generic-components/AgeRangeCheckboxes";
import CategoryInput from "../../../generic-components/CategoryInput";
import UploadEventImage from "../../../generic-components/UploadEventImage";

const AddEntranceTicketsFirst = () => {

    const { filesUploaderCx, extraInfoCx, ageRangesCx, categoriesCx, populationsCx, imageCoordinatesCx, filesManager, changeCoverImage, deleteCoverImage, saveFirstPageDetails, gotoNextPage } = useContext(AddEntranceTicketsContext) as EntranceTicketsContextInterface;
    const firstInputRef = useRef<HTMLInputElement>(null);

    const [extraInfo, setExtraInfo] = useState<string>(extraInfoCx)
    const [ageRanges, setAgeRanges] = useState<AgeRangeArrayItem[]>([]);
    const [categories, setCategories] = useState<CategoriesArray[]>([])
    const [populations, setPopulations] = useState<PopulationArray[]>([]);
    const [imageCoordinates, setImageCoordinates] = useState<Area>(imageCoordinatesCx)

    const [extraInfoError, setExtraInfoError] = useState<string>("")
    const [ageRangesError, setAgeRangesError] = useState<string>("")
    const [categoriesError, setCategoriesError] = useState<string>("")
    const [populationsError, setPopulationsError] = useState<string>("")
    const [imageError, setImageError] = useState<string>("")

    useEffect(() => {
        setAgeRanges(ageRangesCx)
    }, [ageRangesCx])

    useEffect(() => {
        setCategories(categoriesCx)
    }, [categoriesCx])

    useEffect(() => {
        setPopulations(populationsCx)
    }, [populationsCx])

    const validateFields = () => {
        const extraInfoVal = longTextValidation(extraInfo);
        setExtraInfoError(extraInfoVal)

        const ageRangeVal = selectAtLeastOneValidator(ageRanges)
        setAgeRangesError(ageRangeVal);

        const categoryVal = selectAtLeastOneValidator(categories);
        setCategoriesError(categoryVal);

        const populVal = selectAtLeastOneValidator(populations);
        setPopulationsError(populVal)

        const imageVal = fileValidation(imageError, filesManager?.coverImage?.link);
        setImageError(imageVal)

        return !(extraInfoVal || ageRangeVal || categoryVal || populVal || imageVal)
    }


    const handleContinue = () => {
        if (validateFields()) {
            saveFirstPageDetails({ extraInfo, ageRanges, categories, populations, imageCoordinates })
            gotoNextPage()
        } else {
            firstInputRef.current?.scrollIntoView({ block: "end", behavior: 'smooth' })
        }
    }

    return (
        <div className="add-entrance-tickets">
            <GenericInput
                title={AddEntranceTicketsFields.EXTRA_INFO}
                characterLimit={_LONG_TEXT_MAX_LENGTH}
                value={extraInfo}
                onChange={(e) => setExtraInfo(e.target.value)}
                name="extra-info"
                error={extraInfoError}
                inputRef={firstInputRef}
                textarea
            />

            <AgeRangeCheckboxes ageRange={ageRanges} setAgeRange={setAgeRanges} error={ageRangesError} setAgeRangeError={setAgeRangesError} />

            <CategoryInput categories={categories} setCategories={setCategories} categoryError={categoriesError} type="place-type" setCategoryError={setCategoriesError} />

            <div className="input-container margin-top">
                <div className="input-title">{AddEntranceTicketsFields.SELECT_POPULATIONS}</div>
                <GenericOvalClick options={populations} setOptions={setPopulations} error={populationsError} />
            </div>

            <UploadEventImage
                src={filesManager.coverImage?.link || ""}
                imageCoordinates={imageCoordinates}
                setImageCoordinates={setImageCoordinates}
                fileError={imageError}
                setFileError={setImageError}
                onChange={(value) => changeCoverImage({ ...value, isNew: true })}
                filesUploader={filesUploaderCx}
                deleteCoverImage={deleteCoverImage}
                title={PHOTO_OR_GRAPHICS_OF_PLACE}
            />

            <GenericButton handleClick={handleContinue} className="yellow continue-btn">{CONTINUE}</GenericButton>
        </div>
    )
}

export default AddEntranceTicketsFirst;