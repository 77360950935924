//this component gets props - PopupCategoryProps
//it returns a popup of categories for an event

import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { CategoriesArray, PopupCategoryProps, categoryPopupTitles } from '../../../consts/interfaces/Categories.interface';
import { FORM_FIELDS } from '../../../consts/variables';
import { selectAtLeastOneValidator } from '../../../functions/validations';
import { saveInLocalStorage } from '../../../functions/localStorage';

import GenericButton from '../../../generic-components/GenericButton';
import GenericPopup from '../../../generic-components/GenericPopup';
import CategoriesSelect from './CategoriesSelect';

import '../../../style/searchBarCategories.scss';
import './style/popupCategory.scss';

const PopupCategory: React.FC<PopupCategoryProps> = ({ categories, setCategories, setCategoryError, open, handleClose, saveDraft, type = "category" }) => {

    const [filteredCategories, setFilteredCategories] = useState<CategoriesArray[]>([])

    const [search, setSearch] = useState<string>('');
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    useEffect(() => {
        sortArray();
    }, [categories])

    useEffect(() => { // delay after search
        setSearchLoading(true);
        setTimeout(() => {
            sortArray()
            setSearchLoading(false);
        }, 500)
    }, [search])

    const popupTitle = useMemo(() => categoryPopupTitles.hasOwnProperty(type) ? categoryPopupTitles[type] : '', [type])

    const handleSelectChange = (categoryId: number) => { //addes a v next to a category that has been click
        const foundIndex = categories.findIndex(category => category.id === categoryId)
        setCategories(prevCategories => {
            const newCategories = [...prevCategories];
            newCategories[foundIndex] = { ...newCategories[foundIndex], selected: !newCategories[foundIndex].selected }; // select the new category

            saveDraft && saveInLocalStorage(FORM_FIELDS.CATEGORIES, newCategories)
            const categoryVal = selectAtLeastOneValidator(newCategories);
            categoryVal && setCategoryError(categoryVal)
            return newCategories;

        })
    }

    //on change of search bar input
    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);

    const ignoreWhiteSpaces = (str: string) => (str.replace(/  +/g, ' ')).trim()

    const sortArray = () => { //sorts the array by the text in the input and ignore white spaces
        if (categories.length === 0) return;

        const ignoredCaseText = ignoreWhiteSpaces(search)
        setFilteredCategories(categories.filter((item: CategoriesArray) => item.categoryName.includes(ignoredCaseText)))
    }
    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)

    return (
        <GenericPopup
            open={open}
            className={clsx('category-popup', { "place-type": type === 'place-type' })}
            handleClose={handleClose}
        >
            <>
                <div className='category-container'>
                    <div className='title'>{popupTitle}</div>

                    {type !== "place-type" &&
                        <div className="search-container categories">
                            <label htmlFor='search'>
                                <img className={`search-icon ${(searchLoading) && "rotate"}`} src="/icons/search.png" alt="search" />
                            </label>
                            <input id='search' value={search} className="input-search" onChange={(e) => handleSearchChange(e)} placeholder="חיפוש" />
                        </div>}

                    <CategoriesSelect filteredCategories={filteredCategories} handleChange={handleSelectChange} />
                </div>
                <GenericButton className={`yellow  ${tabletMediaQuery ? "big" : "small"} category-pop-up`} handleClick={handleClose}>אישור</GenericButton>
            </>
        </GenericPopup>
    )
}
export default PopupCategory;