import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";

import { UserContext } from "../../../context/UserContext";
import { SocketContext } from "../../../context/SocketContext";
import TicketOrder from "../../../generic-components/ticket-order/TicketOrder";
import PageNotFound from "../../general/page-not-found/PageNotFound";

import UseParamsInterface from "../../../consts/interfaces/UseParams.interface";
import { EventDetails } from "../../../consts/interfaces/TicketOrder.interface";
import { demoEventData } from "../../../consts/eventDetails";
import PopupEventCanceled from "../../../popups/organization/PopupEventCanceled";

const OrganizationEventPage: React.FC<{ ticketOrderType: 'time' | 'ordered' }> = ({ ticketOrderType }) => {
    const { systemID } = useContext(UserContext);
    const { removeEvent } = useContext(SocketContext)
    const { id } = useParams() as UseParamsInterface;
    const eID = Number(id)
    const [eventInfo, setEventInfo] = useState<EventDetails>({ ...demoEventData })
    const [serverLoading, setServerLoading] = useState<boolean>(false)
    const [pageNotFound, setPageNotFound] = useState<boolean>(false)

    useAsyncEffect(async () => {
        if (!eID || !systemID) return

        setServerLoading(true)
        try {
            const res = (await axios.get(`/api/event/all-event-details?id=${eID}&organizationId=${systemID}`)).data;
            if (res.data === false) return setPageNotFound(true);

            setEventInfo(res)

        } catch (error) {
            setPageNotFound(true);
            throw error;
        }
        setServerLoading(false)
    }, [])

    return (
        (!pageNotFound) ?
            <>
                <TicketOrder type={ticketOrderType} serverLoading={serverLoading} eventInfo={eventInfo} />
                <PopupEventCanceled open={removeEvent === eID} />
            </>
            : <PageNotFound />
    )
}
export default OrganizationEventPage;