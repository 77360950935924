import { SetStateAction } from "react"
import { FOOTER_TOOL_TIP } from "../../consts/hebrew"
import GenericToolTip from "../GenericToolTip"

interface AgencyFooterIconsProps {
    setDeletePopup: React.Dispatch<SetStateAction<boolean>>;
    setDuplicatePopup: React.Dispatch<SetStateAction<boolean>>;
}

const AgencyFooterIcons: React.FC<AgencyFooterIconsProps> = ({ setDeletePopup, setDuplicatePopup }) => {

    return (
        <div className='right-buttons-container'>
            <GenericToolTip
                isDark isSmall
                title={<div>
                    <p style={{ marginTop: '0.8vh', marginBottom: '0.5vh' }}>{FOOTER_TOOL_TIP.DUPLICATE_EVENT}</p>
                    <p style={{ fontSize: '1.8vh', fontWeight: 300, marginTop: '0', marginBottom: '0.8vh' }}>{FOOTER_TOOL_TIP.DUPLICATE_EXPLAIN}</p>
                </div>}>
                <span className='icon-in-footer'>
                    <img src='/icons/duplicate.svg' alt='duplicate' onClick={() => setDuplicatePopup(prev => !prev)} />
                </span>
            </GenericToolTip>
            <GenericToolTip title={FOOTER_TOOL_TIP.DELETE_EVENT} isDark isSmall>
                <span className='icon-in-footer'>
                    <img src='/icons/delete.svg' alt='delete' onClick={() => setDeletePopup(prev => !prev)} />
                </span>
            </GenericToolTip>
        </div>
    )
}

export default AgencyFooterIcons