//this component gets props - seatGroupInputsProps
//it returns seat group inputs(one seat group)

import { FileInput, UploadedFile } from "@hilma/fileshandler-client";

import { PLEASE_UPLOAD_PDF_FILE_UP_TO_3MB, _FILE_LIMIT } from "../../../consts/variables";
import { seatGroupInputsProps } from "../../../consts/interfaces/SeatGroup.interface";
import { LOAD_PDF_FILE, SEAT_GROUP, TICKET_NUMBER } from "../../../consts/hebrew";

import GenericInput from "../../../generic-components/GenericInput";
import SeatgroupAccessibilities from "./SeatgroupAccessibilities";

import "./style/seatGroup.scss";

const SeatGroupInputs: React.FC<seatGroupInputsProps> = (props) => {

    //function that handles the onBlur
    const handleSeatGroupInputsBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.setNumSeatsError(e);
    }

    //function that handles the onChange
    const handleSeatGroupInputsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value.includes('.')) return;
        props.setNumSeats(e);
    }

    //handles the pdf file
    const handleInputChange = (file: UploadedFile) => {
        props.setTicketFilePDF(file); // set the currnet ticketFilePdf to the new file
        props.setFiles(prev => {
            let copyPrev = [...prev];
            if (props.numGroup) copyPrev[props.numGroup - 1] = { fileName: file.fileName, id: file.id, link: file.link, isNew: true } // put the new file in the array in the exact place of the group 
            return copyPrev
        });
    }

    return (
        <div className="multiple-seatgroup-container">
            <div className="seatgroup-number-title">{SEAT_GROUP + ' ' + props.numGroup}</div>
            <div className="seatgroups-inputs">
                <GenericInput
                    disabled={props.disabled}
                    value={props.numSeats === undefined ? "" : props.numSeats}
                    error={props.numSeatsError}
                    name="numSeats"
                    type='number'
                    onChange={handleSeatGroupInputsChange}
                    onBlur={handleSeatGroupInputsBlur}
                    className="seatGroup"
                    placeholder={TICKET_NUMBER}
                />
                <div className="seatgroup-file-container">
                    <div className="file-input-container">
                        <div className="upload-file-btn">
                            {props.ticketFilePDF === "" ?
                                <div className="upload-text-and-logo" >
                                    <img src="/icons/file_upload.svg" alt="upload" />
                                    <div>{LOAD_PDF_FILE}</div>
                                </div> :
                                <div>
                                    {props.ticketFilePDF}
                                </div>
                            }
                        </div>
                        <FileInput
                            disabled={props.disabled}
                            type="file"
                            singleUpload
                            sizeLimit={_FILE_LIMIT}
                            filesUploader={props.filesUploader}
                            onChange={handleInputChange}
                            onError={props.setTicketFilePDFError}
                        />
                    </div>

                    <div className={'first-error' + (props.ticketFilePDFError !== PLEASE_UPLOAD_PDF_FILE_UP_TO_3MB ? ' error' : '')}>
                        {props.ticketFilePDFError}
                    </div>
                </div>
            </div>
            <div className="accessibilities-container">
                <SeatgroupAccessibilities
                    disabled={props.disabled}
                    wheelchairAccessibleCount={props.wheelchairAccessible}
                    mobilityAccessibleCount={props.mobilityDifficultiesAccessible}
                    setAccessibility={(value, accessibility) => props.handleAccessibilityChange(value, accessibility, props.index)}
                    numSeats={Number(props.numSeats)}
                    sumOfAccessTicketsError={props.sumOfAccessTicketsError}
                />
            </div>
        </div >
    )
}

export default SeatGroupInputs;