import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLoginWithCode, useSendCode } from '@hilma/auth';

import { LoginRightProps } from "../../../consts/interfaces/Login.interface";
import { CodeError, CodeMessage } from "../../../consts/hebrew";
import { LoginText } from "../../../consts/enums/login.enum";
import { GoogleAnalyticsContext } from "../../../context/GoogleAnalytics";
import { UserContext } from "../../../context/UserContext";

import { codeValidation } from "../../../functions/validations";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import FocusAble from '../../../generic-components/FocusAble';

const LoginRightSecond: React.FC<LoginRightProps> = ({ phone }) => {

    const [codeError, setCodeError] = useState<string>("");
    const [codeValue, setCodeValue] = useState<string>("");
    const [disabled, setDisabled] = useState(true)
    const { loggingIn } = useContext(UserContext);
    const { gaEvent } = useContext(GoogleAnalyticsContext)

    const history = useHistory();
    const loginWithCode = useLoginWithCode();

    const phoneNum = useMemo(() => {
        const last4digits = phone?.slice(-4);
        return `${LoginText.code_sent_to} ${last4digits}`
    }, [phone]);

    //function that checks if all the input is valid and if it is moves on to the next page
    const handleClick = async () => {
        const codeVal = codeValidation(codeValue);
        if (!codeVal) {
            const res = await loginWithCode('/api/contact/login-with-code', codeValue);
            if (res.success) {
                const { user } = res
                const { id, name, email, username, roles, agencyId, organizationId } = user
                switch (roles[0]) {
                    case "organization":
                        loggingIn(id, name, roles[0], organizationId, email, username)
                        break;
                    case "agency":
                        loggingIn(id, name, roles[0], agencyId, email, username)
                        break;
                    default:
                        throw new Error("unknown role");
                }
                gaEvent('login')
                history.push("/");
            } else {
                const errorMessage = res.msg?.data?.message
                switch (errorMessage) {
                    case (CodeError.BLOCKED_CODE):
                        setCodeError(CodeMessage.BLOCKED_CODE);
                        break;
                    case CodeError.EXPIRED_CODE:
                        setCodeError(CodeMessage.EXPIRED_CODE);
                        break;
                    case CodeError.INCORRECT_CODE:
                        setCodeError(CodeMessage.INCORRECT_CODE);
                        break;
                    case CodeError.MAX_ATTEMPTS_CODE:
                        setCodeError(CodeMessage.MAX_ATTEMPTS_CODE);
                        break;
                    default:
                        setCodeError(CodeMessage.GENERAL_MESSAGE);
                        break;
                }
            }
        } else {
            setCodeError(codeVal);
        }
    }

    //function that makes the continue button diabled intil after the animation between the two pages
    useEffect(() => {
        setTimeout(() => {
            setDisabled(false)
        }, 3000)
    }, [])

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value.length === 7 || isNaN(Number(e.target.value))) return // if the input is 6 digits or not a number dont change the value
        setCodeValue(e.target.value);
    }

    const sendCodeA = useSendCode();

    //function that sends  new code if the user didnt get a code or the code expired
    const sendCodeAgain = async () => {
        try {
            const res = await sendCodeA('/api/contact/send-code', {
                username: phone,
            });

            if (!res.success) {
                if (res.msg.data.key === "MaxAttempts" || res.msg.data.key === "UserIsBlocked") {
                    setCodeError(LoginText.try_again_later)
                } else {
                    setCodeError(LoginText.was_error)
                }
            }
        }
        catch (error) {
            throw error
        }
    }

    return (
        <form>
            <div className="login-right-text">
                {phoneNum}
            </div>
            <GenericInput
                onChange={handleInputChange}
                placeholder={LoginText.enter_code}
                name="code"
                error={codeError}
                value={codeValue}
                type="number"
                inputMode="numeric"
                autoComplete="one-time-code"
                className="login-right"
            />
            <FocusAble className="didnt-get-code" onClick={sendCodeAgain}>
                {LoginText.didnt_get_code}
            </FocusAble>
            <GenericButton
                disabled={disabled}
                className="login-right-button cyan big"
                handleClick={handleClick}>
                {LoginText.login}
            </GenericButton>
        </form>
    )
}

export default LoginRightSecond;

