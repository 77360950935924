import clsx from "clsx";

import { EMPTY_TICKET, NOT_INTERESTED_MAYBE_NEXT_TIME } from "../../consts/hebrew";
import useImageCoordinates from "../../consts/hooks/useImageCoordinates";
import { EventSwiperElementCoverProps, EventSwiperElementType } from "../../consts/interfaces/SwipeBoard.interface";
import { eventToDismantledDate } from "../../functions/dateFunctions";

import EventDropDown from "../EventDropDown";
import YellowBanner from "../YellowBanner";

import '../../style/vipBanner.scss';
import './style/eventWebSlide.scss';

export interface EventSwiperElementProps {
    isAgency?: boolean,
    element: EventSwiperElementType,
    eventId: number;
    cover?: React.FC<EventSwiperElementCoverProps>;
    navigateToEventPage: (eventId: number) => void;
}
const EventSwiperElement: React.FC<EventSwiperElementProps> = ({ eventId, isAgency, element, cover: Cover, navigateToEventPage }) => {
    const { date, time, day } = eventToDismantledDate(element.date)
    const imageCoordinatesStyle = useImageCoordinates(element.imageCoordinates)
    const isPossibleToEdit = element.ticketsPurchased === 0

    return (
        <div className="slider-element" >
            <div className="yellow-banner-container">
                {isAgency ?
                    <YellowBanner border tickets={element.ticketsPurchased || 0} /> : ""
                }
            </div>

            <div className="slide-content">
                <div className='swiper-pics' onClick={() => navigateToEventPage(eventId)}>
                    <div className='cutter'>
                        <img src={element.coverImage} alt={element.eventName} style={imageCoordinatesStyle} />
                    </div>
                    {element.vipDetails !== NOT_INTERESTED_MAYBE_NEXT_TIME ?
                        <img className={clsx("vip-banner", { "vip-banner-agency": isAgency })} src='/icons/vip-banner-new.svg' alt="vip-banner" /> : null}

                    {Cover ?
                        <div className="slide-cover">
                            <Cover {...element} />
                        </div>
                        : null}
                </div>

                <div className="events-slide-text">
                    <span className="events-slide-item-right">
                        <span className="event-name">
                            {element.eventName}
                        </span>
                        <span>
                            {`${day} | ${date} | ${time}`}
                        </span>
                    </span>

                    {element.ticketsNum ?
                        <span className="ticket-num-desc">
                            <img src="/icons/ticket.svg" alt="tickets" />
                            {element.ticketsNum} {EMPTY_TICKET}
                        </span> : null}
                    {isAgency && <EventDropDown eventId={eventId} ableToEdit={isPossibleToEdit} />}
                </div>
            </div>
        </div>
    )
}

export default EventSwiperElement;