//Feedback given to the agency about an event - here we save the event data with the feedback details

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Skeleton, useMediaQuery } from "@mui/material";
import { useAsyncEffect } from '@hilma/tools';

import { FeedbackInfo } from '../../../consts/interfaces/Feedback.interface';
import UseParamsInterface from '../../../consts/interfaces/UseParams.interface';
import { _ERROR_MESSAGE } from '../../../consts/variables';
import { TITLES } from '../../../consts/hebrew';

import { counterProvider } from '../../../context/CountMessagesContext';
import { SocketContext } from '../../../context/SocketContext';
import { ErrorContext } from '../../../context/ErrorContext';
import GenericGoBack from '../../../generic-components/GenericGoBack';
import GenericPopup from '../../../generic-components/GenericPopup';
import InfoBar from '../../../generic-components/info-bar/InfoBar';
import PageNotFound from '../../general/page-not-found/PageNotFound';
import ThankYouRowDesktop from '../../../generic-components/ThankYouRowDesktop';

import './style/agencyFeedback.scss';

const FeedbackAgency = () => {
    document.title = 'משוב';
    const { showError } = useContext(ErrorContext)

    const { readFeedback } = useContext(counterProvider)
    const { feedbackSent } = useContext(SocketContext)

    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [imageDisplay, setImageDisplay] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notAnEvent, setNotAnEvent] = useState<boolean>(false);

    const isPhone = useMediaQuery('(max-width: 768px)');
    const [feedbackDetails, setFeedbackDetails] = useState<FeedbackInfo>({
        eventName: "",
        day: "",
        date: "",
        time: "",
        feedbacks: [{ feedbackText: "", profilePhoto: "", organizationName: "", images: [] }],
    })


    const { id } = useParams() as UseParamsInterface;
    const eID = Number(id);

    //given the number of the day in the week and getting back a string with the name of the day in Hebrew
    const dayPerNum = (dayNum: number) => {
        let week = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"]
        return week[dayNum]
    }

    //when feedback is sent it updates the data
    useEffect(() => {
        if (feedbackSent?.eventId === eID) {
            setFeedbackDetails(prev => ({ ...prev, feedbacks: [...prev.feedbacks, feedbackSent] }))
        }
    }, [feedbackSent, eID])


    //if the event id (from params) is changed it will give us the feedbacks of that event if he has access to them
    useAsyncEffect(async () => {
        try {
            //first we request the events feedbacks
            const event = (await axios.get(`/api/event/get-event-feedbacks?eventId=${eID}`)).data;
            //checks to see if there r any feedbacks
            if (event.event.length === 0) setNotAnEvent(true)
            //read flag is for the icon that shows if message has been seen or not updates the server too
            const readFlag = event.feedbacksForEvent.every((feedback: any) => feedback.read)
            if (!readFlag) {
                await axios.post('/api/feedback/read-feedback', { eventId: eID })
                readFeedback(event.feedbacksForEvent.length)
            }
            const date = new Date(event.event[0].date);
            let newDate = JSON.stringify(date.getDate());
            let month = JSON.stringify(date.getMonth() + 1);
            const year = date.getFullYear();
            if (date.getDate() < 10) {
                newDate = '0' + date.getDate();
            }
            if (date.getMonth() < 10) {
                month = '0' + (date.getMonth() + 1);
            }
            const minutesTime = () => (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();
            const hoursTime = () => (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();
            const day = dayPerNum(date.getDay());

            setFeedbackDetails({
                eventName: event.event[0].eventName,
                day: day,
                date: newDate + "." + month + "." + year,
                time: hoursTime() + ":" + minutesTime(),
                feedbacks: event.feedbacksForEvent

            })

            setIsLoading(false);
        } catch (error) {
            showError(_ERROR_MESSAGE)
            console.error(error)
        }

    }, [eID])

    //when image is pressed it opens a popup with the image
    const handleImageClick = (imagePath: string) => {
        setPopupOpen(true);
        setImageDisplay(imagePath)
    }

    const newdate = `${feedbackDetails.day} | ${feedbackDetails.date} | ${feedbackDetails.time}`;

    return (
        <>
            {!notAnEvent ?
                <div>
                    {!isLoading ?
                        <div className="feedbacks-agency-page">
                            <GenericGoBack text={TITLES.BACK_TO_EVENT_ARCHIVE} goTo="/archive" />

                            <div style={{ direction: 'rtl' }}>
                                <div className="container-title">
                                    <span className="artist"> {feedbackDetails.eventName} </span>
                                    <br />
                                    <span className="date">{isLoading ? "" : newdate}</span>
                                </div>
                            </div>
                            <InfoBar barType='feedback' eventId={eID} />

                            <table className='feedbacks-container'>
                                <tbody>
                                    {feedbackDetails.feedbacks.map((item) => {
                                        return (
                                            <React.Fragment key={item.feedbackId}>
                                                < GenericPopup
                                                    open={popupOpen}
                                                    handleClose={() => setPopupOpen(false)}
                                                    image={imageDisplay}
                                                    isFeedback={true}
                                                />

                                                {item.feedbackText &&
                                                    <ThankYouRowDesktop
                                                        feedback={{
                                                            id: item.feedbackId,
                                                            organization: { organizationName: item.organizationName, profilePhoto: item.profilePhoto, imageCoordinates: item.imageCoordinates }
                                                        }}>
                                                        <div>{item.feedbackText}</div>
                                                    </ThankYouRowDesktop >}

                                                {item.images.length > 0 &&
                                                    item.images.map((image, index) => {
                                                        return (
                                                            <ThankYouRowDesktop
                                                                key={index}
                                                                feedback={{
                                                                    id: item.feedbackId,
                                                                    organization: { organizationName: item.organizationName, profilePhoto: item.profilePhoto, imageCoordinates: item.imageCoordinates }
                                                                }}
                                                                isSameOrganization
                                                            >
                                                                <img className="img-in-bubble" src={image} alt='feedback' onClick={() => handleImageClick(image)} />
                                                            </ThankYouRowDesktop>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="loading" style={{ direction: 'rtl' }} >
                            <Skeleton variant="rectangular" height={"7vh"} width={(isPhone) ? "50vw" : "18vw"} sx={(isPhone) ? { mt: "3vh", mr: "10vw" } : { mt: '7vh', mr: '16vh', mb: '2vh' }} />
                            <Skeleton variant="text" height={"17vh"} width={"100vw"} />
                            <div className="loading-chat-row " style={{ marginTop: '7vh', marginRight: "4vw", display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                                <Skeleton className='circle' height={(isPhone) ? "10vw" : "5.3vw"} width={(isPhone) ? "10vw" : "5.3vw"} variant="circular" />
                                <div style={{ display: "flex" }}>
                                    <Skeleton className="triangle-skeleton" variant="rectangular" sx={{ backgroundColor: "unset" }} height={(isPhone) ? "3vw" : "5vw"} width={(isPhone) ? "3vw" : "2vw"} />
                                    <Skeleton className="big-message" variant="rectangular" height={(isPhone) ? "8vh" : "10vh"} width={(isPhone) ? "30vw" : "20vw"} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                :
                <PageNotFound />
            }
        </>
    )
}

export default FeedbackAgency;