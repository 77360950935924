import { useContext, useEffect } from "react";
import { clearFormDataStorage } from "../../functions/localStorage";
import { UserContext } from "../../context/UserContext";

function useDeleteExpiredFormDraft() {
    const { systemID } = useContext(UserContext)
    const savedAgencyId = localStorage.getItem("agencyId")
    useEffect(() => {
        if (systemID !== 0) {
            if (savedAgencyId && JSON.parse(savedAgencyId) !== systemID) {
                clearFormDataStorage()
            }
            const lastEdited = localStorage.getItem("lastEdited")
            if (!lastEdited) return;

            const lastEditedDate = new Date(JSON.parse(lastEdited))
            const expirationDate = new Date(lastEditedDate);
            expirationDate.setDate(lastEditedDate.getDate() + 2)

            const currentDate = new Date();
            if (currentDate.getTime() >= expirationDate.getTime())
                clearFormDataStorage()
        }
    }, [systemID, savedAgencyId])
}

export default useDeleteExpiredFormDraft;