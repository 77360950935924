import React from "react"

import AgencyTypeInput from "../../../generic-components/AgencyTypeInput"
import ProfileContact from "../../../generic-components/ProfileContact"
import GenericProfilePicInput from "../../../generic-components/GenericProfilePicInput"
import GenericCheckbox from "../../../generic-components/GenericCheckbox"
import RadioButtons from "../../../generic-components/RadioButtons"

import { AGENCY_LOGO, CHOOSE_AT_LEAST_ONE, DO_YOUR_EVENTS_TAKE_PLACE_IN_PERMANENT_PLACE, EXPERIENCE_TYPE, IN_NUMBER_ENTER_YOU_CONFIRM_RECEIVING_ADVERTISEMENT_AND_UPDATE_MESSAGES } from "../../../consts/hebrew"
import { FILL_THIS_FIELD } from "../../../consts/variables"
import { experienceTypeOptions, radioOptions } from "../../../consts/arrays"
import { ProfileTabProps } from "../../../consts/interfaces/AgencyProfile.interface"

import { useAgencyContext } from "../../../context/AgencyContext"
import { useConfig } from "../../../context/ConfigContext"

const ProfileTab: React.FC<ProfileTabProps> = (props) => {
    const { featureFlags } = useConfig();
    const isBenefitsFeatureOn = featureFlags["update_agency_profile"]

    const { buttonClicked, imageError, agencyTypeError, agencyNameError, experienceTypeError, filesUploader, setFilesManager, handleInputBlur, handleInputChange, setAgencyNameError } = props
    const { imageLink, agencyType, agencyName, imageCoordinates, contacts, experienceType, radioValue, setImageLink, setAgencyType, setAgencyName, setImageCoordinates, setExperienceType, setRadioValue } = useAgencyContext();

    // const addContact = () => {
    //     if (contacts.length < 4)
    //         setContacts(prev => [...prev, EMPTY_CONTACT()])
    // }

    // const contactTrash = (index: number) => {
    //     if (!contacts) return;
    //     if (index === 0 && contacts?.length === 1) {
    //         setContacts([EMPTY_CONTACT()])
    //     } else {
    //         setContacts(prev => prev.filter((_, ind) => ind !== index))
    //     }
    // }

    const toggleBit = (value: number) => {
        setExperienceType(prev => prev ^ (2 ** value))//if bitIndex is saved as 0,1,2,3
        // setExperienceType(prev => prev ^ value)//if bitIndex is saved as 1,2,4,8
    }

    const getBit = (value: number) => {
        return !!(experienceType & (2 ** value))//if bitIndex is saved as 0,1,2,3
        // return !!(experienceType & value)//if bitIndex is saved as 1,2,4,8
    }

    return (
        <div className="profile">
            <GenericProfilePicInput
                title={AGENCY_LOGO}
                imageCoordinates={imageCoordinates}
                setImageCoordinates={setImageCoordinates}
                filesUploader={filesUploader}
                imageLink={imageLink}
                setImageLink={setImageLink}
                imageError={imageError}
                onFileIdChange={(id) => setFilesManager(prev => ({ ...prev, profilePhoto: id }))}
            />
            <AgencyTypeInput
                agencyType={agencyType}
                agencyName={agencyName}
                setAgencyType={setAgencyType}
                setAgencyName={setAgencyName}
                setAgencyNameError={setAgencyNameError}
                agencyTypeError={agencyTypeError}
                agencyNameError={agencyNameError}
            />
            {contacts?.map((contact, index) => (
                <div key={index}>
                    {/* {contact.name !== "" && ( */}
                    {/* <GenericTrashCan onClick={() => contactTrash(index)} /> */}
                    {/* )} */}
                    <ProfileContact
                        secondaryTitle={IN_NUMBER_ENTER_YOU_CONFIRM_RECEIVING_ADVERTISEMENT_AND_UPDATE_MESSAGES}
                        {...contact}
                        {...contacts}
                        // disabled={!(contact.premissionToEdit || contact.name === name)}   //-> able to edit other people
                        // disabled={index < contacts.length && index !== 0 ? true : false} //-> commented so it can allow change after errors
                        // phoneDisabled={index < organizationInfo.length ? true : false}
                        setName={(e) => handleInputChange(e, index)}
                        setPhone={(e) => handleInputChange(e, index)}
                        setEmail={(e) => handleInputChange(e, index)}
                        setNameError={(e) => handleInputBlur(e, index)}
                        setPhoneError={(e) => handleInputBlur(e, index)}
                        setEmailError={(e) => handleInputBlur(e, index)}
                    />
                    {/* {index === contacts.length - 1 && (
                        <GenericButton disabled={contacts.length > 3} className="right-blue-btn blue" handleClick={addContact}>
                            {ADD_CONTACT} +
                        </GenericButton>
                    )} */}
                    {index !== contacts.length - 1 && (<div style={{ height: "4vh" }}></div>)}
                </div>
            ))}
            {isBenefitsFeatureOn &&
                <div className="input-container">
                    <div className="input-title" children={`${EXPERIENCE_TYPE}:`} />
                    <div className="secondary-title margin-bottom" children={CHOOSE_AT_LEAST_ONE} />
                    {experienceTypeOptions.map(option => (
                        <GenericCheckbox
                            key={option.name}
                            checked={getBit(option.bitIndex)}
                            id={option.name}
                            title={option.label}
                            handleChange={() => toggleBit(option.bitIndex)}
                        />
                    ))}
                    <div className="bottom-text-container">
                        <div className='input-error' children={experienceTypeError} />
                    </div>
                </div>}
            <div>
                <div className="input-title">{DO_YOUR_EVENTS_TAKE_PLACE_IN_PERMANENT_PLACE}?</div>
                <RadioButtons
                    value={radioValue}
                    handleChange={(e: React.FormEvent<HTMLInputElement>) => setRadioValue(e.currentTarget.value)}
                    options={radioOptions}
                />
                <div className="agency-sign-up-errors">
                    {(!radioValue && buttonClicked) ? FILL_THIS_FIELD : ""}
                </div>
            </div>
        </div>
    )
}

export default ProfileTab