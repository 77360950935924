import { Area } from "react-easy-crop"
import moment, { Moment } from "moment"

import { getFromLocalStorage } from "./localStorage"
import { COUPONS, FORM_FIELDS, TICKETS_METHOD } from "../consts/variables"
import { AgencyContact } from "../consts/interfaces/AddNewEventContext.interface"
import { CategoriesArray } from "../consts/interfaces/Categories.interface"
import { PopulationArray } from "../consts/interfaces/PopulationArray.interface"
import { AgeRangeArrayItem } from "../consts/interfaces/AgeRange.interface"
import { Coupons } from "../consts/interfaces/TicketsCouponMethod.interface"
import SeatGroup from "../consts/interfaces/SeatGroup.interface"
import { EventDetails } from "../consts/interfaces/TicketOrder.interface"

export interface eventDetailsFromCx {
    eventName: string;
    duration: string;
    description: string;
    date: Moment | null;
    time: Moment | string | null
    categories: CategoriesArray[];
    populations: PopulationArray[];
    ageRange: AgeRangeArrayItem[];
    imageCoordinates: Area;
    coverImage: string | undefined;
    location: string;
    navigation: string;
    agencyContacts: AgencyContact[];
    currentContact: number;
    isSeating: boolean | undefined;
    ticketCount: number | undefined;
    ticketMethod: string;
    seatGroups: SeatGroup[];
    hearingAids: number;
    coupons: Coupons[];
    isMarkedSeats: boolean | undefined;
    vip: string;
    posterImage?: string
}

export const getEventDetailsFromLocalStorage = () => {
    const eventName = getFromLocalStorage(FORM_FIELDS.NAME) || ""
    const duration = getFromLocalStorage(FORM_FIELDS.DURATION) || ""
    const description = getFromLocalStorage(FORM_FIELDS.DESCRIPTION) || ""

    const categories = getFromLocalStorage(FORM_FIELDS.CATEGORIES)
    const populations = getFromLocalStorage(FORM_FIELDS.POPULATION)
    const ageRange = getFromLocalStorage(FORM_FIELDS.AGE_RANGE)
    const imageCoordinates = getFromLocalStorage(FORM_FIELDS.IMAGE_COORDINATES)
    const poster = getFromLocalStorage(FORM_FIELDS.POSTER)

    const dateString = getFromLocalStorage(FORM_FIELDS.DATE);
    const dateObj = dateString && new Date(dateString)
    const date = moment(dateObj)

    const timeParsed = getFromLocalStorage(FORM_FIELDS.TIME)
    const timeObj = timeParsed ? new Date(timeParsed) : null
    const time = moment(timeObj)

    const coverImage: { fileName: string, type: string, base64: string } | null = getFromLocalStorage(FORM_FIELDS.IMAGE);

    const location = getFromLocalStorage(FORM_FIELDS.ADDRESS);
    const navigation = getFromLocalStorage(FORM_FIELDS.NAVIGATION) || "";
    const selectedHallIndex = getFromLocalStorage(FORM_FIELDS.HALL_INDEX) || 0
    const hallName = getFromLocalStorage(FORM_FIELDS.HALL_NAME) || ''
    const currentContact = getFromLocalStorage(FORM_FIELDS.CURRENT_CONTACT) || 0
    const agencyContacts = getFromLocalStorage(FORM_FIELDS.AGENCY_CONTACTS)

    const isSeating = getFromLocalStorage(FORM_FIELDS.IS_SEATING);
    const ticketCount = Number(getFromLocalStorage(FORM_FIELDS.TICKETS_COUNT)) || undefined;
    const ticketPrice = Number(getFromLocalStorage(FORM_FIELDS.TICKET_PRICE)) || undefined;
    const areAdjacent = getFromLocalStorage(FORM_FIELDS.ARE_ADJACENT);
    const ticketMethod = getFromLocalStorage(FORM_FIELDS.TICKET_METHOD) || "";
    const coupons = getFromLocalStorage(FORM_FIELDS.COUPONS) ? getFromLocalStorage(FORM_FIELDS.COUPONS) : [{ ...COUPONS }];
    const webLink = getFromLocalStorage(FORM_FIELDS.WEB_LINK);
    const seatGroups = getFromLocalStorage(FORM_FIELDS.SEAT_GROUPS);
    const hearingAids = getFromLocalStorage(FORM_FIELDS.HEARING_AIDS) || 0;
    const isMarkedSeats = getFromLocalStorage(FORM_FIELDS.MARKED_SEATS);

    const pdfs: { fileName: string, type: string, base64: string }[] | null = getFromLocalStorage(FORM_FIELDS.PFDS)

    const vip = getFromLocalStorage(FORM_FIELDS.VIP);

    return {
        eventName, duration, description, categories, populations, ageRange, imageCoordinates, poster, date, time, coverImage,
        location, navigation, selectedHallIndex, hallName, currentContact, agencyContacts,
        isSeating, ticketCount, ticketPrice, areAdjacent, ticketMethod, coupons, webLink, seatGroups, hearingAids, isMarkedSeats, pdfs,
        vip
    }
}

export const infoBarPreview = () => {
    const location = getFromLocalStorage(FORM_FIELDS.ADDRESS);
    const ticketMethod = getFromLocalStorage(FORM_FIELDS.TICKET_METHOD) || "";
    const seatGroups = getFromLocalStorage(FORM_FIELDS.SEAT_GROUPS);
    const coupons = getFromLocalStorage(FORM_FIELDS.COUPONS) ? getFromLocalStorage(FORM_FIELDS.COUPONS) : [{ ...COUPONS }];
    const ticketCount = Number(getFromLocalStorage(FORM_FIELDS.TICKETS_COUNT)) || undefined;

    const seatGroupsCount = ticketMethod === TICKETS_METHOD.PDF ? seatGroups.length : coupons.length

    return { location, seatGroupsCount, ticketCount }
}

export const getDetailsForPreview = (eventDetailsCx: eventDetailsFromCx) => {
    const { eventName, duration, description, date, time, categories, populations, ageRange, imageCoordinates, coverImage,
        location, navigation, agencyContacts, currentContact,
        isSeating, ticketCount, ticketMethod, seatGroups, hearingAids, coupons, isMarkedSeats,
        vip, posterImage } = eventDetailsCx

    const filteredCategories: CategoriesArray[] = categories.filter((category: CategoriesArray) => category.selected)
    const modifiedCategories = filteredCategories.map((category) => { return { id: category.id, categoryName: category.categoryName } })

    const filteredPopulations: PopulationArray[] = populations.filter((population: PopulationArray) => population.selected)
    const modifiedPopulations = filteredPopulations.map((population) => { return { id: population.id, populationName: population.title } })

    const filteredAgeRange: AgeRangeArrayItem[] = ageRange.filter((ageRange: AgeRangeArrayItem) => ageRange.selected)
    const modifiedAgeRange = filteredAgeRange.map((ageRange) => { return { id: ageRange.id, rangeName: ageRange.rangeName } })

    const modifiedSeatGroups =
        ticketMethod === TICKETS_METHOD.PDF ?
            seatGroups.map((seatGroup: SeatGroup, index: number) => {
                return {
                    filePath: seatGroup.ticketFilePDF,
                    id: index,
                    seatNum: seatGroup.numSeats || ticketCount || 0,
                    coupon: '0',
                    accessibilities: seatGroupAccessibility(seatGroup),
                    usedSeats: 0,
                    tickets: []
                }
            })
            :
            coupons.map((coupon: Coupons, index: number) => {
                return {
                    filePath: '',
                    id: index,
                    seatNum: parseInt(coupon.numSeats),
                    coupon: coupon.code,
                    accessibilities: [],
                    usedSeats: 0,
                    tickets: []
                }
            })

    const eventAccessibilities = ticketMethod === TICKETS_METHOD.PDF ?
        getEventAccessibilities(seatGroups) : []

    const previewDetails: EventDetails = {
        duration: modifyDuration(duration),
        eventType: ticketMethod === TICKETS_METHOD.COUPON ? "coupon" : "oneGroup",
        coverImage: coverImage || '',
        date: modifyDate(`${date}`, `${time}`),
        posterImage: posterImage,
        contactName: agencyContacts.length !== 0 ? agencyContacts[currentContact].name : '',
        contactPhone: agencyContacts.length !== 0 ? agencyContacts[currentContact].username : '',
        categories: modifiedCategories,
        populations: modifiedPopulations,
        ageRange: modifiedAgeRange,
        description: description,
        eventName: eventName,
        id: 0,
        location: location,
        navigation: navigation,
        isSeating: isSeating || false,
        isMarkedSeats: isMarkedSeats || false,
        seatGroups: modifiedSeatGroups,
        tickets: [],
        hall: {
            id: 0,
            hallName: '',
        },
        imageCoordinates: imageCoordinates,
        accessibilities: eventAccessibilities,
        hearingAid: hearingAids,
        vipDetails: vip,
        purchasedDetails: []
    }
    return previewDetails
}


const modifyDuration = (duration: string) => {
    const time = duration.split(':')
    return parseInt(time[0]) * 60 + parseInt(time[1])
}

const modifyDate = (date: string, time: string) => {
    const fixDate = new Date(date)
    const startHour = new Date(time)
    fixDate.setHours(startHour.getHours())
    fixDate.setMinutes(startHour.getMinutes())
    return `${fixDate}`
}

const getAccessibilityInfo = (accessibility: 'mobility' | 'wheelchair') => {
    switch (accessibility) {
        case "wheelchair":
            return {
                id: 1,
                info: 'כיסאות גלגלים',
                icon: '/icons/accessibility/wheelchair-accessibility.svg'
            }
        case "mobility":
            return {
                id: 3,
                info: 'קשיי ניידות',
                icon: '/icons/accessibility/mobility-accessibility.svg'
            }
    }
}

const seatGroupAccessibility = (seatGroup: SeatGroup) => {
    const { mobilityDifficultiesAccessible, wheelchairAccessible } = seatGroup
    return getAccessibilitiesArray(mobilityDifficultiesAccessible, wheelchairAccessible)
}

const getAccessibilitiesArray = (mobility: number, wheelchair: number) => {
    const accessibilities = []
    if (wheelchair !== 0) {
        accessibilities.push({
            count: wheelchair,
            accessibility: getAccessibilityInfo('wheelchair')
        })
    }
    if (mobility !== 0) {
        accessibilities.push({
            count: mobility,
            accessibility: getAccessibilityInfo("mobility")
        })
    }
    return accessibilities
}

const getEventAccessibilities = (searGroups: SeatGroup[]) => {
    const wheelChairSum = searGroups.reduce((sum, searGroup) => sum + searGroup.wheelchairAccessible, 0);
    const mobilitySum = searGroups.reduce((sum, searGroup) => sum + searGroup.mobilityDifficultiesAccessible, 0);
    return getAccessibilitiesArray(mobilitySum, wheelChairSum)
}