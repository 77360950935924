import React, { FC, useContext, useEffect, useState } from "react";
import { FileInput, MultipleFilesInput, UploadedFile, UploadError } from "@hilma/fileshandler-client";

import { FORM_FIELDS, INVALID_FILE_TYPE, PLEASE_UPLOAD_PDF_FILE, _FILE_LIMIT, _FILE_TOO_BIG, _SEAT_GROUP } from "../../../consts/variables";
import { ADD_SEAT_GROUP, LOAD_MULTIPLE_FILES, LOAD_PDF_FILE, PDF_FILE_WITH_THE_TICKETS } from "../../../consts/hebrew";
import { ANEContextInterface } from "../../../consts/interfaces/AddNewEventContext.interface";
import { TicketsPDFMethodProps } from "../../../consts/interfaces/TicketsPDFMethod.interface";
import { saveInLocalStorage } from "../../../functions/localStorage";
import SeatGroup from "../../../consts/interfaces/SeatGroup.interface";

import GenericTrashCan from "../../../generic-components/GenericTrashCan";
import { AddNewEventContext } from "../../../context/AddNewEventContext";
import GenericButton from "../../../generic-components/GenericButton";
import SeatgroupAccessibilities from "./SeatgroupAccessibilities";
import SeatGroupInputs from "./SeatGroupInputs";

const _MAX_SEATGROUPS_AMOUNT = 100;

const TicketsPDFMethod: FC<TicketsPDFMethodProps> = ({
    isTicketMethodPDF,
    isSeating,
    seatGroups,
    files,
    fileError,
    ticketsNum,
    seatGroupsTicketsSumError,
    disabled,
    setSeatGroups,
    setFiles,
    setFileError,
    handleInputChange,
    handleInputBlur,
    saveDraft,
}) => {

    const { filesUploaderCx } = useContext(AddNewEventContext) as ANEContextInterface;

    const [isAddSeatgroupDisabled, setIsAddSeatgroupDisabled] = useState<boolean>(false)
    const [multipleFilesError, setMultipleFilesError] = useState<string>("")

    const handleSingleSeatgroupFileChange = (value: UploadedFile) => { // function that handle the file uploader
        !value.type.includes('pdf') ? setFileError(PLEASE_UPLOAD_PDF_FILE) : setFileError("");
        const newSeatGroups = [{ ...seatGroups[0], ticketFilePDF: value.fileName }]
        setSeatGroups(newSeatGroups)
        saveInLocalStorage(FORM_FIELDS.SEAT_GROUPS, newSeatGroups);

        setFiles([{ fileName: value.fileName, link: value.link, id: value.id, isNew: true }]);
    };

    const onMultipleFilesChange = (values: UploadedFile[]) => {
        multipleFilesError && setMultipleFilesError("");

        const emptySeatGroups: SeatGroup[] = Array(values.length).fill({ ..._SEAT_GROUP })
        if (emptySeatGroups.length === 1) {
            emptySeatGroups.push({ ..._SEAT_GROUP })
        }

        const newSeatGroups = emptySeatGroups.map((sg, ind) => {
            const file = values[ind]
            return ({
                ...sg,
                ticketFilePDF: file.fileName,
                ticketFilePDFError: file.type.includes('pdf') ? "" : PLEASE_UPLOAD_PDF_FILE,
                numSeats: 1
            })
        })

        setSeatGroups(newSeatGroups)
        saveInLocalStorage(FORM_FIELDS.SEAT_GROUPS, newSeatGroups);
        setFiles(values.map(file => ({ ...file, isNew: true })))
    }

    const ticketPDFHandle = (file: UploadedFile, index: number) => {
        const newSeatGroups = [...seatGroups];
        newSeatGroups[index].ticketFilePDF = file.fileName;

        if (!file.type.includes('pdf')) {
            newSeatGroups[index].ticketFilePDFError = PLEASE_UPLOAD_PDF_FILE;
        } else {
            newSeatGroups[index].ticketFilePDFError = '';
        }

        setSeatGroups(newSeatGroups);
        saveInLocalStorage(FORM_FIELDS.SEAT_GROUPS, newSeatGroups);
    }

    const SeatGroupsTrash = (index: number) => {
        setSeatGroups(prev => prev.slice(0, index).concat(prev.slice(index + 1)))
    }

    const handleSeatGroupClick = () => {
        setSeatGroups(prev => prev.concat({ ..._SEAT_GROUP }));
    }

    useEffect(() => {
        setIsAddSeatgroupDisabled(seatGroups.length >= _MAX_SEATGROUPS_AMOUNT)

        if (saveDraft)
            saveInLocalStorage(FORM_FIELDS.SEAT_GROUPS, seatGroups);
    }, [seatGroups, saveDraft])

    const handleTicketFileError = (e: UploadError, index: number) => {
        let newSeatGroups = [...seatGroups];
        if (e.type === "file-size") {
            newSeatGroups[index].ticketFilePDFError = _FILE_TOO_BIG;
        } else {
            newSeatGroups[index].ticketFilePDFError = PLEASE_UPLOAD_PDF_FILE;
        }
        setSeatGroups(newSeatGroups);
    }

    const handleUploadError = (err: UploadError) => { // function that handles the error
        const error = err.type === "wrong-type" ? INVALID_FILE_TYPE : _FILE_TOO_BIG

        if (seatGroups.length > 1) {
            setMultipleFilesError(error)
        } else {
            setFileError(error)
        }
    }

    const handleAccessibilityChange = (value: number, accessibility: 'wheelchair' | 'mobility', index: number) => {
        switch (accessibility) {
            case 'wheelchair':
                setSeatGroups(prev =>
                    prev.map((seatgroup, ind) =>
                        ind === index ?
                            { ...seatgroup, wheelchairAccessible: value }
                            : seatgroup
                    )
                )
                break;

            case 'mobility':
                setSeatGroups(prev =>
                    prev.map((seatgroup, ind) =>
                        ind === index ?
                            { ...seatgroup, mobilityDifficultiesAccessible: value }
                            : seatgroup
                    )
                )
                break;
        }
    }

    return (
        <div>
            <div>{PDF_FILE_WITH_THE_TICKETS}</div>
            {isTicketMethodPDF && isSeating !== undefined && (
                seatGroups.length > 1 ?
                    <div className="all-multiple-seatgroups-container">
                        <GenericButton className="upload-multiple seat-group turquise">
                            {LOAD_MULTIPLE_FILES}
                            <MultipleFilesInput
                                className="input-file-text"
                                type="file"
                                singleUpload={false}
                                sizeLimit={_FILE_LIMIT}
                                filesUploader={filesUploaderCx}
                                onChange={onMultipleFilesChange}
                                onError={handleUploadError}
                            />
                        </GenericButton>
                        <div className="add-event-errors-third">
                            {multipleFilesError}
                        </div>

                        {seatGroups.map((seatGroup, index) =>
                            <div className="seatgroup-container" key={index}>
                                {index > 1 &&
                                    <GenericTrashCan
                                        onClick={(e) => { e.preventDefault(); SeatGroupsTrash(index) }}
                                    />}

                                <SeatGroupInputs
                                    disabled={disabled}
                                    filesUploader={filesUploaderCx}
                                    {...seatGroup}
                                    numGroup={index + 1}
                                    setNumSeats={(e) => handleInputChange(e, index)}
                                    setNumSeatsError={(e) => handleInputBlur(e, index)}
                                    setTicketFilePDF={(e) => ticketPDFHandle(e, index)}
                                    setTicketFilePDFError={(e: UploadError) => handleTicketFileError(e, index)}
                                    key={index}
                                    setFiles={setFiles}
                                    index={index}
                                    handleAccessibilityChange={handleAccessibilityChange} />
                            </div>

                        )}
                        <GenericButton className="seat-group" handleClick={handleSeatGroupClick} disabled={isAddSeatgroupDisabled || disabled} >+ {ADD_SEAT_GROUP}</GenericButton>
                        <div className="add-event-errors-third tickets-sum-error">
                            {seatGroupsTicketsSumError}
                        </div>
                    </div>
                    :
                    <div>
                        <div className="file-input-container">
                            <div className="upload-file-btn long">
                                {seatGroups[0]?.ticketFilePDF ?
                                    <div>
                                        {seatGroups[0]?.ticketFilePDF}
                                    </div> :
                                    <div className="upload-text-and-logo" >
                                        <img src="/icons/file_upload.svg" alt="upload" />
                                        <div>{LOAD_PDF_FILE}</div>
                                    </div>
                                }
                            </div>
                            <FileInput
                                disabled={disabled}
                                type="file"
                                singleUpload
                                sizeLimit={_FILE_LIMIT}
                                filesUploader={filesUploaderCx}
                                onChange={handleSingleSeatgroupFileChange}
                                onError={handleUploadError}
                            />
                        </div>
                        <div className="file-error">
                            {fileError}
                        </div>
                        <div className='seatgroup-accessibilities'>
                            <SeatgroupAccessibilities
                                disabled={disabled}
                                wheelchairAccessibleCount={seatGroups[0].wheelchairAccessible}
                                mobilityAccessibleCount={seatGroups[0].mobilityDifficultiesAccessible}
                                setAccessibility={(value, accessibility) => handleAccessibilityChange(value, accessibility, 0)}
                                numSeats={ticketsNum}
                                sumOfAccessTicketsError={seatGroups[0].sumOfAccessTicketsError}
                            />
                        </div>
                    </div>
            )}
        </div>
    )
}

export default TicketsPDFMethod;