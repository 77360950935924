//Socket Context - socket will work globally over the website

import React, { useEffect, useState } from "react";
import { useSocket } from '@hilma/socket.io-react';

import { TicketsPurchasedInterface } from "../consts/interfaces/TicketPurchased.interface";
import { SocketContextInterface } from '../consts/interfaces/SocketContext.interface';
import { EditEvent, EventOfferedSocket } from "../consts/interfaces/Event.interface";
import { AgencyInfoInterface } from "../consts/interfaces/AgencyProfile.interface";
import { FeedbackInterface } from "../consts/interfaces/Feedback.interface";
import { Organization } from '../consts/interfaces/Organization.interface';

export const SocketContext = React.createContext<SocketContextInterface>({});

const MySocketProvider: React.FC = ({ children }) => {

    const socket = useSocket();
    const [eventEdited, setEventEdited] = useState<EditEvent | undefined>()
    const [eventOffered, setEventOffered] = useState<EventOfferedSocket | undefined>()
    const [ticketPurchased, setTicketPurchased] = useState<TicketsPurchasedInterface | undefined>()
    const [feedbackSent, setFeedbackSent] = useState<FeedbackInterface | undefined>() // the id of the event of the new feedback
    const [removeEvent, setRemoveEvent] = useState<number | undefined>() // the id of the event of the new feedback
    const [organizationEdited, setOrganizationEdited] = useState<Organization | undefined>()
    const [agencyEdited, setAgencyEdited] = useState<AgencyInfoInterface | undefined>()


    useEffect(() => {

        socket.open()
        // all of these functions are listening to different things from socket.gateway and updating a state accordingly

        socket.on('event-edited', (editEvent: EditEvent) => {  // ✅
            setEventEdited(editEvent)
        })

        socket.on('event-offered', (event: EventOfferedSocket) => { // ✅
            setEventOffered(event)
        })

        socket.on('ticket-purchased', (ticketPurchased: TicketsPurchasedInterface) => {// ✅
            setTicketPurchased(ticketPurchased)
        })

        socket.on('feedback-sent', (feedback: FeedbackInterface) => {// ✅
            setFeedbackSent(feedback)
        })


        socket.on('remove-event', (eventId: string) => { // ✅
            setRemoveEvent(+eventId)
        })

        socket.on('organization-edited', (event: Organization) => { // ✅
            setOrganizationEdited(event)
        })

        socket.on('agency-edited', (event: AgencyInfoInterface) => { // ✅
            setAgencyEdited(event)
        })

        socket.on("connect_error", (err: any) => {

        });

        return () => {
            socket.off('event-edited')
            socket.off('event-offered')
            socket.off('ticket-purchased')
            socket.off('feedback-sent')
            socket.off('remove-event')
            socket.off('organization-edited')
            socket.off('agency-edited')
            socket.close()
        }
    }, [socket])


    return (
        <SocketContext.Provider
            value={{
                eventEdited,
                eventOffered,
                ticketPurchased,
                feedbackSent,
                removeEvent,
                agencyEdited,
                organizationEdited,
            }}
        >
            {children}

        </SocketContext.Provider>
    )
}



export default MySocketProvider