//* COMPONENT TO REFACTOR
//Feedback shown at the organization for the option to edit. -Copied from FeedbackAgency.

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useFiles } from '@hilma/fileshandler-client';
import { useAsyncEffect } from '@hilma/tools';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Skeleton, useMediaQuery } from "@mui/material";
import IconButton from '@mui/material/IconButton';

import { FeedbackInfo, FeedbackType, FileBody } from '../../../consts/interfaces/Feedback.interface';
import UseParamsInterface from '../../../consts/interfaces/UseParams.interface';
import { BACK, SEND_THANKS, FeedbackHebrew } from '../../../consts/hebrew';
import { _ERROR_MESSAGE, _LONG_TEXT_MAX_LENGTH } from '../../../consts/variables';
import { longTextValidation } from '../../../functions/validations';
import useFeedbackMenuItems from '../../../consts/hooks/useFeedbackMenuItems';

import { SocketContext } from '../../../context/SocketContext';
import { ErrorContext } from '../../../context/ErrorContext';
import { UserContext } from '../../../context/UserContext';

import GenericYesNoPopup from '../../../generic-components/GenericYesNoPopup';
import GenericDropDown from '../../../generic-components/GenericDropDown';
import GenericButton from '../../../generic-components/GenericButton';
import GenericGoBack from '../../../generic-components/GenericGoBack';
import GenericPopup from '../../../generic-components/GenericPopup';
import GenericInput from '../../../generic-components/GenericInput';
import ThankYouRowDesktop from '../../../generic-components/ThankYouRowDesktop';

import PageNotFound from '../../general/page-not-found/PageNotFound';
import { formatDate } from '../../../functions/dateFunctions';
import moment from 'moment';

const FeedbackOrganization: React.FC = () => {
    document.title = 'משוב';

    const { feedbackSent } = useContext(SocketContext)
    const { showError } = useContext(ErrorContext)
    const { systemID } = useContext(UserContext);
    const filesUploader = useFiles();
    const history = useHistory()

    const [popupDelete, setPopupDelete] = useState<boolean>(false);
    const [notAnEvent, setNotAnEvent] = useState<boolean>(false);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [popupEdit, setPopupEdit] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [currFeedbackId, setCurrFeedbackId] = useState<number>(0);

    const [feedbackType, setFeedbackType] = useState<string | null>(null);
    const [imageDisplay, setImageDisplay] = useState<string>('');
    const [textToEdit, setTextToEdit] = useState<string>('');
    const [textToEditError, setTextToEditError] = useState<string>('');

    const [menuAnchor, setMenuAnchor] = useState<string | number | null>(null);

    const isPhone = useMediaQuery('(max-width: 768px)');

    const [feedbackDetails, setFeedbackDetails] = useState<FeedbackInfo>({
        eventName: "",
        day: "",
        date: "",
        time: "",
        feedbacks: [{ feedbackText: "", profilePhoto: "", organizationName: "", images: [], feedbackId: 0 }],
    })

    const { id } = useParams() as UseParamsInterface;
    const eID = Number(id);

    const menuItemsText = useFeedbackMenuItems('text', setPopupDelete, popupDelete, setPopupEdit, popupEdit)
    const menuItemsImg = useFeedbackMenuItems('image', setPopupDelete, popupDelete)

    //given the number of the day in the week and getting back a string with the name of the day in Hebrew
    const dayPerNum = (dayNum: number) => {
        let week = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"]
        return week[dayNum]
    }

    const newDate = `${feedbackDetails.day} | ${feedbackDetails.date} | ${feedbackDetails.time}`;

    //when feedback is sent it updates the data
    useEffect(() => {
        if (feedbackSent?.eventId === eID) {
            setFeedbackDetails(prev => ({ ...prev, feedbacks: [...prev.feedbacks, feedbackSent] }))
        }
    }, [feedbackSent, eID])

    useEffect(() => {
        if (currFeedbackId) {
            const feedbackTextToEdit = feedbackDetails.feedbacks.find(feedback => feedback.feedbackId === currFeedbackId)?.feedbackText
            setTextToEdit(feedbackTextToEdit ?? '')
        }
    }, [currFeedbackId, feedbackDetails.feedbacks])

    //if the event id (from params) is changed it will give us the feedbacks of that event if he has access to them
    useAsyncEffect(async () => {
        if (!systemID || !eID)
            return
        try {
            //first we request the events feedbacks
            const event = (await axios.get(`/api/event/get-event-feedbacks?eventId=${eID}&orgId=${systemID}`)).data;
            //checks to see if there r any feedbacks
            if (event.event.length === 0) setNotAnEvent(true)
            //read flag is for the icon that shows if message has been seen or not updates the server too
            // ! - very slow the readFlag - consider to change it - @Michaelsalem720
            // ? - the organziation dosent read the feedback, the only writes them, i comment this for now, @nati5
            // const readFlag = event.feedbacksForEvent.every((feedback: any) => feedback.read)
            // if (!readFlag) {
            //     await axios.post('/api/feedback/read-feedback', { eventId: eID })
            //     readFeedback(event.feedbacksForEvent.length)
            // }
            const date = new Date(event.event[0].date);

            setFeedbackDetails({
                eventName: event.event[0].eventName,
                day: dayPerNum(date.getDay()),
                date: formatDate(date),
                time: moment(date).format('HH:mm'),
                feedbacks: event.feedbacksForEvent,
            })
            setIsLoading(false);
        } catch (error) {
            showError(_ERROR_MESSAGE)
            console.error(error)
        }

    }, [eID, systemID])

    //when image is pressed it opens a popup with the image
    const handleImageClick = (imagePath: string) => {
        setPopupOpen(true);
        setImageDisplay(imagePath)
    }

    const handleDeleteFeedbackClick = async (feedbackId?: number) => {
        if (feedbackId) {
            if (feedbackType === 'text') {
                await axios.delete(`/api/feedback/delete-feedback?id=${feedbackId}&type=${feedbackType}`);
                setFeedbackDetails(prevState => {
                    const updatedFeedbacks = prevState.feedbacks.map(feedback => {
                        if (feedback.feedbackId === feedbackId) {
                            return { ...feedback, feedbackText: '' }
                        } else {
                            return { ...feedback }
                        }
                    })

                    return { ...prevState, feedbacks: updatedFeedbacks }
                })
            }
            if (feedbackType === 'img') {
                await axios.delete(`/api/feedback-image/delete-feedback-img?id=${feedbackId}&src=${menuAnchor}`);
                setFeedbackDetails(prevState => {
                    const updatedFeedbacks = prevState.feedbacks.map(feedback => {
                        if (feedback.feedbackId === feedbackId) {
                            const filterImages = feedback.images.filter(img => img !== menuAnchor)
                            return { ...feedback, images: filterImages }
                        } else {
                            return { ...feedback }
                        }
                    })

                    return { ...prevState, feedbacks: updatedFeedbacks }
                })
            }
            setPopupDelete(false)
            handleMenuClick()
        }
    }

    const handleEditClick = async (feedbackId?: number) => {
        const validationError = longTextValidation(textToEdit);
        if (validationError) {
            setTextToEditError(validationError);
            return;
        }

        const fileBody: FileBody = {
            organizationId: systemID,
            feedbackText: textToEdit,
            feedbackType: FeedbackType.THANK_YOU,
            eventId: eID,
        };

        if (feedbackId) {

            setFeedbackDetails(prevState => {
                const updatedFeedbacks = prevState.feedbacks.map(feedback => {
                    if (feedback.feedbackId === feedbackId) {
                        return { ...feedback, feedbackText: textToEdit }
                    }
                    return { ...feedback }
                })
                return { ...prevState, feedbacks: updatedFeedbacks }
            });
            setPopupEdit(false)
            setCurrFeedbackId(0)
            handleMenuClick(feedbackId)
        }

        try {
            await filesUploader.post('/api/feedback/save-feedback', {
                ...fileBody,
                id: feedbackId,
            })
        } catch (error) {
            showError(_ERROR_MESSAGE)
            return error;
        }
    }

    const handleAddNewFeedback = (feedbackId?: number, text?: string) => {
        history.push(`/thanks/${eID}`, { state: { feedbackId, text } })
    }

    const handleMenuClick = (feedbackId?: number, img?: string) => {
        if (menuAnchor) {
            setMenuAnchor(null)
            return;
        }
        img ? setFeedbackType('img') : setFeedbackType('text')
        setCurrFeedbackId(feedbackId ?? 0)
        setMenuAnchor(img ?? feedbackId ?? null);
    }

    return (
        <>
            {!notAnEvent ?
                <>
                    {!isLoading ?
                        <div className="feedbacks-agency-page">
                            <GenericGoBack text={BACK} goTo="/past-ordered-events" />
                            <div>
                                <div className="container-title">
                                    <span className="artist"> {feedbackDetails.eventName} </span>
                                    <br />
                                    <span className="date">{isLoading ? "" : newDate}</span>
                                </div>
                            </div>

                            <GenericPopup
                                open={popupOpen}
                                handleClose={() => setPopupOpen(false)}
                                image={imageDisplay}
                                isFeedback={true}
                            />
                            <GenericYesNoPopup
                                open={popupDelete}
                                handleClose={() => { handleMenuClick(); setPopupDelete(false); }}
                                className='feedback-popup-delete'
                                title='האם ברצונך למחוק את הפידבק?'
                                handleYes={() => handleDeleteFeedbackClick(currFeedbackId)}
                            />

                            <GenericPopup
                                open={popupEdit}
                                handleClose={() => { handleMenuClick(); setPopupEdit(false); }}
                                title={FeedbackHebrew.EDIT_FEEDBACK}
                                className='feedback-popup-edit'
                                button={{ text: 'שמירה', className: 'yellow', handleClick: () => handleEditClick(currFeedbackId) }}
                                isSmall
                                children={
                                    <GenericInput
                                        title={''}
                                        name="sayToOrg"
                                        className='edit-feedback-text'
                                        placeholder={''}
                                        value={textToEdit}
                                        error={textToEditError}
                                        // onBlur={handleInputBlur}
                                        onChange={(e) => setTextToEdit(e.target.value)}
                                        characterLimit={_LONG_TEXT_MAX_LENGTH}
                                        textarea
                                    />}
                            />

                            <table className='feedbacks-container'>
                                <tbody>
                                    {feedbackDetails.feedbacks.map((item) => {
                                        return (
                                            <React.Fragment key={item.feedbackId}>
                                                {item.feedbackText ?
                                                    <ThankYouRowDesktop
                                                        feedback={{
                                                            id: item.feedbackId,
                                                            organization: { organizationName: item.organizationName, profilePhoto: item.profilePhoto, imageCoordinates: item.imageCoordinates }
                                                        }}
                                                    >
                                                        <div className='feedback-text'>{item.feedbackText}</div>
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-haspopup="true"
                                                            className='feedback-menu'
                                                            onClick={() => { handleMenuClick(item.feedbackId); setCurrFeedbackId(item.feedbackId ?? 0) }}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <GenericDropDown isOpen={menuAnchor === item.feedbackId} elements={menuItemsText} className='feedback-options-text' />
                                                    </ThankYouRowDesktop>
                                                    : null}

                                                {item.images.length ?
                                                    item.images.map((image, index) => {
                                                        return (
                                                            <ThankYouRowDesktop
                                                                key={index}
                                                                feedback={{
                                                                    id: item.feedbackId,
                                                                    organization: { organizationName: item.organizationName, profilePhoto: item.profilePhoto, imageCoordinates: item.imageCoordinates }
                                                                }} >
                                                                <img className="img-in-bubble" src={image} alt='feedback' />
                                                                <IconButton
                                                                    aria-label="more"
                                                                    id="long-button"
                                                                    aria-haspopup="true"
                                                                    className='feedback-menu'
                                                                    onClick={(event) => { event.stopPropagation(); handleMenuClick(item.feedbackId, image) }}
                                                                >
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                                <GenericDropDown isOpen={menuAnchor === image} elements={menuItemsImg} className='feedback-options-img' />
                                                            </ThankYouRowDesktop>
                                                        )
                                                    })
                                                    : null}
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <GenericButton handleClick={() => handleAddNewFeedback()} className={`yellow fixed ${isPhone ? 'round' : 'medium'}`}>
                                {!isPhone ? <span>{SEND_THANKS}</span> : ''}<span className='span'><img src='/icons/sendMessage.svg' alt='send icon' className='send-icon' /></span>
                            </GenericButton>
                        </div>
                        :
                        <div className="loading" style={{ direction: 'rtl' }} >
                            <Skeleton variant="rectangular" height={"7vh"} width={(isPhone) ? "50vw" : "18vw"} sx={(isPhone) ? { mt: "3vh", mr: "10vw" } : { mt: '7vh', mr: '16vh', mb: '2vh' }} />
                            <div className="loading-chat-row " style={{ marginTop: '12vh', marginRight: "4vw", display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                                <Skeleton className='circle' height={(isPhone) ? "10vw" : "5.3vw"} width={(isPhone) ? "10vw" : "5.3vw"} variant="circular" />
                                <div style={{ display: "flex" }}>
                                    <Skeleton className="triangle-skeleton" variant="rectangular" sx={{ backgroundColor: "unset" }} height={(isPhone) ? "3vw" : "5vw"} width={(isPhone) ? "3vw" : "2vw"} />
                                    <Skeleton className="big-message" variant="rectangular" height={(isPhone) ? "8vh" : "10vh"} width={(isPhone) ? "30vw" : "20vw"} />
                                </div>
                            </div>
                        </div>
                    }
                </>
                :
                <PageNotFound />
            }
        </>
    )
}

export default FeedbackOrganization;