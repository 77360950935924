import { useMediaQuery } from "@mui/material";
import { useUserInfo } from "../../context/UserContext";
import './style/WelcomeUser.scss';

export const WelcomeUser: React.FC = () => {

    const { name } = useUserInfo()
    const firstName = name.split(" ")[0];
    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)

    return (
        <div className="welcome-user" >
            {tabletMediaQuery ?
                firstName :
                `שלום, ${name}`}
        </div>
    )
}
