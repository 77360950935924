/**
 * function that filter and map the array in more efficient way
 * @param arr the target array
 * @param filterFn the filter function
 * @param mapFn the map function
 * @returns filtered and mapped array
 */
export function filterAndMap<In, MOut>(
    arr: In[],
    filterFn: (value: In, index: number, array: In[]) => unknown,
    mapFn: (value: In, index: number, array: In[]) => MOut
): MOut[] {
    return arr.reduce((acc, curr, index) => {
        if (!filterFn(curr, index, arr)) return acc;

        const newElement = mapFn(curr, index, arr);
        acc.push(newElement);

        return acc
    }, [] as MOut[])
}

export const turnArrToString = (arr: string[]) => {
    let res = '';
    if (arr.length) {
        arr.forEach((item, index) => {
            res += " " + item;
            if (index < arr.length - 1) res += ',';
        });
    }
    return res;
}

export const filterSelected = <T extends { selected: boolean }>(array: T[]) =>
    array.filter(({ selected }) => selected)
        .map(item => {
            const { selected, ...rest } = item;
            return rest
        })
