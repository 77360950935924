//this component returns event slider that shows the new up coming events for a specific org
import { useContext, useEffect, useState } from 'react';
import { useAsyncEffect } from '@hilma/tools';
import { Skeleton, useMediaQuery } from "@mui/material";
import axios from 'axios';

import { EventSwiperElementType, OrganizationSwiperEvent } from '../../../consts/interfaces/SwipeBoard.interface';
import { UpcomingOrganizationEventsServer } from '../../../consts/interfaces/Organization.interface';
import { filterFuturePurchasedEvents, isFuture } from '../../../functions/filterFuturePurchasedEvents';
import { _ERROR_MESSAGE } from '../../../consts/variables';
import { SocketContext } from '../../../context/SocketContext';
import { ErrorContext } from '../../../context/ErrorContext';
import { UserContext } from '../../../context/UserContext';

import OrganizationUpcomingEvents from './OrganizationUpcomingEvents';
import OrganizationHomeStats from './OrganizationHomeStats';
import FuturePurchasedEvents from '../../../generic-components/FuturePurchasedEvents';
import GreetingOrganization from './GreetingOrganization';
import { EventInfoInterfaceFromServer } from '../../../consts/interfaces/Event.interface';
import SkeletonsFuturePurchasedEvents from '../../../generic-components/SkeletonsFuturePurchasedEvents';

const UpComingEventsOrg: React.FC = () => {
    const { systemID, systemName } = useContext(UserContext);
    const { eventEdited, eventOffered, removeEvent, ticketPurchased } = useContext(SocketContext);
    const { showError } = useContext(ErrorContext);
    const [isLoading, setIsLoading] = useState(true); // boolean flag that indicates whether the page is loading
    const [eventsOffersArray, setEventsOffersArray] = useState<OrganizationSwiperEvent[]>([]);
    const [purchasedFutureEventsArray, setPurchasedFutureEventsArray] = useState<EventSwiperElementType[]>([])

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)');

    //function that gets  from the server all the up coming event for an org
    useAsyncEffect(async () => {
        if (!systemID) return
        try {
            const allOrganizationOffers: UpcomingOrganizationEventsServer[] = (await axios.get(`/api/organization/event-offers?orgId=${systemID}`)).data
            setEventsOffersArray(filterFutureOffers(allOrganizationOffers));

            const allPurchasedEvents: EventInfoInterfaceFromServer[] = (await axios.get(`/api/ticket/getEventsPurchased?organizationId=${systemID}`)).data
            setPurchasedFutureEventsArray(filterFuturePurchasedEvents(allPurchasedEvents))

            setIsLoading(false);
        }

        catch (error) {
            showError(_ERROR_MESSAGE);
            throw error;
        }
    }, [systemID, showError])

    const filterFutureOffers = (allOrganizationOffers: UpcomingOrganizationEventsServer[]) => {
        const organizationOffers = allOrganizationOffers.filter((event) => isFuture(new Date(event.date)));
        return organizationOffers.map((event) => {
            return {
                ...event,
                eventId: event.id,
            }
        })
    }

    //function that changes on socket and deletes an event that has been deleted
    useEffect(() => {
        if (removeEvent) {
            setEventsOffersArray(prev => {
                return prev?.filter((event) => event.eventId !== +removeEvent) || []
            })
        }
    }, [removeEvent])

    //function that changes on socket and changes the amount of tickets that have been purchased for an event
    useEffect(() => {
        if (ticketPurchased) {
            setEventsOffersArray(prev => {
                return prev?.filter((event) => event.eventId !== ticketPurchased?.eventId) || []
            })
        }
    }, [ticketPurchased])

    //function that changes on socket and edits an event that has been edited
    useEffect(() => {
        if (eventEdited) {
            setEventsOffersArray(prev => {
                return prev?.map(event => {
                    if (event.eventId === eventEdited.id) {
                        return {
                            ...event,
                            eventName: eventEdited.eventName,
                            coverImage: eventEdited.coverImage || event.coverImage
                        }
                    } else return event
                })
            })
        }
    }, [eventEdited])

    //function that changes on socket and adds an event that has been added for this org
    useEffect(() => {
        if (eventOffered && eventOffered.organizationId === systemID) {
            const newEvent: OrganizationSwiperEvent = {
                eventId: eventOffered.id,
                eventName: eventOffered.eventName,
                date: eventOffered.date,
                ticketsNum: eventOffered.ticketsNum,
                coverImage: eventOffered.coverImage,
                imageCoordinates: eventOffered.imageCoordinates,
                timeExpired: eventOffered.timeExpired,
            }

            setEventsOffersArray(prev => {
                return prev ? [...prev, newEvent] : [newEvent]
            })
        }
    }, [eventOffered, systemID])

    return (
        <>
            {tabletMediaQuery ? <GreetingOrganization organizationName={systemName} /> : null}
            {!isLoading ?
                <>
                    <OrganizationUpcomingEvents eventArray={eventsOffersArray} />
                    <FuturePurchasedEvents futurePurchasedArray={purchasedFutureEventsArray} serverLoading={isLoading} shortArray={true} className='future-events-home-page' />
                </>
                :
                <div style={{ marginTop: "3vh", paddingRight: "7vw" }}>
                    <Skeleton variant="text" width={"65vw"} height={"8vh"} sx={{ marginBottom: "-10vh" }} />
                    <Skeleton variant="text" width={"75vw"} height={"60vh"} sx={{ justifyContent: "center", marginBottom: "-5vh" }} />
                    <SkeletonsFuturePurchasedEvents />
                </div>
            }
            <OrganizationHomeStats />
        </>
    );
}

export default UpComingEventsOrg;