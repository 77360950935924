import { FC } from "react";

import { ADDITIONAL_TICKETS_VIP, MEETING_WITH_ACTORS_VIP, VISIT_ACTORS_VIP, OTHER, TYPE_TEXT_PLACEHOLDER, WANT_TO_ADD_VIP_TITLE, VIP_EXPLAIN, NOT_INTERESTED_MAYBE_NEXT_TIME } from "../../../consts/hebrew";
import { EventVIPProps } from "../../../consts/interfaces/EventVIP.interface";
import { _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";

import RadioButtons from "../../../generic-components/RadioButtons";
import GenericInput from "../../../generic-components/GenericInput";
import "./style/addEventRightForth.scss";


const EventVIP: FC<EventVIPProps> = ({ vipOptionError, eventVIPDetailsValue, handleInputChange, reasonVIPEvent, changeVipEventOption, vipDetailsError, handleInputBlur }) => {
    const vipOptions = [
        { label: MEETING_WITH_ACTORS_VIP, value: MEETING_WITH_ACTORS_VIP },
        { label: VISIT_ACTORS_VIP, value: VISIT_ACTORS_VIP },
        { label: OTHER, value: 'other' },
        { label: NOT_INTERESTED_MAYBE_NEXT_TIME, value: NOT_INTERESTED_MAYBE_NEXT_TIME }
    ]
    return (
        <div className="vip-container">
            <div>
                <p className="title-vip">{WANT_TO_ADD_VIP_TITLE}</p>
                <p className="vip-explain">{VIP_EXPLAIN}</p>
                <p>{ADDITIONAL_TICKETS_VIP}</p>
            </div>
            <div className="input-container">
                <RadioButtons options={vipOptions}
                    value={reasonVIPEvent}
                    handleChange={(e) => { changeVipEventOption(e.currentTarget.value) }}
                />
                <div className="add-event-errors-third">{vipOptionError}</div>
                <div className="other-option-vip-text">
                    {reasonVIPEvent === 'other'
                        && <GenericInput
                            title={''}
                            value={eventVIPDetailsValue}
                            name="eventVIPDetails"
                            error={vipDetailsError}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            placeholder={TYPE_TEXT_PLACEHOLDER}
                            characterLimit={_LONG_TEXT_MAX_LENGTH}
                            textarea
                        />
                    }
                </div>
            </div>

        </div>
    )
}

export default EventVIP;