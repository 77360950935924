import { useState } from "react";
import TicketOrder from "../../generic-components/ticket-order/TicketOrder"
import { EventDetails } from "../../consts/interfaces/TicketOrder.interface";
import { demoBenefitData } from "../../consts/eventDetails";

const BenefitPage = () => {
    const [benefitInfo, setBenefitInfo] = useState<EventDetails>({ ...demoBenefitData })

    return (
        <TicketOrder type="benefit" eventInfo={benefitInfo} />
    )
}

export default BenefitPage;