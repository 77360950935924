//contact us- is a general page that u can access without being signed in and is there for people to give us feedback
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';

import { _ERROR_MESSAGE, _LONG_TEXT_MAX_LENGTH } from '../../../consts/variables';
import GenericInputProps from '../../../consts/interfaces/GenericInputProps';
import { CONTACT_US_LEFT } from '../../../consts/leftSideLayouts';
import { contact } from '../../../consts/enums/contactUs.enum';
import { TITLES } from '../../../consts/hebrew';

import { emailValidation, longTextValidation, textValidation } from '../../../functions/validations';
import { ErrorContext } from '../../../context/ErrorContext';
import { UserContext } from '../../../context/UserContext';

import GenericSplitPage from '../../../generic-components/GenericSplitPage';
import GenericButton from '../../../generic-components/GenericButton';
import GenericInput from '../../../generic-components/GenericInput';
import GenericPopup from '../../../generic-components/GenericPopup';

import './style/contactUs.scss';

const ContactUs: React.FC = () => {
    document.title = TITLES.MAKE_CONTACT
    const { email, name } = useContext(UserContext);
    const { showError } = useContext(ErrorContext)
    const history = useHistory();

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [serverLoading, setServerLoading] = useState(false) //for skeleton
    const [firstLoading, setFirstLoading] = useState(true)

    const [userName, setUserName] = useState<string>(name);
    const [nameError, setNameError] = useState<string>("");
    const [userEmail, setUserEmail] = useState<string>(email);
    const [emailError, setEmailError] = useState<string>("");
    const [sayToUs, setSayToUs] = useState<string>("");
    const [sayToUsError, setSayToUsError] = useState<string>("");

    useEffect(() => {
        if (email !== undefined && userName !== undefined) {
            setFirstLoading(false)
        }
    }, [email, name, userName])

    //happens when the user has entered all the info needed (name, email, what he wants to say) 
    //when button clicked it validates all info that is sent and then proceeds to sent us an email with the feedback

    const handleClick = async () => {
        const nameVal = textValidation(userName);
        const emailVal = emailValidation(userEmail);
        const sayToUsVal = longTextValidation(sayToUs);
        if (!nameVal && !emailVal && !sayToUsVal) {
            setServerLoading(true)
            try {
                await axios.get(`/api/contact/contact-us-mail?name=${userName}&email=${userEmail}&text=${sayToUs}`);
                setServerLoading(false) // stop the loading of the button
                setShowPopup(true);

                setTimeout(() => {
                    setShowPopup(false);
                    history.push("/");
                }, 5000)
            }
            catch (error) {
                showError(_ERROR_MESSAGE)
                return error;
            }
        } else {
            setNameError(nameVal);
            setEmailError(emailVal);
            setSayToUsError(sayToUsVal);
        }
    }

    const handleClosePopUP = () => {
        setShowPopup(false);
        history.push("/");
    }

    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!firstLoading) {
            switch (e.target.name) {
                case "name":
                    setNameError(textValidation(e.target.value));
                    break;
                case "email":
                    setEmailError(emailValidation(e.target.value));
                    break;
                case "sayToUs":
                    setSayToUsError(longTextValidation(e.target.value));
                    break;
                default:
                    break;
            }
        }
    }

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "name":
                setUserName(e.target.value);
                setNameError("");
                break;
            case "email":
                setUserEmail(e.target.value);
                setEmailError("");
                break;
            case "sayToUs":
                setSayToUs(e.target.value);
                setSayToUsError("");
                break;
            default:
                break;
        }
    }

    const inputArr: Array<GenericInputProps> = useMemo(() =>
        [
            {
                title: contact.name,
                name: "name",
                placeholder: "",
                value: userName,
                type: "text",
                error: nameError
            },
            {
                title: contact.email,
                name: "email",
                placeholder: "",
                value: userEmail,
                type: "text",
                error: emailError
            },
            {
                title: contact.body_of_contact,
                name: "sayToUs",
                placeholder: "",
                value: sayToUs,
                error: sayToUsError,
                characterLimit: _LONG_TEXT_MAX_LENGTH,
                textarea: true
            }
        ], [sayToUs, sayToUsError, userName, nameError, userEmail, emailError]);

    return (
        <div className="contact-us-page">
            <GenericSplitPage
                serverLoading={serverLoading}
                squares={CONTACT_US_LEFT}
            >
                <div className="contact-us-right">
                    <div className="contact-us-right-container">
                        <div className="title">{TITLES.MAKE_CONTACT}</div>
                        <div className="contact-us-inputs">
                            {inputArr.map((object, i) => {
                                return <div className="contact-us-right-input" key={i}>
                                    <GenericInput
                                        {...object}
                                        onBlur={handleInputBlur}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            })}
                        </div>
                        <div className="contact-us-right-button">
                            <GenericButton className="review-event yellow review" serverLoading={serverLoading} handleClick={handleClick}>{contact.send_request}</GenericButton>
                        </div>
                    </div>
                </div>
            </GenericSplitPage>
            {showPopup &&
                <GenericPopup open={showPopup}
                    handleClose={handleClosePopUP}
                    title={contact.sent_successfully}
                    type="contact-us"
                    className="contact-us" />
            }
        </div>
    );
}
export default ContactUs;