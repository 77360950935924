import { Area } from "react-easy-crop";

// enum
export enum FeedbackType {
    NOT_INTERESTED = 'NotInterested',
    THANK_YOU = 'ThankYou',
    DELETE_EVENT = 'DeleteEvent'
}
export interface FeedbackText {
    feedbackId?: number
    feedbackText: string,
    profilePhoto: string,
    organizationName: string,
    images: Array<string>,
    imageCoordinates?: Area
    isOpen?: boolean
}
export interface FeedbackInfo {
    eventName: string,
    day: string,
    date: string,
    time: string,
    feedbacks: Array<FeedbackText>,
    imageCoordinates?: Area
}

export interface FeedbackInterface extends FeedbackText {
    eventId: number;
    id: number
}

export interface FileBody {
    id?: number;
    organizationId: number;
    feedbackText: string;
    feedbackType: FeedbackType;
    eventId: number;
}