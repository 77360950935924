//* COMPONENT TO REFACTOR
//registration page for organization
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Area } from "react-easy-crop";
import axios from "axios";
import { FormControl, IconButton, MenuItem, Select, useMediaQuery } from "@mui/material";
import { FileInput, UploadedFile, UploadError, useFiles } from "@hilma/fileshandler-client";
import { useAsyncEffect } from "@hilma/tools";

import PopupJoiningConditionsMobile from "../../../popups/organization/PopupJoiningConditionsMobile";
import PopupArrayDemographic from "../../../popups/organization/PopupArrayDemographic";
import ProfileContact from "../../../generic-components/ProfileContact";
import { TermsForm, TermsFormRef } from "../../../generic-components/TermForm";
import PopupDemo from "../../../popups/organization/PopupDemo";

import { _ERROR_SEND_MESSAGE_, INVALID_EMAIL_ERROR, ORGANIZATION_TYPES, REQUIRED_FIELD_ERROR } from "../../../consts/variables";
import { DemoInterface, DemoServerInterface } from "../../../consts/interfaces/Demographic.interface";
import { fileIdsInterface, files } from "../../../consts/interfaces/organizationSignUp.interface";
import { orgSignUpErrors, contactErrors } from "../../../consts/interfaces/formErrors.interface";
import GenericInputProps from "../../../consts/interfaces/GenericInputProps";
import { ORGANIZATION_SIGNUP_LEFT } from "../../../consts/leftSideLayouts";
import { organization } from "../../../consts/enums/organization.enum";
import { useExitBrowser } from "../../../consts/hooks/useExitBrowser";
import useIsFirstRender from "../../../consts/hooks/useIsFirstRender";
import { Contact } from "../../../consts/interfaces/Contact.interface";
import { ORG_SIGN_UP } from "../../../consts/enums/InputsNames";
import { LIMIT_ERROR, ORG_LOGO, TITLES } from "../../../consts/hebrew";

import { emailValidation, nameValidation, phoneNumValidation, selectAtLeastOneValidator, textValidation } from "../../../functions/validations";
import { scrollToFirstError } from "../../../functions/scrollToError";
import { GoogleAnalyticsContext } from "../../../context/GoogleAnalytics";
import { ErrorContext } from "../../../context/ErrorContext";

import GenericProfilePicInput from "../../../generic-components/GenericProfilePicInput";
import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import GenericTrashCan from "../../../generic-components/GenericTrashCan";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";

import "../../../style/organizationSignUp.scss";

const OrganizationSignUp: React.FC = () => {
    document.title = organization.organization_sign_up;

    useExitBrowser()

    const { gaEvent, gaException } = useContext(GoogleAnalyticsContext)
    const { showError } = useContext(ErrorContext);

    const firstInputRef = useRef<HTMLInputElement>(null);
    const history = useHistory();
    const isFirstRender = useIsFirstRender()

    const [organizationType, setOrganizationType] = useState<ORGANIZATION_TYPES>(ORGANIZATION_TYPES.ORGANIZATION);
    const [errors, setErrors] = useState({});
    const [serverLoading, setServerLoading] = useState(false)
    const [orgNameError, setOrgNameError] = useState<string>('');
    const [contacts, setContacts] = useState<Contact[]>([{ name: "", phone: "", email: "", nameError: "", phoneError: "", emailError: "" }])

    const [orgNameValue, setOrgNameValue] = useState<string>('');
    const filesUploader = useFiles();
    const [properManagement, setProperManagement] = useState<files>({ name: "", error: "" })
    const [fileIds, setFileIds] = useState<fileIdsInterface>({
        properManagement: -1,
        profilePhoto: -2
    }); // object that send to the server to get the server know which file is which
    const [imageCoordinates, setImageCoordinates] = useState<Area>({ x: 0, y: 0, width: 100, height: 100 })
    const [imageLink, setImageLink] = useState<string>(""); // the link of the uploaded image

    const [demo, setDemo] = useState<DemoInterface[]>([])
    const [popupOpenDemo, setPopupOpenDemo] = useState(false) // the status of the category popup 
    const [demoError, setDemoError] = useState<string>("");
    const [open, setOpen] = useState(true); //popups

    const termsFormRef = useRef<TermsFormRef>(null);
    const inputArr: Array<GenericInputProps> = useMemo(() => [
        {
            title: organization.name_of_organization,
            name: ORG_SIGN_UP.ORG_NAME,
            value: orgNameValue,
            error: orgNameError
        }
    ], [orgNameValue, orgNameError]);

    const formRef = useRef(null);
    const isPhone = useMediaQuery("(max-width:768px)");

    useAsyncEffect(async () => {
        const demoServer = await axios.get<DemoServerInterface[]>('/api/demographic/all');
        const demoArray: DemoInterface[] = demoServer.data.map(item => {
            return { ...item, selected: false }
        })
        // get info for the demographics
        setDemo(demoArray);
    }, [])

    useEffect(() => {
        if (organizationType === ORGANIZATION_TYPES.ORGANIZATION) return;
        setFileIds(prev => ({ ...prev, properManagement: -2 }))
    }, [organizationType])

    useEffect(() => {
        scrollToFirstError(formRef, firstInputRef, errors)
    }, [errors])

    const removeDemographic = (id: number) => {
        const selected = demo.filter(item => item.selected)
        selected.length === 1 ? setDemoError(organization.at_least_one_option) : setDemoError("")
        setDemo(prev => prev.map(item => item.id === id ? { ...item, selected: false } : item))
    }

    const checkDuplicatePhone = (newContact: Contact) => {
        return contacts.some((oldContact) =>
            oldContact.id !== newContact.id
            && oldContact.phone === newContact.phone) // if there is one more contact with different id and the same phone its duplicate case
    }

    const checkDuplicateEmail = (newContact: Contact) => {
        return contacts.some((oldContact) =>
            oldContact.id !== newContact.id
            && oldContact.email === newContact.email) // if there is one more contact with different id and the same phone its duplicate case
    }

    const checkContactsVal = () => {
        const validatedContacts = contacts.map((contact) => {
            const nameVal = nameValidation(contact.name)
            const duplicatePhoneFlag = checkDuplicatePhone(contact);
            const phoneNumVal = duplicatePhoneFlag ? organization.cant_use_phone_twice : phoneNumValidation(contact.phone)
            const duplicateEmailFlag = checkDuplicateEmail(contact);
            const emailVal = duplicateEmailFlag ? organization.cant_use_email_twice : emailValidation(contact.email);

            return {
                ...contact,
                nameError: nameVal,
                phoneError: phoneNumVal,
                emailError: emailVal
            }
        })
        setContacts(validatedContacts)

        const contactErrors: contactErrors[] = validatedContacts.map((contact) => {
            const { emailError, phoneError, nameError } = contact
            const contactErrors: contactErrors = {}
            if (nameError) contactErrors.contactName = nameError
            if (phoneError) contactErrors.contactPhone = phoneError
            if (emailError) contactErrors.contactEmail = emailError
            return contactErrors
        })
        return contactErrors
    }

    const checkFile = () => {
        if (organizationType === ORGANIZATION_TYPES.MUNICIPALITY_DEPARTMENT)
            return ""
        if (fileIds.properManagement < 0)
            return REQUIRED_FIELD_ERROR
        if (properManagement.error)
            return properManagement.error
        return ""
    }

    //function that checks if all the input is valid and if it is moves on to the next page
    const handleSignUp = async () => {
        let newErrors: orgSignUpErrors = {};
        const orgNameVal = textValidation(orgNameValue);
        const demographicVal = selectAtLeastOneValidator(demo)
        const contactsErrors = checkContactsVal()[0] // now there is just one contact person
        const contactsVal = Object.keys(contactsErrors).length === 0
        const fileVal = checkFile()
        const termsVal = termsFormRef.current?.validate()

        if (contactsVal && !demographicVal && !orgNameVal && termsVal && !fileVal) {
            setServerLoading(true);
            const terms = termsFormRef.current?.getTermData()
            try {
                await filesUploader.post('/api/organization/register-organization', {
                    organizationName: orgNameValue,
                    contacts: contacts,
                    demographics: demo.filter(demo => demo.selected),
                    filesIds: fileIds,
                    imageCoordinates,
                    termsName: terms?.termsName,
                    termsDate: terms?.termsDate
                })
                setServerLoading(false)
                history.push("/organization-registered");
                gaEvent('sign_up', {
                    sign_up_label: 'organization',
                    event_category: 'organization',
                    event_label: 'new_organization',
                })
            }
            catch (error: any) {
                setServerLoading(false)
                const errorMessage = error?.data?.message
                if (errorMessage.includes('phone number')) {
                    console.error('phone already exists', errorMessage)
                    setContacts(prev => {
                        return prev.map(user => {
                            if (user.phone === errorMessage.split(' ')[0]) {
                                return { ...user, phoneError: organization.phone_is_already_exist }
                            } else return user
                        })
                    })
                } else if (errorMessage?.includes('email')) {
                    console.error('email is already exists.', errorMessage)
                    setContacts(prev => {
                        return prev.map(user => {
                            if (user.email === errorMessage.split(' ')[0]) {
                                return { ...user, emailError: organization.email_is_already_exist }
                            } else return user
                        })
                    })
                } else if (errorMessage.find((item: string) => item.includes("must be an email"))) {
                    const invalidEmailError = error.data.message?.find((item: string) => item.includes("must be an email"))
                    const index = Number(invalidEmailError.split('.')[1])
                    setContacts(prev => prev.map((user, indx) =>
                        index === indx ? { ...user, emailError: INVALID_EMAIL_ERROR } : user
                    ))
                }
                else {
                    gaException('sign_up', error, true)
                    showError(_ERROR_SEND_MESSAGE_);
                }
                return error
            }
        } else {
            if (orgNameVal) newErrors.organizationName = orgNameVal
            if (!contactsVal) newErrors = { ...newErrors, ...contactsErrors }
            if (demographicVal) newErrors.demographics = demographicVal
            if (fileVal) newErrors.properManagement = fileVal
            if (!termsVal) newErrors.termName = false

            setOrgNameError(orgNameVal);
            setDemoError(demographicVal)
            setProperManagement(prev => ({ ...prev, error: fileVal }))
            setErrors(newErrors);
        }
    }

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        if (isFirstRender) return;
        switch (e.target.name) {
            case "organizationName":
                setOrgNameError(textValidation(e.target.value));
                break;
            case "contactName":
                const copiedContactsName = [...contacts];
                if (typeof index === 'number' && copiedContactsName[index]) {
                    copiedContactsName[index].nameError = nameValidation(e.target.value);
                }
                setContacts(copiedContactsName);
                break;
            case "contactPhone":
                const copiedContactsPhone = [...contacts];
                if (typeof index === 'number' && copiedContactsPhone[index]) {
                    copiedContactsPhone[index].phoneError = phoneNumValidation(e.target.value);
                }
                setContacts(copiedContactsPhone);
                break;
            case "contactEmail":
                const copiedContactsEmail = [...contacts];
                if (typeof index === 'number' && copiedContactsEmail[index]) {
                    copiedContactsEmail[index].emailError = emailValidation(e.target.value);
                }
                setContacts(copiedContactsEmail);
                break;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        switch (e.target.name) {
            case "organizationName":
                setOrgNameValue(e.target.value);
                break;
            case "contactName":
                const contactCopyName = [...contacts];
                if (typeof index === 'number' && contactCopyName[index]) {
                    contactCopyName[index].name = e.target.value;
                }
                setContacts(contactCopyName);
                break;
            case "contactPhone":
                const contactCopyPhone = [...contacts];
                if (typeof index === 'number' && contactCopyPhone[index]) {
                    contactCopyPhone[index].phone = e.target.value.trim();
                }
                setContacts(contactCopyPhone);
                break;
            case "contactEmail":
                const contactCopyEmail = [...contacts];
                if (typeof index === 'number' && contactCopyEmail[index]) {
                    contactCopyEmail[index].email = e.target.value;
                }
                setContacts(contactCopyEmail);
                break;
            default:
                break;
        }
    }

    const handleAddContact = () => {
        if (contacts.length < 4)
            setContacts(prev => {
                return [...prev, { name: "", phone: "", email: "", nameError: "", phoneError: "", emailError: "" }]
            })
    }

    const contactTrash = (index: number) => {
        let newContactArray: Contact[];
        if (index === 0 && contacts.length === 1) {
            newContactArray = [{ name: "", phone: "", email: "", nameError: "", phoneError: "", emailError: "" }]
        } else {
            newContactArray = [...contacts]
            newContactArray.splice(index, 1);
        }
        setContacts(newContactArray)
    }

    const openDemographicPopup = () => { // function that handles the category
        setPopupOpenDemo(true)
    }

    const handleFileChange = (file: UploadedFile) => {
        if (!file.type.includes('pdf')) {
            setProperManagement({ name: "", error: organization.PDF_only })
            return;
        }

        setProperManagement({ name: file.fileName, error: "" })
        setFileIds(prev => ({ ...prev, properManagement: file.id }))
    }

    const handleFileError = (err: UploadError) => {
        switch (err.type) {
            case "wrong-type":
                setProperManagement({ name: "", error: organization.type_of_file_not_valid })
                break;
            case "file-size":
                setProperManagement({ name: "", error: organization.too_large })
                break;
        }
    }

    return (
        <div>
            {isPhone ?
                <PopupJoiningConditionsMobile
                    open={open}
                    handleClose={() => { setOpen(false) }}
                /> : null
            }

            <>
                <GenericSplitPage serverLoading={serverLoading} unauthenticated squares={ORGANIZATION_SIGNUP_LEFT}>
                    <form ref={formRef}>
                        <div className="signup-right">
                            <div className="signup-right-input-container">
                                <div className="signup-right-title" ref={firstInputRef}>{TITLES.REQUEST_FOR_JOINING}</div>
                                <div className="signup-inputs">

                                    <GenericProfilePicInput
                                        title={ORG_LOGO}
                                        imageCoordinates={imageCoordinates}
                                        setImageCoordinates={setImageCoordinates}
                                        filesUploader={filesUploader}
                                        onFileIdChange={(id) => setFileIds(prev => ({ ...prev, profilePhoto: id }))}
                                        imageLink={imageLink}
                                        setImageLink={setImageLink}
                                    />

                                    {inputArr.map((object) => {
                                        return <GenericInput name={object.name} type={object.type} characterLimit={object.characterLimit} key={object.name} {...object} onChange={handleInputChange} onBlur={handleInputBlur} />
                                    })}
                                    <div className="input-container">
                                        <div className="input-title">{organization.organization_type}</div>
                                    </div>
                                    <FormControl fullWidth>
                                        <Select
                                            onChange={(evt) => setOrganizationType(evt.target.value as ORGANIZATION_TYPES)}
                                            value={organizationType}
                                            className="organization-type-select"
                                        >
                                            {Object.values(ORGANIZATION_TYPES).map((item, i) =>
                                                <MenuItem key={i} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>

                                    <div className="right-input-inputs">
                                        <div className="right-input-side-title">{organization.who_we_will_update}</div>
                                        <div style={{ position: "relative" }}>
                                            {contacts.map((contact, index) =>
                                                <div key={index}>
                                                    {contacts.length > 1 &&
                                                        <GenericTrashCan onClick={() => contactTrash(index)} />}
                                                    <ProfileContact
                                                        secondaryTitle={organization.permission_for_details}
                                                        name={contact.name}
                                                        phone={contact.phone}
                                                        email={contact.email}
                                                        setName={(e) => handleInputChange(e, index)}
                                                        setPhone={(e) => handleInputChange(e, index)}
                                                        setEmail={(e) => handleInputChange(e, index)}
                                                        setNameError={(e) => handleInputBlur(e, index)}
                                                        setPhoneError={(e) => handleInputBlur(e, index)}
                                                        setEmailError={(e) => handleInputBlur(e, index)}
                                                        nameError={contact.nameError}
                                                        phoneError={contact.phoneError}
                                                        emailError={contact.emailError}
                                                        {...contacts}
                                                    />
                                                </div>
                                            )}
                                            {contacts.length < 4 && false && //* ENABLE LATER
                                                <GenericButton className="right-blue-btn blue" handleClick={handleAddContact} >הוסף איש קשר +</GenericButton>}
                                        </div>
                                    </div>

                                    <div style={{ marginBottom: '2vh' }}>
                                        <div className="input-container">
                                            <div className="input-title">{organization.audience}</div>
                                            <div style={{ marginBottom: '1vh' }} className="secondary-title">{organization.weak_populations_only}</div>

                                            <div className="special-input-container">
                                                <div className="special-input input" placeholder={organization.add_kind_of_event} onClick={openDemographicPopup}>
                                                    <IconButton onClick={openDemographicPopup}>
                                                        <img style={{ width: '3vh' }} src="/icons/open-circle.svg" alt="add" />
                                                    </IconButton>
                                                    {organization.add_audience}
                                                </div>
                                                <div className="selected-categories">
                                                    {demo && demo.filter(item => item.selected).map((demographic) => <PopupArrayDemographic id={demographic.id} key={demographic.id} title={demographic.demographicName} removeMyself={removeDemographic} />)}
                                                </div>
                                            </div>
                                            {demoError && <div className="signup-right-errors">{demoError}</div>}
                                        </div>
                                    </div>

                                    {organizationType === ORGANIZATION_TYPES.ORGANIZATION &&
                                        <div className="signup-all-other-inputs-container">
                                            <div className="other-inputs-titles">{organization.proper_management}</div>
                                            <div className="other-inputs">
                                                <div className="signup-text-file">
                                                    {properManagement.name ?
                                                        <div className="org-signup-files">
                                                            {properManagement.name}
                                                        </div> :
                                                        <div className="org-signup-files">
                                                            <img src="/icons/file_upload.svg" className="upload-arrow-image" alt="upload" />
                                                            <div> {organization.loading_file}</div>
                                                        </div>
                                                    }
                                                </div>
                                                <FileInput
                                                    className="input-file-text"
                                                    singleUpload type="file"
                                                    name={ORG_SIGN_UP.PROPER_MANAGEMENT}
                                                    sizeLimit={3000}
                                                    filesUploader={filesUploader}
                                                    onChange={handleFileChange}
                                                    onError={handleFileError}
                                                />
                                            </div>

                                            {properManagement.error ?
                                                <div className="signup-right-errors">
                                                    {properManagement.error}
                                                </div> :
                                                <div className="limit" >
                                                    {LIMIT_ERROR}
                                                </div>
                                            }
                                        </div>}

                                    <TermsForm ref={termsFormRef} pdf='policyOrganization' />
                                    <GenericButton className="yellow signup-right-button" serverLoading={serverLoading} handleClick={handleSignUp} >
                                        {organization.send_request}
                                    </GenericButton>
                                </div >
                            </div >
                        </div >
                    </form>
                </GenericSplitPage >
            </>
            <PopupDemo open={popupOpenDemo} handleClose={() => { setPopupOpenDemo(false) }} demo={demo} setDemo={setDemo} setDemoError={setDemoError} />
        </div >
    )
}

export default OrganizationSignUp;