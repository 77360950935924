//this component gets props - toggle: (() => void) | null, page: string, setPage: (val: "first" | "second") => void, setPhoneNum: (e: string) => void;
//it returns the first right side of the login - register with phone number after you are approved
import { useState } from "react";
import { useSendCode } from '@hilma/auth';

import { LoginRightProps } from "../../../consts/interfaces/Login.interface";
import { LoginMessage } from "../../../consts/hebrew";
import { LoginText } from "../../../consts/enums/login.enum";

import GenericButton from "../../../generic-components/GenericButton";
import { phoneNumValidation } from "../../../functions/validations";
import GenericInput from "../../../generic-components/GenericInput";
import { LoginError } from "../../../consts/variables";

const LoginRightFirst: React.FC<LoginRightProps> = ({ setPhoneNum, setPage }) => {

    const [phoneNumError, setPhoneNumError] = useState<string>("");
    const [phoneNumValue, setPhoneNumValue] = useState<string>("");
    const [disabled, setDisabled] = useState(false)

    const sendCode = useSendCode();

    //function that checks if all the input is valid and if it is moves on to the next page
    const sendCodeToUser = async () => {
        const phoneNumVal = phoneNumValidation(phoneNumValue);
        setPhoneNumError(phoneNumVal)
        if (!phoneNumVal) {
            setDisabled(true)
            const { success, msg } = await sendCode('/api/contact/send-code', { username: phoneNumValue });

            if (success) {
                setPhoneNum && setPhoneNum(phoneNumValue);
                setPage && setPage("second");
            } else {
                setDisabled(false)
                const errorMessage = msg?.data?.message
                switch (errorMessage) {
                    case (LoginError.UNAUTHORIZED):  //todo change to the relevant error
                    case (LoginError.CONTACT_NOT_FOUND):
                        setPhoneNumError(LoginMessage.CONTACT_NOT_FOUND);
                        break;
                    case LoginError.AGENCY_NOT_APPROVED:
                        setPhoneNumError(LoginMessage.AGENCY_NOT_APPROVED);
                        break;
                    case LoginError.USER_IS_NOT_ACTIVE:
                        setPhoneNumError(LoginMessage.USER_IS_NOT_ACTIVE);
                        break;
                    case LoginError.ORGANIZATION_NOT_APPROVED:
                        setPhoneNumError(LoginMessage.ORGANIZATION_NOT_APPROVED);
                        break;
                    case LoginError.USER_IS_BLOCKED:
                        setPhoneNumError(LoginMessage.USER_IS_BLOCKED);
                        break;
                    default:
                        setPhoneNumError(LoginMessage.GENERAL_MESSAGE);
                        break;
                }
            }
        } else {
            setPhoneNumError(phoneNumVal)
        }
    }

    //function that handles the onBlur 
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhoneNumError(phoneNumValidation(e.target.value));
    }

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPhoneNumValue(e.target.value);
    }

    return (
        <form>
            <GenericInput
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                placeholder={LoginText.enter_phone_number}
                name="phone"
                error={phoneNumError}
                value={phoneNumValue}
                characterLimit={10}
                hideCharacterLimit
                autoComplete="tel"
                className="login-right"
                phoneInput
                type="tel"
            />
            <GenericButton className="login-right-button cyan big" disabled={disabled} handleClick={sendCodeToUser} >{LoginText.send_code}</GenericButton>
        </form>
    )
}

export default LoginRightFirst;