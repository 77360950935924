//if organization refuses to an event saying its not relevant to them they r shown this page where they 
// say y its not relevant so we can make our algorithm work better
import { FormEvent, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { OvalOptions } from "../../../consts/interfaces/GenericOvalClick.interface";
import { _ERROR_MESSAGE, _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import { CategoryServer } from "../../../consts/interfaces/Categories.interface";
import { NOT_RELEVANT } from "../../../consts/enums/notRelevant.enum";
import { FeedbackType } from "../../../consts/interfaces/Feedback.interface";
import { NOT_RELEVANT_LEFT } from "../../../consts/leftSideLayouts";
import { notInterestedOptions } from '../../../consts/arrays';
import { TITLES } from "../../../consts/hebrew";

import { textValidation } from "../../../functions/validations";
import { ErrorContext } from "../../../context/ErrorContext";
import { UserContext } from "../../../context/UserContext";

import GenericOvalClick from "../../../generic-components/GenericOvalClick";
import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import RadioButtons from "../../../generic-components/RadioButtons";

import '../../../style/notInterested.scss';

const NotInterested: React.FC = () => {
    document.title = NOT_RELEVANT.FEEDBACK_NOT_RELEVANT
    const { showError } = useContext(ErrorContext)
    const { systemID } = useContext(UserContext);
    const location = useLocation();
    const history = useHistory();

    const [radioValue, setRadioValue] = useState<string>(""); // radio buttons value 
    const [buttonClicked, setButtonClicked] = useState(false);
    const [whyNotRel, setWhyNotRel] = useState<string>("");
    const [whyNotRelError, setWhyNotRelError] = useState<string>("");
    const [eventCategories, setEventCategories] = useState<OvalOptions[]>([])
    const [serverLoading, setServerLoading] = useState(false)

    const eventID = useMemo(() => { //checks what id it is by the parameters in the url
        const path = `${location.pathname.split('/')[2]}`;
        return path;
    }, [location]);

    useAsyncEffect(async () => {
        const recievedEventCategories: CategoryServer[] = await (await axios.get(`/api/event/get-chosen-category?id=${eventID}`)).data
        const eventCategories = recievedEventCategories.map(category => ({ id: category.id, title: category.categoryName, selected: false }))
        setEventCategories(eventCategories) // add selected key
    }, [])

    //function that checks if all the input is valid and if it is moves on to the next page
    const handleClick = async () => {
        setButtonClicked(true)
        setServerLoading(true)
        switch (radioValue) {
            case NOT_RELEVANT.OTHER:
                const whyNotRelVal = textValidation(whyNotRel);
                if (!whyNotRelVal) {
                    try {
                        await axios.post('/api/feedback/save-feedback', {
                            organizationId: systemID,
                            feedbackText: whyNotRel,
                            feedbackType: FeedbackType.NOT_INTERESTED,
                            eventId: JSON.parse(eventID),
                        })
                        history.push("/not-relevant-feedback");
                    }
                    catch (error) {
                        showError(_ERROR_MESSAGE)
                        return error;
                    }
                } else {
                    setWhyNotRelError(whyNotRelVal);
                }
                setServerLoading(false)
                break;
            case NOT_RELEVANT.TOO_SOON:
                try {
                    await axios.post('/api/feedback/save-feedback', {
                        organizationId: systemID,
                        feedbackText: radioValue,
                        feedbackType: FeedbackType.NOT_INTERESTED,
                        eventId: JSON.parse(eventID),
                    })
                    history.push("/not-relevant-feedback");
                }
                catch (error) {
                    showError(_ERROR_MESSAGE)
                    return error;
                }
                setServerLoading(false)
                break;
            case NOT_RELEVANT.TIME_NOT_GOOD:
                try {
                    await axios.post('/api/feedback/save-feedback', {
                        organizationId: systemID,
                        feedbackText: radioValue,
                        feedbackType: FeedbackType.NOT_INTERESTED,
                        eventId: JSON.parse(eventID),
                    })
                    history.push("/not-relevant-feedback");
                }
                catch (error) {
                    showError(_ERROR_MESSAGE)
                    return error;
                }
                setServerLoading(false)
                break;
            case NOT_RELEVANT.NOT_MATCH:
                try {
                    const incompatibleCategories: CategoryServer[] = eventCategories.filter(category => category.selected)
                        .map(category => ({ id: category.id, categoryName: category.title }))
                    await axios.post('/api/feedback/save-feedback', {
                        organizationId: systemID,
                        feedbackText: radioValue,
                        feedbackType: FeedbackType.NOT_INTERESTED,
                        eventId: JSON.parse(eventID),
                        incompatibleCategories
                    })
                    history.push("/not-relevant-feedback");
                }
                catch (error) {
                    showError(_ERROR_MESSAGE)
                    return error;
                }
                setServerLoading(false)
                break;
            default:
                setServerLoading(false)
                break;
        }
    }

    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setWhyNotRelError(textValidation(e.target.value));
    }

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setWhyNotRel(e.target.value);
    }

    const handleRadioChange = async (e: FormEvent<HTMLInputElement>) => {
        setRadioValue(e.currentTarget.value)
    }
    const isIos = /iPhone|iPad|iPod|/i.test(navigator.userAgent);

    return (
        <>
            <GenericSplitPage serverLoading={serverLoading} squares={NOT_RELEVANT_LEFT}>
                <div className="right-side-in">
                    <div className="no-title">{TITLES.THANKS_FOR_UPDATE}</div>
                    <div className="paragraph-in">
                        <div>{NOT_RELEVANT.PASS_TO_NEXT_ORG}</div>
                        <div>{NOT_RELEVANT.DONT_WORRY_WE_WILL_OFFER_AGAIN}</div>
                    </div>
                    <div className="wrapper-titles-div"  >
                        <div className="title-text">{NOT_RELEVANT.WHY_NOT_RELEVANT}</div>
                        <div className="title-text">{NOT_RELEVANT.MAKE_BETTER_MATCH}</div>
                    </div>
                    <div className="radio-buttons-not-relevant">
                        <RadioButtons
                            value={radioValue}
                            handleChange={handleRadioChange}
                            options={notInterestedOptions} />
                        {radioValue === NOT_RELEVANT.OTHER &&
                            <GenericInput
                                characterLimit={_LONG_TEXT_MAX_LENGTH}
                                className="text-area"
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                                placeholder={NOT_RELEVANT.ENTER_TEXT}
                                name="whyNotRel"
                                value={whyNotRel}
                                error={whyNotRelError}
                                textarea
                            />
                        }
                        {radioValue === NOT_RELEVANT.NOT_MATCH &&
                            <div className="incompatible-categories">
                                <p className="title">{NOT_RELEVANT.WHICH_CATEGORIES_ARE_NOT_RELEVANT}</p>
                                <GenericOvalClick
                                    options={eventCategories}
                                    setOptions={setEventCategories}
                                    className='categories-select'
                                />
                            </div>
                        }
                    </div>
                    <div className="agency-sign-up-errors">
                        {(radioValue === '' && buttonClicked) ? NOT_RELEVANT.HAVE_TO_FILL : ""}
                    </div>
                    <GenericButton className={!isIos ? "yellow button-in-IOS left" : "yellow button-in left"} handleClick={handleClick}>שליחה</GenericButton>
                </div>
            </GenericSplitPage>
        </>
    )
}

export default NotInterested;