import AddEntranceTicketsFirst from "../pages/agency/add-entrance-tickets/AddEntranceTicketsFirst";
import AddEntranceTicketsThird from "../pages/agency/add-entrance-tickets/AddEntranceTicketsThird";
import AddEntranceTicketsSecond from "../pages/agency/add-entrance-tickets/AddEntranceTicketsSecond";
import EntranceTicketsCouponInputs from "../pages/agency/add-entrance-tickets/EntranceTicketsCouponInputs";
import EntranceTicketsPDFInputs from "../pages/agency/add-entrance-tickets/EntranceTicketsPFDInputs";
import { TICKETS_METHOD } from "./variables";

export const BENEFIT_FORM_PAGES = [
    AddEntranceTicketsFirst,
    AddEntranceTicketsSecond,
    AddEntranceTicketsThird
]

export const TICKET_METHOD_COMPONENT = {
    [TICKETS_METHOD.COUPON]: EntranceTicketsCouponInputs,
    [TICKETS_METHOD.PDF]: EntranceTicketsPDFInputs
}