//this component receives props- TooltipProps
//it returns a generic tooltip (its used in add new event on address and in event page on generic tags - accessability) 

import React, { useContext } from 'react';
import { Fade, useMediaQuery } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/styles';

import { TooltipPropsCustom } from '../consts/interfaces/GenericTooltip.interface';
import { GoogleAnalyticsContext } from '../context/GoogleAnalytics';

const GenericToolTip: React.FC<TooltipPropsCustom> = (props) => {
    const isPhone = useMediaQuery('(max-width:768px)');
    const additionalStyle = props.style
    const { gaEvent } = useContext(GoogleAnalyticsContext)

    const PRIMARY_COLOR = props.isDark ? 'black' : 'white'
    const SECONDARY_COLOR = props.isDark ? 'white' : 'black'

    const StyledTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow />
    ))(({ theme }) => ({
        '& .MuiTooltip-tooltip': {
            direction: "rtl",
            zIndex: '50',
            borderRadius: "0",
            textAlign: "right",
            font: "normal normal 2vh/2.5vh Assistant",
            fontWeight: props.notBold ? undefined : "bold",
            boxShadow: "0 0.278vh 0.556vh #00000029",
            additionalStyle,

            ...(props.isDark ? {
                color: SECONDARY_COLOR,
                backgroundColor: PRIMARY_COLOR,
                maxWidth: 'none'
            } : {
                color: "#41596b",
                backgroundColor: PRIMARY_COLOR,
            }),

            ...(isPhone ? {
                fontSize: '12px',
            } :
                props.isSmall ? {
                    padding: '1vh 2vh',
                } : {
                    padding: '1vh 3vh 1vh 3.5vh',
                }
            )
        }, '& .MuiTooltip-arrow': {
            color: PRIMARY_COLOR
        }
    }
    ));

    let timeout: NodeJS.Timeout // Google Analytics: hovering tooltip,
    const startTimeout = () => { // only when the tooltip is hovering for 1 second at least. clear timeout when the tooltip is not hovering enough time.
        timeout = setTimeout(() => {
            gaEvent('hover', {
                event_category: 'general',
                event_label: props.name + 'hover tooltip'
            })
        }, 1000)
    }

    const stopTimeout = () => {
        clearTimeout(timeout)
    }
    return (
        <>
            <StyledTooltip
                title={props.title}
                placement='bottom-end'
                TransitionComponent={Fade}
                enterTouchDelay={0}
                leaveTouchDelay={0}>
                <div
                    onMouseLeave={() => stopTimeout()}
                    onMouseEnter={() => startTimeout()}>
                    {props.children}
                </div>
            </StyledTooltip>
        </>
    )
}
export default GenericToolTip