import { useContext, useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import FirstTimeAgency from "./FirstTimeAgency";
import UpcomingEvents from "./UpcomingEvents";

import { _ERROR_MESSAGE } from "../../../consts/variables";

import { ErrorContext } from "../../../context/ErrorContext";
import { UserContext } from "../../../context/UserContext";

interface AgencyInfo {
    firstTime: boolean;
    experienceType: number;
}

const AgencyHomePage = () => {
    const { systemID } = useContext(UserContext);
    const { showError } = useContext(ErrorContext);

    const [agencyInfo, setAgencyInfo] = useState<AgencyInfo>()

    //getting events info from database
    useAsyncEffect(async () => {
        try {
            if (systemID) {
                const agencyInfo: AgencyInfo = (await axios.get(`/api/agency/agency-info?id=${systemID}`)).data;
                setAgencyInfo(agencyInfo)
            }
        }
        catch (error) {
            showError(_ERROR_MESSAGE);
            throw error;
        }
    }, [systemID, showError])

    return (
        agencyInfo ? (
            agencyInfo.firstTime ?
                <FirstTimeAgency />
                :
                <UpcomingEvents experienceType={agencyInfo.experienceType} />) :
            null
    )
}

export default AgencyHomePage;
