import clsx from "clsx";
import { ARE_HEARING_AIDS_OWNED, HOW_MANY_HEARING_AIDS_AVAILABLE, INFO_OF_HEARING_AIDS_NOTICE, NO, YES } from "../consts/hebrew"
import { HearingAidsInputProps } from "../consts/interfaces/HearingAidsInput.interface"
import PlusMinusInput from "./PlusMinusInput"
import RadioButtons from "./RadioButtons"
import '../style/hearingAidsInput.scss';

const HearingAidsInput: React.FC<HearingAidsInputProps> = ({ isHearingImpairedAccessible, setIsHearingImpairedAccessible, hearingAidsNumber, setHearingAidsNumber, maxValue, className, error, disabled }) => {

    return (
        <div className={clsx("hearing-aids-input", className)}>
            <div className="hearing-aids-title">{ARE_HEARING_AIDS_OWNED}</div>
            <RadioButtons options={[{ label: NO, value: 'no' }, { label: YES, value: 'yes' }]}
                disabled={disabled}
                value={isHearingImpairedAccessible}
                handleChange={(e) => setIsHearingImpairedAccessible(e.currentTarget.value)}
            />
            <div className="input-container">
                {isHearingImpairedAccessible === "yes" &&
                    <div className="hearing-aids-input-container">
                        <div className="hearing-aids-title">{HOW_MANY_HEARING_AIDS_AVAILABLE}</div>
                        <PlusMinusInput value={hearingAidsNumber} setValue={setHearingAidsNumber} maxValue={maxValue} disabled={disabled} />
                        <div className="hearing-aids-error">
                            {error}
                        </div>
                    </div>
                }
                <div className="secondary-title">{INFO_OF_HEARING_AIDS_NOTICE}</div>
            </div>
        </div>
    )
}

export default HearingAidsInput;