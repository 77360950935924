import { useEffect, useState } from "react"
import axios from "axios"
import { useAsyncEffect } from "@hilma/tools"

import { NO, PURCHASE_TICKETS, YES } from "../../consts/hebrew"
import { PurchaseTicketsProps } from "../../consts/interfaces/PurchaseTickets.interface"

import { User } from "./TicketOrder"
import ContactChoose from "./ContactChoose"
import RadioButtons from "../RadioButtons"
import GenericSeatGroup from "../generic-seat-group/GenericSeatGroup"
import SeatGroupAccessibilityCount from "../generic-seat-group/SeatGroupAccessibilityCount"

import './style/purchaseTickets.scss';

const PurchaseTickets: React.FC<PurchaseTicketsProps> = ({ seatGroups, leftHearingAids, selectedHearingAid, setSelectedHearingAid, systemID, purchaseSeatGroups, setPurchaseSeatGroups, setSelectedUser, purchaseError }) => {
    const [usersArr, setUsersArr] = useState<User[] | null>(null)
    const [needHearingAid, setNeedHearingAid] = useState<string>('')
    const selectedSeatsAmount = purchaseSeatGroups.reduce((amount, seatGroup) => amount + seatGroup.usedSeats, 0)
    const maxOfHearingAids = Math.min(selectedSeatsAmount, leftHearingAids)

    useAsyncEffect(async () => {
        try {
            const res = await axios.get(`/api/organization/all-users-for-contact?id=${systemID}`);
            const data = res.data
            setUsersArr(data)
        }
        catch (err) {
        }
    }, [systemID])

    useEffect(() => {
        if (!usersArr) return;
        setSelectedUser(usersArr[0])
    }, [usersArr])

    const handleChangeInNeedHearing = (e: React.FormEvent<HTMLInputElement>) => {
        setNeedHearingAid(e.currentTarget.value)
        if (e.currentTarget.value === NO) {
            setSelectedHearingAid(0)
        }
    }

    const handleContactSelectChange = (index: number) => {
        setSelectedUser(usersArr?.[index])
    }

    return (
        <div className='purchase-page'>
            <div className="tickets-title">{PURCHASE_TICKETS.HOW_MANY_TICKETS}</div>
            <div className="seat-groups-container">
                {seatGroups.map((seatgroup, index) =>
                    <GenericSeatGroup
                        isOrder
                        key={seatgroup.id}
                        index={index}
                        setPurchaseSeatGroups={setPurchaseSeatGroups}
                        numOfTickets={seatgroup.tickets.length}
                        accessibilities={seatgroup.accessibilities}
                        id={seatgroup.id}
                    />
                )}

                <div className="purchase-error">{purchaseError}</div>
            </div>
            {leftHearingAids > 0 &&
                <div className="hearing-aids-text">
                    <div className='tickets-title'>{PURCHASE_TICKETS.NEED_HEARING_AIDS}</div>
                    <RadioButtons
                        value={needHearingAid}
                        handleChange={(e: React.FormEvent<HTMLInputElement>) => { handleChangeInNeedHearing(e) }}
                        options={[{ label: NO, value: NO }, { label: YES, value: YES }]}
                    />
                    {needHearingAid === YES &&
                        <>
                            <div className="choose-amount">{PURCHASE_TICKETS.CHOOSE_HEARING_AIDS_AMOUNT}</div>
                            <SeatGroupAccessibilityCount
                                numOfTickets={maxOfHearingAids}
                                text={PURCHASE_TICKETS.HEARING_AIDS}
                                imgUrl={'/icons/accessibility/hearing-accessibility.svg'}
                                count={selectedHearingAid}
                                setCount={(value) => setSelectedHearingAid(value)}
                            />
                        </>
                    }
                </div>
            }

            <div className="tickets-title">{PURCHASE_TICKETS.CONTACT_PERSON}</div>
            {usersArr?.map((contact, index) =>
                <ContactChoose key={contact.id} id={contact.id} selectedId={4}
                    handleChange={() => handleContactSelectChange(index)} username={contact.username} name={contact.name} email={contact.email} />
            )}
        </div>
    )
}

export default PurchaseTickets;