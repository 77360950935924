import { IconButton, Modal } from "@mui/material";
import { GenericPopupProps } from "../consts/interfaces/GenericPopup.interface"
import GenericButton from "./GenericButton";
import '../style/genericYesNoPopup.scss';

const GenericYesNoPopup: React.FC<GenericPopupProps> = (props) => {

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown
    >

      <div className={props.image ? "popup-with-img-yes-no" : 'the-popup-yes-no'}>
        <div className='flex-center'>

          {props.image ?
            <>
              <IconButton size="small" onClick={props.handleClose} className='close-button-text'>
                ×
              </IconButton>
              <div className='photo-popup'>
                <img src={props.image} className="the-img" alt="" />
              </div>
            </>
            :
            props.type && props.type !== "acceptOrDeny" ?
              <div className="thumbs-up-popup">
                <IconButton size="small" onClick={props.handleClose} className='close-button-thumbs-up'>
                  ×
                </IconButton>
                <div className="title-and-thumbs-up">
                  <div className='the-title-thumbs-up'>
                    {props.title}
                  </div>
                  <div className="animation-thumbs-up">
                    <img src="/icons/heart-hand.svg" alt="hear hand" className="thumbs-up" />
                  </div>
                </div>
              </div>
              :
              <>
                <div className='the-title'>
                  {props.title}
                </div>
                <div className="yes-or-no-buttons">
                  <GenericButton
                    className={"yellow small"}
                    handleClick={props.handleClose}
                    name={"dont delete"}
                  >{props.type === "acceptOrDeny" ? "ביטול" : "לא"}</GenericButton>
                  <GenericButton
                    className={"turquise small"}
                    handleClick={props.handleYes}
                    name={"delete"}
                  >{props.type === "acceptOrDeny" ? "אישור" : "כן"}</GenericButton>
                </div>
              </>
          }

        </div>
      </div>

    </Modal>
  )
}

export default GenericYesNoPopup;
