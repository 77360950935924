import { ENTER_COUPON_CODE, SEAT_GROUP, TICKET_NUMBER } from "../../../consts/hebrew";
import { EntranceTicketsCouponSeatGroupsInputProps } from "../../../consts/interfaces/EntranceTicketsCouponSeatGroupsInput.interface";
import GenericInput from "../../../generic-components/GenericInput";
import GenericTrashCan from "../../../generic-components/GenericTrashCan";

const EntranceTicketsCouponSeatGroupsInput: React.FC<EntranceTicketsCouponSeatGroupsInputProps> = ({ seatgroup, index, deleteSeatGroup, handleTicketsInputsChange }) => {

    return (
        <div className="coupon-seat-group">
            {deleteSeatGroup &&
                <GenericTrashCan onClick={() => deleteSeatGroup(index)} />}

            <div className="seatgroup-number-title">{SEAT_GROUP + ' ' + (index + 1)}</div>

            <GenericInput
                placeholder={TICKET_NUMBER}
                type="number"
                name="tickets-num"
                className="seatgroup-ticket-num"
                value={seatgroup.ticketNum}
                onChange={(e) => handleTicketsInputsChange(e, "ticketNum", index)}
            />

            <GenericInput
                placeholder={ENTER_COUPON_CODE}
                name="coupon-code"
                characterLimit={8}
                hideCharacterLimit
                value={seatgroup.couponCode}
                onChange={(e) => handleTicketsInputsChange(e, "couponCode", index)}
            />
        </div>
    )
}

export default EntranceTicketsCouponSeatGroupsInput;