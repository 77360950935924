export enum Accessibilities {
    wheelchair = 'מונגש לבעלי כיסאות גלגלים',
    hearing = "מונגש לבעלי ליקויי שמיעה",
    sight = 'מונגש לבעלי ליקויי ראיה',
    non = 'ללא נגישות',
    mobility = 'מונגש לבעלי קשיי ניידות',
    EVERYONE_NEEDS = 'כולם זקוקים להתאמת נגישות',
    PART_OF_THE_ORG = 'חלק זקוקים להתאמת נגישות וחלק לא',
    THERE_IS_NO_NEED_OF_ACCESSIBILITY = 'אין צורך בהתאמת נגישות'

}