const AFK_MINUTES = 15
const AFK_TIMEOUT = AFK_MINUTES * 60 * 1000;

const windowEvents = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const enum BroadcastEvent {
    loggedOut,
    userInteracted
}

type BroadcastEventPayload = {
    type: BroadcastEvent;
}

export class SessionLogOutService {
    private bc: BroadcastChannel
    private logOutTimer: NodeJS.Timeout | null = null;
    private lastForeignEventTime: Date | null = null;
    private lastLocalEventTime: Date | null = null;

    constructor(
        private onLoggedOut: () => void
    ) {
        this.trackEvent = this.trackEvent.bind(this);
        this.untrackEvent = this.untrackEvent.bind(this);
        this.handleBroadcastMessage = this.handleBroadcastMessage.bind(this);
        this.handleLocalInteractionFired = this.handleLocalInteractionFired.bind(this);
        this.handleLogOutTimerTick = this.handleLogOutTimerTick.bind(this);

        this.bc = new BroadcastChannel('auto_log_out')
        this.bc.onmessage = this.handleBroadcastMessage
    }

    start() {
        this.startLogOutTimer()
        windowEvents.forEach(this.trackEvent)
    }

    stop() {
        this.stopLogOutTimer()
        windowEvents.forEach(this.untrackEvent)
    }

    get status(): boolean {
        return !!this.logOutTimer
    }

    private trackEvent(eventName: string): void {
        window.addEventListener(eventName, this.handleLocalInteractionFired)
    }

    private untrackEvent(eventName: string): void {
        window.removeEventListener(eventName, this.handleLocalInteractionFired)
    }

    private stopLogOutTimer() {
        if (!this.logOutTimer) return;
        clearTimeout(this.logOutTimer);
        this.logOutTimer = null;
    }

    private resetLogOutTimer() {
        this.stopLogOutTimer();
        this.startLogOutTimer();
    }

    private startLogOutTimer(): void {
        this.logOutTimer = setTimeout(this.handleLogOutTimerTick, AFK_TIMEOUT)
    }

    private handleLogOutTimerTick(): void {
        if (!this.shouldLogout()) return;
        this.stopLogOutTimer();
        this.postLoggedOutMessage();
        this.onLoggedOut();
    }

    private handleLocalInteractionFired(): void {
        this.lastLocalEventTime = new Date()
        this.postUserInteractedMessage();
        this.resetLogOutTimer()
    }

    private handleForeignInteractionFired(): void {
        this.lastForeignEventTime = new Date();
        this.stopLogOutTimer();
    }

    private handleForeignLoggedOutFired(): void {
        this.stopLogOutTimer()
        this.onLoggedOut()
    }

    private handleBroadcastMessage(e: MessageEvent): unknown {
        if (!this.validateBroadcastMessagePayload(e.data)) return
        switch (e.data.type) {
            case BroadcastEvent.loggedOut:
                this.handleForeignLoggedOutFired()
                break;

            case BroadcastEvent.userInteracted:
                this.handleForeignInteractionFired()
                break;

            default:
                throw Error('data type not supported')
        }
    }

    private validateBroadcastMessagePayload(payload: any): payload is BroadcastEventPayload {
        return (
            !!payload &&
            typeof payload === 'object' &&
            'type' in payload &&
            typeof payload.type === typeof BroadcastEvent.loggedOut
        )
    }

    private shouldLogout(): boolean {
        const now = new Date();
        const latestEventExpiringAt = new Date(now.getTime() - AFK_TIMEOUT);

        const foreignPassed = !this.lastForeignEventTime || latestEventExpiringAt > this.lastForeignEventTime;
        const localPassed = !this.lastLocalEventTime || latestEventExpiringAt > this.lastLocalEventTime;
        return foreignPassed && localPassed
    }

    private postBroadcastMessage(eventType: BroadcastEvent) {
        const payload: BroadcastEventPayload = { type: eventType };
        this.bc.postMessage(payload)
    }

    private postUserInteractedMessage(): void {
        this.postBroadcastMessage(BroadcastEvent.userInteracted)
    }

    private postLoggedOutMessage(): void {
        this.postBroadcastMessage(BroadcastEvent.loggedOut)
    }

}