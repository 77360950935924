//* COMPONENT TO REFACTOR
//Agency profile page contains all agency info and gives option to update it.

import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Area } from "react-easy-crop";
import axios from "axios";
import { useFiles } from "@hilma/fileshandler-client";
import { Box, Tab, Tabs } from "@mui/material";

import AgencyPageSkeleton from "./AgencyPageSkeleton";
import ProfileTab from "./AgencyProfileTab";
import DetailsTab from "./AgencyDetailsTab";

import { EDIT_PROFILE, TITLES, PROFILE, DETAILS, UPDATE_DETAILS } from "../../../consts/hebrew";
import { FILL_THIS_FIELD, _ERROR_MESSAGE, _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import { SELECT_AT_LEAST_ONE_OPTION } from "../../../consts/variables";
import useIsFirstRender from "../../../consts/hooks/useIsFirstRender";
import { FormData } from "../../../consts/interfaces/AgencyProfile.interface";
import { agencyProfileErrors, contactErrors } from '../../../consts/interfaces/formErrors.interface'
import { FilesManagerProfileAgency } from "../../../consts/interfaces/AddNewEventContext.interface";
import { AgeRangeArrayItem, AgeRangeServer } from "../../../consts/interfaces/AgeRange.interface";
import { PopulationArray } from "../../../consts/interfaces/PopulationArray.interface";
import { PopulationArrayServer } from "../../../consts/interfaces/populationArray";
import { CategoriesArray, CategoryServer } from "../../../consts/interfaces/Categories.interface";

import { handleContactsErrors } from "../../../functions/contactsValidations";
import { scrollToFirstError } from "../../../functions/scrollToError";
import { dropDownValidation, emailValidation, fileValidation, longTextValidation, nameValidation, phoneNumValidation, textValidation } from "../../../functions/validations";
import { selectAtLeastOneValidator } from "../../../functions/validations";

import { GoogleAnalyticsContext } from "../../../context/GoogleAnalytics";
import { ErrorContext } from "../../../context/ErrorContext";
import { UserContext } from "../../../context/UserContext";
import { useAgencyContext } from "../../../context/AgencyContext";
import { useConfig } from "../../../context/ConfigContext";

import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import GenericButton from "../../../generic-components/GenericButton";

import { isEmpty } from "lodash";
import { AGENCY_SIGNUP_LEFT } from "../../../consts/leftSideLayouts";

import "../../../style/agencyProfile.scss";

const NewAgencyProfilePage: React.FC = () => {

    document.title = EDIT_PROFILE;

    const history = useHistory();
    const filesUploader = useFiles();
    const firstInputRef = useRef<HTMLInputElement>(null);
    const formRef = useRef(null)
    const { systemID, setName, setUserName, setEmail, userId, refetch } = useContext(UserContext);
    const { showError } = useContext(ErrorContext);
    const { gaEvent, gaException } = useContext(GoogleAnalyticsContext)
    const { featureFlags } = useConfig();
    const isBenefitsFeatureOn = featureFlags["update_agency_profile"]
    const { firstTime, imageLink, agencyType, agencyName, imageCoordinates, contacts, experienceType, radioValue, howToArrive, address, halls, isLoading, setIsLoading, setFirstTime, setContacts, setHowToArrive, setAddress, setHalls } = useAgencyContext();

    const [eventDetailsValue, setEventDetailsValue] = useState(""); // the description of the event
    const [ageRangeArray, setAgeRangeArray] = useState<AgeRangeArrayItem[]>([]);//the array of age ranges and if they are selected or not
    const [categories, setCategories] = useState<CategoriesArray[]>([])
    const [populations, setPopulations] = useState<PopulationArray[]>([]);
    const [filesManager, setFilesManager] = useState<FilesManagerProfileAgency>({ coverImage: null, posterImage: null, profilePhoto: null });
    const [imgCoordinates, setImgCoordinates] = useState<Area>({ width: 0, height: 0, x: 0, y: 0 })
    const [tabIndex, setTabIndex] = useState(0)
    const [atLeastOneHallIsUsed, setAtLeastOneHallIsUsed] = useState<boolean>(false);
    const [serverLoading, setServerLoading] = useState(false)
    const [buttonClicked, setButtonClicked] = useState(false);

    const [eventDetailsError, setEventDetailsError] = useState("");
    const [ageRangeError, setAgeRangeError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [populationError, setPopulationError] = useState("");
    const [fileError, setFileError] = useState("");
    const [imageError, setImageError] = useState<string>("");
    const [agencyTypeError, setAgencyTypeError] = useState<string>("");
    const [agencyNameError, setAgencyNameError] = useState<string>("");
    const [experienceTypeError, setExperienceTypeError] = useState<string>("")
    const [addressError, setAddressError] = useState<string>("");
    const [howToArriveError, setHowToArriveError] = useState<string>("")
    const [toggleError, setToggleError] = useState(true)
    const [errors, setErrors] = useState<agencyProfileErrors>({});

    useEffect(() => {
        fetchAgeRange();
        fetchCategories();
        fetchPopulation();
    }, [])

    useEffect(() => {
        if (!isEmpty(errors)) scrollToFirstError(formRef, firstInputRef, errors)
    }, [errors])

    const hasVisits = isBenefitsFeatureOn ? !!(experienceType & 1) : false
    const hasEvents = !!(experienceType & 2)
    const isFixedLocation = radioValue === 'yes'

    const fetchAgeRange = async () => {
        const { data } = await axios.get("/api/age-range/get-all-ages");
        setAgeRangeArray(data.map((el: AgeRangeServer) => ({ ...el, selected: false })) as AgeRangeArrayItem[])
    }
    const fetchCategories = async () => {
        const { data } = await axios.get('/api/category/get-all-categories');
        setCategories(data.map((el: CategoryServer) => ({ ...el, selected: false })));
    }
    const fetchPopulation = async () => {
        const { data } = await axios.get('api/population')
        setPopulations(data.map((el: PopulationArrayServer) => ({ id: el.id, title: el.populationName, selected: false })))
    }
    const handleValidation = () => {
        if (!validateProfile()) {
            setTabIndex(0)
            return false;
        }
        if (!validateDetails()) {
            setTabIndex(1)
            return false;
        }
        return true
    }
    const validateProfile = () => {
        const imageVal = imageLink ? "" : FILL_THIS_FIELD;
        const agencyTypeVal = textValidation(agencyType);
        const agencyNameVal = textValidation(agencyName);
        const [contactsErr] = validateContacts();
        const contactsVal = Object.keys(contactsErr).length === 0
        const experienceVal = !isBenefitsFeatureOn || experienceType ? '' : SELECT_AT_LEAST_ONE_OPTION;

        setImageError(imageVal);
        setAgencyTypeError(agencyTypeVal);
        setAgencyNameError(agencyNameVal);
        setExperienceTypeError(experienceVal);

        let errors: agencyProfileErrors = {}
        if (agencyNameVal) errors.agencyName = agencyNameVal
        if (agencyTypeVal) errors.agencyType = agencyTypeVal
        if (experienceVal) errors.experience = experienceVal
        if (!contactsVal) errors = { ...errors, ...contactsErr }
        setErrors(errors)
        return (!imageVal && !agencyTypeVal && !agencyNameVal && contactsVal && !experienceVal && !!radioValue);
    }
    const validateDetails = () => {
        if (firstTime || !hasVisits && !isFixedLocation) {
            setAddress('')
            setHowToArrive('')
            setHalls([{ hallName: "", hallNameError: "" }])
            return true
        }
        const eventDetailsVal = hasVisits ? longTextValidation(eventDetailsValue) : '';
        const ageRangeVal = hasVisits ? selectAtLeastOneValidator(ageRangeArray) : '';
        const categoryVal = hasVisits ? selectAtLeastOneValidator(categories) : '';
        const populationVal = hasVisits ? selectAtLeastOneValidator(populations) : '';
        // const fileVal = hasVisits ? fileValidation(fileError, filesManager?.coverImage?.link) : ''; // TODO: fix this line
        const fileVal = hasVisits ? fileValidation(fileError, filesManager.coverImage?.toString()) : ''; //TODO: do this line also for profileLogo
        const addressVal = hasVisits || isFixedLocation ? dropDownValidation(address) : '';
        const howToArriveVal = hasVisits || isFixedLocation ? longTextValidation(howToArrive) : '';
        const hallErrorName = (hasEvents && isFixedLocation) ? validateHalls() : ''

        let errors: agencyProfileErrors = {};
        if (eventDetailsVal) errors.eventDetails = ''
        if (ageRangeVal) errors.ageRange = ''
        if (categoryVal) errors.category = ''
        if (populationVal) errors.population = ''
        if (fileVal) errors.file = ''
        if (addressVal) errors.address = ''
        if (howToArriveVal) errors.navigation = ''
        if (hallErrorName) errors = { ...errors, [hallErrorName]: 'error' }
        setErrors(errors)

        setEventDetailsError(eventDetailsVal)
        setAgeRangeError(ageRangeVal)
        setCategoryError(categoryVal)
        setPopulationError(populationVal)
        setFileError(fileVal)
        setAddressError(addressVal)
        setHowToArriveError(howToArriveVal)

        return (!eventDetailsVal && !ageRangeVal && !categoryVal && !populationVal && !fileVal && !addressVal && !howToArriveVal && !hallErrorName)
    }
    const validateContacts = () => {
        const validatedContacts = handleContactsErrors(contacts)
        setContacts(validatedContacts)
        return validatedContacts.map(({ nameError, phoneError, emailError }) => {
            const contactErrors: contactErrors = {}
            if (nameError) contactErrors.contactName = nameError
            if (phoneError) contactErrors.contactPhone = phoneError
            if (emailError) contactErrors.contactEmail = emailError
            return contactErrors
        })
    }

    const validateHalls = () => {
        const hallsVal = halls.map(hall => ({ ...hall, hallNameError: textValidation(hall.hallName) }));
        setHalls(hallsVal);
        const hallErrorIndex = hallsVal.reduce((acc, val, i) => {
            return (acc === null && val.hallNameError) ? i : acc
        }, null as number | null);
        return hallErrorIndex === null ? '' : `hall${hallErrorIndex}`
    };

    const updateProfile = async () => {
        const formData: FormData = {
            id: systemID,
            agencyType,
            agencyName,
            imageCoordinates,
            contacts: contacts.map(({ id, phone, name, email }) => ({ id, phone, name, email })),
            experienceType,
            filesManager,
        }
        if (!firstTime && (hasVisits || isFixedLocation)) {
            formData.locationDetails = { location: address, navigation: howToArrive }
            if (isFixedLocation) formData.locationDetails.halls = halls.map(({ id, hallName }) => ({ id, hallName }))
        }
        if (!isFixedLocation) {
            formData.locationDetails = { location: null, navigation: null, halls: null }
        }

        setServerLoading(true)
        try {
            await filesUploader.post('/api/agency/edit-agency', formData)
            setFirstTime(false)
            setServerLoading(false)
            refetch()
            contacts?.forEach((contact) => {
                if (contact.id === userId)
                    setName(contact.name)
                setUserName(contact.phone)
                setEmail(contact.email)
            })

            gaEvent('edit_agency')
            history.push("/");
        } catch (error) {
            console.log('_ERROR_MESSAGE: ', _ERROR_MESSAGE);
            showError(_ERROR_MESSAGE)
            gaException('edit_agency')
            setServerLoading(false)
        }
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        switch (e.target.name) {
            case "contactName":
                if (contacts && typeof index === 'number') {
                    const copiedContacts = [...contacts]
                    copiedContacts[index].name = e.target.value
                    setContacts(copiedContacts)
                }
                break;
            case "contactPhone":
                if (contacts && typeof index === 'number') {
                    const copiedContacts = [...contacts]
                    copiedContacts[index].phone = e.target.value
                    setContacts(copiedContacts)
                }
                break;
            case "contactEmail":
                if (contacts && typeof index === 'number') {
                    const copiedContacts = [...contacts]
                    copiedContacts[index].email = e.target.value
                    setContacts(copiedContacts)
                }
                break;
            case "howToArrive":
                setHowToArrive(e.target.value);
                break;
            // case /hall[0-3]/.test(e.target.name):
            // case "hallName":
            //     console.log('e.target.value: ', e.target.value);
            //     console.log('e.target.name: ', e.target.name);
            //     // setHalls(prev => ({ ...prev, [e.target.name]: e.target.value }));
            //     const hallsCopyName = [...halls];
            //     if (typeof index === 'number' && hallsCopyName[index]) {
            //         hallsCopyName[index].hallName = e.target.value;
            //     }
            //     setHalls(hallsCopyName);
            //     break;
            case "eventDetails":
                setEventDetailsValue(e.target.value);
                break;
            default://`hall${i}`
                const hallsCopyName = [...halls];
                if (typeof index === 'number' && hallsCopyName[index]) {
                    hallsCopyName[index].hallName = e.target.value;
                }
                setHalls(hallsCopyName);
                break;
        }
    }
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        switch (e.target.name) {
            case "contactName":
                const nameError = (nameValidation(e.target.value));
                if (contacts && typeof index === 'number') {
                    const copiedContacts = [...contacts]
                    copiedContacts[index].nameError = nameError
                    setContacts(copiedContacts)
                }
                break;
            case "phone":
                const phoneError = (phoneNumValidation(e.target.value));
                if (contacts && typeof index === 'number') {
                    const copiedContacts = [...contacts]
                    copiedContacts[index].phoneError = phoneError
                    setContacts(copiedContacts)
                }
                break;
            case "email":
                const emailError = emailValidation(e.target.value);
                if (contacts && typeof index === 'number') {
                    const copiedContacts = [...contacts]
                    copiedContacts[index].emailError = emailError
                    setContacts(copiedContacts)
                }
                break;
            case "address":
                setAddressError(dropDownValidation(e.target.value));
                break;
            case "navigation":
                setHowToArriveError(longTextValidation(e.target.value));
                break;
            // case "hallName":
            //     const hallsCopyName = [...halls];
            //     if (typeof index === 'number' && hallsCopyName[index]) {
            //         hallsCopyName[index].hallNameError = textValidation(e.target.value);
            //     }
            //     setHalls(hallsCopyName);
            //     break;
            case "eventDetails":
                setEventDetailsError(longTextValidation(e.target.value));
                break;
            default://`hall${i}`
                const hallsCopyName = [...halls];
                if (typeof index === 'number' && hallsCopyName[index]) {
                    hallsCopyName[index].hallNameError = textValidation(e.target.value);
                }
                setHalls(hallsCopyName);
        }
    }
    const handleTabChange = (e: React.SyntheticEvent, index: number) => {
        setTabIndex(index);
    }
    const handleUpdateClick = () => {
        setButtonClicked(true);
        if (!handleValidation()) {
            setButtonClicked(false);
            setToggleError(prev => !prev);
            return;
        }
        updateProfile()
    }

    const profileTabProps = { filesUploader, filesManager, atLeastOneHallIsUsed, buttonClicked, toggleError, imageError, agencyTypeError, agencyNameError, experienceTypeError, setAgencyNameError, setFilesManager, handleInputBlur, handleInputChange }
    const detailsTabProps = { filesUploader, filesManager, toggleError, eventDetailsValue, ageRangeArray, categories, populations, imgCoordinates, hasVisits, hasEvents, isFixedLocation, eventDetailsError, ageRangeError, categoryError, populationError, fileError, addressError, howToArriveError, setAgeRangeArray, setCategories, setPopulations, setImgCoordinates, setFilesManager, setAddressError, setAgeRangeError, setCategoryError, setFileError, handleInputChange, handleInputBlur }

    return (
        <div className="agency-profile">
            <GenericSplitPage serverLoading={serverLoading} isAgency squares={AGENCY_SIGNUP_LEFT}>
                {isLoading ?
                    <AgencyPageSkeleton />
                    :
                    <form ref={formRef}>
                        <div className="inputs-container">
                            <div className="agency-sign-up-right-title" ref={firstInputRef}>{TITLES.UPDATE_AGENCY_DETAILS}</div>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs className="tabs" value={tabIndex} onChange={handleTabChange}>
                                    <Tab className={`tab ${!tabIndex ? 'active' : ''}`} label={PROFILE} disableRipple />
                                    <Tab className={`tab ${tabIndex ? 'active' : !hasVisits && !isFixedLocation ? 'disabled' : ''}`} label={DETAILS} disableRipple disabled={!hasVisits && !isFixedLocation} />
                                </Tabs>
                            </Box>

                            {!tabIndex ? <ProfileTab {...profileTabProps} /> : <DetailsTab {...detailsTabProps} />}
                            <GenericButton className="agency-sign-up-right-button yellow left" serverLoading={serverLoading} handleClick={handleUpdateClick}>{UPDATE_DETAILS}</GenericButton>
                        </div>
                    </form>}
            </GenericSplitPage>
        </div>)
}

export default NewAgencyProfilePage;
