async function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register(
        '/worker.js',
        {
          scope: '/',
        }
      );

    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
}

export default registerServiceWorker