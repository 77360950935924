import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { IconButton, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useIsMobile from "../consts/hooks/useIsMobile";
import { ButtonsText } from "../consts/hebrew"

import PopupDeleteEvent from "../popups/agency/PopupDeleteEvent"
import PopupDuplicateEvent from "../popups/agency/PopupDuplicateEvent"

interface EventDropDownProps {
    eventId: number;
    ableToEdit?: boolean;
    disableAddTickets?: boolean;
}

const EventDropDown: React.FC<EventDropDownProps> = ({ eventId, ableToEdit, disableAddTickets }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const isMobile = useIsMobile()

    const [deletePopup, setDeletePopup] = useState(false);
    const [duplicatePopup, setDuplicatePopup] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const cancelEvent = () => {
        setDeletePopup(true);
    }
    const duplicateEvent = () => {
        setDuplicatePopup(true)
    }

    const dropDownElements = [
        { label: ButtonsText.CancelEvent, onClick: cancelEvent },
        { label: ButtonsText.Duplicate, onClick: duplicateEvent },
        ...(ableToEdit ?
            [{ label: ButtonsText.EditEvent, onClick: () => history.push(`/edit-event/${eventId}`) }] :
            []),
        ...(!disableAddTickets ?
            [{ label: ButtonsText.AddTickets, onClick: () => history.push(`/event/add-tickets/${eventId}`) }] : []),
    ]

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: isMobile ? '40vw' : '10vw',
                    },
                }}
                anchorOrigin={isMobile ? {
                    vertical: 'top',
                    horizontal: 'left',
                } : undefined}
                transformOrigin={isMobile ? {
                    vertical: 'bottom',
                    horizontal: 'left',
                } : undefined}
            >
                {dropDownElements.map((dropDownElement, index) => (
                    <MenuItem key={dropDownElement.label}
                        onClick={dropDownElement.onClick}
                        divider={index !== dropDownElements.length - 1}
                        sx={{ padding: '0.8vh', paddingRight: '1.4vh' }}>
                        {dropDownElement.label}
                    </MenuItem>
                ))}
            </Menu>

            <PopupDeleteEvent eventId={eventId} open={deletePopup} handleClose={() => { setDeletePopup(false) }} />
            <PopupDuplicateEvent open={duplicatePopup} eventId={eventId} />
        </div >
    )
}

export default EventDropDown