export enum DELETE_EVENT {
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL = 'בטוח שברצונך לבטל את האירוע?',
    YES = 'כן',
    NO = 'לא',
    EVENT_CANCELED = "האירוע בוטל",
    EVENT_MOVED_TO_DIFFERENT_DATE = "האירוע נדחה למועד אחר",
    EVENT_DOES_NOT_HAVE_AVAILABLE_TICKETS = "הביקוש לאירוע עולה ואני חושב שכבר לא יהיו לי כרטיסים פנויים כמו שחשבתי",
    ORGANIZATIONS_DID_NOT_BUY_TICKETS = "לא שוריינו כרטיסים מאנטר ורציתי לנסות לממש את הכרטיסים בדרך אחרת",
    THANKS_FOR_YOUR_UPDATE = "תודה על העדכון!",
    WE_WILL_UPDATE_ALL_ORGANIZATIONS_ABOUT_THE_CANCEL = "אנו נדאג לעדכן את העמותות שהזמינו כרטיסים לאירוע על ביטולו",
    WHY_DID_YOE_DECIDE_TO_CANCEL_TICKETS_FOR_EVENT = "נשמח לדעת, מדוע החלטת לבטל את הכרטיסים לאירוע?",
    OTHER = "אחר",
    SEND = "שליחה",
    TYPE_TEXT = "הקלד טקסט",
    OFFER_FOR_YOUR_ORGANIZATION = "הצעות עבור העמותה שלך",
    OFFER_EVENT_FOR_YOUR_ORGANIZATION = "הצעות לאירועים עבור העמותה שלך",
    EMPTY_TICKET = "כרטיסים פנויים",
    HAVE_TO_FILL_THIS_FIELD = 'יש למלא שדה זה'
}