import './style/organizationInfoForStatusOrder.scss'

const OrganizationInfoForStatusOrder: React.FC<{ title: string, value?: string }> = ({ title, value }) => {
    return (
        <>
            {value ?
                <div className="organization-info-item">
                    <p className="organization-info title">{title}</p>
                    <p className="organization-info value">{value}</p>
                </div>
                : undefined}
        </>
    )
}
export default OrganizationInfoForStatusOrder