import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { initialize, Event, VariationValue } from '@harnessio/ff-javascript-client-sdk';

type FeatureFlagRepository = Record<string, VariationValue>;
interface ConfigContextModel {
  featureFlags: FeatureFlagRepository,
}
const ConfigContext = createContext<ConfigContextModel>({
  featureFlags: {},
});

interface ConfigContextProviderProps {
  children: ReactNode;
}
export function ConfigContextProvider({ children }: ConfigContextProviderProps) {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagRepository>({});

  useEffect(() => {
    const harnessClient = initialize(
      process.env.REACT_APP_HARNESS_CONFIG_SDK_KEY!,
      { identifier: 'default' },
      { baseUrl: 'https://config.ff.harness.io/api/1.0', eventUrl: 'https://events.ff.harness.io/api/1.0' }
    );

    harnessClient.on(Event.READY, setFeatureFlags);
    harnessClient.on(Event.CHANGED, (flagInfo) => {
      if (flagInfo.deleted) {
        setFeatureFlags((currentFeatureFlags) => {
          delete currentFeatureFlags![flagInfo.flag];
          return { ...currentFeatureFlags };
        });
        return;
      }

      setFeatureFlags((currentFeatureFlags) => ({ ...currentFeatureFlags, [flagInfo.flag]: flagInfo.value }));
    });

    return () => {
      harnessClient?.close();
    }
  }, []);

  return (
    <ConfigContext.Provider value={{ featureFlags }}>
      {children}
    </ConfigContext.Provider>
  )
}

export function useConfig() {
  return useContext(ConfigContext);
}
