import { UploadedFile } from "@hilma/fileshandler-client";
import { Moment } from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { TICKET_METHOD_COMPONENT } from "../../../consts/BenefitFormPages";
import { BenefitSeatGroup } from "../../../consts/entranceTicketsTypes";
import { AddEntranceTicketsFields, HOW_MANY_TICKET_DO_YOU_WANT_TO_GIVE, POST_ENTRANCE_TICKETS, PREVIOUS, WHAT_IS_THE_VALUE_OF_TICKET } from "../../../consts/hebrew";
import { EntranceTicketsContextInterface } from "../../../consts/interfaces/AddEntranceTicketsContext.interface";
import { EMPTY_BENEFIT_SEATGROUP, HEARING_AIDS_ERROR, SEAT_GROUP_SUM_ERROR, TICKETS_METHOD } from "../../../consts/variables";
import { AddEntranceTicketsContext } from "../../../context/AddEntranceTicketsContext";
import { dateValidation, linkValidation, numValidation } from "../../../functions/validations";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import EntranceTicketsExpirationInput from "./EntraceTicketsExpirationInput";
import HearingAidsInput from "../../../generic-components/HearingAidsInput";

const AddEntranceTicketsThird = () => {
    const { ticketMethod, saveThirdPageDetails, postBenefitTickets, getThirdPageDetails, gotoPrevPage } = useContext(AddEntranceTicketsContext) as EntranceTicketsContextInterface
    const firstInputRef = useRef<HTMLInputElement>(null);

    const [ticketNum, setTicketNum] = useState<string>("")
    const [singleTicketPrice, setSingleTicketPrice] = useState<string>("")
    const [expirationDate, setExpirationDate] = useState<Moment | null>(null)
    const [webLink, setWebLink] = useState<string>('')
    const [seatGroups, setSeatgroups] = useState<BenefitSeatGroup[]>([])
    const [isHearingImpairedAccessible, setIsHearingImpairedAccessible] = useState<string>("")
    const [hearingAidsNumber, setHearingAidsNumber] = useState<number>(0)

    const [ticketNumError, setTicketNumError] = useState<string>("")
    const [ticketWorthError, setTicketWorthError] = useState<string>('')
    const [expirationDateError, setExpirationDateError] = useState<string>('')
    const [webLinkError, setWebLinkError] = useState<string>("");
    const [seatGroupsError, setSeatGroupsError] = useState('')
    const [hearingAidsError, setHearingAidsError] = useState<string>("");

    useEffect(() => {
        const { ticketNum, singleTicketPrice, expirationDate, webLink, seatGroups, hearingAidsNumber } = getThirdPageDetails()
        setTicketNum(ticketNum)
        setSingleTicketPrice(singleTicketPrice)
        setExpirationDate(expirationDate)
        setWebLink(webLink)
        setSeatgroups(seatGroups)
        setHearingAidsNumber(hearingAidsNumber)

        if (hearingAidsNumber > 0)
            setIsHearingImpairedAccessible("yes")
    }, [])

    const handlePreviousClick = () => {
        gotoPrevPage()
        saveThirdPageDetails({ ticketNum, singleTicketPrice, expirationDate, hearingAidsNumber, seatGroups, webLink })
    }

    const addSeatGroup = () => {
        setSeatgroups(prev => prev.concat({ ...EMPTY_BENEFIT_SEATGROUP }))
    }

    const deleteSeatGroup = (index: number) => {
        setSeatgroups(prev => prev.slice(0, index).concat(prev.slice(index + 1)))
    }

    const handleTicketsInputsChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, name: string, index: number) => {
        setSeatgroups(prev => prev.map((seatgroup, ind) => ind === index ? ({ ...seatgroup, [name]: e.target.value }) : seatgroup))
    }

    const handleFileInputChange = (file: UploadedFile, index: number) => {
        setSeatgroups(prev => prev.map((seatgroup, ind) => ind === index ? ({ ...seatgroup, file: { ...file, isNew: true } }) : seatgroup))
    }

    const handlePostBenefitTickets = () => {
        if (validateFields()) {
            postBenefitTickets({ ticketNum, singleTicketPrice, expirationDate, hearingAidsNumber, seatGroups, webLink })
        } else {
            firstInputRef.current?.scrollIntoView({ block: "end", behavior: 'smooth' })
        }
    }

    const validateFields = () => {
        const ticketNumVal = numValidation(ticketNum)
        setTicketNumError(ticketNumVal)

        const ticketWorthVal = numValidation(singleTicketPrice);
        setTicketWorthError(ticketWorthVal)

        const expirationDateVal = dateValidation(expirationDate)
        setExpirationDateError(expirationDateVal)

        const webLinkVal = ticketMethod === TICKETS_METHOD.COUPON ? linkValidation(webLink) : "";
        setWebLinkError(webLinkVal);

        const hearingAidsVal = isHearingImpairedAccessible === "" ? 'יש למלא שדה זה' : Number(ticketNum) - hearingAidsNumber < 0 ? HEARING_AIDS_ERROR : ""
        setHearingAidsError(hearingAidsVal)

        const seatGroupTicketSum = seatGroups.reduce((sum: number, current) => sum + Number(current.ticketNum), 0)
        const seatGroupTicketSumVal = seatGroupTicketSum === Number(ticketNum) ? "" : SEAT_GROUP_SUM_ERROR;
        setSeatGroupsError(seatGroupTicketSumVal);


        return !(ticketNumVal || ticketWorthVal || expirationDateVal || webLinkVal || hearingAidsVal || seatGroupTicketSumVal)
    }

    return (
        <div className="add-entrance-tickets">

            <GenericInput
                title={HOW_MANY_TICKET_DO_YOU_WANT_TO_GIVE}
                type='number'
                value={ticketNum}
                onChange={(e) => setTicketNum(e.target.value)}
                name="ticket-num"
                characterLimit={3}
                hideCharacterLimit
                error={ticketNumError}
                inputRef={firstInputRef}
            />

            <div className="ticket-worth-input">
                <GenericInput
                    title={WHAT_IS_THE_VALUE_OF_TICKET}
                    type='number'
                    value={singleTicketPrice}
                    onChange={e => setSingleTicketPrice(e.target.value)}
                    name="ticket-worth"
                    characterLimit={4}
                    hideCharacterLimit
                    error={ticketWorthError}
                />
                <p className="shekel-sign">₪</p>
            </div>

            <EntranceTicketsExpirationInput date={expirationDate} setDate={setExpirationDate} error={expirationDateError} />

            <div className="input-container">
                <div className="input-title">{AddEntranceTicketsFields.TICKETS}</div>
                {ticketMethod &&
                    TICKET_METHOD_COMPONENT[ticketMethod]({ seatGroups, seatGroupsError, webLink, setWebLink, webLinkError, addSeatGroup, deleteSeatGroup, handleTicketsInputsChange, handleFileInputChange })}
            </div>

            <HearingAidsInput
                className="benefit-hearing-aids"
                isHearingImpairedAccessible={isHearingImpairedAccessible}
                setIsHearingImpairedAccessible={setIsHearingImpairedAccessible}
                hearingAidsNumber={hearingAidsNumber}
                setHearingAidsNumber={setHearingAidsNumber}
                maxValue={Number(ticketNum)}
                error={hearingAidsError}
            />

            <div className="add-entrance-buttons">
                <div onClick={handlePreviousClick} className="back-btn">{PREVIOUS}</div>
                <GenericButton handleClick={handlePostBenefitTickets} className="yellow continue-btn post-btn">{POST_ENTRANCE_TICKETS}</GenericButton>
            </div>
        </div>
    )
}

export default AddEntranceTicketsThird;