import { Area } from 'react-easy-crop'
import GenericProfilePic from '../GenericProfilePic'
import './style/giveBy.scss'

interface giveByProps {
    agencyName: string;
    agencyImageCoordinates: Area;
    agencyProfilePhoto: string
}

const GiveBy: React.FC<giveByProps> = ({ agencyImageCoordinates, agencyName, agencyProfilePhoto }) => {

    return (
        <div className="give-by">
            <div className='give-by-text'>
                הוענק ע"י
            </div>
            <GenericProfilePic src={agencyProfilePhoto} imageCoordinates={agencyImageCoordinates} className='give-by-logo' />
            <span className='give-by-text'>{agencyName}</span>
        </div>
    )
}
export default GiveBy