import { NOT_RELEVANT } from "./enums/notRelevant.enum";
import { DONATE_TICKETS, NO_EACH_EVENT_TAKES_PLACE_IN_DIFFERENT_PLACE, SPONSOR_EVENTS, YES_I_WOULD_LIKE_TO_UPDATE_OUR_PERMANENT_PLACES_ONCE } from "./hebrew";

export const notInterestedOptions = [
    { label: NOT_RELEVANT.TOO_SOON, value: NOT_RELEVANT.TOO_SOON },
    { label: NOT_RELEVANT.TIME_NOT_GOOD, value: NOT_RELEVANT.TIME_NOT_GOOD },
    { label: NOT_RELEVANT.NOT_MATCH, value: NOT_RELEVANT.NOT_MATCH },
    { label: NOT_RELEVANT.OTHER, value: NOT_RELEVANT.OTHER }
]
export const populationOptions = [
    { id: 1, title: 'כללי' },
    { id: 2, title: 'דתי' },
    { id: 3, title: 'ערבי' },
    { id: 4, title: 'חרדי' }
]
export const experienceTypeOptions = [
    { name: 'donateTickets', bitIndex: 0, label: DONATE_TICKETS },
    { name: 'sponsorEvents', bitIndex: 1, label: SPONSOR_EVENTS },
]
export const radioOptions = [
    { label: YES_I_WOULD_LIKE_TO_UPDATE_OUR_PERMANENT_PLACES_ONCE, value: 'yes' },
    { label: NO_EACH_EVENT_TAKES_PLACE_IN_DIFFERENT_PLACE, value: 'no' }
]