import { FC } from "react";
import { useConfig } from "../context/ConfigContext";

export function featureFlagComponent<P extends {}, C extends FC<P>>(featureId: string, FeatureComponent: C, ReplacedComponent?: C): FC<P> {

  const wrapperComponent = (props: P) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { featureFlags } = useConfig();
    const featureIsEnabled = featureFlags[featureId] === true;
    if (!featureIsEnabled) return ReplacedComponent ? <ReplacedComponent {...props as any} /> : <></>;
    return <FeatureComponent {...props as any} />;
  }

  const originalComponentDisplayName = FeatureComponent.displayName || FeatureComponent.name;
  wrapperComponent.displayName = `FeatureFlag: ${featureId}(${originalComponentDisplayName})`;

  return wrapperComponent;
}
