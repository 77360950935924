//In the header the message icon shows the number of messages sent. Here we manage showing the updated number.

import React, { ReactNode, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useIsAuthenticated } from "@hilma/auth";
import { useAsyncEffect } from "@hilma/tools";
import { CounterContextInterface } from "../consts/interfaces/CountMessagesContext.interface";
import { _ERROR_MESSAGE } from "../consts/variables";

import { SocketContext } from "./SocketContext";
import { ErrorContext } from "./ErrorContext";
import { UserContext } from "./UserContext";
export const counterProvider = React.createContext<CounterContextInterface>({
    count: 0,
    readFeedback: () => undefined
});
function MessagesProvider({ children }: { children: ReactNode }) {
    // Initialize state
    const [count, setCount] = useState(0);
    const { role, systemID } = useContext(UserContext);
    const { showError } = useContext(ErrorContext);
    const { feedbackSent } = useContext(SocketContext);
    const isAuthenticated = useIsAuthenticated()

    // Fetch data
    useAsyncEffect(async () => {
        try {
            if (role === 'agency' && systemID && isAuthenticated) {
                const numOfMessages = await axios.get(`/api/feedback/count-feedbacks-per-agency?id=${systemID}`)
                setCount(numOfMessages.data);
            }
        } catch (error) {
            showError(_ERROR_MESSAGE)
            console.error(error);
        }
    }, [role, showError, systemID, feedbackSent])

    // Todo:  we have to add 1 to the setCount only if the feedback was received from new event that doesn't have unread feedback  

    useEffect(() => {
        if (feedbackSent !== undefined) {
            setCount(prevCount => prevCount + 1)
        }
    }, [feedbackSent])

    const readFeedback = () => {
        if (count > 0) {
            setCount(prev => prev - 1)
        }
    }
    return (
        <counterProvider.Provider value={{ count, readFeedback }} >
            {children}
        </counterProvider.Provider>
    );
}
export default MessagesProvider;

