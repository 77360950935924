import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { MultipleFilesInput, UploadedFile, UploadError, useFiles } from '@hilma/fileshandler-client';

import { FeedbackHebrew, MaxCapacityImageError, SAVE, SEND_THANK_YOU, TITLES, TYPE_TEXT_PLACEHOLDER } from '../../../consts/hebrew';
import { FeedbackType, FileBody } from '../../../consts/interfaces/Feedback.interface';
import { _ERROR_MESSAGE, _FILE_LIMIT, _FILE_TOO_BIG, _LONG_TEXT_MAX_LENGTH } from '../../../consts/variables';
import { ORGANIZATION_FEEDBACK_PAGE_LEFT } from '../../../consts/leftSideLayouts';
import { EventDetailsFeedback } from '../../../consts/interfaces/Event.interface';
import useParamsInterface from '../../../consts/interfaces/UseParams.interface';
import { useExitBrowser } from '../../../consts/hooks/useExitBrowser';

import { longTextValidation } from '../../../functions/validations';
import { eventToDismantledDate } from '../../../functions/dateFunctions';
import { ErrorContext } from '../../../context/ErrorContext';
import { UserContext } from '../../../context/UserContext';

import GenericSplitPage from '../../../generic-components/GenericSplitPage';
import GenericButton from '../../../generic-components/GenericButton';
import GenericInput from '../../../generic-components/GenericInput';

import './style/giveFeedbackToAgency.scss';

const GiveFeedbackToAgency: React.FC = () => {

    document.title = "אומרים תודה"

    useExitBrowser()
    const _MAX_IMAGES_FOR_FEEDBACK = 15

    const { systemID } = useContext(UserContext);
    const { showError } = useContext(ErrorContext)
    const filesUploader = useFiles();
    const history = useHistory();

    const [serverLoading, setServerLoading] = useState(false)
    const [pictureLinks, setPictureArr] = useState<string[]>([]); // the pictures links 

    const [inputTextValue, setInputTextValue] = useState('');
    const [inputTextError, setInputTextError] = useState('');

    const [fileError, setFileError] = useState('');
    const [eventDetails, setEventDetails] = useState<EventDetailsFeedback>({
        date: '',
        name: ''
    })
    const [imageDisabled, setImageDisabled] = useState(false)

    const { id } = useParams() as useParamsInterface;
    const eID = Number(id)
    const { feedbackId, text } = useLocation().state as unknown as { text: string, feedbackId: number } || { text: '', feedbackId: null }

    const fetchDetailsOnEvent = useCallback(async () => {
        const eventFromServer = (await axios.get(`/api/event/get-name-date?event_id=${eID}`)).data
        const { date, day, time } = eventToDismantledDate(eventFromServer.date)
        setEventDetails({
            date: ` ${day} | ${date} | ${time}`,
            name: eventFromServer.eventName
        })

        if (text)
            setInputTextValue(text)
    }, [eID])

    //gets name and date of the event reviewing 
    useEffect(() => {
        fetchDetailsOnEvent()
    }, [fetchDetailsOnEvent])


    //function that checks if all the input is valid and if it is moves on to the next page
    const handleClick = async () => {
        const sayToOrgVal = longTextValidation(inputTextValue);
        if (!sayToOrgVal /* && !numToOrgVal && !imageVal*/) {
            setServerLoading(true)
            const fileBody: FileBody = {
                organizationId: systemID,
                feedbackText: inputTextValue,
                feedbackType: FeedbackType.THANK_YOU,
                eventId: eID,
            }
            if (feedbackId) fileBody.id = feedbackId
            try {
                await filesUploader.post('/api/feedback/save-feedback', fileBody)
            }
            catch (error) {
                setServerLoading(false)
                showError(_ERROR_MESSAGE)
                return error;
            }
            setServerLoading(false)
            if (feedbackId) history.goBack()
            else history.push("/review-sent");
        } else {
            setInputTextError(sayToOrgVal);
        }
    }

    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputTextError(longTextValidation(e.target.value));
    }

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputTextValue(e.target.value);
        setInputTextError("");
    }

    // file uploader add image
    const handleImageChange = (newFiles: UploadedFile[]) => { // function that hande
        if (pictureLinks.length + newFiles.length > _MAX_IMAGES_FOR_FEEDBACK) {
            handleReachMaxCapacity(newFiles.length, pictureLinks.length, false)
            newFiles.forEach(({ link }) => filesUploader.delete(link));

        }
        else {
            setFileError(""); // if there is an error in the file uploader, it will be removed
            const newLinks = newFiles.map(singleFile => singleFile.link)
            setPictureArr(prev => [...prev, ...newLinks]) // add the new links the list
        }
    };

    useEffect(() => {
        if (pictureLinks.length === _MAX_IMAGES_FOR_FEEDBACK) {
            handleReachMaxCapacity(0, pictureLinks.length)
        } else {
            setFileError('');
        }
    }, [pictureLinks])

    const handleReachMaxCapacity = (addedAmountRecived: number, currentAmountRecived: number, disableInput = true) => { // if the user reached to the max images capacity disable the input and show error
        disableInput && setImageDisabled(true);
        setFileError(
            MaxCapacityImageError(addedAmountRecived, currentAmountRecived, _MAX_IMAGES_FOR_FEEDBACK)
        );
    }

    const handleUploadError = (err: UploadError) => { // function that handles the error
        if (err.type === "wrong-type") {
            setFileError(`היית אמור להעלות תמונה אבל במקום זה העלאת קובץ מסוג ${err.mimeType}, סוג קובץ שגוי`) // if the file is not an image  - error message
        } else {
            setFileError(_FILE_TOO_BIG); // if the file is too big, an error will be shown
        }
    }

    const DeleteImage = (link: string) => {
        filesUploader.delete(link);
        setPictureArr(prev => prev.filter(item => item !== link));
        setImageDisabled(false)
        setFileError("")
    }

    return (
        <>
            <GenericSplitPage serverLoading={serverLoading} className="reveiw-full-page"
                squares={ORGANIZATION_FEEDBACK_PAGE_LEFT}>
                <div className="review-right-side">
                    <div className="review-right-side-container">
                        <div className="review-right-title">{TITLES.SAY_THANKS}</div>
                        <div className="review-right-paragraph">
                            <div className="artist">{eventDetails.name}</div>
                            <div className="date">{eventDetails.date}</div>
                        </div>

                        <div className="review-right-gen-input">
                            <GenericInput
                                title={FeedbackHebrew.WHAT_DO_YOU_WANT_TO_TELL}
                                name="sayToOrg"
                                placeholder={TYPE_TEXT_PLACEHOLDER}
                                value={inputTextValue}
                                error={inputTextError}
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                                characterLimit={_LONG_TEXT_MAX_LENGTH}
                                textarea
                            />
                        </div>
                        {!feedbackId &&
                            <div>
                                <div className="review-input-container">
                                    <div className="review-first">
                                        <div className="upload-arrow-image-back">
                                            <div className="review-first-pic">
                                                <img src="/icons/file_upload.svg" className="upload-arrow-image" alt="upload" />
                                                <div>{FeedbackHebrew.LOADING_PHOTOS}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <MultipleFilesInput
                                        className="input-file-text"
                                        type="image"
                                        singleUpload={false}
                                        sizeLimit={_FILE_LIMIT}
                                        filesUploader={filesUploader}
                                        onChange={handleImageChange}
                                        onError={handleUploadError}
                                        disabled={imageDisabled}
                                    />
                                </div>
                                <div className="review-errors-first">
                                    {fileError}
                                </div>
                                {pictureLinks.map((link) => {
                                    return (
                                        <div key={link}>
                                            <div className="upload-arrow-images-second">
                                                <div className="img-border">
                                                    <img src={link} className="selected-image" alt="profile" />
                                                </div>
                                                <button onClick={() => DeleteImage(link)} className="review-pic-text">{FeedbackHebrew.DELETE_IMAGE}</button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>}
                        <GenericButton className="review-event yellow review left" serverLoading={serverLoading} handleClick={handleClick}>{feedbackId ? SAVE : SEND_THANK_YOU}</GenericButton>
                    </div>
                </div>
            </GenericSplitPage>
        </>
    )

}

export default GiveFeedbackToAgency;
