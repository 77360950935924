import { FC } from "react"
import { Link } from "react-router-dom"
import { ADDITIONAL_FEEDBACK, VIEW_ALL_FEEDBACKS } from "../../../consts/hebrew"
import GenericProfilePic from "../../../generic-components/GenericProfilePic"
import './style/thirdCard.scss'


const ThirdCard: FC = () => {
    return (
        <div className="thank-you-card third-card">
            <GenericProfilePic className="profile-pic" src={'./icons/hand-heart-yellow.svg'} imageCoordinates={{ x: -25, y: 0, width: 150, height: 100 }} />
            <div className="flex-center main" children={ADDITIONAL_FEEDBACK}></div>
            <div className="horizontal-split"></div>
            <Link className="flex-center link" to="./archive" children={VIEW_ALL_FEEDBACKS} />
        </div>
    )
}
export default ThirdCard