import { useMemo } from "react";
import { Area } from "react-easy-crop";

const useImageCoordinates = (imageCoordinates?: Area) =>
    useMemo((): React.CSSProperties => (imageCoordinates ? {
        position: 'absolute',
        width: `${100 * 100 / imageCoordinates.width}%`, // makes the img bigger so the visible part is smaller
        left: `${-imageCoordinates.x * 100 / imageCoordinates.width}%`, // moves the img left so the cropped part moves right, proportionate to the img width
        top: `${-imageCoordinates.y * 100 / imageCoordinates.height}%` // moves the img up so the cropped part moves down, proportionate to the img height
    } : {}), [imageCoordinates])

export default useImageCoordinates;