//this component gets props - id: number, title: string, categories : CategoriesArray[], setCategories : Dispatch<SetStateAction<CategoriesArray[]>>ץ
//it returns a category in the categories array that comes from the popup

import React from 'react';
import { CategoryPopupArrayInterface } from '../../consts/interfaces/Categories.interface';
import FocusAble from '../../generic-components/FocusAble';

import '../style/typePopupArray.scss';

const PopupArrayCategory: React.FC<CategoryPopupArrayInterface> = ({ deleteMySelf, title }) => {

    return (
        <div className="popup-array-category">
            <FocusAble onClick={(e) => {
                e.stopPropagation();
                deleteMySelf();
            }}>
                <img
                    className="close-category" src="/icons/close-circle.svg"
                    alt="close" />
            </FocusAble>
            <div className='title-popup-array'>{title}</div>
        </div>
    )
}

export default PopupArrayCategory;