import axios from 'axios';
import { saveAs } from 'file-saver';


export interface downloadFileParameter {
    path: string;
    fileName: string;
}
//function takes blob type file and downloads it
const downloadFile = async (path: string, fileName: string) => {
    const blob = await getFileBlob(path)
    saveAs(blob, fileName)
}

const getFileBlob = async (path: string) => {
    const res = await axios({
        url: path,
        method: "GET",
        responseType: "blob"
    });
    return res.data
}

export { downloadFile }
 


