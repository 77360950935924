import { EventInfoInterfaceFromServer } from "../consts/interfaces/Event.interface";

export const filterFuturePurchasedEvents = (allPurchasedEvents: EventInfoInterfaceFromServer[]) => {
    return allPurchasedEvents.filter(event => isFuture(event.date)).map(event => ({
        coverImage: event.coverImage,
        eventName: event.eventName,
        date: event.date,
        ticketsPurchased: event.ticketsPurchased.length,
        eventId: event.id,
        imageCoordinates: event?.imageCoordinates,
        ticketsPurchasedByOrg: event.ticketsPurchasedByOrg
    }))
}

export const isFuture = (date: string | Date) => {
    return new Date(date) > new Date();
}