
import { useHistory } from "react-router-dom";
import { GenericFeedbackProps } from "../consts/interfaces/GenericFeedback.interface";
import { BACK_TO_HOME_PAGE } from "../consts/hebrew";

import GenericButton from "./GenericButton";
import GenericGoBack from "./GenericGoBack";
import AnimationPage from "./AnimationPage";

import "../style/feedBackDetails.scss";

const FeedBack: React.FC<GenericFeedbackProps> = (props) => {

    const { image, firstLine, secondLine, lastLine, button, backArrow, agencyHome } = props
    const history = useHistory();

    //function that redirects to a new place after button is pressed
    const handleClick = () => {
        props.history ?
            history.push(`/${props.history}`) :
            history.push("/org-profile");
    }

    return (
        <>
            {backArrow && <GenericGoBack text={BACK_TO_HOME_PAGE} isFeedback />}

            <div className="feedback-page">
                <div className={`feedback-page-items ${props.animation && "animation-items"}`}>
                    {props.animation ?
                        <AnimationPage /> :
                        <img className="feedback-page-icon" src={image} alt='' />
                    }
                    <div className={`feedback-page-text ${props.animation && "animation-text"}`}>
                        <div className="feedback-first-line">
                            {firstLine}
                        </div>
                        <div>{secondLine}</div>
                        <div className="feedback-last-line">
                            {lastLine}
                        </div>
                    </div>
                    {button &&
                        <GenericButton
                            className={`yellow back-button ${agencyHome ? "agency-home-btn" : ""}`}
                            handleClick={handleClick}
                        >
                            {button}
                        </GenericButton>
                    }
                </div>
            </div>
        </>
    )

}

export default FeedBack;