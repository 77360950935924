import { jssPreset } from "@mui/styles";
import { create, Jss } from "jss";

function jssInitlizing(): Jss {
    return create({
        ...jssPreset(),
        insertionPoint: document.getElementById('jss-insertion-point') as HTMLElement,
    });
}

export { jssInitlizing };
