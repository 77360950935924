//this component gets props - HallInputsProps
//it returns hall inputs - one name hall and three accessability checkboxes

import { HallInputsProps } from "../consts/interfaces/Hall.interface";
import GenericInput from "./GenericInput";

const HallInputs: React.FC<HallInputsProps> = ({ setHallNameError, setHallName, hallName, hallNameError, name }) => {

    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setHallNameError(e);
    }

    //function that handles the onChange
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setHallName(e);
    }

    return (
        <GenericInput
            value={hallName}
            error={hallNameError}
            title="שם האולם:"
            name={name}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            characterLimit={255}
        />
    )
}

export default HallInputs;