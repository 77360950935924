import { FileInput } from "@hilma/fileshandler-client";
import { useContext } from "react";
import { ADDING_SEAT_GROUP, LOAD_PDF_FILES, SEAT_GROUP, TICKET_NUMBER } from "../../../consts/hebrew";
import { EntranceTicketsContextInterface } from "../../../consts/interfaces/AddEntranceTicketsContext.interface";
import { EntranceTicketsPDFInputsProps } from "../../../consts/interfaces/EntranceTicketsPDFInputs.interface";
import { _FILE_LIMIT } from "../../../consts/variables";
import { AddEntranceTicketsContext } from "../../../context/AddEntranceTicketsContext";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import GenericTrashCan from "../../../generic-components/GenericTrashCan";
import './style/entranceTicketsPDFInputs.scss';

const EntranceTicketsPDFInputs: React.FC<EntranceTicketsPDFInputsProps> = ({ seatGroups, seatGroupsError, addSeatGroup, deleteSeatGroup, handleTicketsInputsChange, handleFileInputChange }) => {

    const { filesUploaderCx } = useContext(AddEntranceTicketsContext) as EntranceTicketsContextInterface;

    return (
        <div className="entrance-tickets-pdf-inputs">
            {seatGroups.map((seatgroup, index) =>
                <div key={index} className="coupon-seat-group">
                    {seatGroups.length !== 1 &&
                        <GenericTrashCan onClick={() => deleteSeatGroup(index)} />}

                    <div className="seatgroup-number-title">{SEAT_GROUP + ' ' + (index + 1)}</div>

                    <GenericInput
                        placeholder={TICKET_NUMBER}
                        type="number"
                        name="tickets-num"
                        className="seatgroup-ticket-num"
                        value={seatgroup.ticketNum}
                        onChange={(e) => handleTicketsInputsChange(e, "ticketNum", index)}
                    />

                    <div className="file-input-container">
                        <div className="file-btn input">
                            {seatgroup.file ?
                                <div>
                                    {seatgroup.file.fileName}
                                </div> :
                                <div className="upload-text-and-logo" >
                                    <img src="/icons/file_upload.svg" alt="upload" />
                                    <div>{LOAD_PDF_FILES}</div>
                                </div>

                            }
                        </div>
                        <FileInput
                            type="file"
                            singleUpload
                            sizeLimit={_FILE_LIMIT}
                            filesUploader={filesUploaderCx}
                            onChange={(file) => handleFileInputChange(file, index)}
                        />
                    </div>
                </div>
            )}

            <GenericButton className="seat-group" handleClick={addSeatGroup}>
                {ADDING_SEAT_GROUP}
            </GenericButton>


            <div className="input-error">
                {seatGroupsError}
            </div>
        </div>
    )
}

export default EntranceTicketsPDFInputs;