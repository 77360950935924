import React from "react"
import { FeedbacksForDisplay } from "../consts/interfaces/Feedback"
import GenericProfilePic from "./GenericProfilePic"
import '../style/thankYouRow.scss'

const ThankYouRow: React.FC<{ feedback: FeedbacksForDisplay, isSameOrganization?: boolean }> = ({ isSameOrganization, feedback, children }) => {

    const { organization: { organizationName, profilePhoto, imageCoordinates }, feedbackText } = feedback

    return (
        <tr className="thank-you-row">
            <td className="profile-container">
                {isSameOrganization ?
                    undefined :
                    <>
                        <GenericProfilePic className="profile-pic" src={profilePhoto} imageCoordinates={imageCoordinates} />
                        <div className="profile-title">{organizationName}</div>
                    </>
                }
            </td>
            <td>
                <div className="content">
                    {children}
                    {feedbackText && feedbackText}
                </div>
            </td>
        </tr>
    )
}

export default ThankYouRow