import { UNPUBLISHED_DRAFT_POPUP } from "../../consts/hebrew";
import GenericButton from "../../generic-components/GenericButton";
import GenericPopup from "../../generic-components/GenericPopup"
import "../style/unpublishedSavedDraftPopup.scss";

interface UnpublishedSavedDraftPopupProps {
    open: boolean;
    handleWatchEvent: () => void;
    handleDeleteDraft: () => void;
}
const UnpublishedSavedDraftPopup: React.FC<UnpublishedSavedDraftPopupProps> = ({ open, handleDeleteDraft, handleWatchEvent }) => {

    return (
        <GenericPopup open={open}
            title={UNPUBLISHED_DRAFT_POPUP.TITLE}
            removeXButton
            image="/icons/attention.svg"
            className="unpublished-draft-popup"
        >
            <div className="buttons">
                <GenericButton
                    children={UNPUBLISHED_DRAFT_POPUP.PREVIEW_BUTTON}
                    handleClick={handleWatchEvent}
                    className='cyan small'
                />
                <GenericButton
                    children={UNPUBLISHED_DRAFT_POPUP.DELETE_BUTTON}
                    handleClick={handleDeleteDraft}
                    className='border-cyan small' />
            </div>
        </GenericPopup>
    )
}

export default UnpublishedSavedDraftPopup;