import { ADDING_SEAT_GROUP, ENTER_WEB_LINK } from "../../../consts/hebrew";
import { EntranceTicketsCouponInputsProps } from "../../../consts/interfaces/EntranceTicketsCouponInputs.interface";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import EntranceTicketsCouponSeatGroupsInput from "./EntranceTicketsCouponSeatGroupsInput";
import './style/entranceTicketsCoupunInputs.scss';


const EntranceTicketsCouponInputs: React.FC<EntranceTicketsCouponInputsProps> = ({ webLink, setWebLink, seatGroups, webLinkError, seatGroupsError, addSeatGroup, deleteSeatGroup, handleTicketsInputsChange }) => {

    return (
        <div>
            <GenericInput
                placeholder={ENTER_WEB_LINK}
                name="web-link"
                value={webLink}
                onChange={e => setWebLink(e.target.value)}
                error={webLinkError}
            />

            {seatGroups.map((seatgroup, index) =>
                <EntranceTicketsCouponSeatGroupsInput
                    key={index}
                    seatgroup={seatgroup}
                    index={index}
                    handleTicketsInputsChange={handleTicketsInputsChange}
                    deleteSeatGroup={seatGroups.length !== 1 ? deleteSeatGroup : undefined}
                />
            )}

            <GenericButton className="seat-group" handleClick={addSeatGroup}>
                {ADDING_SEAT_GROUP}
            </GenericButton>

            <div className="input-error">
                {seatGroupsError}
            </div>
        </div>
    )
}

export default EntranceTicketsCouponInputs;