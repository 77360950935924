import { useRef, useState } from "react";
import { FileInput, FileInputRefType, UploadedFile, UploadError } from "@hilma/fileshandler-client";

import { GenericProfilePicInputProps } from "../consts/interfaces/GenericProfilePicInput.interface";
import { DELETE_PHOTO, EDIT_PHOTO, LIMIT_ERROR, LOAD_IMAGE } from "../consts/hebrew";
import { INVALID_FILE_TYPE, _FILE_LIMIT, _FILE_TOO_BIG } from "../consts/variables";
import useImageCoordinates from "../consts/hooks/useImageCoordinates";

import PopupCropPicture from "../popups/general/PopupCropPicture";
import GenericToolTip from "./GenericToolTip";
import FocusAble from "./FocusAble";

import "../style/genericProfilePicInput.scss";

const GenericProfilePicInput: React.FC<GenericProfilePicInputProps> = ({ title: side, imageCoordinates, setImageCoordinates, filesUploader, onFileIdChange, imageLink, setImageLink, imageError: parentImageError }) => {

    const imageCoordinatesStyle = useImageCoordinates(imageCoordinates)

    const [cropPopup, setCropPopup] = useState<boolean>(false);
    const [imageError, setImageError] = useState<string>("");
    const [imageCropLink, setImageCropLink] = useState<string>('')
    const [imageCropId, setImageCropId] = useState<number>(0)
    const fileInputRef = useRef<FileInputRefType>(null)

    const openFilesUploader = () => {
        fileInputRef.current?.open()
    }
    const onEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
        if (event?.key === "Enter") {
            openFilesUploader()
        }
    }
    // file uploader
    const handleFileChange = (value: UploadedFile, index: number) => { // function that handle the file uploader
        imageError && setImageError(""); // if there is an error in the file uploader, it will be removed
        setImageCropLink(value.link)
        setImageCropId(value.id)
        setCropPopup(true)
    };

    const saveImgAfterConfirmCrop = (image: string) => {
        setImageLink(image);
        onFileIdChange && onFileIdChange(imageCropId);
    }

    const closeCropPopUp = () => {
        setCropPopup(false)
        setImageCropLink('')
        filesUploader.deleteAll()
    }

    const deleteProfilePic = () => {
        setImageLink('')
        setImageCropLink('')
        filesUploader.deleteAll()
    }

    const handleUploadError = (err: UploadError, index: number) => { // function that handles the error
        if (err.type === "wrong-type") {
            switch (index) {
                case 1:
                    setImageError(INVALID_FILE_TYPE);
                    break;
                default:
                    break;
            }
        }
        else {
            switch (index) {
                case 1:
                    setImageError(_FILE_TOO_BIG);
                    break;
                default:
                    break;
            }
        }
    }
    return (
        <div className="generic-input-container">
            <div className="generic-photo">
                <div className="generic-logo-title">{side}</div>
                <div className="generic-first">
                    <div className="upload-arrow-image-back" onClick={openFilesUploader} onKeyDown={onEnter} tabIndex={0}>
                        {imageLink === "" ? (
                            <img
                                src="/icons/folderUpload.png"
                                className="upload-arrow-image"
                                alt="upload"
                            />
                        ) : (
                            <div className="upload-arrow-images-second">
                                <img
                                    src={imageLink}
                                    className="selected-image"
                                    alt="profile"
                                    style={imageCoordinatesStyle}
                                />
                            </div>
                        )}
                    </div>
                </div>


                <FileInput
                    className="generic-input-file-text"
                    style={{ display: 'none' }}
                    singleUpload
                    type="image"
                    sizeLimit={_FILE_LIMIT}
                    filesUploader={filesUploader}
                    placeholder={LOAD_IMAGE}
                    onChange={(e) => handleFileChange(e, 1)}
                    onError={(e) => handleUploadError(e, 1)}
                    ref={fileInputRef}
                />
                <PopupCropPicture open={cropPopup} image={imageCropLink || imageLink} ratio={1} handleClose={closeCropPopUp} setCropPopup={setCropPopup} setImageCoordinates={setImageCoordinates} saveImgAfterConfirmCrop={saveImgAfterConfirmCrop} />
            </div>

            {
                imageError || parentImageError ?
                    <div className="generic-right-errors link-error">
                        {imageError || parentImageError}
                    </div>
                    :
                    <div className="limit link-error" >
                        {LIMIT_ERROR}
                    </div>
            }
            {
                imageLink &&
                <div className="edit-delete-box">
                    <GenericToolTip name='edit-photo' title={EDIT_PHOTO}>
                        <FocusAble className="edit-photo-cordinates" onClick={() => setCropPopup(true)}>
                            <img className="edit-icon" src="/icons/edit.svg" alt="edit icon" />
                        </FocusAble>
                    </GenericToolTip>
                    <GenericToolTip name='delete-photo' title={DELETE_PHOTO}>
                        <FocusAble className="delete-photo" onClick={deleteProfilePic}>
                            <img className="delete-icon" src="/icons/trash-can.svg" alt="delete icon" />
                        </FocusAble>
                    </GenericToolTip>
                </div>
            }
        </div >
    )
}
export default GenericProfilePicInput;
