import { useIsAuthenticated } from "@hilma/auth";
import { Badge, Drawer, IconButton, List, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';

import { header } from "../../consts/enums/header.enum";
import useMenuItems from "../../consts/hooks/useMenuItems";

import { counterProvider } from "../../context/CountMessagesContext";
import { SocketContext } from "../../context/SocketContext";
import { UserContext } from "../../context/UserContext";

import FocusAble from "../FocusAble";
import GenericDropDown from "../GenericDropDown";
import GenericProfilePic from "../GenericProfilePic";
import { WelcomeUser } from "./WelcomeUser";

import "./style/header.scss";

const Header: React.FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation()
    const isContactUs = location.pathname === "/contact-us";

    const { count } = useContext(counterProvider);
    const { profilePhoto, profileCoordinates, setProfilePhoto, setProfileCoordinates, role, systemID } = useContext(UserContext);
    const { agencyEdited, organizationEdited } = useContext(SocketContext);
    const history = useHistory();
    const dropDownItems = useMenuItems(!isAuthenticated, role)
    const dropDownElement = React.useRef<HTMLDivElement>(null);
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [showNavigation, setShowNavigation] = useState<boolean>(false);

    const theme = createTheme({
        palette: {
            primary: {
                main: "#FED811",
                contrastText: "#41596B"
            }
        }
    })

    const toggleDropdown = useCallback((e: MouseEvent) => {
        const { current } = dropDownElement;
        if (current && current.contains(e.target as Node)) {
            setShowNavigation(prev => !prev);
        } else {
            setShowNavigation(false);
        }
        if ((e.target as HTMLElement).classList.contains('exit-btn')) {
            e.stopPropagation();
        }
    }, []);

    // function that handle drop down 
    useEffect(() => {
        window.addEventListener("click", toggleDropdown);
        return () => {
            window.removeEventListener("click", toggleDropdown);
        };
    }, [toggleDropdown]);

    useEffect(() => {
        if (!agencyEdited) return;
        if (agencyEdited.profilePhoto) setProfilePhoto(agencyEdited.profilePhoto)
        if (agencyEdited.imageCoordinates) setProfileCoordinates(agencyEdited.imageCoordinates)
    }, [agencyEdited])

    useEffect(() => {
        if (!organizationEdited || systemID !== organizationEdited.id) return;
        if (organizationEdited.profilePhoto) setProfilePhoto(organizationEdited.profilePhoto)
        if (organizationEdited.imageCoordinates) setProfileCoordinates(organizationEdited.imageCoordinates)
    }, [organizationEdited])

    return (
        <header className="header">
            <FocusAble className="website-name-container" onClick={() => history.push("/")}>
                <img src="/icons/new-logo.svg" alt="enter logo" className="website-name" />
            </FocusAble>

            <div className="header-content">
                {((isMobile && !isAuthenticated) || (!isMobile && !isContactUs)) ?
                    <FocusAble onClick={() => history.push("/contact-us")} className="contact-us-title">
                        {header.make_contact}
                    </FocusAble> : null}

                {isAuthenticated &&
                    <div className="user-info">
                        <WelcomeUser />
                        <div className="avatar-logo" ref={dropDownElement}>
                            <ThemeProvider theme={theme}>
                                <Badge badgeContent={count} color="primary" >
                                    <IconButton sx={{ padding: "unset" }} size="large">
                                        <GenericProfilePic src={profilePhoto} imageCoordinates={profileCoordinates} />
                                    </IconButton>
                                </Badge>
                            </ThemeProvider>
                        </div>
                    </div>
                }
                {!isMobile ?
                    <GenericDropDown isOpen={showNavigation} elements={dropDownItems} />
                    :
                    <Drawer
                        variant="persistent"
                        className="side-nav"
                        open={showNavigation}
                        anchor={"left"}
                        hideBackdrop={true}

                    >
                        <List className="sidebar-list" >
                            <div className="exit-btn-div">
                                <img
                                    className="exit-btn"
                                    alt="exit-button"
                                    src="/icons/exit.svg"
                                    onClick={() => setShowNavigation(false)}
                                />
                            </div>
                            <div className="nav-labels-container">
                                {dropDownItems.map((item, index) => (
                                    <div key={index}
                                        className={item?.label === header.logout ? "logout" : "label-container"}>
                                        <img src={item.iconSrc} className="label-name" alt="" />
                                        <div className={item?.label === header.logout ? "logout-div" : "side-nav-text"}
                                            onClick={item.onClick}
                                        >
                                            {item.label}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </List>
                    </Drawer>
                }
            </div>
        </header>
    );
}

export default Header;