import { useEffect, useState } from "react";
import { TICKETS_SAVED_TILL } from "../../../consts/hebrew";
import { OrganizationSwiperCoverProps } from "../../../consts/interfaces/SwipeBoard.interface";
import "./style/organizationSwiperEventCover.scss";

const OrganizationSwiperEventCover: React.FC<OrganizationSwiperCoverProps> = ({ timeExpired }) => {

    const [timeLeft, setTimeLeft] = useState<string>('')

    useEffect(() => {
        const calculateTimeDifference = (expirationDate?: string) => {
            if (!expirationDate) return 0

            const today = Date.now();
            const timeEnd = new Date(expirationDate)
            const milliseconds = timeEnd.getTime() - today
            return milliseconds
        }

        const buildTimeString = (milliseconds: number) => {
            let newTimeSecond = Math.floor(milliseconds / 1000);
            let newTimeMinute = Math.floor(newTimeSecond / 60);
            let newTimeHour = Math.floor(newTimeMinute / 60);

            newTimeSecond = newTimeSecond % 60;
            newTimeMinute = newTimeMinute % 60;

            let NewTimeHourString = newTimeHour >= 10 ? `${newTimeHour}` : `0${newTimeHour}`
            let NewTimeMinuteString = newTimeMinute >= 10 ? `${newTimeMinute}` : `0${newTimeMinute}`
            let NewTimeSecondString = newTimeSecond >= 10 ? `${newTimeSecond}` : `0${newTimeSecond}`

            let newTimeString = `${NewTimeHourString}:${NewTimeMinuteString}:${NewTimeSecondString}`
            return newTimeString
        }

        const TimeInterval = setInterval(() => {
            const timeDifference = calculateTimeDifference(timeExpired?.split(',')[0])

            if (timeDifference <= 0) clearInterval(TimeInterval);
            else setTimeLeft(buildTimeString(timeDifference));
        }, 1000);

        return () => clearInterval(TimeInterval);
    }, [])

    return (
        <div className="organization-swiper-event-cover">
            <img src="/icons/timer.svg" />

            {TICKETS_SAVED_TILL}
            <div className="time-left">{timeLeft}</div>
        </div>
    )
}

export default OrganizationSwiperEventCover;