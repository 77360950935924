import clsx from "clsx";
import { useContext, useEffect, useMemo, useState } from "react";
import { Area } from "react-easy-crop";
import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";

import Tooltip from '@mui/material/Tooltip';
import { Fade } from '@mui/material';

import { InfoBarProps, OrgListItem, Event } from "../../consts/interfaces/InfoBar.interface";
import { InfoBarTicket } from "../../consts/interfaces/Tickets.interface";
import { MULTIPLE_SEAT_GROUPS_INFO } from "../../consts/hebrew";
import { infoBarPreview } from "../../functions/getDetailsFromLocalStorage";

import { SocketContext } from "../../context/SocketContext";
import { UserContext } from "../../context/UserContext";

import OrgsFeedbackList from "./OrgsFeedbackList";

import "./style/infoBar.scss";

const InfoBar: React.FC<InfoBarProps> = ({ eventId, barType, seatGroups, usedSeats }) => {

    const { systemID, role } = useContext(UserContext);
    const { ticketPurchased, feedbackSent } = useContext(SocketContext)

    const [organizationList, setOrganizationList] = useState<OrgListItem[]>([])
    const [eventInfo, setEventInfo] = useState<Event>({
        location: '',
        tickets: [],
        seatGroups: 0,
        numOfOrganization: 0,
        feedback: 0
    })

    const [purchasedTickets, setPurchasedTickets] = useState<number | undefined>(0)
    const [availableTickets, setAvailableTickets] = useState<number | undefined>(0)

    useAsyncEffect(async () => {
        if (barType === 'preview') {
            const { location, seatGroupsCount, ticketCount } = infoBarPreview()
            setEventInfo({
                location: location,
                tickets: ticketCount || 0,
                seatGroups: seatGroupsCount,
                numOfOrganization: 0,
                feedback: 0
            })
            return
        }
        const { data } = await axios.get(`/api/event/details-of-event-info-bar?id=${eventId}`);

        setPurchasedTickets(data.tickets.filter((ticket: InfoBarTicket) => ticket.status === 'purchased').length)
        setAvailableTickets(data.tickets.filter((ticket: InfoBarTicket) => ticket.status !== 'purchased').length)

        setEventInfo({
            location: data.location,
            tickets: data.tickets,
            seatGroups: data.seatGroups.length,
            numOfOrganization: data.uniqueOrganizationsIds.length,
            feedback: data.feedbacksCount
        })

        if (barType === 'feedback') {
            const organizationsList = await Promise.all(data.uniqueOrganizationsIds.map(async (id: number) => {
                const organizationServer = await axios.get(`/api/organization/organization-info?id=${id}`);
                const organizationInfo = organizationServer.data.originalInfo[0].organization
                const orgName = organizationInfo.organizationName;
                const logo = organizationInfo.profilePhoto;
                const imageCoordinates: Area = organizationInfo?.imageCoordinates
                return { name: orgName, logoImage: logo, imageCoordinates };
            }))

            setOrganizationList(organizationsList);
        }

    }, [eventId, systemID, role])

    //adds 1 to num of feedbacks- changes with socket
    useEffect(() => {
        if (feedbackSent?.eventId === eventId) {
            setEventInfo(prev => ({ ...prev, feedback: prev.feedback + 1 }))
        }
    }, [feedbackSent, eventId])

    //adds 1 to num of tickets purchased and increases 1 in available tickets - changes with socket
    useEffect(() => {
        if (ticketPurchased?.eventId === eventId) {
            setPurchasedTickets(prev => prev ? prev + ticketPurchased.amountPurchased : prev)
            setAvailableTickets(prev => prev ? prev - ticketPurchased.amountPurchased : prev)
        }
    }, [ticketPurchased, eventId])

    const barItems: { text: string, img: string, tooltipContent?: string | JSX.Element, underlined?: boolean }[] = useMemo(() => {
        switch (barType) {
            case "time":
            case "viewEvent":
            case "preview":
            case "purchase":
                return [{
                    text: eventInfo.location,
                    img: "location.svg"
                }, {
                    text: typeof eventInfo.tickets === 'number' ? `${eventInfo.tickets} כרטיסים פנויים`
                        : `${eventInfo.tickets.filter(ticket => ticket.status === 'offered' && ticket.organizationId === systemID).length} כרטיסים פנויים`,
                    img: "ticket-empty.svg"
                }, {
                    text: `${eventInfo.seatGroups} קבוצות מושבים`,
                    img: "couch.svg",
                    tooltipContent: MULTIPLE_SEAT_GROUPS_INFO,
                }]
            case "ordered":
                return [{
                    text: eventInfo.location,
                    img: "location.svg"
                }, {
                    text: `${availableTickets} כרטיסים פנויים`,
                    img: "ticket-empty.svg"
                }, {
                    text: `${seatGroups?.length} קבוצות מושבים`,
                    img: "couch.svg",
                    tooltipContent: MULTIPLE_SEAT_GROUPS_INFO,
                }]
            case "edit":
                return [{
                    text: eventInfo.location,
                    img: "location.svg"
                }, {
                    text: `הוזמנו ${usedSeats} כרטיסים`,
                    img: "ticket-empty.svg"
                }, {
                    text: `${eventInfo.seatGroups} קבוצות מושבים`,
                    img: "couch.svg",
                    tooltipContent: MULTIPLE_SEAT_GROUPS_INFO,
                }]
            case "feedback":
                return [{
                    text: `הוזמנו ${purchasedTickets} כרטיסים מתוך ${typeof (eventInfo.tickets) === 'number' ? 0 : eventInfo.tickets.length}`,
                    img: "ticket-empty.svg"
                }, {
                    text: `על ידי ${eventInfo.numOfOrganization} עמותות`,
                    img: "couch.svg",
                    tooltipContent: organizationList.length ? <OrgsFeedbackList orgs={organizationList} /> : undefined,
                    underlined: organizationList.length > 0,
                }, {
                    text: `התקבלו ${eventInfo.feedback} תגובות`,
                    img: "message.svg"
                }]
            case "benefit":
                return [{
                    text: eventInfo.location,
                    img: "location.svg"
                }, {
                    text: `${availableTickets} כרטיסים פנויים`,
                    img: "ticket-empty.svg"
                }]
        }
    }, [barType, eventInfo, availableTickets, purchasedTickets, systemID, organizationList, seatGroups?.length, usedSeats])

    return (
        <div className="info-bar">
            {barItems.map((item) =>
                item.tooltipContent ?
                    <Tooltip
                        title={item.tooltipContent}
                        arrow
                        TransitionComponent={Fade}
                        classes={{ popper: "info-bar-tooltip" }}
                    >
                        <div className="item-container" >
                            <div className="img-container">
                                <img src={`/icons/${item.img}`} alt="" />
                            </div>
                            <span className={clsx("text", { "underlined": item.underlined })}>{item.text}</span>
                        </div>
                    </Tooltip>
                    :
                    <div className="item-container" >
                        <div className="img-container">
                            <img src={`/icons/${item.img}`} alt="" />
                        </div>
                        <span className="text">{item.text}</span>
                    </div>
            )}
        </div >
    );
}
export default InfoBar;