export enum LoginText {
    connecting = 'התחברות',
    user_doesnt_exist = 'איש קשר זה אינו קיים',
    agency_yet_approved = 'מוסד התרבות טרם אושר',
    organization_yet_approved = 'העמותה טרם אושרה',
    error = 'אירעה שגיאה. נסו שוב מאוחר יותר',
    enter_phone_number = 'יש להזין מספר טלפון',
    send_code = 'שלח קוד אימות',
    organization_sign_up = 'רישום עמותה',
    agency_sign_up = 'רישום מוסד תרבות / ספורט',
    code_sent_to = 'נשלח קוד אימות למספר שמסתיים בספרות',
    incorrect_code = 'קוד שגוי',
    was_error = 'אירעה שגיאה',
    try_again_later = 'כמות בקשות רבה מידי, נסו שוב בעוד כ-10 דקות',
    enter_code = 'הזן קוד אימות',
    didnt_get_code = 'לא קיבלתם קוד? נסו שנית',
    login = 'כניסה',
    sign_up = 'רישום',
    sign_up_as = 'הירשמו בתור: ',
    organization = 'רישום עמותה',
    agency = 'רישום מוסד תרבות / ספורט'
}
