//returns a time slider bar
import React from "react";
import { useMediaQuery } from "@mui/material";
import GenericButton from "../GenericButton";
import TimeProgressBar from "./TimeProgressBar";
import { TimeSliderProps } from "../../consts/interfaces/TimeProgressBar.interface";
import { ButtonsText } from "../../consts/hebrew";
import "./style/timeSlider.scss";

const TimeSlider: React.FC<TimeSliderProps> = ({ displayCounterTime, leftTimeForProgressBar, duration, showAddTimeButton, handleAddTime }) => {

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)');

    return (
        <div className="time-slider">
            <div className="time-slider-text">הכרטיסים שמורים עוד</div>
            <div className="time-slider-time">{displayCounterTime}</div>
            <div className='add-time-button-wrapper'>
                {showAddTimeButton ? <GenericButton
                    className='add-time-button extra-small'
                    handleClick={handleAddTime}>
                    <div className='items-in-add-time'>
                        {!tabletMediaQuery && ButtonsText.AddTime}
                        <img src='/icons/add-time.svg' alt='add time icon' className='add-time-icon' />
                    </div>
                </GenericButton> : undefined}
            </div>
            <TimeProgressBar newTimeInSeconds={leftTimeForProgressBar} alwaysTimeSeconds={duration} />
        </div>
    )
}

export default TimeSlider;