import React, { ReactNode } from 'react';
import '../style/genericWrapper.scss';

const GenericWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {

    return (
        <div className="grey-box-wrapper">
            {children}
        </div>
    )
}

export default GenericWrapper;