import { DELETE_EVENT } from "../enums/deleteEvent.enum";

export const OptionsForCancelArr = [{
    label: DELETE_EVENT.EVENT_CANCELED,
    value: DELETE_EVENT.EVENT_CANCELED
},
{
    label: DELETE_EVENT.EVENT_MOVED_TO_DIFFERENT_DATE,
    value: DELETE_EVENT.EVENT_MOVED_TO_DIFFERENT_DATE
},
{
    label: DELETE_EVENT.EVENT_DOES_NOT_HAVE_AVAILABLE_TICKETS,
    value: DELETE_EVENT.EVENT_DOES_NOT_HAVE_AVAILABLE_TICKETS
},
{
    label: DELETE_EVENT.ORGANIZATIONS_DID_NOT_BUY_TICKETS,
    value: DELETE_EVENT.ORGANIZATIONS_DID_NOT_BUY_TICKETS
},
{
    label: DELETE_EVENT.OTHER,
    value: DELETE_EVENT.OTHER
}]