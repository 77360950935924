import { FC, useState } from "react";
import { Skeleton, Typography } from "@mui/material";

import { formatDate } from "../../../functions/dateFunctions";
import { BenefitEventData } from "../../../consts/interfaces/BenefitsPage.interface";
import { TICKETS, TICKETS_REMAINING, TITLES } from "../../../consts/hebrew";
import BenefitEvent from "./BenefitEvent";

import './style/benefitsPage.scss';

const BenefitsPage: FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [ticketsPerOrg, setTicketsPerOrg] = useState({ total: 100, remaining: 42, expDate: new Date() })
    const [events, setEvents] = useState<BenefitEventData[]>([{//TODO: data here is temporary until I connect to the server.
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "כדור-סל",
        id: 1,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 10,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "פארק מים",
        id: 2,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 20,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "פארק מים",
        id: 3,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "פארק מים",
        id: 4,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "פארק מים",
        id: 5,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "new3",
        id: 6,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "new3",
        id: 7,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "new3",
        id: 8,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "new3",
        id: 9,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "new3",
        id: 10,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }, {
        coverImage: "/image/CK0QGBWQAcbf7bkyb305tLVhoMnqG7su.jpg",
        eventName: "new3",
        id: 11,
        imageCoordinates: { x: 0, y: 24.9545, width: 100, height: 50.0911 },
        ticketsNum: 30,
    }]);

    const formattedDate = formatDate(ticketsPerOrg.expDate)

    return (
        <div className='benefits-page'>
            <div className='subheading-container'>
                <div className="main-subheading">
                    <Typography variant="h3" component='div' children={TITLES.ENTER_BENEFITS} />
                    <Typography variant="body1" component='div' children={TITLES.ENTRANCE_TICKETS} />
                </div>
                <div className="left-subheading">
                    <Typography variant="body1" component='div'>
                        <img className="ticket-img" src="/icons/ticket.svg" alt='ticket' /><b>{ticketsPerOrg.remaining}</b>{`/${ticketsPerOrg.total} ${TICKETS}`}
                    </Typography>
                    <Typography variant="body2" component='div' children={`${TICKETS_REMAINING} ${formattedDate}`} />
                </div>
            </div>
            <div className="benefit-events-container">
                {!isLoading ?
                    events.map(event => <BenefitEvent key={event.id} details={event} />) :
                    <>
                        <Skeleton variant='rounded' className="card" sx={{ height: '30vh' }} />
                        <Skeleton variant='rounded' className="card" sx={{ height: '30vh' }} />
                        <Skeleton variant='rounded' className="card" sx={{ height: '30vh' }} />
                        <Skeleton variant='rounded' className="card" sx={{ height: '30vh' }} />
                        <Skeleton variant='rounded' className="card" sx={{ height: '30vh' }} />
                        <Skeleton variant='rounded' className="card" sx={{ height: '30vh' }} />
                    </>}
            </div>
        </div>
    )
}

export default BenefitsPage