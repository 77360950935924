import { useContext, useMemo } from "react";

import { BENEFIT_FORM_PAGES } from "../../../consts/BenefitFormPages";
import { TITLES } from "../../../consts/hebrew";
import { EntranceTicketsContextInterface } from "../../../consts/interfaces/AddEntranceTicketsContext.interface";
import { AddEntranceTicketsContext } from "../../../context/AddEntranceTicketsContext";

import GenericSplitPage from "../../../generic-components/GenericSplitPage";

import FormStepper from "../../../generic-components/FormStepper";

import { ADD_BENEFIT_TICKETS_LEFT } from "../../../consts/leftSideLayouts";
import './style/addEntranceTickets.scss';

const AddEntranceTickets: React.FC = () => {
    document.title = "הוספת כרטיסי כניסה";

    const { page } = useContext(AddEntranceTicketsContext) as EntranceTicketsContextInterface;

    const ActivePage = useMemo(() => BENEFIT_FORM_PAGES[page], [page]);

    return (
        <GenericSplitPage serverLoading={false} isAgency squares={ADD_BENEFIT_TICKETS_LEFT}>
            <div className="add-new-event-right">
                <div className="add-event-title">{TITLES.ADD_ENTRANCE_TICKETS}</div>

                <FormStepper activeStep={page} steps={BENEFIT_FORM_PAGES} />

                <ActivePage />
            </div>
        </GenericSplitPage>
    )
}

export default AddEntranceTickets;