import { useMediaQuery } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FeedbackHebrew } from "../hebrew";

const useFeedbackMenuItems = (type: 'image' | 'text', setPopupDelete: React.Dispatch<React.SetStateAction<boolean>>, popupDelete: boolean, setPopupEdit?: React.Dispatch<React.SetStateAction<boolean>>, popupEdit?: boolean) => {
    const isPhone = useMediaQuery('(max-width: 768px)');

    switch (type) {
        case 'image':
            return [
                {
                    label: isPhone ? FeedbackHebrew.DELETE : FeedbackHebrew.DELETE_FEEDBACK,
                    onClick: (event: KeyboardEvent) => { event.stopPropagation(); setPopupDelete(!popupDelete); },
                    icon: <DeleteIcon />,
                    className: 'delete-option'

                }
            ]
        case 'text':
            return [
                {
                    label: isPhone ? FeedbackHebrew.EDIT : FeedbackHebrew.EDIT_FEEDBACK,
                    onClick: () => setPopupEdit && setPopupEdit(!popupEdit),
                    icon: <EditIcon />,
                    className: 'edit-option'
                },
                {
                    label: isPhone ? FeedbackHebrew.DELETE : FeedbackHebrew.DELETE_FEEDBACK,
                    onClick: () => setPopupDelete(!popupDelete),
                    icon: <DeleteIcon />,
                    className: 'delete-option'

                }
            ]
        default:
            return []
    }
}

export default useFeedbackMenuItems;
