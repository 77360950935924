//this component gets props - open : boolean, handleClose ?: () => void,  email?: string.
//it returns a popup that shows the user that the tickets have been sent to the users email

import { useHistory } from "react-router-dom";
import { PopupSentTicketsProps } from "../../consts/interfaces/PopupSentTickets.interface";
import GenericPopup from "../../generic-components/GenericPopup";
import '../../style/genericPopup.scss';

const PopupSentTickets: React.FC<PopupSentTicketsProps> = ({ open, handleClose, email, phone }) => {

    const history = useHistory();

    //after the user approves that the tickets have been sent to him it redirects to the events ordered page
    const goToPersonal = () => {
        handleClose();
        history.push('/future-ordered-events')
    }

    return (
        <GenericPopup
            image='/icons/blue-plane.svg'
            title='הכרטיסים נשלחו בהצלחה!'
            button={{ text: 'מעבר לאזור האישי', handleClick: goToPersonal, className: 'yellow' }}
            open={open}
            handleClose={handleClose}
            className="sent-tickets"
            removeXButton
            disableBackgroundClick
        >
            <div className='the-sent-tickets-text'>
                <div className="sent-tickets-text-main">
                    אישור ההזמנה והכרטיסים ישלחו בדקות הקרובות
                    <p className='user-email' >  לכתובת המייל- <p>{email}</p></p>
                    <p className='user-email' > {`ולמספר הפלאפון- ${phone}`}</p>
                </div>
                <div>
                    ומופיעים באזור האישי
                </div>
            </div>

        </GenericPopup>
    );
}
export default PopupSentTickets;
