
const FocusAble: React.FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props) => {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLImageElement>) => {
        if (e.key === "Enter" && props.onClick) {
            props.onClick(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>)
        }
    }

    return (
        <div {...props} tabIndex={0} onKeyDown={handleKeyDown}>
            {props.children}
        </div>
    )
}

export default FocusAble;