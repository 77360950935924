import { EVENT_TYPE, PLACE_TYPE } from "../hebrew";

export interface CategoriesArray {
    id: number;
    categoryName: string;
    selected: boolean;
}

export interface CategoryServer {
    id: number;
    categoryName: string;
}

export interface CategoriesSelectProps {
    filteredCategories: CategoriesArray[];
    handleChange: (index: number) => void;
}

export interface CategoryInputProps {
    categories: CategoriesArray[];
    setCategories: React.Dispatch<React.SetStateAction<CategoriesArray[]>>;
    categoryError: string;
    setCategoryError: React.Dispatch<React.SetStateAction<string>>;
    saveDraft?: boolean;
    type?: CategoryInputType;
}

export interface CategoryPopupArrayInterface {
    title: string;
    deleteMySelf: () => void;
}

export interface PopupCategoryProps {
    open: boolean;
    handleClose?: () => void;
    categories: CategoriesArray[];
    setCategories: React.Dispatch<React.SetStateAction<CategoriesArray[]>>;
    setCategoryError: React.Dispatch<React.SetStateAction<string>>;
    saveDraft?: boolean;
    type?: CategoryInputType;
}

type CategoryInputType = 'category' | 'place-type';

export const categoryInputTitles: { [key: string]: string } = {
    'category': EVENT_TYPE,
    'place-type': PLACE_TYPE,
}

export const categoryPopupTitles: { [key: string]: string } = {
    'category': "בחר את הקטגוריות הרלוונטיות לאירוע",
    'place-type': "בחר את הקטגוריות הרלוונטיות לסוג המקום",
}