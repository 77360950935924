//this component gets props - PlaceItemProps
//it returns a part of a static address for the agency

import React, { ReactNode, useContext } from "react";
import { ANEContextInterface } from "../../../consts/interfaces/AddNewEventContext.interface";
import { PlaceItemProps } from "../../../consts/interfaces/PlaceItem.interface";
import { AddNewEventContext } from "../../../context/AddNewEventContext";
import RadioButtons from "../../../generic-components/RadioButtons";

import './style/placeItem.scss';

/**
 * this component is for the second page in add new event. 
 * @props its getting from the parent component three props
 * @item an object that have all the information to show. it could have halls array and it would became radio button when multiple halls insert in.
 * @selectedHallIndex index of the selected hall, this is for the radio button
 * @handleRadioChange function from parent that responsible to change the current index of the selected hall
 */
export const PlaceItem: React.FC<PlaceItemProps> = ({ item, selectedHallIndex, handleRadioChange }) => {

    const { locationDetailsCx } = useContext(AddNewEventContext) as ANEContextInterface;

    /** * object that get the location details from the context and maps it into jsx elements of radio buttons and that value of each radio button is the index of the hall */
    const radioButtonsArray: Array<{ label: ReactNode, value: string }> = locationDetailsCx.halls.map((hall, index) => {
        return {
            label:
                <div className="place-flex-container">
                    <span className="place-item-text" >{hall.hallName}</span>
                </div>
            , value: index.toString()
        }
    })

    return (
        <div className='place-item'>
            {(item.halls && item.halls.length > 1)
                ? <RadioButtons
                    options={radioButtonsArray}
                    handleChange={(e) => handleRadioChange(parseInt(e.currentTarget.value))}
                    value={selectedHallIndex.toString()}

                />
                :
                <div className='place-item-data'>
                    <span className="place-item-text"> {item.data} </span>
                </div>
            }

            <div className='place-item-name'>{item.name}</div>
        </div>
    );
}

