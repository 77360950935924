export enum header {
    login = 'התחבר',
    agency_profile = 'פרופיל מוסד תרבות / ספורט',
    events = 'ארכיון אירועים',
    logout = 'התנתקות',
    org_profile = 'פרופיל עמותה',
    soon_events = 'אירועים קרובים',
    feedbacks = 'פידבקים',
    enter = 'אנטר',
    make_contact = 'יצירת קשר',
    benefits = 'איזור הטבות'
}