import React from 'react';
import { IconButton, Modal } from '@mui/material';
import clsx from 'clsx';

import { GenericPopupProps } from '../consts/interfaces/GenericPopup.interface';
import GenericButton from './GenericButton';
import '../style/genericPopup.scss';

const GenericPopup: React.FC<GenericPopupProps> = (props) => {
  const isIos = /iPhone|iPad|iPod|/i.test(navigator.userAgent);

  return (
    <Modal
      open={props.open}
      onClose={props.disableBackgroundClick ? () => { } : props.handleClose}
      disableEscapeKeyDown
    >
      <div style={isIos ? { paddingBottom: "2vh", outline: "none" } : { paddingBottom: "2vh" }} className={props.isFeedback ? "popup-with-img" : `the-popup ${props.className || ''}`}>
        {props.fullScreen ?
          <div className='add-back-color'>
            <div className="full-screen">
              <div className='close-button'>
                <IconButton sx={{ width: "8vh", height: "8vh", }} onClick={props.handleClose}>
                  <span className='close-button-text'>×</span>
                </IconButton>
              </div>
            </div>
          </div>
          :
          <div className='the-header'>
            {props.imageStyles === "edit" ? [] :
              props.type ?
                <div className="thumbs-up-popup">
                  <IconButton sx={{ width: "8vh", height: "8vh" }} onClick={props.handleClose}>
                    <span className='close-button-thumbs-up'>×</span>
                  </IconButton>
                  <div className="title-and-thumbs-up">
                    <div className='the-title-thumbs-up'>
                      {props.title}
                    </div>
                    <div className="animation-thumbs-up">
                      <img src="/icons/heart-hand.svg" alt="hear hand" className="thumbs-up" />
                    </div>
                  </div>
                </div>
                :
                <div className='close-button-container'>
                  {!props.removeXButton &&
                    <IconButton sx={{ width: "8vh", height: "8vh", }} onClick={props.handleClose}>
                      <span className='close-button-text'>×</span>
                    </IconButton>
                  }
                </div>
            }
          </div>
        }
        <div className='flex-center'>
          {props.isFeedback && props.image ?
            <div className='photo-popup'>
              <img src={props.image} className="the-img" alt="" />
            </div> :
            props.image && <img className={clsx('the-icon', props.imageStyles)} src={props.image} alt="" />
          }
          {props.title && !props.type &&
            <div className='the-title' >{props.title}</div>}
          {props.children}
          {props.button &&
            <div className='the-button'>
              <GenericButton disabled={props.button.disabled} serverLoading={props.button.serverLoading} className={clsx(props.isSmall ? 'small' : 'big', props.button.className)} handleClick={props.button.handleClick}>
                <div className={clsx(props.button.icon && 'items-in-button')}>
                  {props.button.text}
                  <img src={props.button.icon} className='icon-in-button' alt='' />
                </div>
              </GenericButton>
            </div>}
        </div>
      </div>
    </Modal>
  )
}

export default GenericPopup;