import React from "react";
import clsx from "clsx";

import { GenericOvalClickInterface } from "../consts/interfaces/GenericOvalClick.interface";
import { FORM_FIELDS } from "../consts/variables";

import { selectAtLeastOneValidator } from "../functions/validations";
import { saveInLocalStorage } from "../functions/localStorage";
import FocusAble from "./FocusAble";

import '../style/genericOvalClick.scss';

const GenericOvalClick: React.FC<GenericOvalClickInterface> = (props) => {
    const handleClick = (id: number) => {
        const newOptions = [...props.options];
        const selectedOptionInd = newOptions.findIndex(option => option.id === id)
        newOptions[selectedOptionInd].selected = !newOptions[selectedOptionInd].selected;
        props.setOptions(newOptions)

        const populVal = selectAtLeastOneValidator(newOptions);
        props.setError && props.setError(populVal)

        props.saveDraft && saveInLocalStorage(FORM_FIELDS.POPULATION, newOptions)
    }

    return (
        <div className={clsx("oval-box", props.className)}>
            {props.options.map((item, index) => {
                return (
                    <FocusAble key={index} className={`oval-item ${item.selected ? 'clicked' : ''}`} onClick={() => handleClick(item.id)}>
                        <div className="oval-title">{item.title}</div>
                    </FocusAble>
                )
            })}

            <div className="input-error">
                {props.error}
            </div>
        </div>
    )
}
export default GenericOvalClick;