import React, { useState } from "react"
import axios from "axios"

import { PurchasedEventPopupProps } from "../../consts/interfaces/PurchasedEventPopup.interface"
import GenericPopup from "../../generic-components/GenericPopup"
import PopupSentTickets from "./PopupSentTickets"

import '../style/eventCreatedPopup.scss'
import "../../style/genericPopup.scss"

const PopupPurchasedEvent: React.FC<PurchasedEventPopupProps> = ({ open, handleClose, email, phone, eventId, name }) => {

    const [sentTicketsPopup, setSentTicketsPopup] = useState<boolean>(false)
    const [serverLoading, setServerLoading] = useState(false)

    const WeAreComing = async () => {
        try {
            setServerLoading(true)
            await axios.get(`/api/contact/send-ordered-event-messages?email=${email}&eventId=${eventId}&phone=${phone}&name=${name}`);
            setServerLoading(false)
        }
        catch (error) {
            setServerLoading(false)
            throw error;
        }
        setSentTicketsPopup(true)
        handleClose()
    }

    return (
        <>
            <GenericPopup
                open={open}
                handleClose={handleClose}
                imageStyles="event-created-icon"
                image={"/icons/blue-light.svg"}
                button={{ text: 'אנחנו מגיעים!', handleClick: WeAreComing, serverLoading: serverLoading, className: 'yellow' }}
                isSmall={true}
                removeXButton
                disableBackgroundClick
            >
                <div className="event-created-popup">
                    <div className="event-created-title" style={{ marginTop: '3vh' }}>
                        <p className="pay-attention"> שימו לב: </p>
                        <p> בהזמנת הכרטיסים אתם מביעים את רצונכם להגיע לאירוע</p>
                        <p> ומארגני האירוע יצפו לבואכם! </p>
                    </div>
                    <p className="event-created-text purchesed"  >
                        הכרטיסים לא ינותבו לעמותות נוספות ויישמרו לכם
                    </p>
                </div>
            </GenericPopup >
            <PopupSentTickets open={sentTicketsPopup} handleClose={() => setSentTicketsPopup(false)} phone={phone} email={email} />
        </>
    )
}

export default PopupPurchasedEvent;