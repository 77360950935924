import React, { useContext, useEffect, useRef, useState } from "react";
import { Area } from "react-easy-crop";
import moment, { Moment } from "moment";

import { CONTINUE, EVENT_DESCRIPTION, EVENT_DURATION, EVENT_NAME, MARK_IF_EVENT_AUDIENCE_IS_FOLLOWING_POPULATIONS } from "../../../consts/hebrew";
import { ANEContextInterface } from "../../../consts/interfaces/AddNewEventContext.interface";
import { PopulationArray } from "../../../consts/interfaces/PopulationArray.interface";
import { AgeRangeArrayItem } from "../../../consts/interfaces/AgeRange.interface";
import { CategoriesArray } from "../../../consts/interfaces/Categories.interface";
import { addEventFirst } from "../../../consts/interfaces/formErrors.interface";
import { FORM_FIELDS, _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import useIsFirstRender from "../../../consts/hooks/useIsFirstRender";
import { ADD_EVENT_FIRST } from "../../../consts/enums/InputsNames";

import { dateValidation, durationValidator, fileValidation, longTextValidation, selectAtLeastOneValidator, textValidation, timeValidation } from "../../../functions/validations";
import { AddNewEventContext } from "../../../context/AddNewEventContext";
import { saveInLocalStorage } from "../../../functions/localStorage";
import { scrollToFirstError } from "../../../functions/scrollToError";
import { isDateToday } from "../../../functions/dateFunctions";

import GenericOvalClick from "../../../generic-components/GenericOvalClick";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";

import UploadEventPosterImage from "../../../generic-components/UploadEventPosterImage";
import AgeRangeCheckboxes from "../../../generic-components/AgeRangeCheckboxes";
import UploadEventImage from "../../../generic-components/UploadEventImage";
import EventDateInput from "./EventDateInput";
import EventTimeInput from "./EventTimeInput";
import CategoryInput from "../../../generic-components/CategoryInput";

import "./style/addEventRightFirst&Second.scss";

const AddEventRightFirst: React.FC = () => {

    const isFirstRender = useIsFirstRender();
    const formRef = useRef(null)
    const firstInputRef = useRef<HTMLInputElement>(null); // ref for first input
    const { addDetailsFirst, setPageCx, durationCx, eventNameCx, descriptionCx, timeCx, dateCx, categoryCx, populationCx, filesManager, filesUploaderCx, changeCoverImage, changePosterImage, deletePosterImage, ageRangeCx, imageCoordinatesCx, editMode, deleteCoverImage } = useContext(AddNewEventContext) as ANEContextInterface;

    const [eventNameValue, setEventNameValue] = useState(eventNameCx); // the event name
    const [date, setDate] = useState<Moment | null>(dateCx); // the date of the event in moment object
    const [startValue, setStartValue] = useState<Moment | string | null>(dateCx);// the time the event start in moment object  
    const [duration, setDuration] = useState<string>(''); // hh:mm string of the event duration
    const [eventDetailsValue, setEventDetailsValue] = useState<string>(""); // the description of the event
    const [ageRangeArray, setAgeRangeArray] = useState<AgeRangeArrayItem[]>([]);//the array of age ranges and if they are selected or not
    const [categories, setCategories] = useState<CategoriesArray[]>([])
    const [populations, setPopulations] = useState<PopulationArray[]>([]);
    const [imageCoordinates, setImageCoordinates] = useState<Area>(imageCoordinatesCx)

    // errors
    const [startTimeError, setStartTimeError] = useState("");
    const [eventDetailsError, setEventDetailsError] = useState("");
    const [eventNameError, setEventNameError] = useState("");
    const [dateError, setDateError] = useState("");
    const [durationError, setDurationError] = useState("")
    const [fileError, setFileError] = useState("");
    const [filePosterError, setFilePosterError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [populationError, setPopulationError] = useState("");
    const [ageRangeError, setAgeRangeError] = useState<string>("");

    const [errors, setErrors] = useState<addEventFirst>({})

    useEffect(() => { // useEffect that put in the right fields the values from the context
        setEventNameValue(eventNameCx)
        setEventDetailsValue(descriptionCx)
        setDate(dateCx)
        setStartValue(timeCx)
        setDuration(durationCx)
        setCategories(categoryCx)
        setPopulations(populationCx)
        setAgeRangeArray(ageRangeCx);
        setImageCoordinates(imageCoordinatesCx)
    }, [categoryCx, ageRangeCx, populationCx, dateCx, descriptionCx, durationCx, eventNameCx, timeCx, imageCoordinatesCx]);

    useEffect(() => {
        if (startValue) setStartTimeError('')
    }, [startValue])

    useEffect(() => {
        if (categories.some(option => option.selected)) {
            setCategoryError("")
        }
    }, [categories])

    useEffect(() => {
        scrollToFirstError(formRef, firstInputRef, errors)
    }, [errors])

    /**
   * sets fields errors
   * @returns true if all fields are valid, else false
   */
    const validateFields = () => {
        const eventNameVal = textValidation(eventNameValue);
        const dateVal = dateValidation(date, moment(new Date().setFullYear(new Date().getFullYear() + 3)))
        const startVal = timeValidation(startValue, isDateToday(date?.toDate()));
        const durationVal = durationValidator(duration)
        const eventDetailsVal = longTextValidation(eventDetailsValue);
        const ageRangeVal = selectAtLeastOneValidator(ageRangeArray)
        const categoryVal = selectAtLeastOneValidator(categories);
        const populVal = selectAtLeastOneValidator(populationCx);
        const fileVal = fileValidation(fileError, filesManager?.coverImage?.link);

        if (!eventNameVal && !startVal && !durationVal && !eventDetailsVal && !dateVal && !fileVal && !populVal && !categoryVal && !ageRangeVal) {
            return true
        }
        const newErrors: addEventFirst = {}
        if (eventNameVal) newErrors.eventName = eventNameVal
        if (dateError) newErrors.eventDate = dateError
        if (startVal) newErrors.startTime = startVal
        if (durationVal) newErrors.duration = durationVal
        if (eventDetailsVal) newErrors.eventDetails = eventDetailsVal
        if (ageRangeVal) newErrors.ageRange = ageRangeVal
        if (categoryVal) newErrors.category = categoryVal
        if (populVal) newErrors.population = populVal
        if (fileError) newErrors.eventImage = fileError
        setErrors(newErrors)

        setEventNameError(eventNameVal);
        setAgeRangeError(ageRangeVal);
        setStartTimeError(startVal);
        setEventDetailsError(eventDetailsVal);
        setDateError(dateVal);
        setFileError(fileVal);
        setPopulationError(populVal)
        setCategoryError(categoryVal);
        setDurationError(durationVal);
        return false
    }

    const continueButton = () => {
        if (validateFields()) {
            addDetailsFirst(
                eventNameValue,
                duration,
                eventDetailsValue,
                date,
                startValue,
                categories,
                populations,
                ageRangeArray,
                imageCoordinates,
            ) // function that add all the data to the context
            setPageCx(1)
        }
    }

    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        if (isFirstRender) return;

        switch (e.target.name) {
            case "eventName":
                setEventNameError(textValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.NAME, e.target.value)
                break;
            case "startTime":
                setStartTimeError(textValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.TIME, e.target.value);
                break;
            case "duration":
                setDurationError(durationValidator(e.target.value))
                !editMode && saveInLocalStorage(FORM_FIELDS.DURATION, e.target.value)
                break;
            case "eventDetails":
                setEventDetailsError(longTextValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.DESCRIPTION, e.target.value)
                break;
        }
    }

    //function that handles the input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "eventName":
                setEventNameValue(e.target.value);
                break;
            case "eventDetails":
                setEventDetailsValue(e.target.value);
                break;
            case "duration":
                setDuration(e.target.value);
                break;
        }
    }

    return (
        <div className="add-event-right-first">
            <form ref={formRef}>
                <GenericInput
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    title={EVENT_NAME + ':'}
                    name={ADD_EVENT_FIRST.EVENT_NAME}
                    value={eventNameValue}
                    error={eventNameError}
                    inputRef={firstInputRef}
                    characterLimit={50}
                />

                <EventDateInput
                    date={date}
                    setDate={setDate}
                    dateError={dateError}
                    setDateError={setDateError}
                    saveDraft={!editMode}
                />

                <EventTimeInput
                    startValue={startValue}
                    setStartValue={setStartValue}
                    startTimeError={startTimeError}
                    setStartTimeError={setStartTimeError}
                    date={date}
                    saveDraft={!editMode}
                />

                <GenericInput
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    title={EVENT_DURATION + ':'}
                    name={ADD_EVENT_FIRST.DURATION}
                    timeInput
                    value={duration}
                    error={durationError}
                    placeholder="h:mm"
                    characterLimit={4}
                    hideCharacterLimit
                />

                <GenericInput
                    title={EVENT_DESCRIPTION + ':'}
                    textarea
                    value={eventDetailsValue}
                    name={ADD_EVENT_FIRST.EVENT_DETAILS}
                    error={eventDetailsError}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    characterLimit={_LONG_TEXT_MAX_LENGTH}
                />

                <div className="input-container">
                    <AgeRangeCheckboxes ageRange={ageRangeArray} setAgeRange={setAgeRangeArray} setAgeRangeError={setAgeRangeError} saveDraft={!editMode} name={ADD_EVENT_FIRST.AGE_RANGE} />
                    <div className="bottom-text-container">
                        <div className="input-error">
                            {ageRangeError}
                        </div>
                    </div>
                </div>

                <CategoryInput categories={categories} setCategories={setCategories} setCategoryError={setCategoryError} saveDraft={!editMode} categoryError={categoryError}/>

                <div className="input-container margin-top">
                    <div style={{ marginBottom: '3vh' }} className="input-title">{MARK_IF_EVENT_AUDIENCE_IS_FOLLOWING_POPULATIONS}:</div>
                    <GenericOvalClick options={populations} setOptions={setPopulations} saveDraft={!editMode} setError={setPopulationError} />
                    <div className="add-event-errors-first"> {populationError} </div>
                    <GenericInput className="invisible-input" name={ADD_EVENT_FIRST.POPULATION} />
                </div>

                <UploadEventImage
                    filesUploader={filesUploaderCx}
                    src={filesManager.coverImage?.link || ""}
                    imageCoordinates={imageCoordinates}
                    setImageCoordinates={setImageCoordinates}
                    fileError={fileError}
                    setFileError={setFileError}
                    onChange={(value) => changeCoverImage({ fileName: value.fileName, link: value.link, id: value.id, isNew: true })}
                    saveDraft={!editMode}
                    // onDelete={deleteCoverImage}
                    deleteCoverImage={deleteCoverImage}
                    name={ADD_EVENT_FIRST.EVENT_IMAGE}
                />

                <UploadEventPosterImage
                    filesUploader={filesUploaderCx}
                    fileName={filesManager.posterImage?.fileName || ""}
                    fileError={filePosterError}
                    setFileError={setFilePosterError}
                    onChange={(value) => {
                        changePosterImage({ fileName: value.fileName, link: value.link, id: value.id, isNew: true });
                    }}
                    onDelete={deletePosterImage}
                    saveDraft={!editMode}
                />


            </form>
            <GenericButton handleClick={continueButton} className="yellow add-event-yellow-button-first">{CONTINUE}</GenericButton>
        </div >
    )
}

export default AddEventRightFirst;