import React from "react";
import { CategoriesSelectProps } from "../../../consts/interfaces/Categories.interface";
import GenericCheckbox from "../../../generic-components/GenericCheckbox";

const CategoriesSelect: React.FC<CategoriesSelectProps> = ({ filteredCategories, handleChange }) => {
    return (
        <form>
            <div className='category-box'>
                {
                    filteredCategories.length ?
                        filteredCategories.map((item, index) => {
                            return (
                                <div key={index} className='category-item'>
                                    <GenericCheckbox className='-category-checkbox' checked={item.selected} handleChange={() => handleChange(item.id)} />
                                    <div className='category-name'>{item.categoryName}</div>
                                </div>
                            )
                        }) :
                        <div className='no-found-type'>אין סוגי אירוע לפי ערך החיפוש</div>
                }
            </div>
        </form>
    )
}

export default CategoriesSelect;