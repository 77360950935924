import { FC, useCallback, useEffect, useState } from "react";
import { Area, MediaSize, Point } from "react-easy-crop/types";
import Cropper from 'react-easy-crop';

import { Slider, useMediaQuery } from "@mui/material";

import { PopupCropPictureProps } from "../../consts/interfaces/PopupCropPicture.interface";
import GenericButton from "../../generic-components/GenericButton";
import GenericPopup from "../../generic-components/GenericPopup";

import '../style/popupCropPicture.scss';

const PopupCropPicture: FC<PopupCropPictureProps> = ({ open, image, ratio, handleClose, setImageCoordinates, saveImgAfterConfirmCrop, setCropPopup }) => {

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<number>(1)
    const [coordinates, setCoordinates] = useState<Area>({ x: 0, y: 0, width: 100, height: 100 })
    const [scale, setScale] = useState<number>(1);
    const [mediaSize, setMediaSize] = useState<MediaSize>({ width: 0, height: 0, naturalWidth: 0, naturalHeight: 0 })

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)

    useEffect(() => {
        setZoom(1)
        setCrop({ x: 0, y: 0 })
    }, [image])

    const onCropComplete = useCallback((croppedArea: Area) => {
        setCoordinates(croppedArea)
    }, [])

    const handleConfirmCrop = () => {
        setImageCoordinates(coordinates)
        saveImgAfterConfirmCrop && saveImgAfterConfirmCrop(image)
        setCropPopup(false)
    }

    const onMediaLoaded = (mediaSize: MediaSize) => {
        setMediaSize(mediaSize)
        !tabletMediaQuery && ratio === 3 && setScale(400 / mediaSize.width) // to make the crop area and image bigger than the default if using web
    }

    const onCropChange = (crop: Point) => {
        setCrop(prev => ({
            x: Math.abs(crop.x) < mediaSize.width / 2 * zoom ? crop.x : prev.x, // limits x
            y: Math.abs(crop.y) < mediaSize.height / 2 * zoom ? crop.y : prev.y, // limits y
        }
        ))
    }

    const onZoomChange = (newZoom: number) => {
        setZoom(newZoom)
        setCrop(prev => ({
            x: Math.abs(crop.x) < mediaSize.width / 2 * newZoom ? prev.x : (prev.x > 0 ? 1 : -1) * mediaSize.width / 2 * newZoom, // limits x
            y: Math.abs(crop.y) < mediaSize.height / 2 * newZoom ? prev.y : (prev.y > 0 ? 1 : -1) * mediaSize.height / 2 * newZoom, // limits y
        }))
    }

    return (
        <GenericPopup open={open} handleClose={handleClose} >
            <div className="crop-popup">
                <div className="crop-container">
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={ratio}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                        style={{ mediaStyle: { scale: `${scale}` }, cropAreaStyle: { scale: `${scale}`, translate: '-50% -50%', transform: 'none' } }}
                        onMediaLoaded={onMediaLoaded}
                        cropShape={ratio === 1 ? 'round' : 'rect'}
                        restrictPosition={false}
                    />
                </div>

                <div className="crop-controls">
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.01}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => onZoomChange(Number(zoom))}
                        classes={{ root: "slider", rail: 'rail', track: 'track', thumb: 'thumb' }}
                    />
                    <GenericButton handleClick={handleConfirmCrop} className={`yellow ${tabletMediaQuery ? 'big' : 'small'}`}>
                        שמור
                    </GenericButton>
                </div>
            </div >
        </GenericPopup >
    )
}

export default PopupCropPicture;