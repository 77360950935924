//User context - here we save he users info and what type of role he has so he can stay logged in on every page until he logs out

import React, { useContext, useState } from "react";
import { Area } from "react-easy-crop";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

export const UserContext = React.createContext({
    role: '',
    name: '',
    systemID: 0,
    userName: "",
    email: '',
    userId: "",
    profilePhoto: "",
    profileCoordinates: { x: 0, y: 0, width: 100, height: 100 },
    setEmail: (_val: string) => { },
    setRole: (_val: string) => { },
    setName: (_val: string) => { },
    setUserName: (_val: string) => { },
    setSystemID: (_val: number) => { },
    setUserId: (_val: string) => { },
    loggingIn: (_userId: string, _role: string, _name: string, _systemID: number, _email: string, _userName: string) => { },
    setProfilePhoto: (_val: string) => { },
    setProfileCoordinates: (_val: Area) => { },
    systemName: '',
    refetch: () => { }
});

const UserProvider: React.FC = ({ children }) => {

    const [role, setRole] = useState("");
    const [name, setName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [systemID, setSystemID] = useState<number>(0);
    const [email, setEmail] = useState<string>('');
    const [userId, setUserId] = useState<string>("");
    const [profilePhoto, setProfilePhoto] = useState<string>("");
    const [profileCoordinates, setProfileCoordinates] = useState<Area>({ x: 0, y: 0, width: 100, height: 100 });
    const [systemName, setSystemName] = useState<string>("");

    const loggingIn = (userId: string, name: string, role: string, systemID: number, email: string, userName?: string) => {
        setUserId(userId)
        setRole(role)
        setName(name)
        setEmail(email)
        setSystemID(systemID)
        userName ? setUserName(userName) : setUserName("")
    }

    useAsyncEffect(async () => {
        if (systemID && role) {
            refetch()
        }
    }, [systemID, role])

    const refetch = async () => {
        if (systemID && role) {
            const profilePic = (await axios.get(`/api/contact/get-system-profile?role=${role}&systemId=${systemID}`)).data;
            if (profilePic) {
                setProfilePhoto(profilePic?.profilePhoto);
                setProfileCoordinates(profilePic?.imageCoordinates);
                setSystemName(profilePic.systemName);
            }
            else {
                setProfilePhoto("/icons/profile-user-white.svg")
            }
        }
    }

    return (
        <UserContext.Provider
            value={{
                role,
                name,
                systemID,
                userName,
                email,
                userId,
                setUserName,
                setRole,
                setName,
                setUserId,
                setSystemID,
                setEmail,
                loggingIn,
                profilePhoto,
                profileCoordinates,
                setProfilePhoto,
                setProfileCoordinates,
                systemName,
                refetch
            }}
        >
            {children}

        </UserContext.Provider>
    )
}


export const useUserInfo = () => useContext(UserContext)


export default UserProvider