
// valid text- numbers letters and dashes
export const VALID_TEXT = /^[0-9\u0590-\u05fea-z./"'!-:?$,`@;+=\s ]+$/i;

// valid phone number- 10 numbers and has to start with a 0
export const VALID_PHONE_NUMBER = /^05\d([-]{0,1})\d{7}$/
// /^0[0-9]{2}-?[0-9]{3}-?[0-9]{4}$/;

//valid Email address
export const VALID_EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// export const VALID_EMAIL = /^((?!\.)(?!.*\.$)(?!-.*?\.\.)[a-z0-9.]{3,30})[@][a-z0-9]{2,}([.][a-z]{2,})+$/i;

// valid date
export const VALID_DATE = /^[0-9]+.[0-9]+.[0-9]/;

// valid number- only numbers
export const VALID_NUM = /^[0-9]*$/;

export const VALID_NUM_PEOPLE = /^[1-9]\d*$/;

//valid name- only english and hebrew letters, space at end is to allow spaces
export const VALID_NAME = /^[\u0590-\u05FF\u0600-\u06FFa-zA-Z ]+$/;

//valid code- only 6 numbers
export const VALID_CODE = /^[\u0590-\u05FF\uFB1D-\uFB40a-zA-Z0-9]+$/;


//valid link
//eslint-disable-next-line
export const VALID_LINK = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const VALID_DURATION = /^(\d?\d):[0-5][0-9]$/;

export const NOT_HEBREW = /^[A-Za-z!@#$%^&*(),.?":{}|<>]+$/
