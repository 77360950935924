//this component gets - id: number, title: string, categories : CategoriesArray[], setCategories : Dispatch<SetStateAction<CategoriesArray[]>>ץ
//it returns a category in the categorys array that comes from the popup

import React from 'react';
import { DemographicPopupArrayInterface } from '../../consts/interfaces/Demographic.interface';
import FocusAble from '../../generic-components/FocusAble';

import '../style/typePopupArray.scss';

const PopupArrayDemographic: React.FC<DemographicPopupArrayInterface> = ({ id, title, removeMyself }) => {

    //function that removes a category from the array
    const removeDemographic = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        e.stopPropagation(); //stop propagation

        removeMyself(id)
    }

    return (
        <div className="popup-array-category">
            <FocusAble onClick={removeDemographic}>
                <img className="close-category" src="/icons/close-circle.svg" alt="close" />
            </FocusAble>
            <div className='title-popup-array'>{title}</div>
        </div>
    )
}

export default PopupArrayDemographic;