//this component gets props - EventArchiveItemProps
//it returns an event from the events archive(events that have happened already)

import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { EventArchiveItemProps } from "../../../consts/interfaces/EventArchiveItem.interface";
import { EventArchiveEnum } from "../../../consts/hebrew";

import MessageReceived from "./MessageReceived";
import FocusAble from "../../../generic-components/FocusAble";

export default function EventArchiveItem(props: EventArchiveItemProps) {

    const history = useHistory();
    const mobile = useMediaQuery('(max-width: 768px)');

    //find the events-happened-item-name of the event clicked
    const handleClick = () => {
        history.push(`/feedback/${props.id}`); // send to the correct position
    }

    return (
        <FocusAble className='event-archive-item' onClick={handleClick}>
            <div className='details-archive'>
                {mobile && <div className="events-happened-item-name-mobile">{props.name}</div>}
                <div className="events-happened-item-right" >
                    {!mobile &&
                        <div className="events-happened-item-name">
                            {props.name}
                        </div>
                    }
                    <div className="events-happened-item-day">{props.day}</div>
                    |
                    <div className="events-happened-item-date"> {props.date} </div>
                    |
                    <div className="events-happened-item-time"> {props.time}</div>
                </div>
                {mobile ?
                    props.ticketsNum !== 1 ?
                        `${EventArchiveEnum.ORDERED} ${props.ticketsNum} ${EventArchiveEnum.TICKETS} ${EventArchiveEnum.FROM} ${props.ticketsTotal}`
                        : `${EventArchiveEnum.SINGLE_TICKET} ${EventArchiveEnum.FROM} ${props.ticketsTotal}`
                    : null
                }
            </div>

            {!mobile &&
                <div className="events-archive-tickets">
                    {props.ticketsNum !== 1 ?
                        `${EventArchiveEnum.ORDERED} ${props.ticketsNum} ${EventArchiveEnum.TICKETS} ${EventArchiveEnum.FROM} ${props.ticketsTotal}`
                        : `${EventArchiveEnum.SINGLE_TICKET} ${EventArchiveEnum.FROM} ${props.ticketsTotal}`}
                </div>
            }

            <div className="events-archive-item-left">
                <div style={{ visibility: props.feedbackNumber === 0 ? "hidden" : "visible" }}>
                    <MessageReceived showYellowDot={props.hasNewMessage} numOfMessages={props.feedbackNumber} />
                </div>
                <img src="/icons/arrow-partial.svg" className="event-archive-arrow" alt="arrow down" />
            </div>

        </FocusAble>
    )
}


