import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { HOME_PAGE } from "../../../consts/hebrew";

import GenericButton from "../../../generic-components/GenericButton";
import "./style/newHomePage.scss";

interface infoForStatistics {
    agenciesAmount: number
    organizationsAmount: number
    ticketsAmount: number
    donationOfEvent: number
}

const NewHomePage: React.FC = () => {

    document.title = 'אנטר'
    const history = useHistory();

    const partners = [
        {
            img: '/icons/jeroLogo.png',
            alt: 'jerusalem logo'
        },
        {
            img: '/icons/partner2-logo.png',
            alt: 'mifal hapis logo'
        },
    ];

    const partnersSecondColumn = [
        {
            img: '/icons/place-logo.png',
            alt: 'merkaz hamekomi logo'
        },
        {
            img: '/icons/hilma.png',
            alt: 'hilma logo'
        },
        {
            img: '/icons/partner1-logo.png',
            alt: 'merkaz haezori logo'
        }
    ]

    const [statistics, setStatistics] = useState([
        {
            img: '/icons/hands.svg',
            alt: 'agency icon',
            number: 0,
            title: HOME_PAGE.AGENCIES,
        },

        {
            img: '/icons/ticket.svg',
            alt: 'tickets icon',
            number: 0,
            title: HOME_PAGE.TICKETS,
        }
    ])

    const [statisticsRowRight, setStatisticsRowRight] = useState([
        {
            img: '/icons/heart-hand.svg',
            alt: 'org icon',
            number: 0,
            title: HOME_PAGE.ORGANIZATIONS,
        },
        {
            img: '/icons/present.svg',
            alt: 'money icon',
            number: 0,
            title: HOME_PAGE.DONATION,
        }
    ])

    const getInfoForStatistics = useCallback(async () => {
        const info: infoForStatistics = (await axios.get(`/api/ticket/info-for-home-page`)).data
        const { agenciesAmount, organizationsAmount, ticketsAmount, donationOfEvent } = info
        setStatistics(prevStats => prevStats.map(stat => {
            const copiedStat = { ...stat }
            switch (stat.title) {
                case HOME_PAGE.AGENCIES:
                    copiedStat.number = agenciesAmount
                    break;
                case HOME_PAGE.TICKETS:
                    copiedStat.number = ticketsAmount
                    break;
            }
            return copiedStat
        }))
        setStatisticsRowRight(prevStats => prevStats.map(stat => {
            const copiedStat = { ...stat }
            switch (stat.title) {
                case HOME_PAGE.ORGANIZATIONS:
                    copiedStat.number = organizationsAmount
                    break;
                case HOME_PAGE.DONATION:
                    copiedStat.number = donationOfEvent
                    break;
            }
            return copiedStat
        })
        )
    }, [])

    useEffect(() => {
        getInfoForStatistics()
    }, [getInfoForStatistics])

    return (
        <>
            <div className="home">
                <div className="home-page">
                    <img className="home-page-logo" src="/icons/enter-blue-logo.svg" alt="blue logo" />
                    <div className="home-page-title"><span className="home-page-semi-title home-page-bold">מיזם חברתי - תרבותי </span>בעיר ירושלים</div>
                    <GenericButton className="home-middle-button home-button" handleClick={() => history.push("/login")} >כניסה</GenericButton>
                    <div className="statistics-container">

                        <div className="statistic-row-right">
                            {statisticsRowRight.map((item) => {
                                return (
                                    <div key={item.title} className={`statistics-box ${item?.alt === "money icon" ? "bigger-container" : ""}`}>
                                        <img className="stat-img" src={item.img} alt={item.alt} />
                                        {item.title == HOME_PAGE.DONATION ?
                                            <div className="number">
                                                {(Number(item.number || 0)).toLocaleString("en-US")}
                                            </div> :
                                            <div className="number">{item.number || 0}</div>
                                        }
                                        <div className="title">{item.title}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="statiscs-row">
                            {statistics.map((item) => {
                                return (
                                    <div key={item.title} className={`statistics-box ${item?.alt === "agency icon" ? "bigger-container" : ""} `}>
                                        <img className="stat-img" src={item.img} alt={item.alt} />
                                        {item.title == HOME_PAGE.DONATION ?
                                            <div className="number">
                                                <span>₪</span>{(Number(item.number || 0)).toLocaleString("en-US")}
                                            </div> :
                                            <div className="number">{item.number || 0}</div>
                                        }
                                        <div className="title">{item.title}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="paragraph">
                        {/* <div className="paragraph-title home-page-bold">אז איך אנטר בעצם עובד?</div>
                        <div className="paragrph-row">מוסד תרבות / ספורט מזין למערכת<span className="home-page-bold">כרטיסים עודפים או מיועדים לתרומה</span></div>
                        <div className="paragrph-row">פרטי האירוע נשלחים כהצעה לעמותה רלוונטית<span className="home-page-bold">על פי האלגוריתם שלנו</span></div>
                        <div className="paragrph-row">העמותה מושכת כרטיסים עבור<span className="home-page-bold">חוויה בלתי נשכחת</span>או מעבירה את הכרטיסים הלאה לעמותה אחרת שתשמח בהם</div> */}
                    </div>

                    <div className="partners-container">
                        <div className="partners">
                            {partners.map((partner) => {
                                return (
                                    <img
                                        key={partner.alt}
                                        className={`partners-img ${partner.alt == "hilma logo" ? "big-img" : partner.alt === "mifal hapis logo" ? "small-img" : ""}`}
                                        src={partner.img}
                                        alt={partner.alt} />

                                )
                            })}
                        </div>
                        <div className="partners-second-column">
                            {partnersSecondColumn.map((partner) => {
                                return (
                                    <img
                                        key={partner?.alt}
                                        className={`partners-img ${partner?.alt === "hilma logo" ? "big-img" : ""}`}
                                        src={partner?.img}
                                        alt={partner?.alt}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewHomePage