import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { POPUP_DUPLICATE_EVENT } from "../../consts/hebrew"
import { AddNewEventContext } from "../../context/AddNewEventContext";
import GenericPopup from "../../generic-components/GenericPopup"

import "../style/popupDuplicateEvent.scss";

interface PopupDuplicateEventProps {
    open: boolean;
    eventId: number;
}

const PopupDuplicateEvent: React.FC<PopupDuplicateEventProps> = ({ open, eventId }) => {

    const addNewEventCx = useContext(AddNewEventContext)
    const history = useHistory()

    const handleDuplicate = () => {
        if (addNewEventCx) {
            const { setFetchDataFromDB, setEventID, setIsDuplicated } = addNewEventCx
            setIsDuplicated(true)
            setFetchDataFromDB(true)
            setEventID(eventId)
            history.push(`/event/add`)
        }
    }

    return (
        <GenericPopup open={open}
            title={POPUP_DUPLICATE_EVENT.TITLE}
            removeXButton
            button={{
                text: POPUP_DUPLICATE_EVENT.BUTTON,
                handleClick: handleDuplicate,
                className: 'cyan small'
            }}
            className="popup-duplicate-event"
        />
    )
}
export default PopupDuplicateEvent