import { Skeleton } from "@mui/material"

const SkeletonsFuturePurchasedEvents: React.FC<{ shortArray?: boolean }> = ({ shortArray }) => {
    return (
        <div className={shortArray ? 'skeleton-home-page' : 'skeleton-events-page'}>
            <Skeleton className="skeleton-title" />
            <div className="skeleton-cards-container">
                <Skeleton className="skeleton-card" />
                <Skeleton className="skeleton-card" />
                <Skeleton className="skeleton-card" />
                <Skeleton className="skeleton-card" />
            </div>
        </div>
    )
}

export default SkeletonsFuturePurchasedEvents