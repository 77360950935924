import React from "react";
import { DO_YOU_WANT_LOAD_DRAFT, EDIT_NEW_EVENT, RETURN_TO_EDIT, THERES_OPEN_EVENT } from "../../consts/hebrew";
import GenericButton from "../../generic-components/GenericButton";
import GenericPopup from "../../generic-components/GenericPopup";
import '../style/loadDraftEventPopup.scss';


const PopupLoadDraftEvent: React.FC<{ open: boolean, handleClose: () => void, loadData: () => void, discardDraft: () => void }> = ({ open, handleClose, loadData, discardDraft }) => {

    const handleLoadDraft = () => {
        handleClose();
        loadData();
    }

    const handleDiscardDraft = () => {
        handleClose();
        discardDraft();
    }

    return (
        <GenericPopup open={open} handleClose={handleClose} className="load-draft-event-popup">
            <div className="draft-popup-content">
                <div className="first-title">
                    {THERES_OPEN_EVENT}
                </div>
                <div className="second-title">
                    {DO_YOU_WANT_LOAD_DRAFT}
                </div>

                <GenericButton handleClick={handleDiscardDraft} className="turquise draft-popup-btn">{EDIT_NEW_EVENT}</GenericButton>
                <GenericButton handleClick={handleLoadDraft} className="yellow draft-popup-btn">{RETURN_TO_EDIT}</GenericButton>
            </div>
        </GenericPopup>
    )
}

export default PopupLoadDraftEvent;