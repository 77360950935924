import React, { useEffect, useState } from 'react';

import { eventToDismantledDate } from '../functions/dateFunctions';
import { ARRIVING_TO_EVENT, AVAILABLE_TICKETS, ORDERED, ORGANIZATIONS, TICKETS, TICKETS_SAVED_TILL, TICKETS_WERE_PURCHASED } from '../consts/hebrew';
import { EventSwiperElementType } from '../consts/interfaces/SwipeBoard.interface';
import { useUserInfo } from '../context/UserContext';

import EventDropDown from './EventDropDown';
import GenericComingEventCover from './GenericComingEventCover';

import '../style/singleComingEvent.scss'

interface SingleComingEventProps {
    element: EventSwiperElementType
    navigateToEventPage: (eventId: number) => void;
}
const SingleComingEvent: React.FC<SingleComingEventProps> = ({ element, navigateToEventPage }) => {
    const { eventId, coverImage, imageCoordinates, date: eventDate, eventName } = element;
    const { purchasedOrgsCount, ticketsPurchased, ticketsTotal, ticketsNum, timeExpired, ticketsPurchasedByOrg } = element;
    const isPossibleToEdit = ticketsPurchased === 0
    const { date, day, time } = eventToDismantledDate(eventDate);
    const { role } = useUserInfo()

    const [timeLeft, setTimeLeft] = useState<string>('')

    useEffect(() => {
        const calculateTimeDifference = (expirationDate?: string) => {
            if (!expirationDate) return 0

            const today = Date.now();
            const timeEnd = new Date(expirationDate)
            const milliseconds = timeEnd.getTime() - today
            return milliseconds
        }

        const buildTimeString = (milliseconds: number) => {
            let newTimeSecond = Math.floor(milliseconds / 1000);
            let newTimeMinute = Math.floor(newTimeSecond / 60);
            let newTimeHour = Math.floor(newTimeMinute / 60);

            newTimeSecond = newTimeSecond % 60;
            newTimeMinute = newTimeMinute % 60;

            let NewTimeHourString = newTimeHour >= 10 ? `${newTimeHour}` : `0${newTimeHour}`
            let NewTimeMinuteString = newTimeMinute >= 10 ? `${newTimeMinute}` : `0${newTimeMinute}`
            let NewTimeSecondString = newTimeSecond >= 10 ? `${newTimeSecond}` : `0${newTimeSecond}`

            let newTimeString = `${NewTimeHourString}:${NewTimeMinuteString}:${NewTimeSecondString}`
            return newTimeString
        }

        const TimeInterval = setInterval(() => {
            const timeDifference = calculateTimeDifference(timeExpired?.split(',')[0])

            if (timeDifference <= 0) clearInterval(TimeInterval);
            else setTimeLeft(buildTimeString(timeDifference));
        }, 1000);

        return () => clearInterval(TimeInterval);
    }, [])

    return (
        <div className='event-details-card'  >
            <div className='event-cover-div' onClick={() => navigateToEventPage(eventId)}>
                <GenericComingEventCover src={coverImage} imageCoordinates={imageCoordinates} />
            </div>
            <div className='event-title'>
                <span>{eventName}</span>
                {role === 'agency' && < EventDropDown eventId={eventId} ableToEdit={isPossibleToEdit} />}
            </div>
            <div className='event-date'>{day} | {date} | {time}</div>

            <div className='event-details-container'>
                {purchasedOrgsCount !== undefined &&
                    <div className="event-details">
                        <b>{purchasedOrgsCount} {ORGANIZATIONS}</b> {ARRIVING_TO_EVENT}
                    </div>}

                {ticketsPurchased !== undefined && ticketsTotal !== undefined &&
                    <div className="event-details">
                        {ORDERED} <b>{ticketsPurchased}</b>/{ticketsTotal} <b>{TICKETS}</b>
                    </div>}

                {ticketsNum !== undefined &&
                    <div className="event-details with-icon">
                        <img src="/icons/ticket-empty.svg" alt="tickets" />
                        <b>{ticketsNum} {AVAILABLE_TICKETS}</b>
                    </div>}

                {ticketsPurchasedByOrg !== undefined && !ticketsTotal &&
                    <div className="event-details with-icon">
                        <img src="/icons/ticket-empty.svg" alt="tickets" />
                        <b>{ticketsPurchasedByOrg} {TICKETS_WERE_PURCHASED}</b>
                    </div>}

                {timeLeft &&
                    <div className="event-details with-icon">
                        <img src="/icons/timer-dark.svg" />
                        <span>
                            {TICKETS_SAVED_TILL} <b>{timeLeft}</b>
                        </span>
                    </div>}
            </div>
        </div>
    )
}

export default SingleComingEvent