import { useContext, useEffect, useRef, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

import { ADDRESS, CONTACT_AVAILABLE_ON_EVENT, CONTACT_NAME, CONTINUE, HALL, NAME_OF_HALL_OR_PLACE, OTHER, PHONE_NUM, PREVIOUS, PUBLIC_TRANSPORT_NAVIGATION } from "../../../consts/hebrew";
import { ANEContextInterface, AgencyContact } from "../../../consts/interfaces/AddNewEventContext.interface";
import { addEventSecond } from "../../../consts/interfaces/formErrors.interface";
import { FORM_FIELDS, _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import { ADD_EVENT_SECOND } from "../../../consts/enums/InputsNames";
import { saveInLocalStorage } from "../../../functions/localStorage";
import { scrollToFirstError } from "../../../functions/scrollToError";

import { AddNewEventContext } from "../../../context/AddNewEventContext";

import { dropDownValidation, longTextValidation, nameValidation, phoneNumValidation, textValidation } from "../../../functions/validations";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";

import GoogleLocationAutocomplete from "../../../generic-components/google-location-autocomplete/GoogleLocationAutocomplete";
import HallInputs from "../../../generic-components/HallInputs";
import { PlaceItem } from './PlaceItem';

import "./style/addEventRightFirst&Second.scss";

const AddEventRightSecond: React.FC = () => {

    const firstInputRef = useRef<HTMLInputElement>(null); // ref for the first input
    const { addDetailsSecond, setPageCx, changeAgencyContacts, locationDetailsCx, setLocationDetailsCx, currentAgencyContactCx, agencyContactListCx, editMode } = useContext(AddNewEventContext) as ANEContextInterface; // some methods and information from the parent context of add new event
    const formRef = useRef(null)
    const [errors, setErrors] = useState<addEventSecond>({})

    const [addressValue, setAddressValue] = useState<string>(locationDetailsCx.location || "");
    const [addressError, setAddressError] = useState<string>("");
    const [navigation, setNavigation] = useState<string>(locationDetailsCx.navigation || "");
    const [navigationError, setNavigationError] = useState<string>("");
    const [selectedHallIndex, setSelectedHallIndex] = useState(0); // the current hall selected in case there is multiple halls
    const [currentContact, setCurrentContact] = useState<number>(0)
    const [agencyContacts, setAgencyContacts] = useState<AgencyContact[]>(agencyContactListCx || [])
    const [contactNameError, setContactNameError] = useState<string>("");
    const [contactPhoneError, setContactPhoneError] = useState<string>("");

    const placeArray = [ // sub object for printing more easily the tsx
        {
            name: ADDRESS,
            data: locationDetailsCx?.location
        }, {
            name: PUBLIC_TRANSPORT_NAVIGATION,
            data: locationDetailsCx?.navigation
        }, {
            name: (locationDetailsCx.halls.length > 1) ? HALL : NAME_OF_HALL_OR_PLACE,
            data: locationDetailsCx?.halls[0].hallName,
            halls: locationDetailsCx?.halls
        }
    ];

    useEffect(() => {
        !editMode && locationDetailsCx.location && saveInLocalStorage(FORM_FIELDS.ADDRESS, locationDetailsCx.location)
        !editMode && locationDetailsCx.navigation && saveInLocalStorage(FORM_FIELDS.NAVIGATION, locationDetailsCx.navigation)
        !editMode && saveInLocalStorage(FORM_FIELDS.AGENCY_CONTACTS, agencyContactListCx)
    }, [locationDetailsCx, agencyContactListCx, editMode])

    useEffect(() => { // if address is changing to something remove the error
        if (addressValue !== "") {
            setAddressError("");
        }
        !editMode && saveInLocalStorage(FORM_FIELDS.ADDRESS, addressValue)
    }, [addressValue, editMode])

    useEffect(() => {
        scrollToFirstError(formRef, firstInputRef, errors)
    }, [errors])

    useEffect(() => {
        setCurrentContact(currentAgencyContactCx)
    }, [currentAgencyContactCx])

    useEffect(() => {
        setContactPhoneError("")
        setContactNameError("")

        !editMode && saveInLocalStorage(FORM_FIELDS.CURRENT_CONTACT, currentContact)
    }, [currentContact, editMode])

    const BackButton = (e: React.MouseEvent<HTMLButtonElement>) => { //function that moves back a page
        e.preventDefault();
        changeAgencyContacts(currentContact, agencyContacts)
        setPageCx(0);
    }

    const validateFields = () => {
        const addressVal = dropDownValidation(addressValue);
        const navigationVal = longTextValidation(navigation)
        const hallNameVal = locationDetailsCx.halls[0].hallNameError || nameValidation(locationDetailsCx.halls[0].hallName);
        const nameVal = nameValidation(agencyContacts[currentContact].name);
        const phoneVal = phoneNumValidation(agencyContacts[currentContact].username);

        if (agencyContacts && !addressVal && !nameVal && !hallNameVal && !phoneVal && !navigationVal) {
            return true
        }

        const errors: addEventSecond = {}
        if (addressVal) errors.location = addressVal
        if (navigationVal) errors.navigation = navigationVal
        if (hallNameVal) errors.hallName = hallNameVal
        if (nameVal) errors.name = nameVal
        if (phoneVal) errors.phone = phoneVal
        setErrors(errors)

        setAddressError(addressVal);
        setNavigationError(navigationVal);
        setLocationDetailsCx({ ...locationDetailsCx, halls: [{ ...locationDetailsCx.halls[0], hallNameError: hallNameVal }] })
        setContactPhoneError(phoneVal);
        setContactNameError(nameVal);
        return false
    }

    const ContinueButton = async () => { //function that checks if all the input is valid and if it is moves on to the next page
        if (validateFields()) {
            addDetailsSecond(addressValue, navigation, selectedHallIndex, currentContact, agencyContacts || []);
            setPageCx(2);
        }
    }

    const handleRadioChange = (index: number) => { // handle the radio change in the place item component
        setSelectedHallIndex(index)
        !editMode && saveInLocalStorage(FORM_FIELDS.HALL_INDEX, index)
        !editMode && saveInLocalStorage(FORM_FIELDS.HALL_NAME, HallInputs)

    }

    const handleHallNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLocationDetailsCx({ ...locationDetailsCx, halls: [{ ...locationDetailsCx.halls[0], hallName: e.target.value }] })
        !editMode && saveInLocalStorage(FORM_FIELDS.HALL_NAME, e.target.value)
    }

    //handle navigation input blur (error check)
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "navigation":
                setNavigationError(longTextValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.NAVIGATION, e.target.value)
                break;
            case "phone":
                setContactPhoneError(phoneNumValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.AGENCY_CONTACTS, agencyContacts)
                break;
            case "name":
                setContactNameError(nameValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.AGENCY_CONTACTS, agencyContacts)
                break;
        }
    }

    // handle navigation input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        switch (e.target.name) {
            case "navigation":
                setNavigation(e.target.value);
                break;
            case "phone":
                if (index && agencyContacts) {
                    const tempContacts = [...agencyContacts]
                    tempContacts[index].username = e.target.value
                    setAgencyContacts(tempContacts)
                }
                break;
            case "name":
                if (index && agencyContacts) {
                    const tempContacts = [...agencyContacts]
                    tempContacts[index].name = e.target.value
                    setAgencyContacts(tempContacts)
                }
                break;
        }
    }
    return (
        <div className="add-event-right-first">
            <form ref={formRef}>
                <GenericInput className="invisible-input" inputRef={firstInputRef} />
                {locationDetailsCx.status === 'no location found' ?
                    <div className="input-container">
                        <div className="input-title">
                            {ADDRESS}
                        </div>
                        <GoogleLocationAutocomplete
                            value={addressValue}
                            setValue={setAddressValue}
                            setAddressError={setAddressError}
                        />
                        <div className="add-event-errors-first">
                            {addressError}
                        </div>
                        <GenericInput className="invisible-input" name={ADD_EVENT_SECOND.LOCATION} />

                        <GenericInput
                            title={PUBLIC_TRANSPORT_NAVIGATION}
                            type={"textarea"}
                            value={navigation}
                            name={ADD_EVENT_SECOND.NAVIGATION}
                            error={navigationError}
                            characterLimit={_LONG_TEXT_MAX_LENGTH}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            textarea
                        />

                        <HallInputs
                            hallName={locationDetailsCx.halls[0].hallName}
                            setHallName={handleHallNameChange}
                            setHallNameError={(e) => setLocationDetailsCx({ ...locationDetailsCx, halls: [{ ...locationDetailsCx.halls[0], hallNameError: textValidation(e.target.value) }] })}
                            hallNameError={locationDetailsCx.halls[0].hallNameError}
                            name={ADD_EVENT_SECOND.HALL_NAME}
                        />
                    </div>
                    :
                    <div className='hall-info'>
                        {placeArray.map(item => { // this print all the place information according to placeArray.
                            return (
                                <PlaceItem
                                    key={item.name}
                                    item={item}
                                    selectedHallIndex={selectedHallIndex}
                                    handleRadioChange={handleRadioChange}
                                />
                            )
                        })}
                    </div>
                }
                <div className="input-container margin-top">
                    <div className="input-title">{CONTACT_AVAILABLE_ON_EVENT}:</div>
                    <FormControl fullWidth >
                        <Select
                            onChange={e => { setCurrentContact(Number(e.target.value)) }}
                            className="input select-mui"
                            value={currentContact}
                        >
                            {agencyContacts?.map((contact, index: number) => {
                                return <MenuItem key={index} value={index}>{index !== agencyContacts.length - 1 ? contact.name : OTHER}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                    {agencyContacts &&
                        <div className="margin-top">
                            {currentContact === agencyContacts.length - 1 && // option "other"
                                <GenericInput title={CONTACT_NAME}
                                    type={"text"}
                                    value={agencyContacts[currentContact].name}
                                    name={ADD_EVENT_SECOND.CONTACT_NAME}
                                    error={contactNameError}
                                    onChange={(e) => handleInputChange(e, currentContact)}
                                    onBlur={handleInputBlur}
                                />}

                            <GenericInput title={PHONE_NUM}
                                type={"text"}
                                value={agencyContacts[currentContact].username}
                                name={ADD_EVENT_SECOND.CONTACT_PHONE}
                                error={contactPhoneError}
                                characterLimit={10}
                                onChange={(e) => handleInputChange(e, currentContact)}
                                onBlur={handleInputBlur}
                            />
                        </div>
                    }
                </div>

                <div className="add-event-buttons">
                    <button onClick={BackButton} className="add-event-other-button-second">{PREVIOUS}</button>
                    <GenericButton handleClick={ContinueButton} className="yellow add-event-yellow-button-second">{CONTINUE}</GenericButton>
                </div>
            </form>
        </div >
    )
}

export default AddEventRightSecond;