import { useEffect } from "react"
import { useIsAuthenticated, useLogout } from "@hilma/auth"
import { useMemoOnce } from "@hilma/tools"
import { useError } from "../../context/ErrorContext"
import { SessionLogOutService } from "../../functions/SessionLogOutService"

const useAutoLogOut = () => {
    const isAuthenticated = useIsAuthenticated();
    const logOutAuthHilma = useLogout();
    const { showError } = useError()

    const onLoggedOut = () => {

        logOutAuthHilma()
        showError('ניתקנו אותך מהאתר למטרות בטיחות ופרטיות', { stayUntilClick: true, disableExitButton: true })
    }



    const logOutService = useMemoOnce(() => new SessionLogOutService(onLoggedOut));

    useEffect(() => {
        if (logOutService.status !== isAuthenticated) {
            //!  either start or stop the service according to the state of the user`s authentication and the status of the service. 
            // *** if the user is authenticated (true) and the status of the service active (true) - do nothing
            // *** same as if the user isn't authenticated (false) and the status of the service isn`t active (false) - do nothing
            // *** if the user is authenticated (true) and the status of the service isn`t active (false) - start it now
            // *** if the user isn't authenticated (false) and the status of the service is active (true) = stop it now
            const fnName: keyof SessionLogOutService = isAuthenticated ? 'start' : 'stop';
            logOutService[fnName]();
        }

        return () => logOutService.stop();
    }, [isAuthenticated, logOutService]);

}

export default useAutoLogOut