import { HomeRoute, PrivateRoute, PublicOnlyRoute } from "@hilma/auth";
import { useAuth } from "@hilma/auth-core";
import { SocketProvider } from "@hilma/socket.io-react";
import { ThemeProvider } from "@mui/material";
import { StylesProvider } from '@mui/styles';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BrowserRouter, Route, Switch, } from "react-router-dom";

import useAutoLogOut from "./consts/hooks/useAutoLogOut";
import useDeleteExpiredFormDraft from "./consts/hooks/useDeleteExpiredFormDraft";
import useKeyboardFix from "./consts/hooks/useKeyBoardFix";
import useLogIn from "./consts/hooks/useLogIn";
import { jssInitlizing } from "./functions/jss";
import theme from "./style/generateTheme";
import { featureFlagComponent } from "./utils/featureFlagComponent";

import { AddEntranceTicketsProvider } from "./context/AddEntranceTicketsContext";
import { AddNewEventProvider } from "./context/AddNewEventContext";
import { AgencyProvider } from "./context/AgencyContext";
import { ConfigContextProvider } from "./context/ConfigContext";
import MessagesProvider from "./context/CountMessagesContext";
import MySocketProvider from "./context/SocketContext";

import Header from "./generic-components/header/Header";
import { UpdateAddBenefitTickets } from "./pages/agency/add-entrance-tickets/UpdateAddBenefitTickets";
import AddMoreTickets from "./pages/agency/add-new-event/AddMoreTickets";
import AddNewEvent from "./pages/agency/add-new-event/AddNewEvent";
import AgencyHomePage from "./pages/agency/agency-home-page/AgencyHomePage";
import NewAgencyProfilePage from "./pages/agency/agency-profile-page/NewAgencyProfilePage";
import AgencySignUpPage from './pages/agency/agency-sign-up/AgencySignUp';
import BenefitsPage from "./pages/agency/benefits/BenefitsPage";
import CancelEventPage from "./pages/agency/cancel-event/CancelEventPage";
import EventPage from "./pages/agency/event-page/EventPage";
import EventPreview from "./pages/agency/event-preview/EventPreview";
import EventArchive from "./pages/agency/events-archive/EventArchive";
import AgencyRegistered from "./pages/agency/feedback-pages/AgencyRegistered";
import EventAdded from "./pages/agency/feedback-pages/EventAdded";
import FeedbackAgency from "./pages/agency/feedbacks-of-donated-event/FeedbackAgency";
import BenefitPage from "./pages/general/BenefitPage";
import ContactUs from "./pages/general/contact-us/ContactUs";
import NewHomePage from './pages/general/home-page/NewHomePage';
import Login from './pages/general/login/Login';
import PageNotFound from "./pages/general/page-not-found/PageNotFound";
import OrganizationEventPage from "./pages/organization/event-pages/OrganizationEventPage";
import EventsOrdered from './pages/organization/events-ordered/EventsOrdered';
import NotRelevantFeedbackPage from "./pages/organization/feedback-pages/NotRelevantFeedbackPage";
import OrganizationRegistered from './pages/organization/feedback-pages/OrganizationRegistered';
import ReviewSent from './pages/organization/feedback-pages/ReviewSent';
import WelcomeOrganization from './pages/organization/feedback-pages/WelcomeOrganization';
import FeedbackOrganization from "./pages/organization/feedbacks-organization-gave/FeedbackOrganization";
import FutureEventsPage from "./pages/organization/future-events-page/FutureEventsPage";
import ThanksForLettingUsKnow from './pages/organization/not-interested/NotInterested';
import OrgHomePage from "./pages/organization/organization-home-page/OrgHomePage";
import OrganizationProfile from './pages/organization/organization-profile/OrganizationProfile';
import OrganizationSignUp from './pages/organization/organization-sign-up/OrganizationSignUp';
import GiveFeedbackToAgency from './pages/organization/send-feedback-to-agency/GiveFeedbackToAgency';

import './App.scss';

const jss = jssInitlizing();

const App: React.FC = () => {

  //! ENABLE THESE TWO IN PRODUCTION ONLY
  useAutoLogOut() // every set of period of time that the user is not using the website it will disconnect him automatically
  // useLeaveWebsite() 

  useLogIn()
  useKeyboardFix()
  const { getAccessToken } = useAuth()

  useDeleteExpiredFormDraft()

  return (
    <ConfigContextProvider>
      <ThemeProvider theme={theme}>
        <SocketProvider options={{ reconnectionAttempts: 2, query: { token: getAccessToken() } }}>
          <MySocketProvider>
            <StylesProvider jss={jss}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <MessagesProvider>
                  <div className="App">
                    <BrowserRouter>
                      <Header />
                      <Switch>

                        <PublicOnlyRoute path="/agency-signup" componentName="AgencySignUpPage " component={AgencySignUpPage} />

                        <PublicOnlyRoute path="/organization-signup" componentName="OrganizationSignUp " component={OrganizationSignUp} />

                        <PublicOnlyRoute path="/login" componentName="Login" component={Login} />

                        <Route path="/contact-us" component={ContactUs} />

                        <PrivateRoute path="/edit-event/:id" exact component={() => (
                          <AddNewEventProvider>
                            <AddNewEvent type='edit' />
                          </AddNewEventProvider>
                        )} componentName={"EditEvent"} />

                        <PrivateRoute path="/agency-profile" component={() => <AgencyProvider><NewAgencyProfilePage /></AgencyProvider>} componentName={"UpdateAgencyProfile"} redirectComponent={Login} />

                        <PrivateRoute path="/event-added/:id" component={EventAdded} componentName={"EventAdded"} redirectComponent={Login} />

                        <PrivateRoute path="/benefit/:id" component={BenefitPage} componentName={"BenefitPage"} redirectComponent={Login} exact />

                        <PrivateRoute
                          path="/add-entrance-tickets"
                          component={() => (
                            <AddEntranceTicketsProvider>
                              <UpdateAddBenefitTickets />
                            </AddEntranceTicketsProvider>
                          )}
                          componentName={"AddEntranceTickets"}
                        />

                        <PrivateRoute path="/cancel-event-feedback/:id" component={CancelEventPage} componentName={"CancelEventPage"} />

                        <PrivateRoute path="/org-profile" component={OrganizationProfile} componentName={"OrganizationProfile"} redirectComponent={Login} />

                        <PrivateRoute path="/thanks/:id" component={GiveFeedbackToAgency} componentName={"Thanks"} redirectComponent={Login} />

                        <PrivateRoute path="/review-sent" component={ReviewSent} componentName={"ReviewSent"} redirectComponent={Login} />

                        <PrivateRoute path="/not-relevant/:id" component={ThanksForLettingUsKnow} componentName={"ThanksForLettingUsKnow"} redirectComponent={Login} />

                        <Route path="/welcome-organization">
                          <WelcomeOrganization />
                        </Route>

                        <PrivateRoute path="/past-ordered-events" component={EventsOrdered} componentName={"EventsOrdered"} redirectComponent={Login} />

                        <PrivateRoute path="/future-ordered-events" component={FutureEventsPage} componentName={"FutureEventsPage"} redirectComponent={Login} />

                        <PrivateRoute path="/download-tickets/:id"
                          componentName={"OrganizationEventPage"}
                          redirectComponent={Login}
                          component={() => (
                            <OrganizationEventPage ticketOrderType="ordered" />
                          )} />

                        <PrivateRoute path="/order-event/:id"
                          componentName={"OrganizationEventPage"}
                          redirectComponent={Login}
                          component={() => (
                            <OrganizationEventPage ticketOrderType="time" />
                          )} />

                        <PrivateRoute path="/feedbacks/:id" component={FeedbackOrganization} componentName={"OrganizationFeedbackDelay"} redirectComponent={Login} />

                        <PrivateRoute path="/benefits" component={featureFlagComponent('update_agency_profile', BenefitsPage, PageNotFound)} componentName={"BenefitsPage"} redirectComponent={Login} />

                        <Route path="/welcome-agency">
                          <AgencyRegistered />
                        </Route>

                        <Route path='/organization-registered'>
                          <OrganizationRegistered />
                        </Route>

                        <Route path="/not-relevant-feedback">
                          <NotRelevantFeedbackPage />
                        </Route>

                        <AddNewEventProvider>
                          <HomeRoute exact path="/" components={{ AgencyHomePage, OrgHomePage }} redirectComponent={NewHomePage} />
                          <PrivateRoute component={FeedbackAgency} componentName={"AgencyFeedbackDelay"} path="/feedback/:id" redirectComponent={Login} />

                          <PrivateRoute component={EventArchive} componentName={"EventArchive"} path="/archive" exact redirectComponent={Login} />

                          <Route path="/event">
                            <PrivateRoute path="/event/add" exact
                              component={() => (
                                <AddNewEvent />
                              )} componentName={"AddEvent"} />

                            <PrivateRoute path="/event/details/:id" component={EventPage}
                              componentName={"EventPage"}
                              redirectComponent={Login} exact />

                            <PrivateRoute path="/event/preview"
                              component={() => (
                                <EventPreview />
                              )}
                              componentName={"AddEvent"}
                              redirectComponent={Login} />
                            <PrivateRoute path="/event/add-tickets/:id" component={AddMoreTickets} componentName={"AddEvent"} />
                          </Route>
                        </AddNewEventProvider>

                        <Route path='*' component={PageNotFound} />
                      </Switch>
                    </BrowserRouter>
                  </div>
                </MessagesProvider>
              </LocalizationProvider>
            </StylesProvider>
          </MySocketProvider>
        </SocketProvider>
      </ThemeProvider>
    </ConfigContextProvider >
  );
}


export default App;



