//this component takes props - className?: string, handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void, children?: React.ReactNode, name?: string, disabled?: boolean, serverLoading?: boolean
//it returns a generic button

import React from "react";
import { ButtonBase } from "@mui/material";

import { GenericButtonProps } from "../consts/interfaces/GenericButton.interface";
import GenericLoading from "./GenericLoading";
import "../style/genericButton.scss";

const GenericButton: React.FC<GenericButtonProps> = ({ className, handleClick, children, disabled, serverLoading }) => {

  //focuses on the button and the press it on key press
  const handleKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLFormElement;
    const form = target.form;
    if (event.key.toLowerCase() === "arrowup") {
      form.elements[0].focus();
    }
  }

  return (
    <ButtonBase
      disabled={disabled || serverLoading}
      className={`generic-button ${className ? className : ""} ${disabled ? "disabled" : ""}`}
      onKeyDown={handleKeyUp}
      onClick={handleClick}
      focusRipple
    >
      {/* {true ? <div style={{ width: "100%" }}> <GenericLoading /></div> : ''} */}
      <div className={`${serverLoading ? 'loading-container' : 'text-on-button'}`}>
        {serverLoading ? <GenericLoading /> : children}
      </div>
    </ButtonBase>
  );
}


export default GenericButton;

