//this component gets props - name: string, logoImage: string.
//it returns the name and org logo for the tooltipe in info bar

import { OrgsFeedbackListProps } from '../../consts/interfaces/Organization.interface';
import GenericProfilePic from '../GenericProfilePic';
import './style/orgsFeedbackList.scss';

const OrgsFeedbackList: React.FC<OrgsFeedbackListProps> = ({ orgs }) => {

    return (
        <div className="container-of-logos">
            {orgs.map((item, i) => {
                return (
                    <div key={i} className="inner-container">
                        <GenericProfilePic src={item.logoImage} imageCoordinates={item.imageCoordinates}
                            className="logo" />
                        <span className="name">{item.name} </span>
                    </div>
                )
            })}
        </div>
    )
}
export default OrgsFeedbackList;