import { UploadedFile } from "@hilma/fileshandler-client";

import { FileMangerFile } from "../consts/interfaces/AddNewEventContext.interface";
import { FORM_FIELDS } from "../consts/variables"

import { blobToBase64, urlToBlob } from "./filesFunctions";

export const saveInLocalStorage = (key: FORM_FIELDS, value: any) => {
    // if the value is an array of objects with "selected" key and none are selected it removes the data from local storage
    if (isArrayWithSelectedKey(value))
        if (!value.some((option: { selected: boolean }) => option.selected)) {
            localStorage.removeItem(key);
            return;
        }

    const stringifiedValue = JSON.stringify(value)

    if ((!value && value !== false) || stringifiedValue === "null") {
        localStorage.removeItem(key);
        return;
    }

    localStorage.setItem(key, stringifiedValue)

    const lastEdited = new Date()
    localStorage.setItem("lastEdited", JSON.stringify(lastEdited))
}

export const getFromLocalStorage = (key: FORM_FIELDS) => {
    const stringValue = localStorage.getItem(key)
    const parsedValue = stringValue ? JSON.parse(stringValue) : null
    return parsedValue;
}

const isArrayWithSelectedKey = (value: any) => Array.isArray(value) && value.every((obj: Object) => typeof obj === "object" && obj.hasOwnProperty("selected"))

export const clearFormDataStorage = () => {
    Object.values(FORM_FIELDS).forEach(field => localStorage.removeItem(field))
    localStorage.removeItem("lastEdited")
}

export const saveImageInLocalStorage = async (img: Omit<UploadedFile, "file">, fileType: FORM_FIELDS.IMAGE | FORM_FIELDS.POSTER) => {
    const fileName = img.fileName;
    const blob = await urlToBlob(img.link)
    const type = img.type ? img.type : blob.type;
    const base64 = await blobToBase64(blob)
    saveInLocalStorage(fileType, { fileName, type, base64 });
}

export const savePdfsInLocalStorage = (pdfs: FileMangerFile[]) => {
    const files = pdfs.map(async pdf => {
        const { fileName, link } = pdf;

        const blob = await urlToBlob(link)
        const base64 = await blobToBase64(blob)

        return { fileName, base64, type: 'application/pdf' };
    })

    Promise.all(files).then(files => {
        saveInLocalStorage(FORM_FIELDS.PFDS, files)
    })
}