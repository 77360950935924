// When event added it displays a message that it was added successfully
// and gives an option to go to the event page and see it

import { useParams } from "react-router-dom";
import UseParamsInterface from "../../../consts/interfaces/UseParams.interface";
import FeedBack from "../../../generic-components/GenericFeedback";

const EventAdded = () => {
    document.title = 'אירוע חדש';

    const { id } = useParams() as UseParamsInterface;
    const eID = Number(id)

    return (
        <FeedBack
            image={"/icons/date-icon.svg"}
            history={`event/details/${eID}`}
            firstLine={<div >האירוע נוסף בהצלחה!</div>}
            button={< div>  לעמוד האירוע </div >}
            isAgency={true}
        />
    )
}

export default EventAdded;