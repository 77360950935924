import { Skeleton, useMediaQuery } from "@mui/material";

const AgencyPageSkeleton = () => {

    const isMobile = useMediaQuery('(max-width: 768px)');

    return (
        <div style={{ width: isMobile ? "85vw" : "35vw", display: "flex", flexDirection: "column", gap: "5vh" }} >
            <Skeleton sx={{ height: "5vh", transform: "none", mt: "5vh" }} />
            <Skeleton sx={{ height: "4vh", transform: "none", width: "50%" }} />
            <Skeleton variant="circular" sx={{ height: "auto", width: "11vh", aspectRatio: "1/1", m: "3vh 0 5vh" }} />
            <Skeleton sx={{ height: "8vh" }} />
            <Skeleton sx={{ height: "8vh" }} />
            <Skeleton sx={{ height: "8vh" }} />
            <Skeleton sx={{ height: "8vh" }} />
        </div>
    )
}

export default AgencyPageSkeleton;