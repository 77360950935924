//Event archive presents all events that happened in the pass for this agency

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAsyncEffect, useAsyncState } from "@hilma/tools";
import { Skeleton, useMediaQuery } from "@mui/material";

import { EventForArchive } from '../../../consts/interfaces/Event.interface';
import { Events, TITLES, WEEK } from "../../../consts/hebrew";
import { SocketContext } from "../../../context/SocketContext";
import { UserContext } from "../../../context/UserContext";

import EventArchiveItem from "./EventArchiveItem";
import "./style/eventArchive.scss";

const EventArchive = () => {
    document.title = Events.ARECION_EVENTS

    const [searchArray, setSearchArray] = useAsyncState<EventForArchive[]>([]);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<EventForArchive[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [noEvents, setNoEvents] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const { feedbackSent } = useContext(SocketContext);
    const { systemID } = useContext(UserContext);

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)

    const dayPerNum = (dayNum: number) => {
        let week = WEEK
        return week[dayNum]
    }

    const ignoreWhiteSpaces = (str: string) => (str.replace(/\s+/g, ' ')).trim()
    const sortArray = (arr: EventForArchive[], text: string) => {
        return arr.filter((item: EventForArchive) => item.eventName.includes(text))
    }

    //function that brings all data from server and sets to state
    useAsyncEffect(async () => {
        if (systemID) {
            try {
                const allEvents: EventForArchive[] = [];
                const eventsServer = await axios.get(`/api/event/get-all-events-archive?agencyId=${systemID}`);

                await eventsServer.data.forEach(async (e: EventForArchive) => {
                    if (e !== null) {
                        const date = new Date(e.date);
                        let newDate = JSON.stringify(date.getDate());
                        let month = JSON.stringify(date.getMonth() + 1);
                        const year = date.getFullYear();
                        if (date.getDate() < 10) {
                            newDate = '0' + date.getDate();
                        }
                        if (date.getMonth() < 10) {
                            month = '0' + (date.getMonth() + 1);
                        }
                        const minutesTime = () => (date.getMinutes() < 10) ? "0" + date.getMinutes() : date.getMinutes();
                        const hoursTime = () => (date.getHours() < 10) ? "0" + date.getHours() : date.getHours();
                        const day = dayPerNum(date.getDay());

                        allEvents.push({
                            id: e.id,
                            coverImage: e.coverImage,
                            posterImage: e.posterImage,
                            eventName: e.eventName,
                            day: day,
                            date: newDate + "." + month + "." + year,
                            time: hoursTime() + ":" + minutesTime(),
                            ticketsPurchased: e.ticketsPurchased,
                            ticketsTotal: e.ticketsTotal,
                            feedbackNum: e.feedbackNum,
                            hasNewMessage: e.hasNewMessage,
                        })
                    }
                })
                setEvents(allEvents);
                setLoading(false);
            }
            catch (err) {
                throw err;
            }
        }
    }, [systemID])

    //when feedback sent from socket, update the state
    useEffect(() => {
        if (feedbackSent) {

            setSearchArray(prev => {

                return prev.map(e => {
                    let b = e.feedbackNum;
                    if (e.id === feedbackSent.eventId) {
                        b = b + 1;
                        e = { ...e, feedbackNum: b };
                    }
                    return e;
                })
            })
        }
    }, [feedbackSent, setSearchArray])

    useEffect(() => {
        if (events.length === 0) {
            setNoEvents(true)
        } else {
            setNoEvents(false)
        }
    }, [events])



    useEffect(() => { // delay after search
        setSearchLoading(true);
        if (events.length) {
            const searchWithoutSpaces = ignoreWhiteSpaces(search);
            if (searchWithoutSpaces) {
                setSearchArray(sortArray(events, searchWithoutSpaces));
            } else {
                setSearchArray(events);
            }
        }
        setSearchLoading(false);
    }, [search, events, setSearchArray]);

    useEffect(() => {
        if (searchArray.length) {
            setLoading(false)
        }
    }, [searchArray])

    const isIos = /iPhone|iPad|iPod|/i.test(navigator.userAgent);
    const tabletMediaQuery = useMediaQuery('(max-width: 768px)');

    return (
        <div className="event-archive-page">
            <div className="event-archive-container">
                <div className="archive-top">
                    <div className="event-archive-title">{TITLES.ARCHIVE_EVENTS} </div>
                    {noEvents ? null : <div className={`search-container ${isIos && tabletMediaQuery ? "search-container-ios" : ""}`}>
                        <label htmlFor='search'>
                            <img className={`search-icon ${(searchLoading && !loading) && "rotate"}`} src="/icons/search.png" alt="search" />
                        </label>
                        <input type="search" id='search' value={search} className="event-archive-search" onChange={(e) => handleChange(e)} placeholder={Events.SEARCH} />
                    </div>
                    }
                </div>
                {loading ?
                    <div style={{ display: "flex", flexDirection: "column", alignItems: tabletMediaQuery ? "center" : "flex-start", gap: "3vh", marginTop: "4.5vh" }}>
                        <Skeleton variant="text" height={tabletMediaQuery ? "16vh" : "9.3vh"} width={tabletMediaQuery ? "95%" : "80vw"} sx={{ margin: 'auto 0', transform: "none" }}></Skeleton>
                        <Skeleton variant="text" height={tabletMediaQuery ? "16vh" : "9.3vh"} width={tabletMediaQuery ? "95%" : "80vw"} sx={{ margin: 'auto 0', transform: "none" }}></Skeleton>
                        <Skeleton variant="text" height={tabletMediaQuery ? "16vh" : "9.3vh"} width={tabletMediaQuery ? "95%" : "80vw"} sx={{ margin: 'auto 0', transform: "none" }}></Skeleton>
                        <Skeleton variant="text" height={tabletMediaQuery ? "16vh" : "9.3vh"} width={tabletMediaQuery ? "95%" : "80vw"} sx={{ margin: 'auto 0', transform: "none" }}></Skeleton>
                        <Skeleton variant="text" height={tabletMediaQuery ? "16vh" : "9.3vh"} width={tabletMediaQuery ? "95%" : "80vw"} sx={{ margin: 'auto 0', transform: "none" }}></Skeleton>
                    </div>
                    :
                    <div className="event-archive-items-container">

                        {!!searchArray.length ?

                            searchArray.map((event, index) => {
                                return (
                                    <EventArchiveItem
                                        id={event.id}
                                        name={event.eventName}
                                        day={event.day}
                                        date={event.date}
                                        time={event.time}
                                        ticketsNum={+event.ticketsPurchased}
                                        ticketsTotal={event.ticketsTotal}
                                        feedbackNumber={event.feedbackNum}
                                        key={event.id}
                                        isLast={index === searchArray.length - 1}
                                        hasNewMessage={event.hasNewMessage}
                                    />
                                )
                            })
                            :
                            <div className='no-results'>
                                {(noEvents) ?
                                    Events.NO_EVENTS_DONE :
                                    Events.NO_EVENTS_NAME}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default EventArchive;