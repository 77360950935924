//home page for agency if first time or not

import { useContext, useState } from "react";
import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";

import UpComingEventsOrg from "./UpComingEvents";
import { _ERROR_MESSAGE } from "../../../consts/variables";
import { ErrorContext } from "../../../context/ErrorContext";
import { UserContext } from "../../../context/UserContext";
import FirstTimeOrg from "./FirstTimeOrg";

const OrgHomePage = () => {

    const [firstTime, setFirstTime] = useState<boolean>();

    const { systemID } = useContext(UserContext);
    const { showError } = useContext(ErrorContext);

    useAsyncEffect(async () => {
        try {
            if (systemID) {
                const orgInfo = (await axios.get(`/api/organization/organization-info?id=${systemID}`)).data;
                setFirstTime(orgInfo.firstTime);

            }
        }
        catch (error) {
            showError(_ERROR_MESSAGE);
            throw error;
        }

    }, [systemID, showError])

    return (
        firstTime ?
            <FirstTimeOrg />
            :
            <UpComingEventsOrg />
    )
}

export default OrgHomePage;