//this component gets props - GoogleLocationAutocompleteProps
//it returns a text filed with google locations for address

import React from 'react';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { GoogleLocationAutocompleteProps, PlaceType } from '../../consts/interfaces/GoogleLocationAutoComplete.interface';
import { dropDownValidation } from '../../functions/validations';

import './style/googleLocationAutocomplete.scss';


function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const GoogleLocationAutocomplete: React.FC<GoogleLocationAutocompleteProps> = (GLAprops) => {


  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);


  React.useEffect(() => {
    if (GLAprops.value) {
      setValue({
        description: GLAprops.value,
      } as PlaceType);
    }
  }, [GLAprops.value])


  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}&libraries=places&language=iw&region=IL`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        200,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, value, fetch]);

  const tabletMediaQuery = useMediaQuery('(max-width: 768px)');

  return (
    <Autocomplete
      id="google-map-demo"
      popupIcon={<SearchIcon fontSize='inherit' />}
      clearIcon={<ClearIcon fontSize='inherit' />}
      disableClearable={value === null && inputValue.length === 0}
      sx={tabletMediaQuery ?
        {
          maxWidth: "100%",
          minWidth: "100%",
          backgroundColor: "#f5f7f8",
          border: "0.2vh solid #afc0c3",
          borderRadius: "2.5vh",
          height: "4vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        } :
        {
          maxWidth: "100%",
          minWidth: "100%",
          backgroundColor: "#f5f7f8",
          border: "0.2vh solid #afc0c3",
          height: "4vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText={"אין אפשרויות"}
      dir={'rtl'}
      value={value}
      onBlur={() => GLAprops.setAddressError(dropDownValidation(value?.description))}
      onChange={(event: any, newValue: PlaceType | null) => {

        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        GLAprops.setValue(JSON.stringify(newValue?.description)?.slice(1, -1))

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        GLAprops.setValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} hiddenLabel fullWidth />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting?.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting?.main_text ?? '',
          (matches ?? []).map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props} >
            <Grid container alignItems="center">
              <Grid item >
                <Box
                  component={LocationOnIcon}
                  sx={{
                    color: 'text.secondary', mr: 2,
                  }}
                />
              </Grid>
              <Grid item xs >
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );

}

export default GoogleLocationAutocomplete;