//* COMPONENT TO REFACTOR
import { useContext, useEffect, useRef, useState } from "react";

import { ButtonsText, COUPON_CODE_FOR_EXTERNAL_WEBSITE, HOW_MANY_TICKET_DO_YOU_WANT_TO_GIVE, HOW_WILL_THE_ORGANIZATION_RECEIVE_THE_TICKETS, MAKE_SURE_COUPONS_NUMBER_EQUALS_TICKETS_NUMBER, MAKE_SURE_SEATS_NUMBER_EQUALS_TICKETS_NUMBER, PREVIOUS, WHAT_IS_THE_VALUE_OF_TICKET } from "../../../consts/hebrew";
import { COUPONS, FILL_THIS_FIELD, FORM_FIELDS, HEARING_AIDS_ERROR, SEAT_GROUP_SUM_ERROR, TICKETS_METHOD, _SEAT_GROUP } from "../../../consts/variables";
import { ANEContextInterface, FileMangerFile } from "../../../consts/interfaces/AddNewEventContext.interface";
import { AddEventThirdProps } from "../../../consts/interfaces/AddEventThird.interface";
import { Coupons } from "../../../consts/interfaces/TicketsCouponMethod.interface";
import { addEventThird } from "../../../consts/interfaces/formErrors.interface";
import SeatGroup from "../../../consts/interfaces/SeatGroup.interface";
import { ADD_EVENT_THIRD } from "../../../consts/enums/InputsNames";
import { CONTINUE } from "../../../consts/hebrew";

import { codeValidation, fileValidation, linkValidation, numValidation } from "../../../functions/validations";
import { convertStringToNumber, positiveInteger } from "../../../functions/convertsFunctions";
import { scrollToFirstError } from "../../../functions/scrollToError";
import { saveInLocalStorage } from "../../../functions/localStorage";
import { AddNewEventContext } from "../../../context/AddNewEventContext";

import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import RadioButtons from "../../../generic-components/RadioButtons";

import EventSeatingDetailsInputs from "./EventSeatingDetailsInputs";
import TicketsCouponMethod from "./TicketsCouponMethod";
import TicketsPDFMethod from "./TicketsPDFMethod";
import HearingAidsInput from "../../../generic-components/HearingAidsInput";

import "./style/addEventRightThird.scss";

const AddEventRightThird: React.FC<AddEventThirdProps> = ({ serverLoading, setServerLoading }) => {

    const firstInputRef = useRef<HTMLInputElement>(null);
    const { filesUploaderCx, changeFiles, setPageCx, filesManager, getThirdPage, backButtonThirdPage, editMode, sendEditEvent } = useContext(AddNewEventContext) as ANEContextInterface;
    const formRef = useRef(null)
    const [ticketsNum, setTicketNum] = useState<number>();
    const [priceValue, setPriceValue] = useState<number>();
    const [isSeating, setIsSeating] = useState<boolean>();
    const [isMarkedSeats, setIsMarkedSeats] = useState<boolean>();
    const [areAdjacentValue, setAreAdjacentValue] = useState<boolean>();
    const [ticketMethodValue, setTicketMethodValue] = useState<TICKETS_METHOD | ''>("");
    const [seatGroups, setSeatGroups] = useState<SeatGroup[]>([]);
    const [coupons, setCoupons] = useState<Coupons[]>([{ ...COUPONS }]);
    const [files, setFiles] = useState<FileMangerFile[]>([]);
    const [isHearingImpairedAccessible, setIsHearingImpairedAccessible] = useState<string>('no')
    const [hearingAidsNumber, setHearingAidsNumber] = useState<number>(0);

    const [webLink, setWebLink] = useState<string>("");

    const [numError, setNumError] = useState<string>("");
    const [priceError, setPriceError] = useState<string>('')
    const [isSeatingError, setIsSeatingError] = useState<string>("");
    const [isMarkedSeatsError, setIsMarkedSeatsError] = useState<string>("");
    const [areAdjacentError, setAreAdjacentError] = useState<string>("");
    const [ticketMethodError, setTicketMethodError] = useState<string>("");
    const [seatGroupsTicketsSumError, setSeatGroupsTicketsSumError] = useState('')
    const [webLinkError, setWebLinkError] = useState<string>("");
    const [fileError, setFileError] = useState<string>("");
    const [hearingAidsError, setHearingAidsError] = useState<string>("");
    const [errors, setErrors] = useState<addEventThird>({})

    useEffect(() => {
        if (files.length) changeFiles(files)
    }, [files])

    useEffect(() => {
        if (filesManager.files.length) setFiles(filesManager.files)
    }, [filesManager])

    useEffect(() => {
        scrollToFirstError(formRef, firstInputRef, errors)
    }, [errors])

    useEffect(() => {
        const thirdPageDetails = getThirdPage()
        setTicketNum(thirdPageDetails.numValueCx)
        setPriceValue(thirdPageDetails.priceValueCx)
        setIsSeating(thirdPageDetails.isSeatingCx)
        setAreAdjacentValue(thirdPageDetails.areAdjacentValueCx)
        setIsMarkedSeats(thirdPageDetails.isMarkedSeatsCx)
        setTicketMethodValue(thirdPageDetails.ticketMethodValueCx)
        setWebLink(thirdPageDetails.webLinkCx)
        setSeatGroups(thirdPageDetails.seatGroupsCx)
        setCoupons(thirdPageDetails.couponsCx)
        Number(thirdPageDetails.hearingAidsNumberCx) > 0 && setIsHearingImpairedAccessible('yes')
        setHearingAidsNumber(Number(thirdPageDetails.hearingAidsNumberCx))
    }, [])

    useEffect(() => { // changes num of seatgroups based on seating details
        if (!isSeating || areAdjacentValue) {
            seatGroups.length > 1 &&
                setSeatGroups([{ ..._SEAT_GROUP }])
        } else {
            seatGroups.length < 2 &&
                setSeatGroups([{ ..._SEAT_GROUP }, { ..._SEAT_GROUP }])
        }
    }, [isSeating, areAdjacentValue, ticketMethodValue])

    useEffect(() => {
        !editMode && saveInLocalStorage(FORM_FIELDS.HEARING_AIDS, hearingAidsNumber);
    }, [hearingAidsNumber, editMode])

    const BackButton = (e: React.MouseEvent<HTMLButtonElement>) => { // back to the previous page and save the data for the next time the this page is opened
        e.preventDefault();
        backButtonThirdPage(isSeating,
            ticketsNum,
            priceValue,
            areAdjacentValue,
            ticketMethodValue,
            webLink,
            seatGroups,
            hearingAidsNumber,
            coupons,
            isMarkedSeats,
        )
        setPageCx(1); // move back a page
    }

    /**
     * 
     * @returns true if seat groups are valid, else false
     */
    const validateSeats = () => {
        if (ticketMethodValue === TICKETS_METHOD.COUPON) {
            let valid = true;
            let numSeatsCouponsSum = 0;
            coupons.forEach((coupon, index) => {
                numSeatsCouponsSum += Number(coupon.numSeats);
                const codeVal = codeValidation(coupon.code);
                const numSeatsVal = numValidation(coupon.numSeats);
                coupons[index].codeError = codeVal;
                coupons[index].numSeatsError = numSeatsVal;
                if (codeVal || numSeatsVal) {
                    valid = false;
                }
            });
            const webLinkVal = linkValidation(webLink);
            if (webLinkVal) {
                setWebLinkError(webLinkVal);
                valid = false;
            }
            if (numSeatsCouponsSum !== Number(ticketsNum)) {
                setSeatGroupsTicketsSumError(MAKE_SURE_COUPONS_NUMBER_EQUALS_TICKETS_NUMBER)
                valid = false;
            } else {
                setSeatGroupsTicketsSumError('')
            }
            return valid;
        }

        else if (seatGroups.length === 1) {
            const ticketFilePDFError = fileValidation(fileError, files[0]?.fileName || '')
            setFileError(ticketFilePDFError)
            const isSumOfAccessValid = Number(ticketsNum) - seatGroups[0].wheelchairAccessible >= 0 &&
                Number(ticketsNum) - seatGroups[0].mobilityDifficultiesAccessible >= 0;
            setSeatGroups(prev => [{ ...prev[0], sumOfAccessTicketsError: isSumOfAccessValid ? '' : SEAT_GROUP_SUM_ERROR, ticketFilePDFError: ticketFilePDFError }])
            return !ticketFilePDFError && isSumOfAccessValid
        }

        let isValid = true;
        let ticketsSum = 0;
        const validatedSeatGroups = seatGroups.map(seatGroup => {
            const ticketFilePDFError = seatGroups.length > 1 ? fileValidation(seatGroup.ticketFilePDFError, seatGroup.ticketFilePDF[0]) : '';
            const numOfSeats = numValidation(seatGroup.numSeats)
            const isSumOfAccessValid = Number(seatGroup.numSeats) - seatGroup.wheelchairAccessible >= 0 &&
                Number(seatGroup.numSeats) - seatGroup.mobilityDifficultiesAccessible >= 0
            const numSeatsError = numOfSeats;
            const sumError = isSumOfAccessValid ? '' : SEAT_GROUP_SUM_ERROR
            if (ticketFilePDFError || numSeatsError || !isSumOfAccessValid) isValid = false;

            ticketsSum += Number(seatGroup.numSeats);

            return {
                ...seatGroup,
                ticketFilePDFError,
                numSeatsError,
                sumOfAccessTicketsError: sumError
            }
        })
        setSeatGroups(validatedSeatGroups);

        if (ticketsSum !== Number(ticketsNum) && seatGroups.length > 1) {
            setSeatGroupsTicketsSumError(MAKE_SURE_SEATS_NUMBER_EQUALS_TICKETS_NUMBER)
            isValid = false;
        } else {
            setSeatGroupsTicketsSumError('')
        }
        return isValid;
    }

    const validateFields = () => {
        const ticketNumVal = numValidation(ticketsNum)
        const priceVal = numValidation(priceValue, true);
        const isSeatingValidation = (isSeating === null) ? FILL_THIS_FIELD : '';
        const isMarkedSeatsValidation = (isSeating && isMarkedSeats === null) ? FILL_THIS_FIELD : '';

        const areAdjacentValidation = (isSeating && areAdjacentValue === null) ? FILL_THIS_FIELD : '';
        const ticketMethodVal = ticketMethodValue ? '' : FILL_THIS_FIELD;
        const areSeatGroupsValid = validateSeats();
        const hearingAidsVal = isHearingImpairedAccessible === "" ? 'יש למלא שדה זה' : Number(ticketsNum) - hearingAidsNumber < 0 ? HEARING_AIDS_ERROR : ""

        if (!ticketNumVal && !priceVal && !isSeatingValidation && !isMarkedSeatsValidation && !areAdjacentValidation && areSeatGroupsValid && !ticketMethodVal && !hearingAidsVal) {
            return true
        }

        const errors: addEventThird = {}
        if (ticketNumVal) errors.howManyTickets = ticketNumVal
        if (priceVal) errors.ticketPrice = priceVal
        if (isSeatingValidation) errors.isSeating = isSeatingValidation
        if (isMarkedSeatsValidation) errors.isMarkedSeats = isMarkedSeatsValidation
        if (areAdjacentValidation) errors.areAdjacentSeats = areAdjacentValidation
        setErrors(errors)

        setNumError(ticketNumVal);
        setPriceError(priceVal)
        setIsSeatingError(isSeatingValidation);
        setIsMarkedSeatsError(isMarkedSeatsValidation)
        setAreAdjacentError(areAdjacentValidation)
        setTicketMethodError(ticketMethodVal)
        setHearingAidsError(hearingAidsVal)
        return false;
    }

    //function that checks if all the input is valid and if it is moves on to the next page
    const handleContinueButtonClick = async () => {
        if (!validateFields()) return
        backButtonThirdPage(isSeating, ticketsNum, priceValue, areAdjacentValue, ticketMethodValue, webLink, seatGroups, hearingAidsNumber, coupons, isMarkedSeats) // save all the data inside the context 
        setPageCx(3);
    }

    const EditButton = async () => {
        if (!validateFields()) return

        setServerLoading(true)
        const isCoupon = (ticketMethodValue === TICKETS_METHOD.COUPON)
        const newSeatGroups = seatGroups.map(seatGroup => {
            return {
                ...seatGroup,
                numSeats: areAdjacentValue || !isSeating || isCoupon ? ticketsNum : seatGroup.numSeats,
                ticketFilePDF: isCoupon ? coupons : ["pdf"],
            }
        })

        try {
            await sendEditEvent(
                ticketsNum!,
                priceValue!,
                newSeatGroups,
                Boolean(isSeating),
                Boolean(isMarkedSeats),
                isHearingImpairedAccessible === "yes" ? hearingAidsNumber : 0);


        } catch (error) {
            console.error(error);
        }
        setServerLoading(false)
    }


    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        switch (e.target.name) {
            case "howManyTickets":
                setNumError(numValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.TICKETS_COUNT, convertStringToNumber(e.target.value))
                break;
            case 'ticketPrice':
                setPriceError(numValidation(e.target.value, true));
                !editMode && saveInLocalStorage(FORM_FIELDS.TICKET_PRICE, convertStringToNumber(e.target.value))
                break;
            case "couponCode":
                const codeCouponsCopy = [...coupons];
                if (index !== undefined) {
                    codeCouponsCopy[index].codeError = codeValidation(e.target.value);
                }
                setCoupons(codeCouponsCopy);
                !editMode && saveInLocalStorage(FORM_FIELDS.COUPONS, coupons)
                break;
            case "numSeatsCoupon":
                const numSeatsCouponsCopy = [...coupons];
                if (index !== undefined) {
                    numSeatsCouponsCopy[index].numSeatsError = numValidation(e.target.value);
                }
                setCoupons(numSeatsCouponsCopy);
                !editMode && saveInLocalStorage(FORM_FIELDS.COUPONS, coupons)
                break;
            case "webLink":
                setWebLinkError(linkValidation(e.target.value));
                !editMode && saveInLocalStorage(FORM_FIELDS.WEB_LINK, e.target.value)
                break;
            case "numSeats":
                const seatGroupsCopy = [...seatGroups];
                if (index !== undefined) {
                    seatGroupsCopy[index].numSeatsError = numValidation(e.target.value);
                }
                setSeatGroups(seatGroupsCopy);
                break;
        }
    }

    //function that handles the onChange
    const handleInputChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        switch (name) {
            case "howManyTickets":
                setTicketNum(positiveInteger(value))
                break;
            case 'ticketPrice':
                setPriceValue(positiveInteger(value))
                break;
            case "webLink":
                setWebLink(value);
                break;
            case "couponCode":
                if (index === undefined) break;
                const CouponsCopyForCodes = [...coupons];
                CouponsCopyForCodes[index].code = value;
                setCoupons(CouponsCopyForCodes);
                break;
            case "numSeatsCoupon":
                if (index === undefined) break;
                const CouponsCopyForNumSeats = [...coupons];
                CouponsCopyForNumSeats[index].numSeats = positiveInteger(value).toString();
                setCoupons(CouponsCopyForNumSeats);
                break;
            case "numSeats": //!what does this change?
                if (index === undefined) break;
                const SeatGroupsCopy = [...seatGroups];
                SeatGroupsCopy[index].numSeats = convertStringToNumber(value);
                // SeatGroupsCopy[index].numSeats = validPositiveNum(value);
                setSeatGroups(SeatGroupsCopy);
                break;
        }
    }

    /**
     * function that delete all files except the main event photo from the filesUploader instance
     * i use this approach because the filesUploader deleteAll function will delete the first photo that was uploaded in the first page, and cause some bugs
     */
    const deleteAllFiles = () => {
        files.forEach(file => { // files is all the images that was load in this page because of that i can use foreach method and delete directly all the files except the main event photo
            filesUploaderCx.delete(file.id, file.link);
        })
        setFiles([]);
        setSeatGroups(prev => prev.map(seatgroup => ({ ...seatgroup, ticketFilePDF: "" })))
    }

    const handleIsSeatingChange = (isSeating?: boolean) => {
        setIsSeating(isSeating)
        setIsSeatingError('')
        !editMode && saveInLocalStorage(FORM_FIELDS.IS_SEATING, isSeating)
        !isSeating && !editMode && saveInLocalStorage(FORM_FIELDS.ARE_ADJACENT, false)
    }

    const handleMarkedSeatsChange = (isMarked?: boolean) => {
        setIsMarkedSeats(isMarked)
        setIsMarkedSeatsError('')
        !editMode && saveInLocalStorage(FORM_FIELDS.MARKED_SEATS, isMarked)
    }

    const handleAreAdjacentSeatsChange = (areAdjacent?: boolean) => {
        setAreAdjacentValue(areAdjacent);
        setAreAdjacentError('')
        !editMode && saveInLocalStorage(FORM_FIELDS.ARE_ADJACENT, areAdjacent)
    }

    const handleTicketsMethodChange = (value: string) => {
        switch (value) {
            case TICKETS_METHOD.PDF:
                setTicketMethodValue(value)
                break;
            case TICKETS_METHOD.COUPON:
                setTicketMethodValue(value)
                break;
            default:
                setTicketMethodValue('')
                break;
        }

        deleteAllFiles()
        !editMode && saveInLocalStorage(FORM_FIELDS.TICKET_METHOD, value)
    }

    return (
        <div className="add-event-right-third">
            <form ref={formRef}>
                <GenericInput
                    disabled={editMode}
                    onBlur={handleInputBlur}
                    onChange={handleInputChange}
                    title={HOW_MANY_TICKET_DO_YOU_WANT_TO_GIVE}
                    type='number'
                    name={ADD_EVENT_THIRD.HOW_MANY_TICKETS}
                    value={ticketsNum === undefined ? "" : ticketsNum}
                    error={numError}
                    className={"add-new-event-third-page " + (!numError && 'tickets-limit-text')}
                    characterLimit={3}
                    hideCharacterLimit
                    inputRef={firstInputRef}
                />
                <div className="shekel">
                    <GenericInput
                        onBlur={handleInputBlur}
                        onChange={handleInputChange}
                        title={WHAT_IS_THE_VALUE_OF_TICKET}
                        type='number'
                        name={ADD_EVENT_THIRD.TICKET_PRICE}
                        value={priceValue === undefined ? "" : priceValue}
                        error={priceError}
                        className="add-new-event-third-page"
                        inputRef={firstInputRef}
                        inputClassName='input-price'
                        characterLimit={4}
                        hideCharacterLimit
                    />
                    <p className="shekel-sign">₪</p>
                </div>

                <EventSeatingDetailsInputs
                    disabled={editMode}
                    isSeating={isSeating}
                    isSeatingError={isSeatingError}
                    isMarkedSeats={isMarkedSeats}
                    isMarkedSeatsError={isMarkedSeatsError}
                    areAdjacent={areAdjacentValue}
                    areAdjacentError={areAdjacentError}
                    handleIsSeatingChange={handleIsSeatingChange}
                    handleMarkedSeatsChange={handleMarkedSeatsChange}
                    handleAreAdjacentSeatsChange={handleAreAdjacentSeatsChange}
                />

                <div className="add-event-input-title">{HOW_WILL_THE_ORGANIZATION_RECEIVE_THE_TICKETS}?</div>
                <RadioButtons
                    disabled={editMode}
                    className="add-new-event"
                    value={ticketMethodValue}
                    dontHaveLabel
                    handleChange={(e: React.FormEvent<HTMLInputElement>) => handleTicketsMethodChange(e.currentTarget.value)}
                    options={[
                        {
                            label: <TicketsPDFMethod
                                disabled={editMode}
                                isTicketMethodPDF={ticketMethodValue === TICKETS_METHOD.PDF}
                                isSeating={isSeating}
                                areAdjacent={areAdjacentValue}
                                seatGroupsTicketsSumError={seatGroupsTicketsSumError}
                                seatGroups={seatGroups}
                                files={files}
                                fileError={fileError}
                                setSeatGroups={setSeatGroups}
                                setFiles={setFiles}
                                setFileError={setFileError}
                                handleInputChange={handleInputChange}
                                handleInputBlur={handleInputBlur}
                                ticketsNum={Number(ticketsNum)}
                                saveDraft={!editMode}
                            />,
                            value: TICKETS_METHOD.PDF
                        },
                        {
                            label:
                                <>
                                    {COUPON_CODE_FOR_EXTERNAL_WEBSITE}
                                    {ticketMethodValue === TICKETS_METHOD.COUPON
                                        ?
                                        < TicketsCouponMethod
                                            coupons={coupons}
                                            webLink={webLink}
                                            webLinkError={webLinkError}
                                            seatGroupsTicketsSumError={seatGroupsTicketsSumError}
                                            setCoupons={setCoupons}
                                            handleInputChange={handleInputChange}
                                            handleInputBlur={handleInputBlur}
                                            editMode={editMode}
                                        />
                                        : null}
                                </>
                            ,
                            value: TICKETS_METHOD.COUPON
                        }
                    ]}
                />
                <div className="add-event-errors-third">
                    {ticketMethodError}
                </div>

                <HearingAidsInput
                    isHearingImpairedAccessible={isHearingImpairedAccessible}
                    setIsHearingImpairedAccessible={setIsHearingImpairedAccessible}
                    hearingAidsNumber={hearingAidsNumber}
                    setHearingAidsNumber={setHearingAidsNumber}
                    error={hearingAidsError}
                    disabled={editMode}
                    maxValue={ticketsNum}
                />

                <div className="add-event-buttons-third">
                    <button onClick={BackButton} className="add-event-other-button-third">{PREVIOUS}</button>
                    {
                        editMode
                            ? <GenericButton handleClick={EditButton} serverLoading={serverLoading} className="yellow add-event-yellow-button-third">{ButtonsText.Update}</GenericButton>
                            : <GenericButton handleClick={handleContinueButtonClick} className="yellow add-event-yellow-button-third">{CONTINUE}</GenericButton>
                    }
                </div>
            </form >
        </div >
    )
}

export default AddEventRightThird;
