//after organization signed up it shows a page which says that its waiting for the organization to be approved and 
//tells what the approving process consists of

import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { TITLES } from "../../../consts/hebrew";
import FeedBack from "../../../generic-components/GenericFeedback";

const OrganizationRegistered: React.FC = () => {
    document.title = 'ברוכים הבאים'

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)');
    const [display, setDisplay] = useState<boolean>(false);

    const disciption1 = [
        { text: "לאחר שהצטרפות העמותה תאושר על ידי העירייה" },
        { text: "תוכלו להיכנס לאתר באמצעות מספר הטלפון" },
        { text: "של איש הקשר" },
      ];
    
      const disciption2 = [
        { text: "לאחר שהצטרפות העמותה תאושר" },
        { text: "על ידי העירייה, תוכלו להיכנס" },
        { text: "לאתר באמצעות מספר הטלפון" },
        { text: "של איש הקשר" },
      ];    

    useEffect(() => {
        if (tabletMediaQuery) {
            setDisplay(true);
        }
    }, [tabletMediaQuery])


    return (

        <FeedBack
            backArrow
            image="/icons/blue-plane.svg"
            firstLine={<div>{TITLES.REQUEST_WAS_SENT}</div>}
            secondLine={""}
            lastLine={<div style={{ fontWeight: "normal", fontSize: "3vh" }}>
                {!display ?
                    <>
                        {disciption1.map((obj) => {
                            return (
                                <div key={obj.text}>{obj.text}</div>
                            )
                        })}
                    </> :
                    <>
                        {disciption2.map((obj) => {
                            return (
                                <div key={obj.text}>{obj.text}</div>
                            )
                        })}
                    </>
                }
            </div>}
            unauthenticated
        />

    )

}

export default OrganizationRegistered;