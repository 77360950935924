//if agency logged in for the first time it will show this page that says:
//update ur agency info so you can add new events

import { WelcomeOrganization } from "../../../consts/hebrew";
import FeedBack from "../../../generic-components/GenericFeedback";
import "../../../style/firstTimeRegistered.scss";

const FirstTimeOrg = () => {

    return (
        <FeedBack
            image={"/icons/ticket.svg"}
            firstLine={<div >{WelcomeOrganization.TITLE}</div>}
            // secondLine={""}
            lastLine={<div className="no-new-events-text">
                <span>{WelcomeOrganization.FIRST_LINE}</span>
                <br />
                <span>{WelcomeOrganization.SECOND_LINE}</span>
            </div>}
            button={WelcomeOrganization.BUTTON}
        />
    )

}

export default FirstTimeOrg;