import React, { FC } from "react";

import { ARE_SEATS_ADJACENT, ARE_SEATS_MARKED, IS_EVENT_SITTING_OR_STANDING, NO, NO_SEAT_BASED_ON_AVAILABLE_SEATS, SITTING, STANDING, YES, YES_SEATS_ARE_MARKED } from "../../../consts/hebrew";
import { EventSeatingDetailsInputsProps } from "../../../consts/interfaces/Event.interface";
import { ARE_ADJANCENT, IS_MARKED, SEATING_OR_STANDING } from "../../../consts/variables";
import { ADD_EVENT_THIRD } from "../../../consts/enums/InputsNames";

import { checkIsAdjacent, checkIsMarked, checkIsSeating } from "../../../functions/seatGroupDetails";
import RadioButtons from "../../../generic-components/RadioButtons";

const EventSeatingDetailsInputs: FC<EventSeatingDetailsInputsProps> = ({
    isSeating,
    isSeatingError,
    isMarkedSeats,
    isMarkedSeatsError,
    areAdjacent,
    areAdjacentError,
    handleIsSeatingChange,
    handleMarkedSeatsChange,
    handleAreAdjacentSeatsChange,
    disabled,
    disableIsSeating,
    disableIsMarkedSeats,
}) => {

    return (
        <div dir="rtl">
            <div className="add-event-input-title" >{IS_EVENT_SITTING_OR_STANDING}?</div>
            <RadioButtons
                disabled={disableIsSeating || disabled}
                className="add-new-event"
                value={isSeating ? SEATING_OR_STANDING.SEATING : isSeating === false ? SEATING_OR_STANDING.STANDING : ''}
                handleChange={(e: React.FormEvent<HTMLInputElement>) => handleIsSeatingChange(checkIsSeating(e.currentTarget.value))}
                options={[
                    { label: STANDING, value: SEATING_OR_STANDING.STANDING },
                    { label: SITTING, value: SEATING_OR_STANDING.SEATING }
                ]}
                name={ADD_EVENT_THIRD.IS_SEATING} />
            <div className="add-event-errors-third">
                {isSeatingError}
            </div>

            {(isSeating) &&
                <>
                    <div className="add-event-input-title" >{ARE_SEATS_MARKED}?</div>
                    <RadioButtons
                        disabled={disableIsMarkedSeats || disabled}
                        className="add-new-event"
                        value={isMarkedSeats ? IS_MARKED.MARKED : isMarkedSeats === false ? IS_MARKED.NOT_MARKED : ''}
                        handleChange={(e: React.FormEvent<HTMLInputElement>) => handleMarkedSeatsChange(checkIsMarked(e.currentTarget.value))}
                        options={[
                            { label: YES_SEATS_ARE_MARKED, value: IS_MARKED.MARKED },
                            { label: NO_SEAT_BASED_ON_AVAILABLE_SEATS, value: IS_MARKED.NOT_MARKED }
                        ]}
                        name={ADD_EVENT_THIRD.IS_MARKED_SEATS}
                    />
                    <div className="add-event-errors-third">
                        {isMarkedSeatsError}
                    </div>

                    <div className="add-event-input-title" >{ARE_SEATS_ADJACENT}?</div>
                    <RadioButtons
                        disabled={disabled}
                        className="add-new-event"
                        value={areAdjacent ? ARE_ADJANCENT.ADJACENT : areAdjacent === false ? ARE_ADJANCENT.NOT_ADJACENT : ''}
                        handleChange={(e: React.FormEvent<HTMLInputElement>) => handleAreAdjacentSeatsChange(checkIsAdjacent(e.currentTarget.value))}
                        options={[
                            { label: YES, value: ARE_ADJANCENT.ADJACENT },
                            { label: NO, value: ARE_ADJANCENT.NOT_ADJACENT }
                        ]}
                        name={ADD_EVENT_THIRD.ARE_ADJACENT_SEATS}
                    />
                    <div className="add-event-errors-third">{areAdjacentError}</div>
                </>}

        </div>
    )
}

export default EventSeatingDetailsInputs;