import clsx from "clsx";
import axios from "axios";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAsyncEffect } from "@hilma/tools";

import { UserContext } from "../../../context/UserContext";
import { ORG_HOME_PAGE_STATS, SEND_FEEDBACK, SHARE_YOUR_EXPERIENCE } from "../../../consts/hebrew"

import GenericButton from "../../../generic-components/GenericButton";

import "./style/organizationHomeStats.scss";

const OrganizationHomeStats: React.FC = () => {

    const history = useHistory();

    const { systemID } = useContext(UserContext);

    const [eventCount, setEventCount] = useState<number>(0)
    const [sentFeedbacks, setSentFeedbacks] = useState<number>(0)
    const [receivedTickets, setReceivedTickets] = useState<number>(0)

    useAsyncEffect(async () => {
        if (!systemID) return;

        const { eventCount, ticketsData, sentFeedbacks } = (await axios.get(`/api/organization/general-statistics/${systemID}`)).data

        setEventCount(eventCount)
        setReceivedTickets(ticketsData.purchasedTicketsCount)
        setSentFeedbacks(sentFeedbacks)

    }, [systemID])

    const isNoData = !eventCount && !sentFeedbacks && !receivedTickets;

    const navigateToSendFeedback = () => {
        history.push('/past-ordered-events')
    }

    return (
        <div className={clsx("org-home-stats-container", { "no-data": isNoData })}>
            <div className="send-feedback">
                {SHARE_YOUR_EXPERIENCE}
                <br />
                <GenericButton className="send-feedback-btn yellow" handleClick={navigateToSendFeedback}>
                    <div className="items-in-feedback-button">
                        {SEND_FEEDBACK}
                        <img src="/icons/full-arrow-dark.svg" alt="" />
                    </div>
                </GenericButton>
            </div>

            <hr />

            <div className="org-stats">
                <div className="single-stat">
                    <img src="/icons/blue-light.svg" alt="" />
                    <div className="number-container">
                        {eventCount}
                    </div>
                    {ORG_HOME_PAGE_STATS.EVENTS}
                </div>
                <div className="single-stat">
                    <img src="/icons/ticket.svg" alt="" />
                    <div className="number-container">
                        {receivedTickets}
                    </div>
                    {ORG_HOME_PAGE_STATS.TICKETS}
                </div>
                <div className="single-stat">
                    <img src="/icons/blue-heart.svg" alt="" />
                    <div className="number-container">
                        {sentFeedbacks}
                    </div>
                    {ORG_HOME_PAGE_STATS.FEEDBACKS}
                </div>
            </div>
        </div>
    )
}

export default OrganizationHomeStats;