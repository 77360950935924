import React, { createContext, useContext, useEffect, useState } from "react";
import { Area } from "react-easy-crop";
import { Contact } from "../consts/interfaces/Contact.interface";
import { AgencyContextInterface, AgencyDataInterface, HallsProfileAgency } from "../consts/interfaces/AgencyProfile.interface";
import axios from "axios";
import { ErrorContext } from "./ErrorContext";
import { UserContext } from "./UserContext";
import { _ERROR_MESSAGE } from "../consts/variables";

export const AgencyContext = createContext<AgencyContextInterface>({
    firstTime: true,
    imageLink: "",
    agencyType: "",
    agencyName: "",
    imageCoordinates: { x: 0, y: 0, width: 100, height: 100 },
    contacts: [] as Contact[],
    experienceType: 0,
    radioValue: "",
    address: "",
    howToArrive: "",
    halls: [{ hallName: "", hallNameError: "" }],
    setFirstTime: () => { },
    setImageLink: () => { },
    setAgencyType: () => { },
    setAgencyName: () => { },
    setImageCoordinates: () => { },
    setContacts: () => { },
    setExperienceType: () => { },
    setRadioValue: () => { },
    setAddress: () => { },
    setHowToArrive: () => { },
    setHalls: () => { },
    isLoading: true,
    atLeastOneHallIsUsed: false,
    setIsLoading: () => { },
    setAtLeastOneHallIsUsed: () => { },
});

export const AgencyProvider: React.FC = ({ children }) => {

    const { systemID, role } = useContext(UserContext);
    const { showError } = useContext(ErrorContext);
    const [firstTime, setFirstTime] = useState<boolean>(true);
    const [imageLink, setImageLink] = useState<string>("");
    const [agencyType, setAgencyType] = useState<string>("");
    const [agencyName, setAgencyName] = useState<string>("");
    const [imageCoordinates, setImageCoordinates] = useState<Area>({ x: 0, y: 0, width: 100, height: 100 });
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [experienceType, setExperienceType] = useState(0);
    const [radioValue, setRadioValue] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [howToArrive, setHowToArrive] = useState<string>("");
    const [halls, setHalls] = useState<HallsProfileAgency[]>([{ hallName: "", hallNameError: "" }]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [atLeastOneHallIsUsed, setAtLeastOneHallIsUsed] = useState<boolean>(false);

    useEffect(() => {
        if (role === 'agency') fetchAgencyData()
    }, [systemID, showError, role])

    const fetchAgencyData = async () => {
        try {
            if (!systemID) return
            const { data }: { data: AgencyDataInterface } = await axios.get(`/api/agency/agency-info?id=${systemID}`);
            const { firstTime, profilePhoto, agencyType, agencyName, contacts, experienceType, locationDetails, imageCoordinates } = data
            setFirstTime(firstTime);
            setImageLink(profilePhoto)
            setAgencyType(agencyType)
            setAgencyName(agencyName)
            setContacts(contacts.map(contact => ({ ...contact, phone: contact.username, nameError: '', phoneError: '', emailError: '' })))
            setExperienceType(experienceType)
            setRadioValue(locationDetails ? 'yes' : 'no')
            setAddress(locationDetails?.location || '');
            setHowToArrive(locationDetails?.navigation || '')
            setHalls(locationDetails?.halls.map(hall => ({ ...hall, hallNameError: "" })) || [{ hallName: '', hallNameError: '' }])
            imageCoordinates && setImageCoordinates(imageCoordinates)
            setIsLoading(false)
        } catch (err) {
            showError(_ERROR_MESSAGE);
            throw err;
        }
    }

    return (
        <AgencyContext.Provider
            value={{
                firstTime, setFirstTime,
                imageLink, setImageLink,
                agencyType, setAgencyType,
                agencyName, setAgencyName,
                imageCoordinates, setImageCoordinates,
                contacts, setContacts,
                experienceType, setExperienceType,
                radioValue, setRadioValue,
                howToArrive, setHowToArrive,
                address, setAddress,
                halls, setHalls,
                isLoading, setIsLoading,
                atLeastOneHallIsUsed, setAtLeastOneHallIsUsed,
            }}
        >
            {children}
        </AgencyContext.Provider>
    );
};

export const useAgencyContext = () => useContext(AgencyContext)