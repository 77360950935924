import React from "react";
import clsx from "clsx";
import { GenericSplitPageProps } from "../consts/interfaces/GenericSplitPage.interface";
import GenericLeftSideLayout from "./GenericLeftSideLayout";
import "../style/genericSplitPage.scss";

const GenericSplitPage: React.FC<GenericSplitPageProps> = ({ className, serverLoading, children, squares }) => {

    return (
        <div className={clsx("main-container", className, { 'server-loading': serverLoading })}>
            <div className="content-container">
                <div className="right-side">
                    {children}
                </div>
                <div className="left-side">
                    <GenericLeftSideLayout squares={squares} />
                </div>
            </div>
        </div>
    )
}

export default GenericSplitPage