export const EVENT_CANCELED: string = "האירוע בוטל";
export const EVENT_MOVED_TO_DIFFERENT_DATE: string = "האירוע נדחה למועד אחר";
export const EVENT_DOES_NOT_HAVE_AVAILABLE_TICKETS: string = "הביקוש לאירוע עולה ואני חושב שכבר לא יהיו לי כרטיסים פנויים כמו שחשבתי";
export const ORGANIZATIONS_DID_NOT_BUY_TICKETS: string = "לא שוריינו כרטיסים מאנטר ורציתי לנסות לממש את הכרטיסים בדרך אחרת";
export const THANKS_FOR_YOUR_UPDATE: string = "תודה על העדכון!";
export const WE_WILL_UPDATE_ALL_ORGANIZATIONS_ABOUT_THE_CANCEL = "אנו נדאג לעדכן את העמותות שהזמינו כרטיסים לאירוע על ביטולו";
export const WHY_DID_YOE_DECIDE_TO_CANCEL_TICKETS_FOR_EVENT: string = "נשמח לדעת, מדוע החלטת לבטל את הכרטיסים לאירוע?";
export const WEEK: string[] = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"]
export const OTHER: string = "אחר"
export const SEND: string = "שליחה"
export const TYPE_TEXT_PLACEHOLDER: string = "הקלד טקסט"
export const THE_GIVERS: string = 'הנותנים';
export const AGENCY_SIGNUP: string = 'רישום מוסד תרבות / ספורט'
export const AGENCY_LOGO: string = 'לוגו מוסד:';
export const EDIT_PHOTO: string = 'עריכת תמונה';
export const DELETE_PHOTO = "מחיקת תמונה";
export const IN_NUMBER_ENTER_YOU_CONFIRM_RECEIVING_ADVERTISEMENT_AND_UPDATE_MESSAGES: string = 'בהכנסת המספר הינך מאשר קבלת הודעות לצורך פרסום ועדכוני אירוע';
export const I = 'אני';
export const I_read_the_term = '(שם הנציג/ה החותמ/ת) מאשר/ת שקראתי את';
export const terms_for_enter = 'תקנון מיזם אנטר';
export const in_date = 'בתאריך';
export const I_agree_to_the_terms = 'ואני מסכימ/ה לכתב ההתחייבות המצורף';
export const loading_file = 'טעינת קובץ';
export const SEND_REQUEST: string = 'שלח בקשה';
export const ADD_CONTACT: string = 'הוסף איש קשר';
export const AGENCY_TYPE: string = 'מוסד יוזם';
export const NAME_OF_AGENCY_OR_SPORT_BODY: string = 'שם המוסד / גופי תרבות וספורט';
export const TYPE_AGENCY_TYPE_OR_SPORT_BODY: string = 'הקלד מוסד יזום או גוף תרבות וספורט'
export const EVENT_NAME: string = 'שם האירוע';
export const EVENT_DATE: string = 'תאריך האירוע';
export const START_TIME: string = 'שעת התחלה';
export const NOTICE_POST_EVENT_HOURS_BEFORE_START: string = 'אנא שימו לב שאפשר לפרסם אירוע עד 5 שעות לפני תחילת האירוע על מנת לאפשר מספיק זמן למערכת לחלק אותו לעמותה הנכונה'
export const EVENT_DURATION = 'משך האירוע';
export const EVENT_DESCRIPTION = 'תיאור האירוע';
export const EVENT_TYPE = 'סוג אירוע';
export const PLACE_TYPE = 'סוג מקום';
export const ADD = 'הוסף';
export const MARK_IF_ORG_HAS_IS_FOLLOWING_POPULATIONS = 'יש לסמן מגזר אחד או יותר:'
export const MARK_IF_EVENT_AUDIENCE_IS_FOLLOWING_POPULATIONS = 'האם האירוע מותאם למגזר'
export const PHOTO_OR_GRAPHICS_OF_EVENT = 'תמונה/גרפיקה של אירוע';
export const PHOTO_OR_GRAPHICS_OF_PLACE = 'תמונה/גרפיקה של מקום';
export const POSTER_OF_THE_EVENT = 'פוסטר של האירוע';
export const LOAD_IMAGE = 'טעינת תמונה';
export const UPDATE_PHOTO = 'עדכון תמונה';
export const CONTINUE = 'המשך';
export const ADDRESS = 'כתובת:';
export const PUBLIC_TRANSPORT_NAVIGATION = 'דרכי הגעה בתחבורה ציבורית:';
export const CONTACT_AVAILABLE_ON_EVENT = 'איש קשר זמין באירוע';
export const PHONE_NUM = 'מס\' טלפון:';
export const CONTACT_NAME = 'שם איש הקשר:';
export const PREVIOUS = 'הקודם';
export const HOW_MANY_TICKET_DO_YOU_WANT_TO_GIVE = 'כמה כרטיסים תרצו להעניק?';
export const WHAT_IS_THE_VALUE_OF_TICKET = 'מהו שווי כרטיס בודד?';
export const IS_EVENT_SITTING_OR_STANDING = 'האם האירוע מתקיים בישיבה או בעמידה';
export const SITTING = 'ישיבה';
export const STANDING = 'עמידה';
export const ARE_SEATS_MARKED = 'האם המושבים מסומנים';
export const YES_SEATS_ARE_MARKED = 'כן, המושבים מסומנים';
export const NO_SEAT_BASED_ON_AVAILABLE_SEATS = 'לא, המושבים הם על בסיס מקום פנוי';
export const ARE_SEATS_ADJACENT = 'האם כל המושבים סמוכים אחד לשני';
export const PDF_FILE_WITH_THE_TICKETS = 'קובץ PDF עם כל הכרטיסים';
export const ADD_SEAT_GROUP = 'הוסף קבוצת מושבים';
export const ADD_COUPON = 'הוסף קופון נוסף';
export const HOW_WILL_THE_ORGANIZATION_RECEIVE_THE_TICKETS = 'כיצד יקבלו העמותות את הכרטיסים';
export const LOAD_PDF_FILE = 'טעינת קובץ PDF';
export const LOAD_PDF_FILES = 'טעינת קבצי PDF';
export const LOAD_MULTIPLE_FILES = "טעינת קבצי PDF מרובים";
export const COUPON_CODE_FOR_EXTERNAL_WEBSITE = 'קוד קופון להזמנה באתר חיצוני';
export const ENTER_COUPON_CODE = 'הכנס קוד קופון';
export const ENTER_LINK_TO_TICKET_ORDER = 'הכנס קישור לרכישת כרטיסים';
export const CHOOSE_ACCESSIBILITY_TYPE = 'בחר סוג נגישות';
export const POST_EVENT = 'פרסום אירוע';
export const SAVE_EVENT = 'שמירה'
export const YES = 'כן';
export const NO = 'לא'
export const MAKE_SURE_SEATS_NUMBER_EQUALS_TICKETS_NUMBER = 'וודאו שסכום המושבים שווה למספר הכרטיסים שאתם מעניקים';
export const MAKE_SURE_COUPONS_NUMBER_EQUALS_TICKETS_NUMBER = 'וודאו שסכום הכרטיסים בכל קופון שווה למספר הכרטיסים שאתם מעניקים';
export const OFFER_FOR_YOUR_ORGANIZATION: string = "הצעות עבור העמותה שלך"
export const SEND_THANKS: string = 'שליחת תודה';
export const SEND_THANK_YOU: string = 'שליחה';
export const SAVE: string = 'שמירה';
export const WANT_TO_ADD_VIP_TITLE = 'רוצה להוסיף לכרטיסים תוספת VIP?'
export const ADDITIONAL_TICKETS_VIP = "התוספות המיוחדות לכרטיסים"
export const MEETING_WITH_ACTORS_VIP = "פגישה של החברים מהעמותה עם השחקנים / מאחורי הקלעים"
export const VISIT_ACTORS_VIP = "ביקור של השחקנים בעמותה"
export const ORGANIZATION_SUSPENDED = "השהיית עמותה"
export const ADDITIONAL_FEEDBACK = 'קיימים פידבקים נוספים רוצה לראות עוד?'
export const VIEW_ALL_FEEDBACKS = 'לצפייה בכל הפידבקים שקיבלתי >'
export const MY_DONATIONS = 'שווי התרומה שלי במיזם'
export const SHEKEL_SIGN = '₪'

export const EMPTY_TICKET: string = "כרטיסים פנויים"
export const LIMIT_ERROR = "אנא העלו קובץ עד 3MB"
export const ORG_LOGO = "לוגו עמותה:"
export const MOBILITY_ACCESS_INFO = 'התאמה עבור אנשים שנעזרים בתומכי הליכה כגון: הליכון, קביים ועוד'
export const EXPERIENCE_TYPE = 'סוג מוסד'
export const CHOOSE_AT_LEAST_ONE = 'יש לבחור אפשרות אחת או יותר'
export const DONATE_TICKETS = 'ביקור באתר'
export const SPONSOR_EVENTS = 'כרטיסים לאירועים'
export const TICKETS_REMAINING = 'נותרו למימוש עד לתאריך'
export const TICKETS = 'כרטיסים'
export const EVENT_PREVIEW = 'תצוגה מקדימה'

export enum GenericTagsEnum {
    CATEGORY = "קטגוריות:",
    NO_CHOSE_CATEGORY = "לא נבחרו קטגוריות",
    ASSEBILITY = "נגישות:",
    NO_ASSEBILITY = "אין נגישויות",
    GENERAL_MESSAGE = "אירעה שגיאה. נסו שוב מאוחר יותר",
    INTENDED_FOR_SECTORS = "מתאים למגזרים:",
    TYPE_EVENT = "סוג אירוע:",
    SEAT = "בישיבה",
    STAND = "בעמידה",
    PLACE_MARKET = ', מקומות מסומנים',
    PLACE_NOT_MARKET = ', מקומות לא מסומנים',
    TIME_OF_EVENT = "משך זמן האירוע:",
    HOURS = "שעות",
    MINUTES = "דקות",
    WAY_COMEING = "דרכי הגעה:",
    AGES = "טווח גילאים:",
    MONY_TICKETS = "שווי כרטיס:",
    READ_LESS = 'קרא פחות',
    READ_MORE = 'קרא עוד',
    REGULAR_CHAIRS_ONLY = 'מושבים רגילים בלבד',
    EVENT_VIP = "אירוע VIP",
    PRICE_INFO = "מחיר הכרטיס אינו גלוי לעמותות"

}
export enum HOME_PAGE {
    ENTER = 'אנטר',
    CULTURE_MAKES_REALITY = 'תרבות מייצרת מציאות',
    SOCIAL_DARE = 'מיזם חברתי-תרבותי בעיר ירושלים',
    MAKE_CONTACT = 'צור קשר',
    ORGANIZATIONS = 'עמותות',
    AGENCIES = 'מוסדות',
    DONATION = 'שווי התרומה במיזם',
    TICKETS = 'כרטיסים שנתרמו'


}

export enum LoginMessage {
    CONTACT_NOT_FOUND = "איש קשר זה אינו קיים",
    ORGANIZATION_NOT_APPROVED = "העמותה טרם אושרה",
    AGENCY_NOT_APPROVED = "מוסד התרבות טרם אושר",
    USER_IS_BLOCKED = "משתמש זה נחסם עקב ניסיונות רבים מידיי, נסה שוב מאוחר יותר",
    GENERAL_MESSAGE = "אירעה שגיאה. נסו שוב מאוחר יותר",
    USER_IS_NOT_ACTIVE = "משתמש זה אינו פעיל יותר",
}


export enum EventArchiveEnum {
    ORDERED = "הוזמנו",
    FROM = "מתוך",
    TICKETS = "כרטיסים",
    SINGLE_TICKET = 'הוזמן כרטיס אחד'
}
export enum CodeError {
    INCORRECT_CODE = "Incorrect code",
    EXPIRED_CODE = "Code has expired",
    BLOCKED_CODE = "User is blocked",
    MAX_ATTEMPTS_CODE = "Max attempts"

}

export enum CodeMessage {
    INCORRECT_CODE = "קוד שגוי",
    EXPIRED_CODE = "פג תוקפו של הקוד, לחצו על נסו שנית",
    BLOCKED_CODE = "משתמש זה נחסם עקב ניסיונות רבים מידיי, נסה שוב מאוחר יותר",
    MAX_ATTEMPTS_CODE = "ניסיות רבים מידיי, נסה עוד 10 דקות",
    GENERAL_MESSAGE = "אירעה שגיאה"
}
export enum Events {
    ARECION_EVENTS = "ארכיון אירועים",
    SEARCH = "חיפוש",
    NO_EVENTS_DONE = "עוד לא התקיימו ארועים",
    NO_EVENTS_NAME = "לא נמצאו אירועים בשם זה"

}
export enum ErrorMessage {
    DOWNLOAD = "משהו השתבש בהורדה, נסו שוב מאוחר יותר"
}

export enum GenericSeatGroupEnum {
    SEAT_GROUP = 'קבוצת מושבים',
    TICKETS = 'כרטיסים',
    ACCESSIBILITY = 'נגישות:',
    REGULAR_SEATS = 'מושבים רגילים / ללא נגישות'
}

export const MULTIPLE_SEAT_GROUPS_INFO = 'אם יש יותר מקבוצת מושבים אחת המשמעות היא שהכרטיסים נמצאים בקבוצות ישיבה שונות באזור האולם בהתאם לכמות הכרטיסים ולנגישות.'
export const HALL = 'אולם';
export const NAME_OF_HALL_OR_PLACE = 'שם אולם/מיקום:';
export const SEAT_GROUP = 'קבוצת מושבים';
export const TICKET_NUMBER = 'מספר כרטיסים';
export const BACK_TO_HOME_PAGE = "חזרה לעמוד בית";
export const BACK = "חזרה";
export const PROFILE = "פרופיל";
export const EDIT_PROFILE = "עריכת פרופיל";
export const FOR_US_TO_KNOW_WHAT_EVENTS_FITS_YOU = "על מנת שנוכל לדעת אילו אירועים הכי יתאימו לכם"
export const DO_YOUR_EVENTS_TAKE_PLACE_IN_PERMANENT_PLACE = "האם האירועים שלכם מתקיימים במיקום קבוע";
export const YES_I_WOULD_LIKE_TO_UPDATE_OUR_PERMANENT_PLACES_ONCE = "כן, אשמח לעדכן פעם אחת את הכתובת והאולמות הקבועים שלנו";
export const NO_EACH_EVENT_TAKES_PLACE_IN_DIFFERENT_PLACE = "לא, כל אירוע מתקיים במקום אחר";
export const FILL_HALL_AND_PLACE_DETAILS = "מלא את הפרטים אודות המבנה והאולמות";
export const DETAILS = "פרטים";
export const UPDATE_DETAILS = "עדכון";
export const ADD_HALL = "הוסף אולם";
export const ARE_HEARING_AIDS_OWNED = "האם קיימים אביזרי שמע עבור לקויי שמיעה באירוע זה?";
export const HOW_MANY_HEARING_AIDS_AVAILABLE = "כמה אביזרי שמע זמינים?";
export const AVAILABLE_HEARING_AIDS = "מכשירי שמיעה נגישים"
export const HOW_MANY_ACCESSIBLE_TICKETS = "כמה מתוך הכרטיסים מותאמים לנגישות?";
export const WHEELCHAIRS = "כיסאות גלגלים";
export const MOBILITY_DIFFICULTIES = "קשיי ניידות";
export const AVAILABLE_SEATS = "מקומות זמינים"

export enum ButtonsText {
    Interested = 'יכול לעניין אותנו',
    InterestedShort = 'מעניין אותנו',
    NotRelevant = 'לא רלוונטי אלינו',
    NeedTime = 'אני צריך עוד זמן',
    OrderTickets = 'הזמנת כרטיסים',
    EditEvent = 'עריכת אירוע',
    CancelEvent = 'ביטול אירוע',
    DownloadTicketPDF = 'הורדת קובץ PDF של הכרטיסים',
    ShowSingleCoupon = 'הצגת קוד קופון',
    ShowMultipleCoupons = 'הצגת קודי קופון',
    Purchase = "הזמנה",
    Update = "עדכון",
    AddTime = 'תוספת זמן',
    Duplicate = 'שכפול פרטי אירוע',
    PublishEvent = 'פרסום אירוע',
    BackToEdit = 'בחזרה לעריכה',
    AddTickets = 'הוספת כרטיסים'
}

export enum FeedbackHebrew {
    SHARE_THE_EXPIRINCES_FIRST = "שתפו את המוסדות בחוויה של ",
    SHARE_THE_EXPIRINCES_SECOND = "החניכים ואמרו תודה",
    LOADING_PHOTOS = "טעינת תמונות ",
    DELETE_IMAGE = "מחק תמונה",
    WHAT_DO_YOU_WANT_TO_TELL = "מה תרצו להגיד למוסד?",
    EDIT_FEEDBACK = 'עריכת פידבק',
    DELETE_FEEDBACK = 'מחיקת פידבק',
    EDIT = 'עריכה',
    DELETE = 'מחיקה'
}

export enum TITLES {
    REQUEST_FOR_JOINING = 'בקשה לרישום עמותה חדשה',
    CONDITIONALS_FOR_JOINING = 'התנאים להצטרפות כעמותה למיזם:',
    REQUEST_WAS_SENT = 'הבקשה נשלחה בהצלחה',
    AGENCIES_AND_SPORTS_BODIES_SIGNUP = 'רישום מוסדות וגופי תרבות וספורט',
    EXCITED_YOURE_JOINING_THIS_SPECIAL_PROJECT = 'מתרגשים שאתם מצטרפים למיזם המיוחד הזה!',
    LOGIN_USERS = 'כניסת משתמשים',
    DONT_HAVE_ACOUNT = 'לא נרשמתם עדיין למיזם?',
    NO_EVENTS_ARE_OFFERED = 'לא קיימים אירועים',
    UPDATE_PROFILE_ORG = 'עדכון פרטי עמותה',
    HELP_US_TO_KNOW_YOU = 'רגע לפני שמתחילים, עזרו לנו להכיר אתכם',
    EVENTS_I_ORDERED = 'אירועים שהזמנתי',
    SAY_THANKS = "אומרים תודה",
    FEEDBACK_WAS_SENT = 'המשוב נשלח למוסד התרבות בהצלחה',
    WANT_TO_GIVE_TICKETS = 'תרצו להעניק כרטיסים לאירוע קרוב?',
    ADD_NEW_EVENT = 'הוספת אירוע חדש',
    ARCHIVE_EVENTS = 'ארכיון אירועים',
    UPDATE_AGENCY_DETAILS = "עדכון פרטי מוסד תרבות / ספורט",
    MAKE_CONTACT = "יצירת קשר",
    OFFER_EVENT_FOR_YOUR_ORGANIZATION = "הצעות לאירועים עבור העמותה שלך",
    OFFERS_FOR_YOUR_ORG = 'הצעות עבור העמותה שלך',
    BACK_TO_EVENT_ARCHIVE = "חזרה לארכיון אירועים",
    CANCELED_SUCCSESFULLY = "האירוע בוטל בהצלחה!",
    THANKS_FOR_UPDATE = 'תודה על העידכון!',
    ADD_ENTRANCE_TICKETS = "הוספת כרטיסי כניסה",
    ENTER_BENEFITS = 'אזור ההטבות של אנטר',
    ENTRANCE_TICKETS = 'כרטיסי כניסה למוזיאונים, אתרי מורשת וספורט',
    NEXT_EVENTS = "אירועים קרובים",
    RECEIVED_FEEDBACKS = "הפידבקים האחרונים שקיבלתי",
    NEW_EVENT_OFFERS = "הצעות לאירועים חדשים שממתינים עבורך"
}

//function that checks how much pictures there are and how many we want to add and it makes sure that we dont go over 15 pictures
export const MaxCapacityImageError = (addedAmount: number, currentAmount: number, MaxAmount: number) => {
    const totalAmount = addedAmount + currentAmount;

    if (totalAmount === 15) {
        return `הגעת למכסת התמונות. לא ניתן להעלות יותר תמונות`
    }
    if (totalAmount > MaxAmount && currentAmount === 0) {
        return (`הגעת למכסת התמונות. לא ניתן להעלות יותר מ${MaxAmount} תמונות`)
    }
    if ((MaxAmount - currentAmount) > 1) {
        return (`כמעט נגמר המקום, ניתן להוסיף רק עוד ${MaxAmount - currentAmount} תמונות`);
    }
    return (`כמעט נגמר המקום, ניתן להוסיף רק עוד תמונה אחת`);


};
export const reachedMaxLimitError = `הגעת למכסת התמונות. לא ניתן להעלות יותר תמונות`

export const INFO_OF_HEARING_AIDS_NOTICE = "*המידע על התאמות הנגישות האפשריות נכונות ליום הזנת האירוע וייתכנו שינויים בפועל בהתאם לקיבולת."
export const AT_THE_MOMENT_THERE_WILL_BE_TICKETS = 'ברגע שיהיו כרטיסים מתאימים לעמותה'
export const UPDATE_YOU_WITH_SMS = 'שלכם נעדכן אתכם בהודעה'
export const MEET_IN_NEXT_EVENTS = 'ניפגש באירועים הבאים'

export enum WelcomeOrganization {
    TITLE = "ברוכים הבאים לאנטר!",
    FIRST_LINE = "כדי שתוכלו להתחיל ולקבל אירועים",
    SECOND_LINE = "תצטרכו לעדכן את פרופיל העמותה שלכם בעוד כמה פרטים",
    BUTTON = "לעדכון פרופיל עמותה"

}
// export const WELCOME_ORGANIZATION_MESSAGE = "ברוכים הבאים לאנטר!"

export const HOW_MANY_TICKETS_WILL_BE_USED = "כמה כרטיסים תממשו מתוך קבוצת המושבים?";
export const THERES_OPEN_EVENT = "ישנו אירוע פתוח במערכת שעוד לא פורסם";
export const DO_YOU_WANT_LOAD_DRAFT = "האם תרצה לחזור לעריכה שלו?"
export const RETURN_TO_EDIT = "כן, חזור לעריכה"
export const EDIT_NEW_EVENT = "לא, ברצוני להזין אירוע חדש"
export const WANT_EXTRA__TICKETS = "מעוניינים בכרטיסים נוספים לאתר זה?";
export const REQUEST_EXTRA_TICKETS_FROM_AGENCY = "בקשת כרטיסים נוספים מהמוסד";
export const REQUEST_EXTRA_TICKETS_DETAILS = "במידה ואתם מעוניינים ביותר כרטיסים מהכמות שהמוסד מציע, יש להעלות בקשה עם כמות הכרטיסים הרצויה. המוסד יבחן את האפשרות, ואולי יתרום כרטיסים נוספים במיוחד בשבילכם"
export const TICKET_CAN_BE_USED_TO = "ניתן לממש את הכרטיסים עד לתאריך";

export const VIP_EXPLAIN = 'Enter VIP זו דרך נוספת לשמח את חברי העמותות שיגיעו ולדאוג להם לחוויה בלתי נשכחת מהאירוע'

export const NOT_INTERESTED_MAYBE_NEXT_TIME = 'לא מעוניין, אולי פעם הבאה'

export enum StatusOrderAgency {
    STATUS_ORDER = "סטטוס הזמנה",
    SUMMARY = 'סיכום',
    NO_TICKETS_PURCHASED = 'לא הוזמנו כרטיסים',
    ORG_WAITING_RESPOND = 'האירוע הוצע - ממתינים לתגובה'
}

export const passedThroughOrgs = (num: number) => num === 1 ? 'ההצעה עברה אצל עמותה אחת' : `ההצעה עברה אצל ${num} עמותות`

export const orgsPurchased = (num: number) => num === 1 ? 'עמותה אחת משכה כרטיסים' : `${num} עמותות משכו כרטיסים`

export const ticketSummary = (num: number, total: number) => num === 1 ? `סך הכל הוזמנו כרטיס אחד מתוך ${total}` : `סך הכל הוזמנו ${num} כרטיסים מתוך ${total}`

export enum StatusOrderOrganizationInfo {
    ORGANIZATION_DESCRIPTION = 'תיאור העמותה:',
    AGE_RANGE = 'טווח הגילאים:',
    AUDIENCE = 'קהל היעד:'
}

export enum AddEntranceTicketsFields {
    EXTRA_INFO = "מידע על מקום",
    AUDIENCE = "קהל יעד",
    PLACE_TYPE = "סוג מקום",
    SELECT_POPULATIONS = "יש לסמן מגזר אחד או יותר:",
    PHOTO = "תמונה / גרפיקה של המקום",
    ADDRESS = "כתובת:",
    NAVIGATION = "דרכי הגעה בתחבורה ציבורית",
    AVAILABLE_CONTACT = "איש קשר זמין",
    TICKETS_EXPIRATION = "תוקף למימוש כרטיסים",
    TICKETS = "כרטיסים",
}

export const PDF_FILE = "קובץ PDF";
export const COUPON_CODE = "קוד קופון";
export const WITH_ALL_TICKETS = "עם כל הכרטיסים";
export const ORDER_ON_EXTERNAL_WEBSITE = "להזמנה באתר חיצוני";
export const ENTER_WEB_LINK = "הכנס קישור לאתר";
export const ADDING_SEAT_GROUP = "+ הוספת קבוצת מושבים נוספת";
export const POST_ENTRANCE_TICKETS = "פרסום כרטיסי כניסה";

export enum WantMoreTickets {
    REQUEST_FOR_MORE_TICKETS = 'בקשת כרטיסים נוספים מהמוסד',
    SENDING_REQUEST = 'שליחה',
    HELLO = 'שלום',
    NOT_ENOUGH_TICKETS = 'אנחנו נשמח להגיע אליכם, אבל אין באנטר מספיק כרטיסים בשבילנו...',
    WE_ARE_INTERESTED_TO_COME = 'אנחנו מעוניינים להגיע',
    AUDIENCE_FROM_ORGANIZATION = 'חניכים / מדריכים / אנשים מהעמותה,',
    THANKS_IN_ADVANCE = 'תודה מראש!'

}
export const CONTACT = "איש קשר"
export const WANT_TO_THINK_WITH_US = 'רוצה לחשוב יחד איתנו? נשמח לסייע!'
export const EMAIL_COPPIED = 'כתובת המייל הועתקה בהצלחה!'

export const TICKETS_WERE_PURCHASED = 'כרטיסים הוזמנו'
export enum PopupSaveTicketsText {
    ticketsAreSavedForYou = 'הכרטיסים נשמרים עבורך',
    payAttentionToLeftTime = 'יש לשים לב לזמן שנותר להזמנת הכרטיסים',
    canAddTimeIfNeed = 'ניתן להוסיף זמן במקרה הצורך',
    gotIt = 'הבנתי'
}
export const HELLO = "שלום"
export const EVENTS = "אירועים"
export const ONE_EVENT = "אירוע אחד"
export const AMOUNT = "כמות"
export const TO_ADD = "להוספת"
export const ADD_CLOSE_EVENTS = "אירועים קרובים";
export const ADD_ENTRANCE_TICKETS = "כרטיסים לביקור באתר"
export const TO_ALL_RECEIVED_FEEDBACKS = "לכל הפידבקים שקיבלתי";
export const ORDER_STATUS_SUMMERY = "סיכום סטטוס הזמנה:";
export const ORGANIZATIONS = "עמותות";
export const ONE_ORGANIZATION = "עמותה אחת"
export const ARRIVING_TO_EVENT = "מגיעות לאירוע"
export const ONE_ARRIVING_TO_EVENT = 'מגיעה לאירוע'
export const VIEW_EVENT = "לצפייה באירוע";
export const ORDERED = "הוזמנו";
export const TICKETS_SAVED_TILL = "הכרטיסים שמורים עוד";

export enum ORG_HOME_PAGE_STATS {
    EVENTS = "אירועים שהשתתפתי",
    TICKETS = "כרטיסים",
    FEEDBACKS = "פידבקים שלחתי",
}
export const SHARE_YOUR_EXPERIENCE = "שתפו את התורמים בחוויה שלכם ואמרו תודה!";
export const SEND_FEEDBACK = "שליחת פידבק למוסד";

export enum ORG_NO_EVENTS_TITLE {
    WORKING_ON_NEW_OFFERS = "אנחנו עובדים על הצעות חדשות בשבילך...",
    MESSAGE_WHEN_OFFERS_ARRIVE = "ברגע שיהיו, נשלח לך הודעה"
}
export enum GREETING_ORGANIZATION {
    HELLO = 'שלום,',
    ORGANIZATION = 'עמותת'
}

export const AVAILABLE_TICKETS = "כרטיסים פנויים";
export const NO_FUTURE_PURCHASED_EVENTS = 'אין אירועים קרובים'

export enum PURCHASE_TICKETS {
    HOW_MANY_TICKETS = 'כמה כרטיסים תרצו להזמין?',
    NEED_HEARING_AIDS = 'האם מבין משתתפי האירוע יש אנשים הזקוקים לאביזרי שמע?',
    CHOOSE_HEARING_AIDS_AMOUNT = 'יש לבחור את כמות אביזרי השמע הנחוצה:',
    HEARING_AIDS = 'אביזרי שמע',
    CONTACT_PERSON = 'איש קשר שאליו ישלחו הכרטיסים'
}

export const EVENT_IN_INACTIVITY_HOURS = "ההזמנה מושהית עד לחזרה לשעות הפעילות";

export enum EVENT_PREVIEW_POP_UP {
    EVENT_DETAILS_ARE_SAVED = 'פרטי האירוע נשמרו',
    BEFORE_PUBLISH_EVENT = 'רגע לפני פרסום האירוע',
    RECOMMEND_TO_GO_OVER = 'אנו ממליצים לבצע הגהות',
    PREVIEW = 'תצוגה מקדימה',
    PUBLISH_EVENT = 'פרסום אירוע'
}

export enum FOOTER_TOOL_TIP {
    DELETE_EVENT = 'מחיקת אירוע',
    DUPLICATE_EVENT = 'שכפול פרטי אירוע',
    DUPLICATE_EXPLAIN = 'כפתור זה יפתח אירוע חדש עם פרטים זהים שניתן לערוך'
}

export enum POPUP_DUPLICATE_EVENT {
    TITLE = 'האירוע שוכפל בהצלחה!',
    BUTTON = 'המשך לעריכה'
}

export enum FORGOT_PUBLISH {
    PUBLISH_EVENT = "פרסום אירוע",
    PAY_ATTENTION = "יש לשים לב!",
    FORGOT_PUBLISH = "שכחת לפרסם את האירוע",
}

export enum ADD_MORE_TICKETS {
    ADD_TICKETS = "הוספת כרטיסים",
    ADD = "הוספה",
    CANCEL = "ביטול",
}

export enum UNPUBLISHED_DRAFT_POPUP {
    TITLE = "יש לך אירוע ששמרת ושכחת לפרסם",
    PREVIEW_BUTTON = "צפייה באירוע",
    DELETE_BUTTON = "מחיקת טיוטה"
}

export const PREVIEW = "תצוגה מקדימה"

export enum COUPON_POPUP {
    TITLE = 'הצגת קודי קופון',
    PURCHASE_TICKETS_AT_WEBSITE = 'רכישת הכרטיסים מתבצעת באתר המופע, יש להקליד את קודי הקופון במעמד התשלום',
    TO_EVENT_WEBSITE = 'מעבר לאתר המופע',
    COPY_COUPON = 'העתקת קוד קופון',
    COUPON_COPIED = 'הקופון הועתק'

}

export const ticketsNum = (tickets: number) => tickets === 1 ? 'כרטיס אחד' : `${tickets} כרטיסים`
