//this component gets props - AgeRangeCheckboxesProps
//it returns a list of checkboxes

import { useMediaQuery } from "@mui/material";
import { AgeRangeArrayItem, AgeRangeCheckboxesProps } from "../consts/interfaces/AgeRange.interface";
import { organization } from "../consts/enums/organization.enum";
import { FORM_FIELDS } from "../consts/variables";

import GenericCheckbox from './GenericCheckbox';
import { saveInLocalStorage } from "../functions/localStorage";

const AgeRangeCheckboxes: React.FC<AgeRangeCheckboxesProps> = ({ setAgeRange, setAgeRangeError, ageRange, saveDraft, error, name }) => {

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)
    const isIos = /iPhone|iPad|iPod|/i.test(navigator.userAgent);

    const handleCheck = (checkId: number) => {
        setAgeRange(prev => {
            const updated = prev.map((item: AgeRangeArrayItem) => {
                if (checkId + 1 === item.id) {
                    return { ...item, selected: !item.selected }
                }
                return item;

            }) as AgeRangeArrayItem[]

            updated.some(option => option.selected) ?
                setAgeRangeError("") : setAgeRangeError(organization.at_least_one_option)

            saveDraft && saveInLocalStorage(FORM_FIELDS.AGE_RANGE, updated)

            return updated;
        })
    }

    return (
        <div className={isIos && tabletMediaQuery ? "add-event-right-checkboxes-first-ios" : "add-event-right-checkboxes-first"}>
            <div className="input-title" style={{ fontWeight: "600", color: "#41596b", marginTop: "1vh", marginBottom: "vh(1)" }}>טווח גילאים: </div>
            <div className="inside-profile-ages">
                <div>
                    {ageRange.map((age, i) =>
                        (i < 4) &&
                        <GenericCheckbox
                            key={i}
                            id={i}
                            name={name && i === 0 ? name : ''}
                            title={age.rangeName}
                            className="question-checkbox"
                            handleChange={() => {
                                handleCheck(i)
                            }}
                            checked={age.selected}
                        />
                    )}
                </div>
                <div className="second-profile-ages">
                    {ageRange.map((age, i) =>
                        (i >= 4) &&
                        <GenericCheckbox
                            key={i}
                            id={i}
                            title={age.rangeName}
                            className="question-checkbox"
                            handleChange={() => {
                                handleCheck(i)
                            }}
                            checked={age.selected}
                        />
                    )}
                </div>
            </div>
            <div className="input-error">
                {error}
            </div>
        </div >
    )
}
export default AgeRangeCheckboxes;