import PlusMinusInput from '../PlusMinusInput';
import './style/seatGroupAccessibilityCount.scss';

interface CountSeatGroupProps {
    numOfTickets: number,
    text: string,
    imgUrl: string,
    count: number,
    setCount: React.Dispatch<React.SetStateAction<number>>
}
const SeatGroupAccessibilityCount: React.FC<CountSeatGroupProps> = ({ numOfTickets, text, imgUrl, count, setCount }) => {
    return (
        <div className='seatgroup-accessibility-count'>
            <PlusMinusInput value={count} setValue={setCount} maxValue={numOfTickets} />
            <img src={imgUrl} alt='accessibility icon' />
            {text}
        </div>
    )
}
export default SeatGroupAccessibilityCount;
