import { FC, useMemo, useState } from "react";
import { IconButton } from "@mui/material";

import { CategoryInputProps, categoryInputTitles } from "../consts/interfaces/Categories.interface";
import { ADD, EVENT_TYPE } from "../consts/hebrew";
import { ADD_EVENT_FIRST } from "../consts/enums/InputsNames";
import { FORM_FIELDS } from "../consts/variables";

import { selectAtLeastOneValidator } from "../functions/validations";
import { saveInLocalStorage } from "../functions/localStorage";

import GenericInput from "./GenericInput";
import PopupArrayCategory from "../popups/agency/PopupArrayCategory";
import PopupCategory from "../popups/agency/popup-category/PopupCategory";

import '../style/categoryInput.scss';

const CategoryInput: FC<CategoryInputProps> = ({ categories, setCategories, setCategoryError, categoryError, saveDraft, type = "category" }) => {

    const [categoryPopup, setCategoryPopup] = useState(false) // the status of the category popup 

    const inputTitle = useMemo(() => categoryInputTitles.hasOwnProperty(type) ? categoryInputTitles[type] : EVENT_TYPE, [type])

    const removeCategory = (categoryId: number) => {
        setCategories(prevCategories => {
            const newCategories = prevCategories.map(category => {
                if (category.id === categoryId) {
                    return { ...category, selected: !category.selected }
                } else {
                    return category
                }
            })
            const categoryVal = selectAtLeastOneValidator(newCategories);
            categoryVal && setCategoryError(categoryVal)
            saveDraft && saveInLocalStorage(FORM_FIELDS.CATEGORIES, newCategories)
            return newCategories
        })
    }

    return (
        <div className="input-container">
            <div className="input-title">{inputTitle}:</div>
            <div className="input categories-input" onClick={() => setCategoryPopup(true)} >
                <IconButton onClick={() => setCategoryPopup(true)} className='add-category-btn'>
                    <img src="/icons/open-circle.svg" alt="add" />
                </IconButton>
                {ADD} {inputTitle}
            </div>

            <div>
                {categories.filter(item => item.selected).map((category) =>
                    <PopupArrayCategory key={category.id}
                        deleteMySelf={() => removeCategory(category.id)}
                        title={category.categoryName}
                    />)}
            </div>

            <div className="input-error"> {categoryError} </div>
            <GenericInput name={ADD_EVENT_FIRST.CATEGORY} className="invisible-input" />
            <PopupCategory open={categoryPopup} handleClose={() => setCategoryPopup(false)} categories={categories} setCategories={setCategories} setCategoryError={setCategoryError} saveDraft={saveDraft} type={type} />
        </div>
    )
}

export default CategoryInput;