export const scrollToFirstError = (formRef: React.MutableRefObject<null>, firstInputRef: React.RefObject<HTMLInputElement>, errors: any) => {
    if (!formRef.current) return

    const firstErrorField = Object.keys(errors)[0];
    if (!firstErrorField && firstInputRef) {
        firstInputRef.current?.scrollIntoView({ block: "end", behavior: 'smooth' })
        return
    }

    const firstErrorElement: HTMLInputElement = formRef.current[firstErrorField];
    if (firstErrorElement) {
        firstErrorElement?.scrollIntoView({ block: "center", behavior: 'smooth' });
        setTimeout(() => {
            firstErrorElement.focus();
        }, 1000);
    }
}