//thank u for signing up as an organization page

import FeedBack from "../../../generic-components/GenericFeedback";

const WelcomeOrganization: React.FC = () => {

    document.title = 'אירועים קרובים'

    return (
        <FeedBack
            image={"/icons/date-icon.svg"}
            firstLine={<div>ברוכים הבאים</div>}
            secondLine={<div style={{ marginBottom: "6.5vh" }}>כמה טוב שנרשמתם</div>}
            lastLine={<div style={{ fontWeight: "normal", fontSize: "3vh", marginTop: "-3.7vh" }}>
                <div>ברגע שיהיו כרטיסים מתאימים לקהל</div>
                <div>היעד שלכם נעדכן אתכם בהודעה</div>
            </div>}
        />
    )
}

export default WelcomeOrganization;