//this component receives props- genericTags
//it returns a generic tags for an event (its used in event page under description)  
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import GenericToolTip from './GenericToolTip';
import { AVAILABLE_SEATS, GenericTagsEnum, NOT_INTERESTED_MAYBE_NEXT_TIME } from '../consts/hebrew';
import { GenericTagsInterface, Style } from '../consts/interfaces/GenericTags.interface';
import { turnArrToString } from '../functions/arrayUtils';

import '../style/genericTags.scss';

const GenericTags: React.FC<GenericTagsInterface> = (props) => {

    const isPhone = useMediaQuery('(max-width:768px)');
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [expandedDivHeight, setExpandedDivHeight] = useState<number>(0)
    const [style, setStyle] = useState<Style>()
    const time = useMemo(() => {
        if (!props.duration) return "";

        if (props.duration < 60) {
            return `${props.duration} ${GenericTagsEnum.MINUTES}`;
        }
        else {
            const hours = Math.floor(props.duration / 60);
            const minutes = props.duration % 60;

            return `${hours}:${(minutes < 10 ? '0' + minutes : minutes)} ${GenericTagsEnum.HOURS}`;
        }
    }, [props.duration])

    const expendedDiv = useRef<HTMLInputElement>(null); // ref for first input

    useEffect(() => {
        expendedDiv && expendedDiv.current && setExpandedDivHeight(expendedDiv.current.offsetHeight)
    }, [])

    useEffect(() => {
        isExpanded || props.disableReadMore ?
            setStyle({
                transition: 'height 0.3s ease-in-out',
                height: `${expandedDivHeight}px`,
                overflow: 'hidden'
            })
            :
            setStyle({
                transition: 'height 0.3s ease-in-out',
                height: "0",
                overflow: "hidden"
            })
    }, [expandedDivHeight, isExpanded, props.disableReadMore])

    return (
        <div className="generic-tags-container">
            {props?.vipDetails !== NOT_INTERESTED_MAYBE_NEXT_TIME ? <div className='tag-categories tag-vip-category'>
                <div className='icon-title-div'>
                    <img className='small-icons' src='/icons/star.svg' alt="vip" />
                    <p className='tag-vip-title'>{GenericTagsEnum.EVENT_VIP}:</p>
                </div>
                <div className='demographic-tag-div'>
                    {props.vipDetails}
                </div>
            </div> : null}

            <div className={`tag-categories`}>
                <div className='icon-title-div'>
                    <img className='small-icons' src='/icons/ticket-empty.svg' alt="" />
                    <p className='category-title' >{GenericTagsEnum.CATEGORY}</p>
                </div>
                <div className={`tag-div ${isPhone && 'phone-categories'}`}>
                    {(props.categories.length)
                        ? props.categories.map((category) =>
                            <div className='category-tags' key={category.id}>{category.categoryName}</div>
                        )
                        : <div className='accessibility-tags'>{GenericTagsEnum.NO_CHOSE_CATEGORY}</div>}
                </div>

            </div>
            <div className={`tag-categories ${props.accessibilities.length && 'baseline'} `} >
                <div className='icon-title-div'>
                    <img className='small-icons' src='/icons/accessibility.svg' alt="" />
                    <p className='category-title' >{GenericTagsEnum.ASSEBILITY}</p>
                </div>
                <div className='a-tag-div'>

                    {props.accessibilities.length ? props.accessibilities.map(({ accessibility, count }) => {
                        return (
                            <React.Fragment key={accessibility.id}>
                                <div className='an-access-type'>
                                    <img className='small-icons-separate' src={accessibility.icon} alt="" />
                                    <div className='accessibility-tags' >{accessibility.info}</div>
                                    <div className='accessibility-count'>{count} {AVAILABLE_SEATS}</div>
                                </div>
                            </React.Fragment>
                        )
                    }) :
                        <div className='an-access-type' >
                            <img className='small-icons-separate' src="/icons/accessibility/no-accessibility.svg" alt="regular chair" />
                            <div className='accessibility-tags' >{GenericTagsEnum.REGULAR_CHAIRS_ONLY}</div>
                        </div>
                    }

                </div>

            </div>
            <div className='tag-categories demographic-category'>
                <div className='icon-title-div'>
                    <img className='small-icons' src='/icons/people.svg' alt="population" />
                    <p className='category-title' >{GenericTagsEnum.INTENDED_FOR_SECTORS}</p>
                </div>
                <div className='a-tag-div demographic-tag-div'>
                    {turnArrToString(props.populations.map(item => item.populationName))}
                </div>
            </div>

            {props.isSeating !== undefined && props.isMarkedSeats !== undefined &&
                <div className='tag-categories seating-category'>
                    <div className='icon-title-div '>
                        <img className='small-icons' src='/icons/chairs.svg' alt="chair icon" />
                        <p className='category-title' >{GenericTagsEnum.TYPE_EVENT}</p>
                    </div>
                    <div className='a-tag-div seating-tag-div'>
                        {props.isSeating ?
                            GenericTagsEnum.SEAT :
                            GenericTagsEnum.STAND}

                        {props.isMarkedSeats ?
                            GenericTagsEnum.PLACE_MARKET :
                            GenericTagsEnum.PLACE_NOT_MARKET}
                    </div>
                </div>
            }

            <div style={style} ref={expendedDiv}>
                {
                    (!!props.duration) &&
                    <div className='tag-categories seating-category'>
                        <div className='icon-title-div '>
                            <img className='small-icons' src='/icons/clock.svg' alt="clock icon" />
                            <p className='category-title' >{GenericTagsEnum.TIME_OF_EVENT}</p>
                        </div>
                        <div className='a-tag-div seating-tag-div'>
                            {time}
                        </div>
                    </div>
                }
                <div className='tag-categories seating-category'>

                    <div className='icon-title-div '>
                        <img className='small-icons' src='/icons/bus.svg' alt="bus icon" />
                        <p className='category-title' >{GenericTagsEnum.WAY_COMEING}</p>
                    </div>
                    <div className='a-tag-div seating-tag-div'>
                        {props.navigation}
                    </div>
                </div>

                <div className='tag-categories seating-category'>

                    <div className='icon-title-div '>
                        <img className='small-icons' src='/icons/age-range.svg' alt="age icon" />
                        <p className='category-title' >{GenericTagsEnum.AGES}</p>
                    </div>
                    <div className='a-tag-div seating-tag-div'>
                        {turnArrToString(props.ageRanges.map(item => item.rangeName))}
                    </div>
                </div>


                {props.role === 'agency' && <div className='tag-categories price-tag'>
                    <div className='icon-title-div'>
                        <img className='small-icons' src='/icons/cash-money.svg' alt="money icon" />
                        <p className='category-title' >{GenericTagsEnum.MONY_TICKETS}</p>
                    </div>
                    <div className='a-tag-div seating-tag-div'>
                        ₪ {props.price}
                        <GenericToolTip title={GenericTagsEnum.PRICE_INFO}>
                            <span>
                                <img className='price-info' src='/icons/info.png' alt='information' />
                            </span>
                        </GenericToolTip>
                    </div>
                </div>}
            </div>

            {!props.disableReadMore &&
                <p className='read-more' onClick={() => setIsExpanded(prev => !prev)}>{isExpanded ? GenericTagsEnum.READ_LESS : GenericTagsEnum.READ_MORE}</p>
            }
        </div >

    )
}


export default GenericTags

