import '../style/trashCan.scss';
import FocusAble from './FocusAble';

const GenericTrashCan: React.FC<{ onClick: React.MouseEventHandler<HTMLDivElement> }> = ({ onClick }) => {

    return (
        <FocusAble className="trash-can-container" onClick={onClick}>
            <img
                className="top"
                src="/icons/trash-con-top.svg"
                alt="top of trash can"
            />
            <img
                className='bottom'
                src="/icons/trash-can-bottom.svg"
                alt="bottom of trash can"
            />
        </FocusAble>
    )
}

export default GenericTrashCan;