import { ARRIVING_TO_EVENT, ONE_ARRIVING_TO_EVENT, ONE_ORGANIZATION, ORDERED, ORDER_STATUS_SUMMERY, ORGANIZATIONS, TICKETS, VIEW_EVENT } from "../../../consts/hebrew";
import { AgencySwiperCoverProps } from "../../../consts/interfaces/SwipeBoard.interface";
import GenericButton from "../../../generic-components/GenericButton";
import './style/agencySwiperEventCover.scss';

const AgencySwiperEventCover: React.FC<AgencySwiperCoverProps> = ({ purchasedOrgsCount, ticketsPurchased, ticketsTotal }) => {
    return (
        <div className="event-order-details-container">
            {ORDER_STATUS_SUMMERY}
            <div className="order-details">
                {purchasedOrgsCount === 1 ?
                    <> <b>{ONE_ORGANIZATION}</b> {ONE_ARRIVING_TO_EVENT}</>
                    : <><b>{purchasedOrgsCount} {ORGANIZATIONS}</b> {ARRIVING_TO_EVENT}</>}
                <br />
                {ORDERED} <b>{ticketsPurchased}</b>/{ticketsTotal} <b>{TICKETS}</b>
            </div>
            <GenericButton className="yellow order-details-btn">
                {VIEW_EVENT}
            </GenericButton>
        </div>
    )
}

export default AgencySwiperEventCover;