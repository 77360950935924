import { ChangeEvent, FC, useEffect, useState } from "react";
import moment from "moment";
import { isToday } from "date-fns";

import { IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers";

import { NOTICE_POST_EVENT_HOURS_BEFORE_START, START_TIME } from "../../../consts/hebrew";
import { EventTimeInputProps } from "../../../consts/interfaces/Event.interface";
import { ADD_EVENT_FIRST } from "../../../consts/enums/InputsNames";
import { FORM_FIELDS } from "../../../consts/variables";

import { saveInLocalStorage } from "../../../functions/localStorage";
import { timeValidation } from "../../../functions/validations";
import { isDateToday } from "../../../functions/dateFunctions";

const EventTimeInput: FC<EventTimeInputProps> = ({ startValue, date, setStartValue, setStartTimeError, startTimeError, saveDraft }) => {

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)
    const today = new Date() // date of today

    const [openTime, setOpenTime] = useState(false); // date picker popup open state

    useEffect(() => {
        if (date && isToday(date.toDate())) {
            const newTime = moment({ h: today.getHours() + 6 })
            setStartValue(newTime);
            if (saveDraft) saveInLocalStorage(FORM_FIELDS.TIME, newTime);
        }
    }, [date])

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target
        if (moment(value, true).isValid()) setStartValue(moment(value))
        else if (/^\d{0,2}:\d{0,2}$/.test(value)) setStartValue(value)
    }

    return (
        <div className="input-container">
            <div className="input-title">{START_TIME}:</div>

            <div className='secondary-title event-time-notice'>
                {NOTICE_POST_EVENT_HOURS_BEFORE_START}
            </div>

            <TimePicker
                open={openTime}
                onOpen={() => setOpenTime(true)}
                onClose={() => setOpenTime(false)}
                onAccept={newValue => saveDraft && saveInLocalStorage(FORM_FIELDS.TIME, newValue)}
                DialogProps={{
                    PaperProps: {
                        sx: { "& *:focus": { outline: "none" } },
                    },
                }}
                desktopModeMediaQuery='@media (min-width: 768px)' // responsive design 
                value={startValue} // the value of the TimePicker
                minTime={!date || !isToday(date.toDate()) ? moment({ h: 0, m: 0 }) : moment({ h: today.getHours() + 5, m: today.getMinutes() })} // if the date input above is today limit the time to just the next hour!
                onChange={(newValue) => {// change the current value (startValue)
                    setStartValue(newValue);
                    setStartTimeError(timeValidation(newValue, isDateToday(date?.toDate()))) //every change, update the error message
                }}
                renderInput={(params: any) => (
                    <TextField
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => setStartTimeError(timeValidation(startValue, isDateToday(date?.toDate())))}
                        name={ADD_EVENT_FIRST.START_TIME}
                        {...params}
                        inputProps={{ ...params.inputProps, placeholder: 'hh:mm' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton sx={{ marginTop: tabletMediaQuery ? '1vh' : '0vh', fontSize: '1rem' }} onClick={() => setOpenTime(true)}>
                                        <AccessTime fontSize="inherit" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} className="input mui-input" />)}
            />
            <div className="add-event-errors-first">
                {startTimeError}
            </div>
        </div>
    )
}

export default EventTimeInput;