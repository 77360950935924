import { useHistory } from "react-router-dom";
import { LoginText } from '../../../consts/enums/login.enum';
import { TITLES } from "../../../consts/hebrew";
import GenericButton from "../../../generic-components/GenericButton";
import useIsMobile from "../../../consts/hooks/useIsMobile";

const LoginLeft: React.FC = () => {
    const history = useHistory();
    const isMobile = useIsMobile();

    return (
        <div className="login-left-content">
            <div>
                {!isMobile &&
                    <div className="login-logo">
                        <img src="/icons/ticket.svg" alt="" />
                    </div>}
                <div className="login-title">{TITLES.DONT_HAVE_ACOUNT}</div>
            </div>

            <div className="login-left-buttons">
                <GenericButton
                    className="yellow big"
                    handleClick={() => { history.push("/organization-signup") }}
                >
                    {LoginText.organization}
                </GenericButton>
                <GenericButton className="blue big" handleClick={() => { history.push("/agency-signup") }}>
                    {LoginText.agency}
                </GenericButton>
            </div>
        </div>
    )
}

export default LoginLeft;