import { FC, useState } from "react";

import { IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import EventIcon from '@mui/icons-material/Event';
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";

import { EventDateInputProps } from "../../../consts/interfaces/Event.interface";
import { ADD_EVENT_FIRST } from "../../../consts/enums/InputsNames";
import { FORM_FIELDS } from "../../../consts/variables";
import { EVENT_DATE } from "../../../consts/hebrew";

import { saveInLocalStorage } from "../../../functions/localStorage";
import { dateValidation } from "../../../functions/validations";


const EventDateInput: FC<EventDateInputProps> = ({ date, setDate, dateError, setDateError, saveDraft }) => {

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)

    const [openDate, setOpenDate] = useState(false); // time picker popup open state

    const currentTime = moment()
    const futureLimit = moment(currentTime.toDate().setFullYear(currentTime.toDate().getFullYear() + 2))

    // Determine if disable the date or not
    const disabledDates = (date: Moment) => {
        if (date.isBetween(currentTime, futureLimit)) return false
        if (date.isSame(currentTime, 'day')) return currentTime.hour() >= 18 // if the time is after 6PM we could not chose today because it too early
        return true
    };

    const minDate = moment();

    return (
        <div className="input-container">
            <div className="input-title">{EVENT_DATE}:</div>
            <DatePicker
                open={openDate}
                onOpen={() => setOpenDate(true)}
                onClose={() => setOpenDate(false)}
                onAccept={(newValue) => saveDraft && saveInLocalStorage(FORM_FIELDS.DATE, newValue)}
                inputFormat="DD.MM.yyyy"
                desktopModeMediaQuery='@media (min-width: 768px)' // for responsive design
                value={date} // the date picker value
                minDate={minDate}
                maxDate={futureLimit}
                views={['year', 'month', 'day']}
                shouldDisableDate={disabledDates}
                onChange={(newValue) => { // change the current value (date)
                    setDate(newValue);

                    setDateError(dateValidation(newValue, moment(new Date().setFullYear(new Date().getFullYear() + 3)))) //every change, update the error message
                }}
                renderInput={(params) => <TextField name={ADD_EVENT_FIRST.EVENT_DATE} {...params} InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton sx={{ marginTop: tabletMediaQuery ? '1vh' : '0vh', fontSize: '1rem' }} onClick={() => setOpenDate(true)}>
                                <EventIcon fontSize="inherit" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }} className="input mui-input" />}
            />
            <div className="add-event-errors-first">
                {dateError}
            </div>
        </div>
    )
}

export default EventDateInput;