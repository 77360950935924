//! REMEMBER TO CLEAN THIS COMPONENT FROM COMMENTS & UNUSED CODE
import { FC, useCallback, useRef } from "react";
import { FileInput, FileInputRefType, UploadedFile, UploadError } from "@hilma/fileshandler-client";

import { saveImageInLocalStorage } from "../functions/localStorage";
import { UploadEventPosterImage as UploadEventPosterImageProps } from "../consts/interfaces/UploadEventPosterImage.interface";
import { _FILE_LIMIT, _FILE_TOO_BIG, FORM_FIELDS, WRONG_FILE_TYPE } from "../consts/variables";
import { LOAD_IMAGE, POSTER_OF_THE_EVENT } from "../consts/hebrew";

import FocusAble from "./FocusAble";

const UploadEventPosterImage: FC<UploadEventPosterImageProps> = (props) => {

    const { fileName, fileError, setFileError, onChange, onDelete, filesUploader } = props;
    const fileInputRef = useRef<FileInputRefType>(null)

    const handleImageChange = (value: UploadedFile) => { // function that handle the image upload
        onChange(value)
        fileError && setFileError(""); // if there is an error in the file uploader, it will be removed
        if (props.saveDraft) saveImageInLocalStorage(value, FORM_FIELDS.POSTER)
    };

    const handleUploadError = (err: UploadError) => { // function that handles the error
        if (err.type === "wrong-type") {
            setFileError(WRONG_FILE_TYPE) // if the file is not an image - error message
        } else {
            setFileError(_FILE_TOO_BIG); // if the file is too big, an error will be shown
        }
    }

    const openFileInput = useCallback(() => {
        fileInputRef.current?.open()
    }, [])

    const handleDeletePosterImg = () => {
        onDelete()
    }

    return (
        <div className="input-container margin-top">
            <div className="poster-title-box">
                <div className="input-title">{POSTER_OF_THE_EVENT}:</div>
                {fileName
                    ? <div className="trash-can-container" onClick={handleDeletePosterImg}>
                        <img className="trash-can-top" src="/icons/trash-con-top.svg" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDeletePosterImg(); }} alt="top of trash can" />
                        <img className="trash-can-bottom" src="/icons/trash-can-bottom.svg" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDeletePosterImg(); }} alt="bottom of trash can" />
                    </div>
                    : null}
            </div>
            <div className='upload-event-poster-image'>
                <FocusAble className="upload-poster-box" onClick={openFileInput}>

                    <div className="upload-image-poster">
                        {!fileName && <img src="/icons/file_upload.svg" className="upload-arrow-image" alt="upload arrow" />}
                        <div>{!fileName ? LOAD_IMAGE : fileName}</div>
                    </div>

                </FocusAble>
                {/* <PopupCropPicture open={cropPopup} handleClose={() => setCropPopup(false)} setImageCoordinates={setImageCoordinates} ratio={_EVENT_IMAGE_RATIO} image={fileName} /> */}
                <FileInput
                    style={{ display: "none" }}
                    singleUpload
                    type="image"
                    sizeLimit={_FILE_LIMIT}
                    filesUploader={filesUploader}
                    placeholder={LOAD_IMAGE}
                    onChange={handleImageChange}
                    onError={handleUploadError}
                    ref={fileInputRef} />
                {fileName &&
                    <>
                        {/* <div>{fileName}</div>
                        <FocusAble className="edit-photo" onClick={() => setCropPopup(true)}>
                            <img src="/icons/edit.svg" alt="" />{EDIT_PHOTO}
                        </FocusAble> */}
                    </>
                }
                <div className="add-event-errors-first">
                    {fileError}
                </div>
            </div>
        </div>
    )
}

export default UploadEventPosterImage;