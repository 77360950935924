import { useHistory } from "react-router-dom";

import { AMOUNT, EVENTS, ONE_EVENT, ORG_NO_EVENTS_TITLE, TITLES } from "../../../consts/hebrew";
import { OrganizationSwiperEvent } from "../../../consts/interfaces/SwipeBoard.interface";

import OrganizationSwiperEventCover from "./OrganizationSwiperEventCover";
import EventSwiper from "../../../generic-components/event-swiper/EventSwiper";

import './style/organizationUpcomingEvents.scss';

interface OrganizationUpcomingEventsProps {
    eventArray: OrganizationSwiperEvent[]
}

const OrganizationUpcomingEvents: React.FC<OrganizationUpcomingEventsProps> = ({ eventArray }) => {
    const history = useHistory()

    const eventAmountText = eventArray.length === 1 ? ONE_EVENT : `${eventArray.length} ${EVENTS}`

    return (
        <div className="organization-upcoming-events">
            <div className="title">{TITLES.NEW_EVENT_OFFERS}</div>
            <div className="second-title">{AMOUNT}: {eventAmountText}</div>

            {eventArray.length === 0 ?
                <div className="no-events-container">
                    <img src="/icons/orange-light.svg" alt="" />
                    {ORG_NO_EVENTS_TITLE.WORKING_ON_NEW_OFFERS}
                    <br />
                    {ORG_NO_EVENTS_TITLE.MESSAGE_WHEN_OFFERS_ARRIVE}
                </div>
                :
                <EventSwiper
                    array={eventArray}
                    cover={OrganizationSwiperEventCover}
                    navigateToEventPage={eventId => history.push(`order-event/${eventId}`)}
                />}
        </div>
    )
}

export default OrganizationUpcomingEvents;