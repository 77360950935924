import { Switch, SwitchProps, styled } from "@mui/material";
import { genericSwitchProps } from "../consts/interfaces/GenericSwitch.interface";
import '../style/genericSwitch.scss'

const GenericSwitch: React.FC<genericSwitchProps> = ({ label, onChange, checked }) => {
    return (
        <div className="generic-switch-wrapper">
            <CustomizedSwitch onChange={onChange} checked={checked} />
            <div className="switch-label">{label}</div>
        </div>
    )
}
export default GenericSwitch

const CustomizedSwitch = styled((props: SwitchProps) => (
    <Switch
        {...props}
    />
))(({ theme }) => ({
    width: '2.5rem',
    height: '1.25rem',
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: '0.125rem .25rem',
        transitionDuration: '300ms',
        left: 'auto',
        '&.Mui-checked': {
            transform: 'translateX(-1rem)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#02CECB',
                opacity: 1,
                border: 1,
            }
        },
    },
    '& .MuiSwitch-thumb': {
        width: '1rem',
        height: '1rem',
    },
    '& .MuiSwitch-track': {
        borderRadius: '10vh',
        backgroundColor: '#00000019',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));