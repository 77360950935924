//Date convert functions when transferring from the client to server and the other way

import { isToday } from "date-fns";
import moment, { Moment } from "moment";

//self explanatory
export const convertToMilliseconds = (date: string, time: string) => {
        let DateFinal = new Date(date)
        let timeFinal = new Date(time)
        DateFinal.setHours(timeFinal.getHours(), timeFinal.getMinutes(), 0) // set date object with the correct time (HH:MM) from the timestamTime that send from input time
        return DateFinal.getTime()
}

//func used for when picking dates. makes sure u cant pick a date before today
export const disableTimes = (timeValue: number, clockType: "hours" | "minutes" | "seconds", date: Date | undefined): boolean => {
        if (date && isToday(date)) {
                if (clockType === "hours") {
                        return (new Date().getHours() + 1 > timeValue);
                }
        }
        return false;
}

//pretty much the same like before , to disable a time or date before now
export const minDateFunction = (date: Moment | null): Moment => {
        if (!date || !isToday(date.toDate())) return moment({ h: 0, m: 0 })
        const currentTime = new Date()
        return moment({ h: currentTime.getHours() + 1, m: currentTime.getMinutes() })
}

//returns day in hebrew lang (יום שישי) + date in "DD.MM.YYYY" format + time in "HH:mm" format
export function eventToDismantledDate(orignialDate?: string) {
        if (!orignialDate) {
                console.error('cant transform undefined to Date');
                return { day: '', date: '', time: '' }
        }
        const momentDate = moment(orignialDate);
        const day = "יום " + momentDate.format("dddd")
        const date = momentDate.format("DD.MM.YYYY");
        const time = momentDate.format("HH:mm");
        return { day, date, time };
}


/**
 * check if certain date is today?
 * @param date the certain date for comparing
 * @returns if its today it will return true otherwise it will return false
*/
export const isDateToday = (date: Date | undefined) => {
        const today = new Date()
        if (today.getDate() === date?.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()) {
                return true;
        } else {
                return false;
        }
}

export function getDay(stringDate: string): string {
        const date = new Date(stringDate)
        let week = ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"]
        const dayNum = date.getDay()
        return week[dayNum]
}

/**
 * @returns the date in DD.MM.YYYY format
 */
export const formatDate = (orignialDate: string | Date) => {
        const date = new Date(orignialDate)
        return date.toLocaleDateString("en-GB").replaceAll('/', '.')
}
