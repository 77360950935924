//this component gets props - eventInfoProps
//it returns a list of events that happened already
import SinglePastEvent from "./SinglePastEvent";
import { SwiperProps } from "../../../consts/interfaces/Event.interface";
import "./style/pastEvents.scss";

const PastEvents: React.FC<SwiperProps> = ({ eventsArray }) => {
    return (
        <div className="event-happened-item-container" >
            {eventsArray.map((event, index) => {
                return <SinglePastEvent key={index} event={event} />
            })}
        </div>
    )
}
export default PastEvents;
