import React from "react";
import { FORGOT_PUBLISH } from "../../consts/hebrew";
import GenericButton from "../../generic-components/GenericButton";
import GenericPopup from "../../generic-components/GenericPopup"

import "../style/forgotPublishEventPopup.scss";

interface ForgotPublishEventPopupProps {
    open: boolean;
    handleClose: () => void;
    onPublishEventClick: () => void;
    publishLoading: boolean;
}
const ForgotPublishEventPopup: React.FC<ForgotPublishEventPopupProps> = ({ open, handleClose, onPublishEventClick, publishLoading }) => {

    return (
        <GenericPopup className="forgot-publish-popup" open={open} handleClose={handleClose}>
            {FORGOT_PUBLISH.PAY_ATTENTION}

            <h3>{FORGOT_PUBLISH.FORGOT_PUBLISH}</h3>

            <GenericButton className="publish-event yellow small" handleClick={onPublishEventClick} serverLoading={publishLoading}>
                {FORGOT_PUBLISH.PUBLISH_EVENT}
            </GenericButton>
        </GenericPopup>
    )
}

export default ForgotPublishEventPopup;