//* COMPONENT TO REFACTOR
//organization page - shows all the derails of the organization and gives the option to update/edit it

import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Area } from "react-easy-crop";
import axios from "axios";
import { IconButton, Skeleton, useMediaQuery } from "@mui/material";

import { useFiles } from "@hilma/fileshandler-client";
import { useAsyncEffect } from "@hilma/tools";
import { useLogout } from "@hilma/auth-core";

import PopupArrayDemographic from "../../../popups/organization/PopupArrayDemographic";
import PopupDeleteContact from "../../../popups/organization/PopupDeleteContact";
import ProfileContact from "../../../generic-components/ProfileContact";
import AgeRangeCheckboxes from "../../../generic-components/AgeRangeCheckboxes";
import PopupDemo from "../../../popups/organization/PopupDemo";

import { MARK_IF_ORG_HAS_IS_FOLLOWING_POPULATIONS, MOBILITY_ACCESS_INFO, ORG_LOGO, TITLES, ORGANIZATION_SUSPENDED } from "../../../consts/hebrew";
import { PopulationArray, PopulationArrayServer } from "../../../consts/interfaces/PopulationArray.interface";
import { DemoInterface, DemoServerInterface } from "../../../consts/interfaces/Demographic.interface";
import { AgeRangeArrayItem, AgeRangeServer } from "../../../consts/interfaces/AgeRange.interface";
import { accessibilitiesArr } from "../../../consts/interfaces/organizationSignUp.interface";
import { OrganizationInfo } from "../../../consts/interfaces/Organization.interface";
import { contactErrors, orgProfileErrors } from '../../../consts/interfaces/formErrors.interface'
import { Accessibilities } from "../../../consts/enums/accessibilities.enum";
import GenericInputProps from "../../../consts/interfaces/GenericInputProps";
import { _ERROR_MESSAGE } from "../../../consts/variables";
import { Option } from "../../../consts/interfaces/RadioButton.interface";
import { organization } from "../../../consts/enums/organization.enum";
import { ORGANIZATION_PROFILE_LEFT } from "../../../consts/leftSideLayouts";
import { useExitBrowser } from "../../../consts/hooks/useExitBrowser";
import { Contact } from "../../../consts/interfaces/Contact.interface";
import { ORG_PROFILE } from "../../../consts/enums/InputsNames";

import { emailValidation, fileValidation, nameValidation, numOfPeopleValidation, phoneNumValidation, selectAtLeastOneValidator, textValidation } from "../../../functions/validations";
import { scrollToFirstError } from "../../../functions/scrollToError";
import { GoogleAnalyticsContext } from "../../../context/GoogleAnalytics";
import { ErrorContext } from "../../../context/ErrorContext";
import { UserContext } from "../../../context/UserContext";

import GenericProfilePicInput from "../../../generic-components/GenericProfilePicInput";
import GenericOvalClick from "../../../generic-components/GenericOvalClick";
import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import GenericCheckbox from "../../../generic-components/GenericCheckbox";
import GenericToolTip from "../../../generic-components/GenericToolTip";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import RadioButtons from "../../../generic-components/RadioButtons";
import GenericSwitch from "../../../generic-components/GenericSwitch";

import "./style/secondOrganizationSignup.scss";
import "../../../style/genericInput.scss";
import '../../../style/genericTags.scss';

interface accessServer {
    info: string,
    id: number,
    icon: string
}

const OrganizationProfile: React.FC = () => {
    const formRef = useRef(null);
    document.title = organization.edit_profile
    const { gaEvent, gaException } = useContext(GoogleAnalyticsContext)
    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)
    const history = useHistory();
    const firstInputRef = useRef<HTMLInputElement>(null);
    const [serverLoading, setServerLoading] = useState(false)
    const [firstLoading, setFirstLoading] = useState(true);
    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
    const { systemID, userName, setName, userId, refetch, setUserName, setEmail } = useContext(UserContext);
    const { showError } = useContext(ErrorContext)
    const filesUploader = useFiles();
    const logout = useLogout()
    useExitBrowser()

    const [errors, setErrors] = useState<orgProfileErrors>({});
    const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo[]>([])
    const [orgNameValue, setOrgNameValue] = useState<string>("");
    const [orgNameError, setOrgNameError] = useState<string>("");
    const [orgDescription, setOrgDescription] = useState<string>("");
    const [orgDescriptionError, setOrgDescriptionError] = useState<string>("");
    const [numPeople, setNumPeople] = useState<string>('"');
    const [numPeopleError, setNumPeopleError] = useState<string>("");
    const [ageRangeArray, setAgeRangeArray] = useState<AgeRangeArrayItem[]>([{ id: 1, rangeName: '', selected: true }]);//the array of age ranges and if they are selected or not
    const [ageRangeError, setAgeRangeError] = useState<string>("");
    const [demographics, setDemographics] = useState<DemoInterface[]>([])
    const [demographicsError, setDemographicsError] = useState<string>("")
    const [initialDemographics, setInitailDemographics] = useState<Array<DemoInterface>>([])
    const [populations, setPopulations] = useState<PopulationArray[]>([])
    const [populationError, setPopulationError] = useState<string>("")
    const [contacts, setContacts] = useState<Contact[]>([])
    const [isActive, setIsActive] = useState<boolean>(false)
    const [imageCoordinates, setImageCoordinates] = useState<Area>({ x: 0, y: 0, width: 100, height: 100 })

    const [fileError, setFileError] = useState<string>("");
    const [imageLink, setImageLink] = useState<string>(""); // the link of the uploaded image
    const [popupOpenDemo, setPopupOpenDemo] = useState(false) // the status of the category popup 
    const [popupTrashOpen, setPopupTrashOpen] = useState(false)
    const [accessibilities, setAccessibilities] = useState<accessibilitiesArr[]>([
        {
            title: '',
            id: 0,
            src: '',
            selected: false
        },
    ])
    const [accessibilityError, setAccessibilityError] = useState<boolean>(false)
    const [whoNeedsAccessibility, setWhoNeedsAccessibility] = useState<string>('')
    const [whoNeedsError, setWhoNeedsError] = useState<string>('')
    const [style, setStyle] = useState<any>()

    const whoNeedsAccessibilityArr: Option[] = [
        { label: Accessibilities.EVERYONE_NEEDS, value: Accessibilities.EVERYONE_NEEDS },
        { label: Accessibilities.PART_OF_THE_ORG, value: Accessibilities.PART_OF_THE_ORG },
        { label: Accessibilities.THERE_IS_NO_NEED_OF_ACCESSIBILITY, value: Accessibilities.THERE_IS_NO_NEED_OF_ACCESSIBILITY }
    ]

    // const type = useMemo(() => { //checks if agency or organization
    //     if (location.pathname.split('/')[1] === 'org-profile') {
    //         return 'organization'
    //     }
    //     return ''
    // }, [location]);

    // const systemId = Number(useParams().id)
    // const systemId = contact.

    //!TODO: I need to change the params to the specific organization id


    //closes and opens the accessibilities div according to the chosen whoNeedsAccessibility value
    useEffect(() => {
        whoNeedsAccessibility && whoNeedsAccessibility !== Accessibilities.THERE_IS_NO_NEED_OF_ACCESSIBILITY ?
            setStyle({
                transition: 'height 0.3s ease-in-out',
                height: `30vh`,
                overflow: 'hidden',
            })
            :
            setStyle({
                transition: 'height 0.3s ease-in-out',
                height: "0",
                overflow: "hidden",
            })
    }, [whoNeedsAccessibility])

    useEffect(() => {
        scrollToFirstError(formRef, firstInputRef, errors)
    }, [errors])

    //gets all organizations info from the database
    useAsyncEffect(async () => {
        if (systemID !== 0) {
            try {
                const orgInfo = await (await axios.get(`/api/organization/organization-info?id=${systemID}`)).data

                if (orgInfo.originalInfo[0].organization.participantNum !== null) setIsFirstTime(false);
                setOrganizationInfo(orgInfo.originalInfo);
                setIsActive(orgInfo.isActive);
                //sets the selected demographics in db to be true
                setInitailDemographics(orgInfo.demographics);
                const demoServer: DemoServerInterface[] = orgInfo.demographics;
                const demoOrgServer: DemoServerInterface[] = await (await axios.get(`/api/demographic/all-org?orgid=${systemID}`)).data;
                const demoArray = selectedDemoFromServer(demoOrgServer, demoServer)
                setDemographics(demoArray);

                const ageRangeOrg = await orgInfo.originalInfo[0].organization.ageRange;
                const ageArrayItems = await (await axios.get("/api/age-range/get-all-ages")).data;
                const newAgeRange = selectedAgeRangeFromServer(ageRangeOrg, ageArrayItems)
                setAgeRangeArray(newAgeRange)

                const initPopulation: PopulationArrayServer[] = orgInfo.populations
                setPopulations(initPopulation.map(population => {
                    const isSelected = orgInfo.originalInfo[0].organization.populations.some((selectedPop: PopulationArray) => selectedPop.id === population.id)
                    return { ...population, title: population.populationName, selected: isSelected }
                }))

                const recieverdCoordinates = orgInfo.originalInfo[0].organization.imageCoordinates
                recieverdCoordinates && setImageCoordinates(recieverdCoordinates)

                const orgAccess: accessServer[] = orgInfo.originalInfo[0].organization.accessibilities
                const accessibilitiesServer: accessServer[] = await (await axios.get('/api/accessibility/all')).data
                const newAccess: accessibilitiesArr[] = selectedAccessibilitiesFromServer(orgAccess, accessibilitiesServer)
                setWhoNeedsAccessByServer(newAccess)
                setAccessibilities(newAccess)

                setFirstLoading(false)
            } catch (error) {
                throw error;
            }
        }
    }, [systemID])

    useEffect(() => {
        if (whoNeedsAccessibility === Accessibilities.THERE_IS_NO_NEED_OF_ACCESSIBILITY) {
            setAccessibilities((prev) => {
                return prev.map((acc) => {
                    acc.selected = false;
                    return acc
                })
            })
        }
    }, [whoNeedsAccessibility])

    useEffect(() => {
        const accessibilityVal = checkAccessibilities()
        setAccessibilityError(!accessibilityVal)
    }, [accessibilities])

    useEffect(() => {
        const demogVal = selectAtLeastOneValidator(demographics)
        setDemographicsError(demogVal)
    }, [demographics])

    useEffect(() => {
        const populationVal = selectAtLeastOneValidator(populations)
        setPopulationError(populationVal)
    }, [populations])

    //sets starting value of contacts and accessibilities from values in database
    useEffect(() => {
        if (organizationInfo.length && initialDemographics.length) {

            setImageLink(organizationInfo[0].organization.profilePhoto)
            setOrgNameValue(organizationInfo[0].organization.organizationName)
            setOrgDescription(organizationInfo[0].organization.organizationDescription)
            setNumPeople(organizationInfo[0].organization.participantNum ?? '')
            organizationInfo.forEach((e) => {
                setContacts(prev => {
                    if (organizationInfo.length > prev.length) {
                        return [...prev, {
                            id: e.id,
                            name: e.name,
                            phone: e.username,
                            email: e.email,
                            nameError: "",
                            phoneError: "",
                            emailError: ""
                        }]
                    } else return prev
                })
            })
        }
    }, [organizationInfo, initialDemographics])

    const inputArr: Array<GenericInputProps> = useMemo(() => [
        {
            title: organization.name_of_organization,
            value: orgNameValue,
            name: ORG_PROFILE.ORG_NAME,
            error: orgNameError
        },
        {
            title: organization.organization_description,
            value: orgDescription,
            textarea: true,
            name: ORG_PROFILE.ORG_DESCRIPTION,
            characterLimit: 150,
            error: orgDescriptionError
        },
        {
            title: organization.number_of_apprentices,
            value: numPeople ?? "",
            name: ORG_PROFILE.NUM_PEOPLE,
            characterLimit: 5,
            autoFocus: false,
            type: 'number',
            hideCharacterLimit: true,
            error: numPeopleError
        }
    ], [orgNameValue, orgNameError, numPeople, numPeopleError, orgDescription, orgDescriptionError]);

    //sets the selected ageRange from server
    const selectedAgeRangeFromServer = (ageRangeOrg: AgeRangeServer[], ageArrayItems: AgeRangeServer[]) => {
        return ageArrayItems.map((item) => {
            const isSelected = ageRangeOrg.some((age) => age.id === item.id)
            return { ...item, selected: isSelected }
        })
    }

    //sets the selected demo from server
    const selectedDemoFromServer = (demoOrgServer: DemoServerInterface[], demoServer: DemoServerInterface[]) => {
        const demoArray = !demoOrgServer ? demoServer.map(el => ({ ...el, selected: false })) as DemoInterface[]
            :
            demoServer.map((item) => {
                const isSelected = demoOrgServer.some((demo) => demo.id === item.id)
                return { ...item, selected: isSelected }
            })
        return demoArray
    }

    //sets the relevant accessibilities to true by the info from db
    const selectedAccessibilitiesFromServer = (orgAccess: accessServer[], accessibilitiesServer: accessServer[]) => {
        const newAccess = accessibilitiesServer.map((access: accessServer) => {
            return {
                title: `${organization.happy_to_get_accessable_events}${access.info}`,
                id: access.id,
                selected: false,
                src: access.icon
            }
        })
        orgAccess.forEach((acc: accessServer) => {
            const index = newAccess.findIndex((accessibility: accessibilitiesArr) => accessibility.id === acc.id);
            if (index !== -1) {
                newAccess[index].selected = true
            }
        })
        return newAccess
    }

    const setWhoNeedsAccessByServer = (startAccessibilities: accessibilitiesArr[]) => {
        if (startAccessibilities.length > 1) {
            const amountOfAccess = startAccessibilities.filter((access) => (access.selected === true)).length;  //counts the selected startAccessibilities
            const noAccessIndex = startAccessibilities.findIndex((access) => (access.id === 4))
            const noAccessChosen = startAccessibilities[noAccessIndex].selected
            if (noAccessChosen) {
                if (amountOfAccess > 1) {
                    setWhoNeedsAccessibility(Accessibilities.PART_OF_THE_ORG)
                }
                else {
                    setWhoNeedsAccessibility(Accessibilities.THERE_IS_NO_NEED_OF_ACCESSIBILITY)
                }
            }
            else {
                setWhoNeedsAccessibility(Accessibilities.EVERYONE_NEEDS)
            }
        }
    }

    const checkContacts = () => {
        const validatedContacts = contacts.map((contact) => {
            const nameVal = nameValidation(contact.name)
            const phoneVal = phoneNumValidation(contact.phone)
            const emailVal = emailValidation(contact.email)
            return {
                ...contact,
                nameError: nameVal,
                phoneError: phoneVal,
                emailError: emailVal
            }
        })
        setContacts(validatedContacts)

        const contactErrors: contactErrors[] = validatedContacts.map((contact) => {
            const { nameError, emailError, phoneError } = contact
            const contactErrors: contactErrors = {}
            if (nameError) contactErrors.contactName = nameError
            if (phoneError) contactErrors.contactPhone = phoneError
            if (emailError) contactErrors.contactEmail = emailError
            return contactErrors
        })
        return contactErrors
    }

    const accessibilitiesForServer = () => {
        const isAccesRequired: Boolean[] = accessibilities.map((acc) => {
            return acc.selected
        })
        const noAccessIndex = accessibilities.findIndex((access) => (access.id === 4))
        if (whoNeedsAccessibility === Accessibilities.EVERYONE_NEEDS) {
            isAccesRequired[noAccessIndex] = false
        }
        else {
            isAccesRequired[noAccessIndex] = true
        }
        return isAccesRequired
    }

    const removeDemographic = (id: number) => {
        setDemographics(prev => prev.map(demographic => demographic.id === id ? { ...demographic, selected: false } : demographic))
    }

    const checkAccessibilities = () => {
        switch (whoNeedsAccessibility) {
            case Accessibilities.EVERYONE_NEEDS:
                return accessibilities.some(item => item.selected === true)
            case Accessibilities.THERE_IS_NO_NEED_OF_ACCESSIBILITY:
                return true
            case Accessibilities.PART_OF_THE_ORG:
                return accessibilities.filter((access) => (access.selected === true)).length > 0;
            default:
                return true
        }
    }

    //function that checks if all the inputs are valid and if they are moves on to the next page
    const submitForm = async () => {
        const orgNameVal = textValidation(orgNameValue);
        const orgDescriptionVal = textValidation(orgDescription)
        const numPeopleVal = numOfPeopleValidation(numPeople);
        const imageVal = fileValidation(fileError, imageLink);
        const contactsErrors: contactErrors = checkContacts()[0]
        const contactsVal = Object.keys(contactsErrors).length === 0
        const demogVal = selectAtLeastOneValidator(demographics)
        const populationVal = selectAtLeastOneValidator(populations)
        const whoNeedsAccessibilityVal = textValidation(whoNeedsAccessibility)
        const accessibilityVal = checkAccessibilities()
        const isOneInAgeRange = selectAtLeastOneValidator(ageRangeArray)
        const newOrgDemos = demographics.filter(demo => demo.selected).map(demo => ({ id: demo.id, demographicName: demo.demographicName }))
        //checks if edited 
        if (!orgNameVal && !orgDescriptionVal && !numPeopleVal && contactsVal && !isOneInAgeRange && imageLink !== "" && !demogVal && accessibilityVal && !whoNeedsAccessibilityVal && !imageVal && !populationVal) {
            const accessForServer: Boolean[] = accessibilitiesForServer()
            setServerLoading(true)
            try {
                // updates database with new values
                const ageRangeForServer = ageRangeArray.filter(age => age.selected).map(age => age.rangeName)
                await filesUploader.post('/api/organization/edit-organization', {
                    id: systemID,
                    organizationName: orgNameValue,
                    organizationDescription: orgDescription,
                    ageRange: ageRangeForServer,
                    participantNum: numPeople,
                    demographics: newOrgDemos,
                    accessibilities: accessForServer,
                    organizationActive: isActive,
                    contacts: contacts.map((contact) => {
                        return {
                            id: contact.id,
                            name: contact.name,
                            phone: contact.phone,
                            email: contact.email
                        }
                    }),
                    imageCoordinates,
                    populations: populations.filter(popu => popu.selected).map(popu => ({ id: popu.id, populationName: popu.title }))
                })

                setServerLoading(false)
                refetch()
                //sets the user context if the current user changed his details
                contacts.forEach((contact) => {
                    if (contact.id === userId) {
                        setName(contact.name)
                        setUserName(contact.phone)
                        setEmail(contact.email)
                    }
                })
                gaEvent('update_organization', {
                    event_category: 'organization',
                    event_label: 'update_organization'
                });
                history.push("/");
            } catch (error) {
                showError(_ERROR_MESSAGE)
                setServerLoading(false)
                gaException('edit_organization', error, true)
            }
        }
        else {
            let errors: orgProfileErrors = {}
            if (orgNameVal) errors.orgName = orgNameVal
            if (orgDescriptionVal) errors.orgDescription = orgDescriptionVal
            if (numPeopleVal) errors.numPeople = numPeopleVal
            if (isOneInAgeRange) errors.ageRange = isOneInAgeRange
            if (demogVal) errors.demo = demogVal
            if (populationVal) errors.population = populationVal
            if (!accessibilityVal) errors.accessibilty = accessibilityVal
            if (!contactsVal) {
                errors = { ...errors, ...contactsErrors }
            }

            setOrgNameError(orgNameVal);
            setOrgDescriptionError(orgDescriptionVal);
            setNumPeopleError(numPeopleVal);
            setWhoNeedsError(whoNeedsAccessibilityVal)
            setFileError(imageVal);
            setAgeRangeError(isOneInAgeRange);
            setDemographicsError(demogVal)
            setPopulationError(populationVal)
            setAccessibilityError(!accessibilityVal)
            setErrors(errors)
        }
    }

    const handleContactBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const coppiedContacts = [...contacts]
        switch (e.target.name) {
            case "name":
                coppiedContacts[index].nameError = nameValidation(e.target.value)
                break;
            case "phone":
                coppiedContacts[index].phoneError = phoneNumValidation(e.target.value)
                break;
            case "email":
                coppiedContacts[index].emailError = emailValidation(e.target.value)
                break;
            default:
                break;
        }
        setContacts(coppiedContacts)
    }

    //function that handles the onBlur
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!firstLoading) {
            switch (e.target.name) {
                case "orgName":
                    setOrgNameError(textValidation(e.target.value));
                    break;
                case "orgDescription":
                    setOrgDescriptionError(textValidation(e.target.value));
                    break;
                case "numPeople":
                    setNumPeopleError(numOfPeopleValidation(e.target.value))
                    break;
                default:
                    break;
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "orgName":
                setOrgNameValue(e.target.value);
                break;
            case "orgDescription":
                setOrgDescription(e.target.value);
                break;
            case "numPeople":
                setNumPeople(e.target.value);
                break;
            default:
                break;
        }
    }

    const handleContactChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const coppiedContacts = [...contacts]
        switch (e.target.name) {
            case "contactName":
                coppiedContacts[index].name = e.target.value
                break;
            case "contactPhone":
                coppiedContacts[index].phone = e.target.value
                break;
            case "contactEmail":
                coppiedContacts[index].email = e.target.value
                break;
            default:
                break;
        }
        setContacts(coppiedContacts)
    }

    const changeAccessibilities = (id: number) => {
        setAccessibilities(prev => {
            return prev?.map((acc, index) => {
                if (acc.id === id) {
                    return {
                        ...acc,
                        selected: !prev[index].selected
                    }
                } else return acc
            })
        })
    }

    //deletes contact
    const contactTrash = (index: number) => {
        let newContactArray = [...contacts]
        newContactArray.splice(index, 1);
        setContacts(newContactArray)
    }

    const DeleteSelfContact = async () => {
        try {
            await axios.delete(`/api/contact/delete-contact/?id=${userName}`)
            setPopupTrashOpen(false)
            logout()
        } catch (error) {
            throw error;
        }
    }

    const handleDemographic = () => { // function that handles the category
        setPopupOpenDemo(true)
    }

    const handleChangeSwitch = () => {
        setIsActive(prev => !prev)
    }


    return (
        <>
            {firstLoading ?
                <GenericSplitPage serverLoading={serverLoading}>
                    <div className="loading" style={{ width: tabletMediaQuery ? "80vw" : "35vw" }}>
                        <Skeleton height={"12vh"} width="75%" sx={{ mt: '7vh', mr: '16vh', mb: '2vh', marginRight: "-1vw" }} />
                        <Skeleton className='circle' height="0" width="20%" variant="circular" sx={{ marginRight: "-1vw", paddingTop: "20%" }} />
                        <div>
                            <Skeleton height={"8vh"} width="100%" sx={{ mt: '4vh', mr: '16vh', mb: '2vh', marginRight: "-1vw" }} />
                            <Skeleton height={"18vh"} width="100%" sx={{ mt: '4vh', mr: '16vh', mb: '2vh', marginRight: "-1vw" }} />
                            <Skeleton height={"8vh"} width="100%" sx={{ mt: '4vh', mr: '16vh', mb: '2vh', marginRight: "-1vw" }} />
                        </div>
                        <div style={{ marginTop: "4vh", display: "flex" }}>
                            <Skeleton height={"6vh"} width={"10%"} />
                            <Skeleton height={"6vh"} width={"10%"} sx={{ marginRight: "15vw" }} />
                        </div>
                        <div style={{ marginTop: "4vh", display: "flex" }}>
                            <Skeleton height={"6vh"} width={"10%"} />
                            <Skeleton height={"6vh"} width={"10%"} sx={{ marginRight: "15vw" }} />
                        </div>
                        <div style={{ marginTop: "4vh", display: "flex" }}>
                            <Skeleton height={"6vh"} width={"10%"} />
                            <Skeleton height={"6vh"} width={"10%"} sx={{ marginRight: "15vw" }} />
                        </div>
                        <div style={{ marginTop: "4vh", display: "flex" }}>
                            <Skeleton height={"6vh"} width={"10%"} />
                        </div>
                    </div>
                </GenericSplitPage>
                :
                <GenericSplitPage serverLoading={serverLoading} squares={ORGANIZATION_PROFILE_LEFT}>
                    <div className="input-container">
                        <div>
                            <form ref={formRef}>
                                <div className="right-input-inputs sosu-right-inputs">
                                    <div className="blabla">
                                        <div className={`right-input-title`} ref={firstInputRef}>
                                            {!isFirstTime ? TITLES.UPDATE_PROFILE_ORG : TITLES.HELP_US_TO_KNOW_YOU}
                                        </div>
                                    </div>
                                    <GenericProfilePicInput
                                        title={ORG_LOGO}
                                        imageCoordinates={imageCoordinates}
                                        setImageCoordinates={setImageCoordinates}
                                        filesUploader={filesUploader}
                                        imageLink={imageLink}
                                        setImageLink={setImageLink}
                                        imageError={fileError}
                                    />

                                    {inputArr.map((object, i) => {
                                        return (<GenericInput key={i} {...object} onChange={handleInputChange} onBlur={handleInputBlur} />
                                        )
                                    })}

                                    <AgeRangeCheckboxes ageRange={ageRangeArray} name={ORG_PROFILE.AGE_RANGE} setAgeRange={setAgeRangeArray} setAgeRangeError={setAgeRangeError} />
                                    <div className="bottom-text-container">
                                        <div className="input-error">
                                            {ageRangeError}
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '2vh' }}>
                                        <div className="input-container">
                                            <div className="input-title">{organization.audience}</div>
                                            <div style={{ marginBottom: '1vh' }} className="secondary-title">{organization.weak_populations_only}</div>

                                            <div className="special-input-container">
                                                <div className="special-input input" onClick={handleDemographic} /*onChange={(e) => { setActivity(e.target.value); setAdded(true); }}*/>
                                                    <IconButton onClick={handleDemographic}>
                                                        <img style={{ width: '3vh' }} src="/icons/open-circle.svg" alt="add" />
                                                    </IconButton>
                                                    {organization.add_audience}
                                                </div >
                                                <div className="selected-categories">
                                                    {demographics && demographics.filter(item => item.selected).map((demographic) => <PopupArrayDemographic id={demographic.id} key={demographic.id} title={demographic.demographicName} removeMyself={removeDemographic} />)}
                                                </div>
                                            </div >
                                            <GenericInput name={ORG_PROFILE.DEMO} className="invisible-input" />
                                            {demographicsError &&
                                                <div className="bottom-text-container">
                                                    <div className="input-error">{organization.at_least_one_audience}</div>
                                                </div>}
                                        </div >
                                    </div >
                                </div >

                                <div className="input-container">
                                    <div className="input-title">{MARK_IF_ORG_HAS_IS_FOLLOWING_POPULATIONS}</div>
                                    <GenericOvalClick options={populations} setOptions={setPopulations} />
                                    <div className="bottom-text-container ">
                                        <div className="input-error">
                                            {populationError}
                                        </div>
                                    </div>
                                </div>

                                <div className="input-container" >
                                    <div className="input-title" style={{ direction: "rtl" }}>{organization.who_needs_accessibility_in_your_org}</div>
                                    <RadioButtons
                                        options={whoNeedsAccessibilityArr}
                                        handleChange={(e: React.FormEvent<HTMLInputElement>) => setWhoNeedsAccessibility(e.currentTarget.value)}
                                        value={whoNeedsAccessibility}
                                    />
                                    {whoNeedsError &&
                                        <div className="bottom-text-container">
                                            <div className="input-error">{organization.have_to_fill_filed}</div>
                                        </div>}
                                </div>
                                {/* <div style={{ overflow: 'hidden', transition: 'height 4s ease-in-out' }}> */}
                                {/* {whoNeedsAccessibility && whoNeedsAccessibility !== Accessibilities.THERE_IS_NO_NEED_OF_ACCESSIBILITY && */}
                                <div className="input-container" style={style}>
                                    <div className="input-title" style={{ direction: "rtl" }}>{organization.what_are_the_required_accessibilities}</div>
                                    {accessibilities.length !== 0 && accessibilities.map((accessibility, index) => {
                                        return (
                                            accessibility.id !== 4 &&  //doesn't present the regular seats option
                                            <div key={index} style={{ display: "flex" }}>
                                                <GenericCheckbox
                                                    key={index}
                                                    name={ORG_PROFILE.ACCESSIBILITY}
                                                    id={`access${accessibility.id}`}
                                                    title={accessibility.title}
                                                    className="question-checkbox"
                                                    src={"/icons" + accessibility.src}
                                                    handleChange={() => changeAccessibilities(index + 1)}
                                                    checked={accessibility.selected} />
                                                {accessibility.id === 3 &&
                                                    <div className='info-mobility'>
                                                        <GenericToolTip title={MOBILITY_ACCESS_INFO} name="generic tags tooltip">
                                                            <img className='info-icon' src='/icons/info.png' alt="info on demographic" />
                                                        </GenericToolTip>
                                                    </div>
                                                }
                                            </div>)
                                    })}
                                    {whoNeedsAccessibility && accessibilityError &&
                                        <div className="bottom-text-container">
                                            <div className="input-error">
                                                {organization.at_least_one_option}
                                            </div>
                                        </div>}
                                </div>
                                {/* </div> */}
                                <div className="right-input-inputs">
                                    <div className="right-input-side-title">{organization.who_we_will_update}</div>
                                    <div style={{ position: "relative" }}>
                                        <>{contacts.map((contact, index) => {
                                            return (
                                                <div key={index}>
                                                    {index >= organizationInfo.length &&
                                                        < div className="trash-can-container" style={{ position: "relative" }}>
                                                            <img style={{ position: "absolute", left: 0, top: "1vh", width: "2.5vh" }} className="trash-can-top" src="icons/trash-con-top.svg" onClick={() => { contactTrash(index) }} alt="top of trash can" />
                                                            <img style={{ position: "absolute", left: 0, top: "2vh", width: "2.5vh" }} src="icons/trash-can-bottom.svg" onClick={() => { contactTrash(index) }} alt="bottom of trash can" />
                                                        </div>
                                                    }
                                                    {index < organizationInfo.length && userName === contact.phone && organizationInfo.length !== 1 &&
                                                        <div className="trash-can-container" style={{ position: "relative" }}>
                                                            <img style={{ position: "absolute", left: 0, top: "1vh", width: "2.5vh" }} className="trash-can-top" src="icons/trash-con-top.svg" onClick={() => { setPopupTrashOpen(true) }} alt="top of trash can" />
                                                            <img style={{ position: "absolute", left: 0, top: "2vh", width: "2.5vh" }} src="icons/trash-can-bottom.svg" onClick={() => { setPopupTrashOpen(true) }} alt="bottom of trash can" />
                                                            <PopupDeleteContact open={popupTrashOpen} handleClose={() => { setPopupTrashOpen(false) }} handleDelete={DeleteSelfContact} />
                                                        </div>
                                                    }

                                                    <ProfileContact
                                                        {...contact}
                                                        setName={(e) => handleContactChange(e, index)}
                                                        setPhone={(e) => handleContactChange(e, index)}
                                                        setEmail={(e) => handleContactChange(e, index)}
                                                        setNameError={(e) => handleContactBlur(e, index)}
                                                        setPhoneError={(e) => handleContactBlur(e, index)}
                                                        setEmailError={(e) => handleContactBlur(e, index)}
                                                    />
                                                    {index !== contacts.length - 1 && (<div style={{ height: "4vh" }}></div>)}
                                                </div>
                                            )
                                        })}
                                        </>
                                        {/* {!isDisabled ? <GenericButton disabled={isDisabled} className="right-blue-btn blue" handleClick={() => { if (contacts.length < 4) { setContacts([...contacts, { name: "", phone: "", email: "", nameError: "", phoneError: "", emailError: "" }]) } }} >הוסף איש קשר +</GenericButton> : ""} */}
                                    </div>
                                    {/* <div>sdf</div> */}

                                    <div className="suspendOrganization">
                                        <GenericSwitch
                                            checked={!isActive}
                                            onChange={handleChangeSwitch}
                                            label={ORGANIZATION_SUSPENDED}
                                        />
                                        <div className="secondary-title">{organization.organizaion_is_unActive}</div>
                                    </div>
                                </div>
                            </form>
                            <PopupDemo open={popupOpenDemo} handleClose={() => { setPopupOpenDemo(false) }} demo={demographics} setDemo={setDemographics} setDemoError={setDemographicsError} />
                            <div className="right-yellow-btn-container"><GenericButton className="right-yellow-btn" serverLoading={serverLoading} handleClick={submitForm} >{!isFirstTime ? organization.update : organization.save}</GenericButton></div>
                        </div >
                    </div >
                </GenericSplitPage >
            }
        </>
    )
}

export default OrganizationProfile;


