import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NOT_RELEVANT } from "../../../consts/enums/notRelevant.enum";
import FeedBack from "../../../generic-components/GenericFeedback";

const NotRelevantFeedbackPage: React.FC = () => {
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push('/')
        }, 5000)
    }, [])

    return (
        <FeedBack
            image={"/icons/present.svg"}
            firstLine={<p className="feedback-first-line">{NOT_RELEVANT.THANKS_FOR_FEEDBACK}</p>}
            secondLine={""}
            lastLine={<p className="feedback-last-line">{NOT_RELEVANT.EVENT_WAS_PASSED}</p>}
        />
    )
}

export default NotRelevantFeedbackPage;