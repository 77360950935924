import { FileInput, FileInputRefType, UploadError, UploadedFile } from "@hilma/fileshandler-client";
import { FC, useCallback, useRef, useState } from "react";
import { Area } from "react-easy-crop";

import { FORM_FIELDS, WRONG_FILE_TYPE, _EVENT_IMAGE_RATIO, _FILE_LIMIT, _FILE_TOO_BIG } from "../consts/variables";
import { DELETE_PHOTO, EDIT_PHOTO, LOAD_IMAGE, PHOTO_OR_GRAPHICS_OF_EVENT } from "../consts/hebrew";
import { UploadEventImageProps } from "../consts/interfaces/UploadEventImage.interface";
import useImageCoordinates from "../consts/hooks/useImageCoordinates";

import { saveImageInLocalStorage, saveInLocalStorage } from "../functions/localStorage";
import GenericToolTip from "./GenericToolTip";
import FocusAble from "./FocusAble";
import PopupCropPicture from "../popups/general/PopupCropPicture";

import '../style/uploadEventImage.scss';

const UploadEventImage: FC<UploadEventImageProps> = ({ name, src, imageCoordinates, setImageCoordinates, fileError, setFileError, onChange, filesUploader, saveDraft, deleteCoverImage, title }) => {

    const fileInputRef = useRef<FileInputRefType>(null)

    const imageCoordinatesStyle = useImageCoordinates(imageCoordinates)

    const [cropPopup, setCropPopup] = useState<boolean>(false)
    const [imgFileValue, setImgFileValue] = useState<UploadedFile>();

    const handleImageChange = (value: UploadedFile) => { // function that handle the image upload
        setImgFileValue(value)
        setCropPopup(true)

        if (saveDraft) saveImageInLocalStorage(value, FORM_FIELDS.IMAGE)
    };

    const saveImgAfterConfirmCrop = (image: string) => {
        if (imgFileValue) {
            onChange(imgFileValue)
            saveImageInLocalStorage(imgFileValue, FORM_FIELDS.IMAGE)
            fileError && setFileError("");
        }
    }

    const handleUploadError = (err: UploadError) => { // function that handles the error
        if (err.type === "wrong-type") {
            setFileError(WRONG_FILE_TYPE) // if the file is not an image - error message
        } else {
            setFileError(_FILE_TOO_BIG); // if the file is too big, an error will be shown
        }
    }

    const openFileInput = useCallback(() => {
        fileInputRef.current?.open()
    }, [])

    const handleImageCoordinatesChange = (value: React.SetStateAction<Area>) => {
        setImageCoordinates(value)
        saveDraft && saveInLocalStorage(FORM_FIELDS.IMAGE_COORDINATES, value)
    }

    const deleteImage = () => {
        setImgFileValue(undefined)
        deleteCoverImage()
    }

    return (
        <div className="input-container margin-top">
            <div className="input-title">{title || PHOTO_OR_GRAPHICS_OF_EVENT}:</div>
            <div className='upload-event-image'>
                <FocusAble className="upload-box" onClick={openFileInput}>
                    {!src ?
                        <div className="upload-image">
                            <img src="/icons/file_upload.svg" className="upload-arrow-image" alt="upload arrow" />
                            <div>{LOAD_IMAGE}</div>
                        </div> :
                        <img style={imageCoordinatesStyle} src={src} className="selected-image" alt="event-cover" />
                    }
                </FocusAble>
                <PopupCropPicture open={cropPopup} handleClose={() => setCropPopup(false)} setImageCoordinates={handleImageCoordinatesChange} ratio={_EVENT_IMAGE_RATIO} image={imgFileValue?.link || src} setCropPopup={setCropPopup} saveImgAfterConfirmCrop={saveImgAfterConfirmCrop} />
                <FileInput
                    name={name}
                    style={{ display: "none" }}
                    singleUpload
                    type="image"
                    sizeLimit={_FILE_LIMIT}
                    filesUploader={filesUploader}
                    placeholder={LOAD_IMAGE}
                    onChange={handleImageChange}
                    onError={handleUploadError}
                    ref={fileInputRef} />

                {
                    src &&
                    <div className="edit-delete-box">
                        <GenericToolTip name='edit-photo' title={EDIT_PHOTO}>
                            <FocusAble className="edit-photo-cordinates" onClick={() => setCropPopup(true)}>
                                <img className="edit-icon" src="/icons/edit.svg" alt="edit icon" />
                            </FocusAble>
                        </GenericToolTip>
                        <GenericToolTip name='delete-photo' title={DELETE_PHOTO}>
                            <FocusAble className="delete-photo" onClick={deleteImage}>
                                <img className="delete-icon" src="/icons/trash-can.svg" alt="delete icon" />
                            </FocusAble>
                        </GenericToolTip>
                    </div>
                }
                <div className="input-error">
                    {fileError}
                </div>
            </div >
        </div >
    )
}

export default UploadEventImage;