import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import useIsMobile from "../../consts/hooks/useIsMobile";
import { PopupCouponProps } from "../../consts/interfaces/PopupCoupon.interface";
import { COUPON_POPUP, ticketsNum } from "../../consts/hebrew";
import GenericPopup from "../../generic-components/GenericPopup";
import GenericWrapper from "../../generic-components/GenericWrapper";
import GenericToolTip from "../../generic-components/GenericToolTip";

import '../../style/popupCoupon.scss'

const PopupCoupon: React.FC<PopupCouponProps> = ({ coupons, type, filePath, eventId, orgId, open, handleClose }) => {
    const [copiedIndex, setCopiedIndex] = useState<number | null>(null)
    const [serverLoading, setServerLoading] = useState(false)
    const history = useHistory()
    const isMobile = useIsMobile()

    const copy = (couponCode: string, index: number) => {
        navigator.clipboard.writeText(couponCode).then(function () {
        }, function () {
        });
        setCopiedIndex(index)
    }

    useEffect(() => {
        if (copiedIndex !== null) {
            const removeCheck = setTimeout(() => {
                setCopiedIndex(null);
            }, 4000);

            return () => clearTimeout(removeCheck);
        }
    }, [copiedIndex]);

    //function that opens the link in a new tab
    const windowOpen = (url: string) => {
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        return window.open(url, '_blank');
    }

    //function that assigns the tickets with the coupon code that the have been purchased by a specific org
    const Order = async () => {
        try {
            setServerLoading(true)
            if (type === "ordered") {
                windowOpen(filePath);
            }
            else {
                await axios.post('/api/ticket/order-ticket-with-link', {
                    eventId: eventId,
                    organizationId: orgId,
                })

                history.push('/future-ordered-events');
            }
            setServerLoading(false)
        } catch (error) {
            setServerLoading(false)
            throw error;
        }

    }

    return (
        <GenericPopup
            button={{
                text: COUPON_POPUP.TO_EVENT_WEBSITE, handleClick: Order, serverLoading: serverLoading, className: 'yellow big'
            }}
            open={open}
            title={COUPON_POPUP.TITLE}
            handleClose={handleClose}
            className="coupon"
        >
            <>
                <div className="description">{COUPON_POPUP.PURCHASE_TICKETS_AT_WEBSITE}</div>
                {coupons?.map((coupon, index) => (
                    <GenericWrapper>
                        <div className="coupon-container" >
                            <div className="right-container">
                                <div>{ticketsNum(coupon.seatNum)}</div>
                                <div >{coupon.coupon}</div>
                            </div>
                            <div className='copy-column'>
                                {copiedIndex !== index ?
                                    <div className='copy-coupon'>
                                        <GenericToolTip title={COUPON_POPUP.COPY_COUPON}>
                                            <div onClick={() => copy(coupon.coupon, index)}>
                                                <img className="coupon-copy-icon" src='/icons/duplicate.svg' alt='copy' />
                                            </div>
                                        </GenericToolTip>
                                    </div> :
                                    <img className="coupon-copy-icon check-icon" src='/icons/Check.svg' alt="coupon copied" />
                                }
                                {!isMobile && copiedIndex === index && <div className="copied-text">{COUPON_POPUP.COUPON_COPIED}</div>}

                            </div>
                        </div>
                    </GenericWrapper>
                ))}
            </>
        </GenericPopup >
    );
}

export default PopupCoupon;