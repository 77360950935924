import { useHistory } from "react-router-dom";
import { Skeleton, useMediaQuery } from "@mui/material";
import clsx from "clsx";

import GenericButton from "./GenericButton";
import EventSwiper from "./event-swiper/EventSwiper";
import SingleComingEvent from "./SingleComingEvent"

import { EventSwiperElementType } from "../consts/interfaces/SwipeBoard.interface";
import { OrderEvents } from '../consts/enums/events.enum';
import { NO_FUTURE_PURCHASED_EVENTS } from "../consts/hebrew";

import '../style/futurePurchasedEvents.scss'

interface FuturePurchasedEventsProps {
    futurePurchasedArray: EventSwiperElementType[];
    serverLoading?: boolean;
    shortArray?: boolean;
    className?: string;
    isFutureEventsPage?: boolean;
}

const FuturePurchasedEvents: React.FC<FuturePurchasedEventsProps> = ({ futurePurchasedArray, serverLoading, shortArray, className, isFutureEventsPage }) => {
    const history = useHistory()
    const tabletMediaQuery = useMediaQuery('(max-width: 768px)');

    const eventsToPresent = tabletMediaQuery || !shortArray ? futurePurchasedArray : futurePurchasedArray?.slice(0, 4)

    const eventAmountText = futurePurchasedArray.length === 1 ? OrderEvents.one_event : `${futurePurchasedArray.length} ${OrderEvents.events}`

    return (
        <>
            {!serverLoading ?
                <>
                    {futurePurchasedArray.length > 0 ?
                        <div className={clsx("future-events", className)}>
                            <div className="title">{OrderEvents.soon_events}</div>
                            <div className="info-about-all-events" >
                                <p className="second-title">{OrderEvents.amount} {eventAmountText}</p>
                                {!tabletMediaQuery && shortArray && futurePurchasedArray.length > 4 ?
                                    <GenericButton className="large show-events"
                                        handleClick={() => { history.push('/future-ordered-events') }}>
                                        <div className="items-in-button">
                                            {OrderEvents.show_all_events}
                                            <img src='/icons/arrow-partial.svg' alt='arrow' />
                                        </div>
                                    </GenericButton>
                                    : null
                                }
                            </div>

                            {tabletMediaQuery && !isFutureEventsPage ?
                                <>
                                    <EventSwiper
                                        array={eventsToPresent}
                                        navigateToEventPage={eventId => history.push(`/download-tickets/${eventId}`)}
                                    />
                                    {shortArray && futurePurchasedArray.length > 2 ?
                                        <GenericButton className="large show-events"
                                            handleClick={() => { history.push('/future-ordered-events') }}>
                                            <div className="items-in-button">
                                                {OrderEvents.show_soon_events}
                                                <img src='/icons/arrow-partial.svg' alt='arrow' />
                                            </div>
                                        </GenericButton>
                                        : null
                                    }
                                </>
                                :
                                <div className="ordered-events-list">
                                    {eventsToPresent.map((event, index) =>
                                        <SingleComingEvent
                                            key={index}
                                            element={event}
                                            navigateToEventPage={eventId => history.push(`/download-tickets/${eventId}`)}
                                        />
                                    )}
                                </div>
                            }
                        </div >
                        : isFutureEventsPage ?
                            <div className={clsx("future-events", className)}>
                                <div className="title">{OrderEvents.soon_events}</div>
                                <div className="no-events"> {NO_FUTURE_PURCHASED_EVENTS} </div>
                            </div>
                            : null}
                </>
                : <div className={shortArray ? 'skeleton-home-page' : 'skeleton-events-page'}>
                    <Skeleton className="skeleton-title" />
                    <div className="skeleton-cards-container">
                        <Skeleton className="skeleton-card" />
                        <Skeleton className="skeleton-card" />
                        <Skeleton className="skeleton-card" />
                        <Skeleton className="skeleton-card" />
                    </div>
                </div>
            }
        </ >
    )
}

export default FuturePurchasedEvents