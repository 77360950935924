//Server error message popup

import React, { useCallback, useContext, useState } from "react";
import { ClosePopUpSettings, ShowErrorSettingsInterface } from "../consts/interfaces/ErrorContext.interface";
import { _ERROR_MESSAGE } from "../consts/variables";
import ErrorMessageModal from "../generic-components/ErrorMessageModal";

const CLOSING_DELAY = 200;
const DEFAULT_TIME_POPUP = 5000
const WINDOW_EVENTS = [
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

export const ErrorContext = React.createContext({ showError: (_message?: string, settings?: ShowErrorSettingsInterface) => { } });

const ErrorProvider: React.FC = ({ children }) => {

    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState(_ERROR_MESSAGE)
    const [disableExitButton, setDisableExitButton] = useState(false)

    function closePopUp(CloseSettings?: ClosePopUpSettings) {
        CloseSettings?.inDelay ? // only if the settings of closeSettings.inDelay is true
            setTimeout(() => {
                setOpen(false) // close pop-up
            }, CLOSING_DELAY) //close in delay
            : setOpen(false)


    }
    function openPopUp() { setOpen(true) }


    const showError = useCallback((message?: string, settings?: ShowErrorSettingsInterface): void => {
        message && setMessage(message)
        setDisableExitButton(Boolean(settings?.disableExitButton))
        settings?.error && console.error(settings?.error)
        openPopUp()
        settings?.stayUntilClick // if UntilClick is true wait for interaction from the user to close the popup
            ?
            WINDOW_EVENTS.forEach(event => window.addEventListener(event, () => closePopUp({ inDelay: true })))
            :
            setTimeout(() => closePopUp(), DEFAULT_TIME_POPUP)
    }, [])

    return (
        <ErrorContext.Provider
            value={{
                showError
            }}
        >
            {children}
            <ErrorMessageModal open={open} setOpen={setOpen} message={message} disableExitButton={disableExitButton} />
        </ErrorContext.Provider>
    );

}
export const useError = () => useContext(ErrorContext)
export default ErrorProvider

