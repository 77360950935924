import { useEffect, useRef, useState } from "react";

export const useFormattedLeftTime = (isActive: boolean, timeEnd: () => void, whenTimeEnd: string) => {
    const [displayCounterTime, setDisplayCounterTime] = useState("");
    const [leftTimeForProgressBar, setLeftTimeForProgressBar] = useState<number>(0);
    const timerRef: { current: NodeJS.Timeout | null } = useRef(null);

    useEffect(() => {
        if (!isActive || !whenTimeEnd) return;
        timerRef.current = setInterval(countDown, 1000);
        return () => clearInterval(timerRef.current as NodeJS.Timeout);
    }, [whenTimeEnd]);

    const countDown = () => {
        const newTimeInSeconds = calculateRemainingTime();
        setLeftTimeForProgressBar(newTimeInSeconds);
        setDisplayCounterTime(buildTimeString(newTimeInSeconds));

        if (newTimeInSeconds <= 0) {
            clearInterval(timerRef.current as NodeJS.Timeout);
            timeEnd();
        }
    };

    const calculateRemainingTime = () => {
        const now = new Date().getTime();
        const endTime = new Date(whenTimeEnd.split(',')[0].replace(/-/g, '/')).getTime();
        const milliseconds = endTime - now;
        return milliseconds / 1000;
    };

    const formatTime = (value: number) => (value >= 10 ? value.toString() : `0${value}`);

    const buildTimeString = (timeInSeconds: number) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
    };

    return {
        displayCounterTime,
        leftTimeForProgressBar
    }
}