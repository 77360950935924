import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import axios from 'axios';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { useFormattedLeftTime } from '../../consts/hooks/useFormattedLeftTime';
import { FooterProps } from '../../consts/interfaces/Footer.interface';
import { FooterTypes, TicketStatus } from '../../consts/variables';
import { ButtonsText, EVENT_PREVIEW, ErrorMessage } from '../../consts/hebrew';
import useIsMobile from '../../consts/hooks/useIsMobile';

import { GoogleAnalyticsContext } from '../../context/GoogleAnalytics';
import { AddNewEventContext } from '../../context/AddNewEventContext';
import { UserContext } from '../../context/UserContext';
import { useError } from '../../context/ErrorContext';

import PopupAccompanyingGuide from '../../popups/organization/PopupAccompanyingGuide';
import { downloadFile } from '../../functions/download-server-file';
import GenericButton from '../GenericButton';
import PopupDeleteEvent from '../../popups/agency/PopupDeleteEvent';
import PopupPurchasedEvent from '../../popups/organization/PopupPurchasedEvent';
import PopupSaveTickets from '../../popups/organization/PopupSaveTickets';
import PopupDuplicateEvent from '../../popups/agency/PopupDuplicateEvent';
import PopupCoupon from '../../popups/organization/PopupCoupon';
import AgencyFooterIcons from './AgencyFooterIcons';
import TimeSlider from './TimeSlider';
import EventDropDown from '../EventDropDown';

import './style/footer.scss';

const Footer: React.FC<FooterProps> = ({ tickets, linkCouponOrPdf, footerType, eventType, seatGroups, selectedUser, setEventType, purchaseTickets, updateTickets, eventId }) => {
  const { systemID } = useContext(UserContext);
  const addNewEventCx = useContext(AddNewEventContext)

  const history = useHistory();
  const isMobile = useIsMobile()
  const [textOrangeButton, setTextOrangeButton] = useState<string | ReactJSXElement>("");
  const [textBlueButton, setTextBlueButton] = useState<string | ReactJSXElement>("")
  const [couponPopup, setCouponPopup] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [duplicatePopup, setDuplicatePopup] = useState<boolean>(false);
  const [saveTicketsPopup, setSaveTicketsPopup] = useState<boolean>(false);
  const [accompanyingGuidePopup, setAccompanyingGuidePopup] = useState<boolean>(false);
  const [eventPurchasedPopup, setEventPurchasedPopup] = useState<boolean>(false);
  const [canAddTime, setCanAddTime] = useState<boolean>(true)
  const [showAddTimeButton, setShowAddTimeButton] = useState<boolean>(false);
  const [serverLoading, setServerLoading] = useState<boolean>(false)
  const [timeExpiredOfFirstTicket, setTimeExpiredOfFirstTicket] = useState<string>('')
  const { gaEvent } = useContext(GoogleAnalyticsContext)
  const [flashingButton, setFlashingButton] = useState<boolean>(false)
  const { showError } = useError()

  const isPossibleToEdit = (!tickets.some(ticket => ticket.status === TicketStatus.Purchased))

  useEffect(() => {
    setTimeExpiredOfFirstTicket(tickets.filter(ticket => ticket.status === TicketStatus.Offered)[0]?.timeExpired)
  }, [tickets])

  useEffect(() => {
    setButtonsTextForEachPage()
  }, [footerType, eventType, isMobile]);

  useEffect(() => {
    if (footerType !== FooterTypes.EVENT_PREVIEW) return

    const interval = setInterval(() => {
      setFlashingButton(prevFlashingButton => !prevFlashingButton);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleDownloadPDF = useCallback(async () => {
    try {
      const singleFile = linkCouponOrPdf?.length === 1
      linkCouponOrPdf?.forEach((file, index) => downloadFile(file.filePath, `ticket${singleFile ? '' : '-' + (index + 1) + '.pdf'}`))
    }
    catch (error) {
      showError(ErrorMessage.DOWNLOAD)
    }
  }, [showError, linkCouponOrPdf])

  const setButtonsTextForEachPage = () => {
    switch (footerType) {
      case FooterTypes.TIME:
        setTextOrangeButton(isMobile ? ButtonsText.InterestedShort : ButtonsText.Interested);
        setTextBlueButton(ButtonsText.NotRelevant);
        break;
      case FooterTypes.VIEW_ORDER:
        if (!seatGroups) break;
        else if (eventType === 'coupon' && seatGroups.length === 1)
          setTextOrangeButton(ButtonsText.ShowSingleCoupon);
        else if (eventType === 'coupon' && (seatGroups.length > 1))
          setTextOrangeButton(ButtonsText.ShowMultipleCoupons);
        else
          setTextOrangeButton(ButtonsText.DownloadTicketPDF);
        break;
      case FooterTypes.EDIT_EVENT:
        setTextOrangeButton(ButtonsText.AddTickets);
        !isMobile ? setTextBlueButton(ButtonsText.EditEvent) : setTextBlueButton('')
        break;
      case FooterTypes.BENEFIT:
        setTextOrangeButton(ButtonsText.OrderTickets)
        break;
      case FooterTypes.EVENT_PREVIEW:
        setTextOrangeButton(ButtonsText.PublishEvent)
        setTextBlueButton(ButtonsText.BackToEdit)
        break;
    }
  }

  const checkIfAbleToAddTime = async () => {
    const hoursToAdd: number = await (await axios.get(`/api/ticket/is-able-to-add-time?organizationId=${systemID}&eventId=${eventId}`)).data
    if (hoursToAdd === 0) setCanAddTime(false)
  }

  const handleOrangeButtonClick = async () => {
    switch (footerType) {
      case FooterTypes.EDIT_EVENT:
        if (textOrangeButton === ButtonsText.AddTickets) {
          history.push(`/event/add-tickets/${eventId}`)
        }
        break;
      case FooterTypes.VIEW_ORDER:
        if (textOrangeButton === ButtonsText.ShowSingleCoupon || textOrangeButton === ButtonsText.ShowMultipleCoupons)
          setCouponPopup(prev => !prev);
        else if (textOrangeButton === ButtonsText.DownloadTicketPDF)
          handleDownloadPDF();
        break;
      case FooterTypes.TIME:
        if (textOrangeButton === ButtonsText.Interested || textOrangeButton === ButtonsText.InterestedShort) {
          checkIfAbleToAddTime()
          setSaveTicketsPopup(true);
          setTimeout(() => {
            setShowAddTimeButton(true)
          }, 1500);
        }
        else if (textOrangeButton === ButtonsText.OrderTickets) {
          setAccompanyingGuidePopup(true);
          setEventType("purchase");
          setTextOrangeButton(ButtonsText.Purchase);
          setTextBlueButton("");
        }
        break;
      case FooterTypes.PURCHASE:
        const res = await purchaseTickets();
        if (!res) return;
        setEventPurchasedPopup(true)
        break;
      case FooterTypes.COUPON:
        setCouponPopup(prev => !prev)
        break;
      case FooterTypes.EVENT_PREVIEW:
        if (addNewEventCx) {
          const { publishNewEvent } = addNewEventCx
          publishNewEvent()
        }
        else {
          history.push('/event/add')
        }
    }
  }

  const handleBlueButtonClick = async () => {
    if (footerType === FooterTypes.TIME) {
      handleNotRelevantClick()
    }
    else if (footerType === FooterTypes.EDIT_EVENT) {
      history.push(`/edit-event/${eventId}`)
    }
    else if (footerType === FooterTypes.EVENT_PREVIEW) {
      history.push('/event/add')
    }
  }

  const handleNotRelevantClick = async () => {
    try {
      setServerLoading(true)
      await axios.post('/api/ticket/tickets-not-relevant', {
        eventId: eventId,
        organizationId: systemID,
      })
      history.push(`/not-relevant/${eventId}`);
      gaEvent('event_not_relevant', {
        event_category: 'event',
        event_label: 'not_relevant'
      })
      setServerLoading(false)
    } catch (error) { setServerLoading(false) }
  }

  const handleNeedTimeClick = async () => {
    setShowAddTimeButton(false)
    try {
      const res = await axios.put(`/api/ticket/add-time?organizationId=${systemID}&eventId=${eventId}`)
      if (res.data === "can't add time") {
        showError("תאריך האירוע קרוב ולכן אין אפשות לקבל תוספת זמן");
      }
      else {
        updateTickets(res.data);
      }
    } catch (error) { }
  }

  //function that sends the tickets to a new org after the timer expired
  const handleTimeExpired = async () => {
    history.push('/')
  }

  const saveTickets = () => {
    setTextOrangeButton(ButtonsText.OrderTickets);
    setSaveTicketsPopup(false)
  }

  const counterForSliderData = useFormattedLeftTime(footerType === FooterTypes.TIME, handleTimeExpired, timeExpiredOfFirstTicket)

  return (
    <footer>
      <div className={clsx("footer", footerType === FooterTypes.EDIT_EVENT && 'edit-footer')}>
        <div className='left-buttons-container' >
          {(!textBlueButton) || textBlueButton === ButtonsText.EditEvent && !isPossibleToEdit ? null
            : <GenericButton
              serverLoading={footerType === FooterTypes.TIME && serverLoading}
              handleClick={handleBlueButtonClick}
              className={clsx("button-in-footer", "small", "blue-button", { "special-button": textOrangeButton === ButtonsText.OrderTickets })}
            >
              <div className='items-in-button'>
                {textBlueButton === ButtonsText.EditEvent || textBlueButton === ButtonsText.BackToEdit ? <img className='action-icon' alt="edit event icon" src="/icons/edit.svg" /> : undefined}
                {textBlueButton}
              </div>
            </GenericButton>}

          <GenericButton
            handleClick={handleOrangeButtonClick}
            serverLoading={footerType === FooterTypes.EVENT_PREVIEW && addNewEventCx?.isPublishLoading}
            className={clsx("button-in-footer", "small", "orange-button", flashingButton && 'flashing-button')}
          >
            <div className='items-in-button'>
              {footerType === FooterTypes.EDIT_EVENT ? <img className='action-icon' alt="add tickets" src="/icons/add-tickets.svg" /> : undefined}
              {textOrangeButton}
            </div>
          </GenericButton>
        </div>

        {
          footerType === FooterTypes.EVENT_PREVIEW && !isMobile &&
          <div className='right-text'>{EVENT_PREVIEW}</div>
        }
        {footerType !== FooterTypes.EDIT_EVENT ? undefined :
          isMobile ?
            <EventDropDown eventId={eventId} ableToEdit={isPossibleToEdit} disableAddTickets /> :
            <AgencyFooterIcons setDeletePopup={setDeletePopup} setDuplicatePopup={setDuplicatePopup} />
        }

        {(footerType === FooterTypes.TIME) && counterForSliderData && timeExpiredOfFirstTicket ?
          <TimeSlider displayCounterTime={counterForSliderData.displayCounterTime}
            leftTimeForProgressBar={counterForSliderData.leftTimeForProgressBar}
            duration={JSON.parse(timeExpiredOfFirstTicket.split(',')[1])}
            showAddTimeButton={showAddTimeButton && canAddTime}
            handleAddTime={handleNeedTimeClick} />
          : null}
      </div>
      {
        footerType === FooterTypes.EDIT_EVENT ?
          <PopupDuplicateEvent open={duplicatePopup} eventId={eventId} /> : null
      }
      {
        footerType === FooterTypes.EDIT_EVENT ?
          <PopupDeleteEvent eventId={eventId} open={deletePopup} handleClose={() => { setDeletePopup(false) }} /> : null
      }
      {
        footerType === FooterTypes.TIME && counterForSliderData !== undefined ?
          <PopupSaveTickets open={saveTicketsPopup} timer={counterForSliderData.displayCounterTime} handleClose={saveTickets} /> : null
      }
      {
        footerType === FooterTypes.TIME ?
          <PopupAccompanyingGuide open={accompanyingGuidePopup} handleClose={() => setAccompanyingGuidePopup(false)} /> : null
      }
      {
        footerType === FooterTypes.PURCHASE ?
          <PopupPurchasedEvent open={eventPurchasedPopup} handleClose={() => setEventPurchasedPopup(false)}
            phone={selectedUser?.username} email={selectedUser?.email} name={selectedUser?.name} eventId={eventId} /> : null
      }

      {
        (footerType === FooterTypes.VIEW_ORDER && eventType === 'coupon' && linkCouponOrPdf) ?
          <PopupCoupon
            open={couponPopup}
            coupons={linkCouponOrPdf}
            filePath={linkCouponOrPdf[0].filePath}
            handleClose={() => setCouponPopup(false)}
            eventId={eventId}
            orgId={systemID}
            type={"ordered"}
          /> : null
      }
    </footer >
  );
}

export default Footer;