import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import GenericToolTip from './GenericToolTip'

const GenericInfoToolTip: React.FC<{ children: ReactJSXElement }> = ({ children }) => {
    return (
        <div>
            <GenericToolTip title={children} notBold>
                <img className='info-icon' src='/icons/info.png' />
            </GenericToolTip>
        </div>
    )
}

export default GenericInfoToolTip
