import { useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import { GenericGoBackProps } from "../consts/interfaces/GenericGoBack.interface";
import "../style/genericGoBack.scss";

const GenericGoBack: React.FC<GenericGoBackProps> = ({ isFeedback, goTo, text }) => {

    const history = useHistory();

    return (
        <div className={`go-back ${isFeedback && "with-background"}`} style={{ marginBottom: "unset" }}>
            <div className="all-go-back" onClick={() => history.push(goTo || '/')}>
                <IconButton className='arrow-img-go-back'>
                    <img className='img-go-back' src='/icons/arrow.svg' alt='back' />
                </IconButton>
                <div className='go-back-text'>{text}</div>
            </div>
        </div>
    )
}
export default GenericGoBack;