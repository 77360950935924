import { BenefitSeatGroup } from "./entranceTicketsTypes";
import { organization as organizationEnum } from "./enums/organization.enum";
import { Contact } from "./interfaces/Contact.interface";
import SeatGroup from "./interfaces/SeatGroup.interface";
import { Coupons } from "./interfaces/TicketsCouponMethod.interface";

export const _ERROR_MESSAGE = 'אירעה שגיאה בטעינת הנתונים';
export const _ERROR_DATE_PAST = 'התאריך שבחרת כבר עבר'
export const _ERROR_DATE_TOO_CLOSE = 'התאריך שבחרת קרוב מידיי'
export const _ERROR_DATE_DURING_WEEKEND = 'לא ניתן להעלות אירוע לסופ"ש בהתראה קצרה'

export const _ERROR_SEND_MESSAGE_ = 'אירעה שגיאה בשמירת הנתונים';
export const _NORMAL_TEXT_MAX_LENGTH = 255;
export const _LONG_TEXT_MAX_LENGTH = 500;
export const ERROR_EVENT_TOO_SOON = 'האירוע קרוב מדי'

export const _FILE_LIMIT = 5000
export const _FILE_TOO_BIG = '5MB קובץ גדול מדי. אנא העלו קובץ עד'
export const WRONG_FILE_TYPE = 'סוג קובץ שגוי. ניתן להעלות רק תמונות';
export const _NOT_AUTHENTICATED = 'אירעה שגיאה בזיהוי המשתמש';

export enum LoginError {
    CONTACT_NOT_FOUND = "User doesn't exist",
    UNAUTHORIZED = "Unauthorized login",
    ORGANIZATION_NOT_APPROVED = "organization-yet-approved",
    AGENCY_NOT_APPROVED = "agency-yet-approved",
    USER_IS_NOT_ACTIVE = "user-not-active",
    USER_IS_BLOCKED = "User is blocked",
}

export const DATE_TOO_CLOSE = 'date is not in the valid date range'
export const DATE_DURING_WEEKEND = 'date is during this weekend'
export const _SEAT_GROUP: SeatGroup = {
    numSeats: undefined,
    numSeatsError: "",
    firstAttempt: true,
    ticketFilePDF: "",
    ticketFilePDFError: "אנא העלו קובץ עד 3MB",
    wheelchairAccessible: 0,
    mobilityDifficultiesAccessible: 0,
    sumOfAccessTicketsError: ''
};
export const COUPONS: Coupons = {
    code: '',
    numSeats: ''
};
export const _EVENT_IMAGE_RATIO: number = 3;
export const LIMIT_ERROR = "אנא העלו קובץ עד 3MB"
export const INVALID_FILE_TYPE = "סוג הקובץ אינו תקין";
export const TERMS_ERROR = 'על מנת להמשיך את הרישום חובה לאשר את תנאי השימוש';
export const PHONE_NUMBER_EXISTS_ERROR = 'מספר הטלפון שהזנת קיים במערכת';
export const EMAIL_EXISTS_ERROR = 'כתובת האימייל שהזנת קיימת במערכת';
export const INVALID_EMAIL_ERROR = "אימייל לא תקין"
export const GIVING_AGENCIES = [
    "גופי תרבות וספורט ירושלמיים",
    "מוסדות תרבות",
    "אגודות ומתקני ספורט",
    "מחלקות עירוניות",
    "חברות בין עירוניות",
    "מינהלים קהילתיים"
]
export const PLEASE_UPLOAD_PDF_FILE = 'נא להעלות קובץ PDF';
export const FILL_THIS_FIELD = 'יש למלא שדה זה';
export const SELECT_AT_LEAST_ONE_OPTION = 'יש לבחור לפחות אופציה אחת';
export const PLEASE_UPLOAD_PDF_FILE_UP_TO_3MB = "אנא העלו קובץ עד 3MB";
export const ENTER_EMAIL_ADDRESS = 'Enter.jerusalem@gmail.com'

//array of left side elements
export const leftSideList = [
    { text: organizationEnum.approval_for_conducting, img: "/icons/docs.svg", alt: "documents-icon" },
    { text: organizationEnum.social_organization, img: "/icons/hands.svg", alt: "hand-icon" }
]

export enum SEATING_OR_STANDING {
    SEATING = 'seating',
    STANDING = 'standing'
}
export enum IS_MARKED {
    MARKED = 'marked',
    NOT_MARKED = 'not marked'
}

export enum ARE_ADJANCENT {
    ADJACENT = 'adjacent',
    NOT_ADJACENT = 'not adjacent'
}

export enum TICKETS_METHOD {
    PDF = 'pdf',
    COUPON = 'coupon'
}

export const EMPTY_CONTACT = (): Contact => ({ id: "", name: "", phone: "", email: "", nameError: "", phoneError: "", emailError: "" })
export const HALL_USED_ERROR = "לא ניתן למחוק את האולם מכיוון שהוא נמצא בשימוש ע\"י האירוע:";
export const HEARING_AIDS_ERROR = "לא ניתן לתת יותר אביזרי שמע ממספר הכרטיסים";

export const EMPTY_BENEFIT_SEATGROUP: BenefitSeatGroup = { ticketNum: "", couponCode: "" }

export enum FORM_FIELDS {
    NAME = "eventName",
    DATE = "date",
    TIME = "time",
    DURATION = "duration",
    DESCRIPTION = "description",
    AGE_RANGE = "ageRange",
    CATEGORIES = "categories",
    POPULATION = "population",
    IMAGE = "image",
    IMAGE_COORDINATES = "imageCoordinates",
    POSTER = 'poster',
    ADDRESS = "address",
    NAVIGATION = "navigation",
    HALL_INDEX = "hallIndex",
    HALL_NAME = "hallName",
    CURRENT_CONTACT = "currentContact",
    AGENCY_CONTACTS = "agencyContacts",
    TICKETS_COUNT = "ticketCount",
    TICKET_PRICE = "ticketPrice",
    IS_SEATING = "itSeating",
    ARE_ADJACENT = "areAdjacent",
    MARKED_SEATS = "markedSeats",
    TICKET_METHOD = "ticketMethod",
    PFDS = "pfds",
    COUPONS = "coupons",
    WEB_LINK = "webLink",
    SEAT_GROUPS = "seatGroups",
    HEARING_AIDS = "hearingAids",
    VIP = "vip",
    IS_SAVED = "isSaved"
}
export enum ORGANIZATION_TYPES {
    ORGANIZATION = "עמותה",
    MUNICIPALITY_DEPARTMENT = "אגף עירייה"
}

export const SEAT_GROUP_SUM_ERROR = "יש לוודא שסכום הכרטיסים אינו עולה על סכום הכרטיסים שהוענקו";
export const REQUIRED_FIELD_ERROR = "יש למלא שדה זה";

export enum TicketStatus {
    Unassigned = "unassigned",
    Offered = "offered",
    Purchased = "purchased",
    Created = "created",
    ExtraTime = "extraTime",
    Utilized = "utilized",
}

export enum FooterTypes {
    EDIT_EVENT = 'edit',
    TIME = 'time',
    VIEW_ORDER = 'ordered',
    BENEFIT = 'benefit',
    PURCHASE = 'purchase',
    COUPON = 'coupon',
    EVENT_PREVIEW = 'preview'
}