import { GenericLeftSideLayoutProps } from "./interfaces/GenericLeftSideLayout.interface"

const blue = "#95C1CE"
const orange = "#EDA424"
const yellow = "#FFE082"

export const ORGANIZATION_SIGNUP_LEFT = [
    { color: orange },
    { icon: "/icons/present.svg", flex: 1.8 },
    { color: yellow, flex: 0.8 },
    { icon: "/icons/docs.svg", flex: 1.3 },
    { color: blue },
    { icon: "/icons/heart-hand.svg" },
]

export const ADD_EVENT_LEFT = [
    { color: orange },
    { icon: "/icons/ticket.svg", flex: 1.8 },
    { color: yellow, flex: 0.8 },
    { icon: "/icons/docs.svg", flex: 1.3 },
    { color: blue },
    { icon: "/icons/hands.svg" },
]

export const NOT_RELEVANT_LEFT = [
    { color: yellow, flex: 2 },
    { icon: "/icons/hands.svg", flex: 1.7 },
    { color: blue },
    { icon: "/icons/ticket.svg" },
    { color: orange, flex: 1.1 },
    { icon: "/icons/blue-light.svg" },
]

export const CANCEL_EVENT_LEFT = [
    { color: yellow, flex: 2 },
    { icon: "/icons/docs.svg", flex: 1.7 },
    { color: blue },
    { icon: "/icons/ticket.svg" },
    { color: orange, flex: 1.1 },
    { icon: "/icons/blue-light.svg" },
]

export const ORGANIZATION_PROFILE_LEFT = [
    { color: yellow, flex: 2 },
    { icon: "/icons/hands.svg", flex: 1.7 },
    { color: blue },
    { icon: "/icons/docs.svg" },
    { color: orange, flex: 1.1 },
    { icon: "/icons/blue-light.svg" },
]

export const ADD_BENEFIT_TICKETS_LEFT = [
    { color: yellow, flex: 2 },
    { icon: "/icons/heart-hand.svg", flex: 1.7 },
    { color: blue },
    { icon: "/icons/docs.svg" },
    { color: orange, flex: 1.1 },
    { icon: "/icons/blue-light.svg" },
]

export const AGENCY_PROFILE_LEFT = [
    { color: yellow, flex: 2 },
    { icon: "/icons/heart-hand.svg", flex: 1.7 },
    { color: blue },
    { icon: "/icons/docs.svg" },
    { color: orange, flex: 1.1 },
    { icon: "/icons/blue-light.svg" },
]

export const AGENCY_SIGNUP_LEFT = [
    { color: blue },
    { text: "מתרגשים שאתם מצטרפים למיזם המיוחד הזה!", flex: 1.1 },
    { color: orange, flex: 1.6 },
    { icon: "/icons/docs.svg" },
    { color: yellow, flex: 1.1 },
    { icon: "/icons/hands.svg", flex: 0.6 },
]

export const CONTACT_US_LEFT = [
    { color: blue },
    { text: "נשמח לשמוע מכם!", flex: 1.1 },
    { color: orange, flex: 1.6 },
    { icon: "/icons/blue-plane.svg" },
    { color: yellow, flex: 1.1 },
    { text: "מוזמנים ליצור קשר גם במייל שלנו: Enter.jerusalem@gmail.com", flex: 0.6 },
]

export const ORGANIZATION_FEEDBACK_PAGE_LEFT = [
    { color: blue, flex: 2 },
    { icon: "/icons/present.svg", flex: 1.7 },
    { color: orange },
    { icon: "/icons/heart-hand.svg" },
    { color: yellow, flex: 1.1 },
    { icon: "/icons/hands.svg" },
]

export const AGENCY_HOME_LEFT = (donationWorth: number): GenericLeftSideLayoutProps["squares"] => [
    { color: blue },
    { flex: 1.4, text: ["שווי התרומה שלי במיזם", { text: `${donationWorth} ₪`, big: true }] },
    { icon: "/icons/present.svg" },
    { color: orange, flex: 0.9 }
]

export const RECEIVED_FEEDBACKS_HOME_LEFT = (events: number, organizations: number, tickets: number, feedbacks: number): GenericLeftSideLayoutProps["squares"] => [
    { icon: "/icons/blue-light.svg", text: [{ text: events.toString(), big: true }, { text: "אירועים", gray: true }, { text: "שהצעתי", gray: true }] },
    { icon: "/icons/hands.svg", flex: 0.8, text: [{ text: organizations.toString(), big: true }, { text: "עמותות נהנו", gray: true }, { text: "מהאירועים שלי", gray: true }] },
    { icon: "/icons/ticket.svg", flex: 0.8, text: [{ text: tickets.toString(), big: true }, { text: "כרטיסים נמשכו", gray: true }, { text: "מהאירועים שלי", gray: true }] },
    { icon: "/icons/heart-hand.svg", text: [{ text: feedbacks.toString(), big: true }, { text: "פידבקים קיבלתי", gray: true }, { text: "על אירועים", gray: true }] }
]