import { useContext, useEffect, useMemo, useState } from "react";

import { EMAIL_COPPIED, MEETING_WITH_ACTORS_VIP, NOT_INTERESTED_MAYBE_NEXT_TIME, PREVIOUS, SAVE_EVENT, VISIT_ACTORS_VIP, WANT_TO_THINK_WITH_US } from "../../../consts/hebrew";
import { ANEContextInterface } from "../../../consts/interfaces/AddNewEventContext.interface";
import { ENTER_EMAIL_ADDRESS, FORM_FIELDS } from "../../../consts/variables";

import { dropDownValidation, longTextValidation } from "../../../functions/validations";
import { saveInLocalStorage } from "../../../functions/localStorage";
import { AddNewEventContext } from "../../../context/AddNewEventContext";

import PopupEventPreview from "../../../popups/agency/PopupEventPreview";
import GenericButton from "../../../generic-components/GenericButton";
import EventVIP from "./EventVIP";

import "./style/addEventRightThird.scss";
import "./style/addEventRightForth.scss"

const AddEventRightForth: React.FC = () => {
    const { saveDetailsForthPage, vipDetailsCx, setPageCx, editMode, publishNewEvent, isPublishLoading } = useContext(AddNewEventContext) as ANEContextInterface
    const [optionVIPEvent, setOptionVIPEvent] = useState<string>('');
    const [eventVIPDetailsValue, setEventVIPDetailsValue] = useState<string>('');
    const [vipOptionsError, setVipOptionsError] = useState<string>('')
    const [vipDetailsError, setVipDetailsError] = useState<string>("");
    const [emailCopied, setEmailCopied] = useState<boolean>(false)
    const [eventPreviewPopup, setEventPreviewPopup] = useState<boolean>(false)

    const eventVipDetails = useMemo(() => optionVIPEvent && optionVIPEvent !== 'other' ? optionVIPEvent : eventVIPDetailsValue, [eventVIPDetailsValue, optionVIPEvent])

    useEffect(() => {
        if (vipDetailsCx) {
            if (vipDetailsCx !== MEETING_WITH_ACTORS_VIP && vipDetailsCx !== VISIT_ACTORS_VIP && vipDetailsCx !== NOT_INTERESTED_MAYBE_NEXT_TIME) {
                setOptionVIPEvent('other')
                setEventVIPDetailsValue(vipDetailsCx)
            }
            else {
                setOptionVIPEvent(vipDetailsCx)
            }
        }
    }, [vipDetailsCx]);

    useEffect(() => {
        if (!editMode && eventVipDetails) {
            saveInLocalStorage(FORM_FIELDS.VIP, eventVipDetails)
        }
    }, [eventVipDetails, editMode])

    const changeVipOption = (option: string) => {
        setOptionVIPEvent(option)
        setVipOptionsError("")
    }

    const BackButton = (e: React.MouseEvent<HTMLButtonElement>) => { // back to the previous page and save the data for the next time the this page is opened
        e.preventDefault();
        saveDetailsForthPage(eventVipDetails)
        setPageCx(2)
    }

    const vipValidation = () => {
        const optionVIPEventVal = dropDownValidation(optionVIPEvent)
        const eventVIPDetailsVal = optionVIPEvent === 'other' ? longTextValidation(eventVIPDetailsValue) : ''
        setVipOptionsError(optionVIPEventVal)
        setVipDetailsError(eventVIPDetailsVal)
        return (!optionVIPEventVal && !eventVIPDetailsVal)
    }

    const Copy = () => {
        navigator.clipboard.writeText(ENTER_EMAIL_ADDRESS).then(function () {
        }, function () {
        });
        setEmailCopied(true)
        setTimeout(() => {
            setEmailCopied(false)
        }, 3000)
    }

    const handleSaveEvent = () => {
        if (!vipValidation()) return
        saveDetailsForthPage(eventVipDetails)
        saveInLocalStorage(FORM_FIELDS.IS_SAVED, true)
        setEventPreviewPopup(true)
    }

    const handlePublishEvent = async () => {
        await publishNewEvent()
        setEventPreviewPopup(false)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEventVIPDetailsValue(e.target.value);
    }

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setVipDetailsError(longTextValidation(e.target.value));
    }

    return (
        <div className="add-event-right-third">
            <EventVIP
                eventVIPDetailsValue={eventVIPDetailsValue}
                reasonVIPEvent={optionVIPEvent}
                changeVipEventOption={changeVipOption}
                handleInputChange={handleInputChange}
                vipDetailsError={vipDetailsError}
                handleInputBlur={handleInputBlur}
                vipOptionError={vipOptionsError}
            />
            <div className="add-event-buttons-third">
                <button onClick={BackButton} className="add-event-other-button-third">{PREVIOUS}</button>
                <GenericButton handleClick={handleSaveEvent} className="yellow add-event-yellow-button-third">{SAVE_EVENT}</GenericButton>
            </div>

            <div className="contact-enter">{WANT_TO_THINK_WITH_US} <span className="enter-email" onClick={Copy}>{ENTER_EMAIL_ADDRESS}</span></div>
            {emailCopied && <div className='coppied'>{EMAIL_COPPIED}</div>}

            <PopupEventPreview open={eventPreviewPopup} handlePublishEvent={handlePublishEvent} handleClose={() => { setEventPreviewPopup(false) }} serverLoading={isPublishLoading} />
        </div>
    )
}

export default AddEventRightForth;