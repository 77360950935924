import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import moment from "moment";

import { organization } from "../consts/enums/organization.enum";
import { ORG_SIGN_UP } from "../consts/enums/InputsNames";
import { TERMS_ERROR } from "../consts/variables";

import GenericInput from "./GenericInput";
import GenericCheckbox from "./GenericCheckbox";
import { nameValidation } from "../functions/validations";

import "../style/termForm.scss"

export interface TermsFormRef {
    validate(): boolean;
    getTermData(): { termsName: string, termsDate: string }
}

interface TermsFormProps {
    pdf: string;
}

export const TermsForm = forwardRef<TermsFormRef, TermsFormProps>((props, ref) => {
    const [termsChecked, setTermsChecked] = useState<boolean>(false);
    const [termsName, setSignedName] = useState<string>('');
    const [termsError, setTermsError] = useState<string>('');

    const toggleCheckbox = () => {
        setTermsChecked(prev => !prev)
        termsError && validateForm()
    }

    const handleNameChangeBlur = (name: string) => {
        setSignedName(name)
        validateForm()
    }

    useEffect(() => {
        termsError && validateForm() //reset the error message after toggling the term on.
    }, [termsChecked, termsError, termsName])

    const validateForm = () => {
        if (!termsChecked) {
            setTermsError(TERMS_ERROR)
            return false
        }

        const nameValidated = nameValidation(termsName)
        if (nameValidated) {
            setTermsError(nameValidated)
            return false
        }
        setTermsError('')
        return true
    }

    useImperativeHandle(ref, () => ({
        validate() {
            return validateForm()
        },

        getTermData() {
            return {
                termsName: termsName,
                termsDate: termsDate,
            }
        }
    }));

    const termsDate = moment().format('DD.MM.YYYY')

    return (
        <>
            <GenericCheckbox
                terms={true}
                checked={termsChecked}
                className={"terms"}
                element={
                    <div className="term-div">
                        {organization.I + '\n'}
                        <GenericInput className="terms" name={ORG_SIGN_UP.TERM_NAME} onBlur={(e) => handleNameChangeBlur(e.target.value)} />
                        {'\n' + organization.I_read_the_term + '\n'}

                        <a href={`${process.env.PUBLIC_URL}/${props.pdf}.pdf`} target="_blank" style={{ color: '#41596b' }}>{organization.terms_for_enter}</a>
                        {'\n' + organization.in_date + '\n'}

                        <GenericInput value={termsDate} className="terms" disabled={true} />
                        {'\n' + organization.I_agree_to_the_terms}
                    </div>
                }
                handleChange={toggleCheckbox}
            />
            <div className="signup-right-errors">{termsError}</div></>
    )
});
