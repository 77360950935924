import { useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

import { TITLES, TO_ALL_RECEIVED_FEEDBACKS } from "../../../consts/hebrew";
import { RecentFeedbacks } from "../../../consts/interfaces/Feedback";

import { SwiperSlide } from 'swiper/react';
import SwipeBoard from "../../../generic-components/SwipeBoard";
import ThankYouCard from "./ThankYouCardMobile";
import ThankYouRow from "../../../generic-components/ThankYouRowDesktop";
import ThirdCard from "./ThirdCard";

import './style/thankYouFeedback.scss';

const ThankYouFeedback: React.FC<{ feedbacks: RecentFeedbacks[] }> = ({ feedbacks }) => {

    const isPhone = useMediaQuery("(max-width:768px)");
    const thirdCard = isPhone && feedbacks.length > 1;
    const mapFeedback = isPhone ? feedbacks.slice(0, 2) : feedbacks;

    return (
        <div className={'feedback-container'}>
            <div className="title" children={TITLES.RECEIVED_FEEDBACKS} />
            {!isPhone && <Link className="feedback-link" to="/archive" children={TO_ALL_RECEIVED_FEEDBACKS} />}

            {isPhone ?
                <SwipeBoard
                    className="feedbacks-slider"
                    pagination
                >
                    {mapFeedback.map(feedback =>
                        <SwiperSlide>
                            <ThankYouCard key={feedback.id} feedback={feedback} />
                        </SwiperSlide>
                    )}

                    {thirdCard &&
                        <SwiperSlide>
                            <ThirdCard />
                        </SwiperSlide>}
                </SwipeBoard>
                :
                <div className="feedbacks-list-container">
                    <table className="feedbacks-container">
                        <tbody>
                            {mapFeedback.map(feedback =>
                                <ThankYouRow key={feedback.id} feedback={feedback} />)}
                        </tbody>
                    </table>
                </div>}
        </div >
    )
}

export default ThankYouFeedback