//this component gets props - eventName: string, date: string, ticketsPurchased: number, eventId: number.
//it returns an event that has been purchased and passed so you can thank the agency for it
import { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";

import { ErrorContext } from "../../../context/ErrorContext";
import { UserContext } from "../../../context/UserContext";
import { EventsHappenedItemProps } from '../../../consts/interfaces/Event.interface';
import { _ERROR_MESSAGE } from "../../../consts/variables";
import { OrderEvents } from '../../../consts/enums/events.enum';
import { eventToDismantledDate } from "../../../functions/dateFunctions";
import GenericButton from "../../../generic-components/GenericButton"

const SinglePastEvent: React.FC<EventsHappenedItemProps> = ({ event }) => {
    const { showError } = useContext(ErrorContext)
    const history = useHistory();
    const { systemID } = useContext(UserContext);
    const { date, day, time } = eventToDismantledDate(event.date);
    const [feedback, setFeedback] = useState(false);

    useAsyncEffect(async () => {
        try {
            const feedback = await axios.get(`/api/feedback/did-org-send-feedback?organizationId=${systemID}&eventId=${event.eventId}`)
            if (feedback.data.length !== 0) {
                setFeedback(true)
            }
        } catch (error) {
            showError(_ERROR_MESSAGE)
            console.error(error)
        }
    }, [])

    const handleClick = () => {
        if (feedback) history.push(`/feedbacks/${event.eventId}`)
    }

    return (
        <div className="events-happened-item" onClick={handleClick}>
            <div className='past-event-right-and-middle'>
                <div className="past-event-right">
                    <div className="event-name"><span>{event.eventName} </span></div>
                    <span> {day} </span>
                    |
                    <span> {date} </span>
                    |
                    <span> {time}</span>
                </div>
                <div className="past-event-middle">
                    <img className="img-ticket" src="/icons/ticket.svg" alt="" />
                    {event.ticketsPurchasedByOrg === 1 ?
                        <span>{OrderEvents.single_ticket_ordered}</span> :
                        <span>{OrderEvents.ordered} {event.ticketsPurchasedByOrg} {OrderEvents.tickets}</span>
                    }
                </div>
            </div>
            <div className="button-before-text">
                {feedback ?
                    <div className="thank-you-pressed-container">
                        <div className="yellow thank-you-pressed">{OrderEvents.thanks_for_feedback}</div>
                        <img className="arrow-for-feedback" src="/icons/arrow-partial.svg" alt="click for feedback" />
                    </div>
                    :
                    <GenericButton
                        className="yellow thank-you"
                        handleClick={() => {
                            history.push(`/thanks/${event.eventId}`)
                        }}
                    >
                        {OrderEvents.want_to_thank}
                    </GenericButton>
                }
            </div>
        </div >
    )
}
export default SinglePastEvent;