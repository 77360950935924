export enum organization {
    organization_sign_up = 'רישום עמותה',
    not_larger_than_3MB = 'אנא העלו קובץ עד 3MB',
    name_of_organization = 'שם העמותה:',
    approval_for_conducting = 'אישור לניהול תקין',
    social_organization = 'עמותה חברתית',
    cant_use_phone_twice = 'לא ניתן להשתמש באותו מספר פלאפון עבור שני אנשי קשר',
    cant_use_email_twice = 'לא ניתן להשתמש באותה כתובת מייל עבור שני אנשי קשר',
    phone_is_already_exist = 'מספר הטלפון שהזנת קיים במערכת',
    email_is_already_exist = 'כתובת האימייל שהזנת קיימת במערכת',
    have_to_approve = 'על מנת להמשיך את הרישום חובה לאשר את תנאי השימוש',
    PDF_only = "נא להעלות קובץ PDF",
    type_of_file_not_valid = 'סוג הקובץ אינו תקין',
    too_large = 'קובץ גדול מדי',
    logo = 'לוגו עמותה:',
    loading_logo = 'טעינת תמונה',
    who_needs_accessibility_in_your_org = 'האם ישנם חניכים/ מקבלי שירות בארגונך שזקוקים להתאמות נגישות?',
    edit_logo = 'עריכת תמונה',
    who_we_will_update = 'את מי נעדכן כשיהיו כרטיסים פנויים?',
    permission_for_details = 'בהכנסת המספר הינך מאשר קבלת הודעות לצורך פרסום ועדכוני אירוע',
    audience = 'קהל יעד:',
    add_audience = 'הוסף קהל יעד',
    at_least_one_audience = 'חובה לבחור לפחות קהל יעד אחד',
    send_request = 'שלח בקשה',
    I = 'אני',
    I_read_the_term = '(שם הנציג/ה החותמ/ת) מאשר/ת שקראתי את',
    terms_for_enter = 'תקנון מיזם אנטר',
    in_date = 'בתאריך',
    I_agree_to_the_terms = 'ואני מסכימ/ה לכתב ההתחייבות המצורף',
    loading_file = 'טעינת קובץ',
    proper_management = 'ניהול תקין:',
    approval_signed_agreement = 'אישור חוזה חתום:',
    add_kind_of_event = 'הוסף סוג אירוע',
    weak_populations_only = 'מטרת המיזם לנתב כרטיסים לאירועים עבור אוכלוסיות רווחה בלבד',
    edit_profile = 'עריכת פרופיל',
    wheel_chairs_access = 'נשמח לאירועים נגישים לכיסאות גלגלים',
    hearing_access = 'נשמח לאירועים נגישים לכבדי שמיעה',
    sight_access = 'נשמח לאירועים נגישים לכבדי ראיה',
    mobility_access = 'נשמח לאירועים נגישים לקשיי ניידות',
    no_access = 'נשמח לכרטיסים רגילים / ללא נגישות',
    have_to_fill_filed = 'יש למלא שדה זה',
    you_had_to_upload_image = 'היית אמור להעלות תמונה אבל במקום זה העלאת קובץ מסוג',
    invalid_type_of_file = 'סוג קובץ שגוי',
    in_order_to_know_which_events_are_relevant = 'על מנת שנוכל לדעת אילו אירועים הכי יתאימו לכם',
    access_in_event = 'נגישות באירוע:',
    at_least_one_option = 'יש לבחור לפחות אופציה אחת',
    update = "עדכון",
    save = "שמירה",
    happy_to_get_accessable_events = 'נשמח לאירועים נגישים ל',
    number_of_apprentices = "מספר חניכים:",
    what_are_the_required_accessibilities = 'מה הן התאמות הנגישות המבוקשות?',
    organization_type = "סוג העמותה:",
    organizaion_is_unActive = "השהייה תמנע מהעמותה לקבל התראות והצעות לאירועים",
    organization_description = 'תיאור עמותה:'
}