//this component gets props - PopupJoinAgencyProps
//it returns a popup for information in the agency sign up only in mobile

import React from "react"

import { PopupJoinAgencyProps } from "../../consts/interfaces/PopupJoinAgency.interface"
import { TITLES } from "../../consts/hebrew"
import GenericPopup from "../../generic-components/GenericPopup"

import "../../style/genericPopup.scss"

const PopupJoinAgency: React.FC<PopupJoinAgencyProps> = ({ open, handleClose }) => {

    const agencylist = [
        { text: "גופי תרבות וספורט ירושלמיים" },
        { text: "מוסדות תרבות" },
        { text: "אגודות ומתקני ספורט" },
        { text: "מחלקות עירוניות" },
        { text: "חברות בין עירוניות" },
        { text: "מינהלים קהילתיים" }
    ]

    return (
        <div>
            <GenericPopup
                open={open}
                handleClose={handleClose}
                className="join-us-Agency"
                imageStyles="join-us-img"
                image={"/icons/couch.svg"}
            >
                <div>
                    <div className="join-us-title">
                        {TITLES.EXCITED_YOURE_JOINING_THIS_SPECIAL_PROJECT}
                    </div>
                    <div className="join-us-list-container">
                        <div className="join-us-list">
                            <div className="join-us-list-title">הנותנים</div>
                            {agencylist.map((obj) => {
                                return (<div key={obj.text}>{obj.text}</div>)
                            })}
                        </div>
                    </div>

                </div>
            </GenericPopup>
        </div>
    )
}

export default PopupJoinAgency;