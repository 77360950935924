import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";
import { useMediaQuery } from "@mui/material";

import { ADD_CLOSE_EVENTS, ADD_ENTRANCE_TICKETS, AMOUNT, EVENTS, HELLO, ONE_EVENT, TITLES, TO_ADD } from "../../../consts/hebrew";
import { AgencySwiperEvent } from "../../../consts/interfaces/SwipeBoard.interface";
import { AGENCY_HOME_LEFT, RECEIVED_FEEDBACKS_HOME_LEFT } from "../../../consts/leftSideLayouts";
import { AgencyEventsServer } from "../../../consts/interfaces/UpcomingEvents.interface";
import { AgencyHomeStats } from "../../../consts/interfaces/AgencyHomeStats.interface";
import { RecentFeedbacks } from "../../../consts/interfaces/Feedback";
import { FORM_FIELDS, _ERROR_MESSAGE } from "../../../consts/variables";
import { clearFormDataStorage } from "../../../functions/localStorage";

import { SocketContext } from "../../../context/SocketContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { useUserInfo } from "../../../context/UserContext";
import { useConfig } from "../../../context/ConfigContext";
import { AddNewEventContext } from "../../../context/AddNewEventContext";

import UnpublishedSavedDraftPopup from "../../../popups/agency/UnpublishedSavedDraftPopup";
import AgencySwiperEventCover from "./AgencySwiperEventCover";
import ThankYouFeedback from "./ThankYouFeedback";
import MyDonationsMobile from "./MyDonationsMobile";

import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import GenericButton from "../../../generic-components/GenericButton";
import EventSwiper from "../../../generic-components/event-swiper/EventSwiper";
import GenericLeftSideLayout from "../../../generic-components/GenericLeftSideLayout";

import './style/agencyHomePage.scss';

const UpcomingEvents: React.FC<{ experienceType: number }> = ({ experienceType }) => {

    document.title = 'אירועים קרובים'

    const history = useHistory();
    const { systemID, systemName } = useUserInfo()
    const { ticketPurchased } = useContext(SocketContext) //the amount of tickets purchased per event- is on socket context so if one orders ticket it will update immediately 
    const { showError } = useContext(ErrorContext);
    const AddNewEventCx = useContext(AddNewEventContext)
    const { featureFlags } = useConfig();
    const isBenefitsFeatureOn = featureFlags["update_agency_profile"]
    const isMobile = useMediaQuery("(max-width:768px)");

    const [isLoading, setIsLoading] = useState(true) ///for skeleton
    const [eventArray, setEventArray] = useState<AgencySwiperEvent[]>([]);
    const [feedbacks, setFeedbacks] = useState<RecentFeedbacks[]>([])
    const [statsData, setStatsData] = useState<AgencyHomeStats>()
    const [unpublishedDraftPopup, setUnpublishedDraftPopup] = useState<boolean>(false)

    const eventAmountText = eventArray.length === 1 ? ONE_EVENT : `${eventArray.length} ${EVENTS}`

    //getting events info from database
    useAsyncEffect(async () => {
        try {
            if (!systemID) return;

            const agencyEvents: AgencyEventsServer[] = (await axios.get(`/api/agency/agency-events?agencyId=${systemID}&onlyFuture=${true}`)).data;

            const allAgencyEvents: Array<AgencySwiperEvent> = agencyEvents.map((item, index) => {
                const purchasedTickets = item.tickets.filter(ticket => ticket.status === "purchased")
                const distinctOrganizationsIds = new Set(purchasedTickets.map(ticket => ticket.organization));

                return {
                    id: index,
                    eventId: item.id,
                    eventName: item.eventName,
                    date: item.date,
                    ticketsPurchased: purchasedTickets.length,
                    ticketsNotPurchased: item.tickets.length - purchasedTickets.length,
                    ticketsTotal: item.tickets.length,
                    coverImage: item.coverImage,
                    posterImage: item.posterImage,
                    vipDetails: item.vipDetails,
                    imageCoordinates: item.imageCoordinates,
                    purchasedOrgsCount: distinctOrganizationsIds.size,
                }
            })
            setEventArray(allAgencyEvents)
            setIsLoading(false)
        }
        catch (error) {
            showError(_ERROR_MESSAGE);
            throw error;
        }
    }, [systemID])

    useAsyncEffect(async () => {
        if (!systemID) return;
        const recentFeedbacks = (await axios.get(`/api/feedback/recently-received-feedbacks?agencyId=${systemID}`)).data;
        setFeedbacks(recentFeedbacks)
    }, [systemID])

    useAsyncEffect(async () => {
        const { data } = await axios.get('/api/agency/home-page-statistics');
        setStatsData(data)
    }, [systemID])

    //if the socket recognized an update in ticketsPurchased it will request the right amount of tickets and present the new number
    useEffect(() => {
        if (ticketPurchased) {
            setEventArray(prev => {
                return prev.map((event) => {
                    if (event.eventId === ticketPurchased.eventId) {
                        return {
                            ...event,
                            ticketsPurchased: event.ticketsPurchased ?? 0 + ticketPurchased.amountPurchased,
                        }
                    }
                    return event
                })
            })
        }
    }, [ticketPurchased])

    useEffect(() => {
        const isSavedEvent = localStorage.getItem(FORM_FIELDS.IS_SAVED)
        if (isSavedEvent) {
            setUnpublishedDraftPopup(true)
        }
    }, [])

    return (
        <div className="upcoming-events-page">
            <GenericSplitPage squares={AGENCY_HOME_LEFT(statsData?.donationWorth || 0)} className="agency-home-page" serverLoading={isLoading} noHeader>
                <div className="agency-home-right">
                    <div className="home-title">{HELLO}, <b>{systemName}</b></div>

                    <div className="buttons-container">
                        {experienceType & 1 << 1 ?
                            <GenericButton className="yellow agency-home-btn" handleClick={() => history.push("/event/add")}>
                                {TO_ADD} <b>{ADD_CLOSE_EVENTS}</b>
                            </GenericButton> : null}

                        {experienceType & 1 << 0 && isBenefitsFeatureOn ?
                            <GenericButton className="blue agency-home-btn" handleClick={() => history.push("/add-entrance-tickets")}>
                                {TO_ADD} <b>{ADD_ENTRANCE_TICKETS}</b>
                            </GenericButton> : null}
                    </div>
                    {isMobile && <MyDonationsMobile donationValue={statsData?.donationWorth} />}
                </div>
            </GenericSplitPage>

            <div className="upcoming-events">
                <div className="title">{TITLES.NEXT_EVENTS}</div>
                <div className="second-title">{AMOUNT}: {eventAmountText}</div>

                <EventSwiper
                    array={eventArray}
                    isAgency
                    cover={AgencySwiperEventCover}
                    navigateToEventPage={eventId => history.push(`/event/details/${eventId}`)}
                    className="agency-events-swiper"
                />
            </div>

            {isMobile && < GenericLeftSideLayout squares={RECEIVED_FEEDBACKS_HOME_LEFT(
                statsData?.eventsCount || 0,
                statsData?.receivedOrgs || 0,
                statsData?.receivedTicketsCount || 0,
                statsData?.totalFeedbacks || 0
            )} />}

            <GenericSplitPage className="received-feedbacks" noHeader serverLoading={false}
                squares={RECEIVED_FEEDBACKS_HOME_LEFT(
                    statsData?.eventsCount || 0,
                    statsData?.receivedOrgs || 0,
                    statsData?.receivedTicketsCount || 0,
                    statsData?.totalFeedbacks || 0
                )}
            >
                <ThankYouFeedback feedbacks={feedbacks} />
            </GenericSplitPage>

            <UnpublishedSavedDraftPopup
                open={unpublishedDraftPopup}
                handleWatchEvent={() => {
                    setUnpublishedDraftPopup(false);
                    history.push("/event/preview")
                }}
                handleDeleteDraft={() => {
                    setUnpublishedDraftPopup(false);
                    AddNewEventCx && AddNewEventCx.setClearCx(true)
                    clearFormDataStorage()
                }}
            />
        </div>
    )
}

export default UpcomingEvents;