//this component takes props - newTimeInSeconds: number, alwaysTimeSeconds: number.
//it returns a time slider bar

import { useMemo, useState } from "react";
import { TimeProgressBarProps } from "../../consts/interfaces/TimeProgressBar.interface";

const TimeProgressBar: React.FC<TimeProgressBarProps> = (props) => {

    //calculates the width- proportionate to seconds left / total seconds

    const [proportionTime, setproportionTime] = useState<number>(0);

    useMemo(() => {
        const { newTimeInSeconds } = props;
        setproportionTime(newTimeInSeconds / props.alwaysTimeSeconds * 100);
    }, [props]);

    return (
        <div style={{ width: `${proportionTime}vw` , height:'1vh'}} className="time-slider-progress-bar-yes" />
    )
}

export default TimeProgressBar;