import { useState } from "react";
import { useMediaQuery } from "@mui/material";

import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { Swiper } from 'swiper/react';
import { Keyboard } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import '../style/swipeBoard.scss';

interface SwipeBoardProps extends Swiper {
    onSlideEnterKeyDown?: (activeSlideIndex: number) => void;
}
/**
 * wrap each slide with `SwiperSlide` component
 */
const SwipeBoard: React.FC<SwipeBoardProps> = (props) => {
    const { children, onSlideEnterKeyDown } = props;

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)');
    SwiperCore.use([Keyboard, Navigation, Pagination]);

    const [activeIndex, setActiveIndex] = useState<number>(0)

    const onSwiperKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter")
            onSlideEnterKeyDown?.(activeIndex)
    }

    return (
        <div onKeyDown={onSwiperKeyDown}>
            <Swiper
                slidesPerView={tabletMediaQuery ? 1.2 : 2.25}
                centeredSlides={tabletMediaQuery}
                keyboard
                tabIndex={0}
                onActiveIndexChange={swiper => setActiveIndex(swiper.activeIndex)}
                navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev', }}
                {...props}
            >
                {children}

                <div className="swiper-button-prev custom-arrow">
                    <img src="/icons/swiper-arrow.svg" alt="arrow" />
                </div>
                <div className="swiper-button-next custom-arrow">
                    <img src="/icons/swiper-arrow.svg" alt="arrow" />
                </div>
            </Swiper>
        </div>
    );
}

export default SwipeBoard
