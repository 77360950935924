import React, { Fragment } from "react";
import { GenericRadioButtonsProps } from "../consts/interfaces/RadioButton.interface";
import "../style/radioButtons.scss";

const RadioButtons: React.FC<GenericRadioButtonsProps> = (props) => {

  //focuses on the button and the press it on key press
  const onKeyPress = (e: any) => {

    const form = e.target.form;
    const index = [...form].indexOf(e.target);

    if (e.key === "Enter") {
      props.handleChange?.(e)
      e.preventDefault()
    }
    else if (e.key === "ArrowDown") {
      e.preventDefault();
      form.elements[index + 1].focus()
    }
    else if (e.key === "ArrowUp") {
      e.preventDefault();
      form.elements[index - 1].focus()
    }

  }

  return (
    <div className={props.className ? props.className : ""}>
      <div dir="rtl" className="radio-buttons-wrapper">
        {props.options.map((opt, index) => {
          return (
            <Fragment key={opt.value}>
              <div className="radio-buttons-item">
                <input
                  disabled={props.disabled}
                  onKeyDown={(e) => { onKeyPress(e) }}
                  type="radio"
                  value={opt.value}
                  id={opt.value}
                  onChange={props.handleChange}
                  name={index === 0 ? props.name : ''}
                  checked={opt.value === props.value}
                />
                <label className="radio-btn" htmlFor={props.dontHaveLabel ? "" : opt.value}>
                  {opt.label}
                </label>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtons;