/**
 * convert function
 * @param minutes the minutes that we want to convert
 * @returns the duration time in format of H:MM
 */
export const convertToHours = (minutes: number): string => {
    if (minutes === 0) return '';
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return `${hours}:${minutesLeft < 10 ? `0${minutesLeft}` : minutesLeft}`;
}
/**
 * convert function
 * @param value is the hours in format H:MM that we want to convert to minutes 
 * @returns the result minutes in Integer
 */
export const convertToMinutes = (value: string): number => {
    if (value === '') return 0;
    if (value.indexOf(":") !== -1) {
        const [hours, minutes] = value.split(":");
        return parseInt(hours) * 60 + parseInt(minutes);
    } else {
        return parseInt(value);
    }
}
export const convertDurationIdToMinutes = (durationIndex: number): number => {
    return durationIndex * 30
}

export const convertMilisecondsToMinutes = (miliseconds: number) => {
    return miliseconds * 1000 * 60
}

export const convertMilisecondsToSeconds = (miliseconds: number) => {
    return miliseconds * 1000
}

/**
 * @param duration a string in hh:mm format
 * @returns the duration in minutes
 */
export const convertDurationToMinutes = (duration: string): number => {
    const hours = parseInt(duration.split(':')[0])
    const minutes = parseInt(duration.split(':')[1])
    return hours * 60 + minutes;
}

export const convertStringToNumber = (value: string) => {
    return isNaN(parseInt(value)) ? undefined : parseInt(value)
}

export const positiveInteger = (value: number | string) => {
    const num = parseInt(value.toString()) || 0
    return num < 0 ? 0 : num
}