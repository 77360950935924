export const enum ORG_SIGN_UP {
    ORG_NAME = 'organizationName',
    CONTACT_NAME = 'contactName',
    CONTACT_PHONE = 'contactPhone',
    CONTACT_EMAIL = 'contactEmail',
    PROPER_MANAGEMENT = 'properManagement',
    TERM_NAME = 'termName'
}

export const enum AGENCY_SIGN_UP {
    AGENCY_NAME = 'agencyName',
    AGENCY_TYPE = 'agencyType'
}

export enum ORG_PROFILE {
    ORG_NAME = 'orgName',
    ORG_DESCRIPTION = 'orgDescription',
    NUM_PEOPLE = 'numPeople',
    AGE_RANGE = 'ageRange',
    DEMO = 'demo',
    POPULATION = 'population',
    ACCESSIBILITY = 'accessibility',
}

export enum AGENCY_PROFILE {
    EXPERIENCE = 'experience',
    NAVIGATION = 'navigation'
}

export enum ADD_EVENT_FIRST {
    EVENT_NAME = 'eventName',
    EVENT_DATE = 'eventDate',
    START_TIME = 'startTime',
    DURATION = 'duration',
    EVENT_DETAILS = 'eventDetails',
    AGE_RANGE = 'ageRange',
    CATEGORY = 'category',
    POPULATION = 'population',
    EVENT_IMAGE = 'eventImage'
}

export enum ADD_EVENT_SECOND {
    NAVIGATION = 'navigation',
    LOCATION = 'location',
    HALL_NAME = 'hallName',
    CONTACT_NAME = 'name',
    CONTACT_PHONE = 'phone'
}

export enum ADD_EVENT_THIRD {
    HOW_MANY_TICKETS = 'howManyTickets',
    TICKET_PRICE = 'ticketPrice',
    IS_SEATING = 'isSeating',
    IS_MARKED_SEATS = 'isMarkedSeats',
    ARE_ADJACENT_SEATS = 'areAdjacentSeats',

}