import { COUPON_CODE, HOW_WILL_THE_ORGANIZATION_RECEIVE_THE_TICKETS, ORDER_ON_EXTERNAL_WEBSITE, PDF_FILE, WITH_ALL_TICKETS } from "../../consts/hebrew";
import { TICKETS_METHOD } from "../../consts/variables";
import GenericButton from "../../generic-components/GenericButton";
import GenericPopup from "../../generic-components/GenericPopup"
import '../style/ticketMethodPopup.scss';

const TicketMethodPopup: React.FC<{ open: boolean, handleClose: () => void, handleSelect: (method: TICKETS_METHOD) => void }> = ({ open, handleClose, handleSelect }) => {

    const handleClick = (method: TICKETS_METHOD) => {
        handleSelect(method)
        handleClose();
    }

    return (
        <GenericPopup open={open} handleClose={handleClose} className="ticket-method-popup" title={HOW_WILL_THE_ORGANIZATION_RECEIVE_THE_TICKETS}>
            <div className="buttons-container">
                <GenericButton className="yellow ticket-method-btn" handleClick={() => handleClick(TICKETS_METHOD.PDF)}>
                    {PDF_FILE}
                    <div className="method-desc">
                        {WITH_ALL_TICKETS}
                    </div>
                </GenericButton>

                <GenericButton className="blue ticket-method-btn" handleClick={() => handleClick(TICKETS_METHOD.COUPON)}>
                    {COUPON_CODE}
                    <div className="method-desc">
                        {ORDER_ON_EXTERNAL_WEBSITE}
                    </div>
                </GenericButton>
            </div>
        </GenericPopup>
    )
}

export default TicketMethodPopup;