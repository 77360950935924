import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAsyncEffect } from '@hilma/tools';

import { GenericSeatGroupEnum, HOW_MANY_TICKETS_WILL_BE_USED, NO, YES } from '../../consts/hebrew';
import { Accessibility } from '../../consts/interfaces/Accessibility.interface';
import { SeatGroupProps } from '../../consts/interfaces/SeatGroup.interface';
import { SeatGroupAccessibility } from '../../consts/eventTypes';

import SeatGroupAccessibilityCount from './SeatGroupAccessibilityCount';
import GenericCheckbox from '../GenericCheckbox';
import PlusMinusInput from '../PlusMinusInput';
import RadioButtons from '../RadioButtons';

import './style/genericSeatGroup.scss';

const GenericSeatGroup: React.FC<SeatGroupProps> = ({ id, isOrder, numOfTickets, index, accessibilities, setPurchaseSeatGroups }) => {
    const [checked, setChecked] = useState(false)
    const [needAccessibility, setNeedAccessibility] = useState<string>("")

    const [seatgroupAccessibilities, setSeatgroupAccessibilities] = useState<SeatGroupAccessibility[]>([])
    const [usedSeats, setUsedSeats] = useState<number>(0)

    useAsyncEffect(async () => {
        const allAccessibilities: Accessibility[] = await (await axios.get(`/api/accessibility/all`)).data;
        const usedAccessibilities = allAccessibilities.filter(accessibility =>
            accessibilities.find(seatAccessibility => seatAccessibility.accessibility.id === accessibility.id)
        )
        setSeatgroupAccessibilities(usedAccessibilities.map(accessibility => ({ accessibility, count: 0 })))
    }, [accessibilities])

    useEffect(() => {
        !checked && setNeedAccessibility("")
    }, [checked])

    //adds or removes id of current seat group when is clicked
    useEffect(() => {
        if (checked) {
            setPurchaseSeatGroups?.(prev => [...prev, { id, accessibilities: seatgroupAccessibilities, usedSeats: 0 }]
            )
        } else {
            setPurchaseSeatGroups?.(prev =>
                prev.filter(seatgroup => seatgroup.id !== id))
        }
    }, [checked])

    useEffect(() => {
        setPurchaseSeatGroups?.(prev => {
            const foundIndex = prev.findIndex((seatgroup => seatgroup.id === id));
            return prev.map((seatgroup, ind) => ind === foundIndex ? ({ ...seatgroup, accessibilities: seatgroupAccessibilities, usedSeats }) : seatgroup)
        })
    }, [seatgroupAccessibilities, usedSeats])

    const updateState: (newState: number | ((prevState: number) => number), index: number) => void = (newState, index: number) => {
        let value: number = seatgroupAccessibilities[index].count;
        if (typeof newState === "function") {
            value = (newState as (prevState: number) => number)(value);
        } else {
            value = newState;
        }

        setSeatgroupAccessibilities(prev => prev.map((seatAccessibility, ind) => ind === index ? { ...seatAccessibility, count: value } : seatAccessibility))
    }

    const findLimitOfAccessibilitiesAmount = (index: number): number => {
        if (seatgroupAccessibilities.length === 0) return 0
        const givenTickets = accessibilities.find(({ accessibility }) => accessibility.id === seatgroupAccessibilities[index].accessibility.id)
        if (givenTickets) {
            if (usedSeats === 0) {
                return givenTickets.count
            }
            return Math.min(givenTickets.count, usedSeats)
        }
        return usedSeats
    }

    return (
        <div className='whole-seatgroup'>
            <div className={isOrder ? 'seat-group-container is-order' : "seat-group-container"}>

                <div className='seat-group-number-container'>
                    {isOrder &&
                        <GenericCheckbox checked={checked} handleChange={() => setChecked(prev => !prev)} className='order-seat-groups' />
                    }
                    <div className='seat-group-number'>{GenericSeatGroupEnum.SEAT_GROUP} {index + 1}</div>
                </div>
                <div className='number-of-tickets'>{numOfTickets} {GenericSeatGroupEnum.TICKETS}</div>

                <div className='accessibility-container-big'>
                    {seatgroupAccessibilities.length !== 0 &&
                        <div className='accessibility-container'>
                            <div className='accessibilty-title'>{GenericSeatGroupEnum.ACCESSIBILITY}</div>
                            {seatgroupAccessibilities.map(({ accessibility }, index) => {
                                if (accessibility.info === GenericSeatGroupEnum.REGULAR_SEATS) {
                                    return <img key={index} className={'seat-group-image'} src={'/icons/accessibility/no-accessibility.svg'} alt={accessibility.info} />
                                } else {
                                    return <img key={index} className={accessibility.icon === "/image/fLbmjD5aGrcXDbJTjyFPqoTvACxREcWp.png" ? "eye-icon" : 'seat-group-image'} src={"/icons" + accessibility.icon} alt={accessibility.info} />
                                }
                            })}
                        </div>
                    }
                </div>
                <br />
            </div>
            {checked &&
                <div className='used-seats-container'>
                    {HOW_MANY_TICKETS_WILL_BE_USED}
                    <PlusMinusInput value={usedSeats} setValue={setUsedSeats} maxValue={numOfTickets} className="used-seats-input" />
                </div>
            }

            {(checked && seatgroupAccessibilities.length !== 0) &&
                <div className='purchase-details'>
                    <div className='description'>האם מבין משתתפי האירוע ישנם אנשים הזקוקים להתאמות נגישות?</div>
                    <RadioButtons
                        value={needAccessibility}
                        handleChange={(e: React.FormEvent<HTMLInputElement>) => { setNeedAccessibility(e.currentTarget.value) }}
                        options={[{ label: NO, value: NO }, { label: YES, value: YES }]}
                    />
                    {needAccessibility === YES &&
                        <div>
                            <div className='description'>ציין כמה ומהן התאמות הנגישות המבוקשות</div>
                            {seatgroupAccessibilities.map((seatAccessibility, index) =>
                                <SeatGroupAccessibilityCount
                                    numOfTickets={findLimitOfAccessibilitiesAmount(index)}
                                    text={seatAccessibility.accessibility.info} imgUrl={seatAccessibility.accessibility.icon}
                                    count={seatAccessibility.count}
                                    setCount={(value) => updateState(value, index)}
                                />
                            )}
                        </div>
                    }
                </div>
            }
        </div>
    )
}
export default GenericSeatGroup;
