import { AuthProvider } from '@hilma/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ErrorProvider from "./context/ErrorContext";
import GoogleAnalyticsProvider from './context/GoogleAnalytics';
import UserProvider from './context/UserContext';
import registerServiceWorker from "./functions/registerServiceWorker";
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <GoogleAnalyticsProvider>
      <AuthProvider hasTwoFactorToken={true} accessTokenCookie='kololo' twoFactorCookie="kloolk" logoutOnUnauthorized={true}>
        <ErrorProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </ErrorProvider>
      </AuthProvider>
    </GoogleAnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerServiceWorker();
