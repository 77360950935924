import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useContext, useRef, useState } from "react";
import { AddEntranceTicketsFields, CONTACT_NAME, CONTINUE, OTHER, PHONE_NUM, PREVIOUS } from "../../../consts/hebrew";
import { EntranceTicketsContextInterface } from "../../../consts/interfaces/AddEntranceTicketsContext.interface";
import { TICKETS_METHOD, _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import { AddEntranceTicketsContext } from "../../../context/AddEntranceTicketsContext";
import { dropDownValidation, longTextValidation, nameValidation, phoneNumValidation } from "../../../functions/validations";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import GoogleLocationAutocomplete from "../../../generic-components/google-location-autocomplete/GoogleLocationAutocomplete";
import TicketMethodPopup from "../../../popups/agency/TicketMethodPopup";


const AddEntranceTicketsSecond = () => {

    const { agencyContacts, locationCx, navigationCx, setAgencyContacts, setTicketMethod, saveSecondPageDetails, gotoPrevPage, gotoNextPage } = useContext(AddEntranceTicketsContext) as EntranceTicketsContextInterface;
    const firstInputRef = useRef<HTMLInputElement>(null); // ref for the first input

    const [location, setLocation] = useState<string>(locationCx)
    const [navigation, setNavigation] = useState<string>(navigationCx)
    const [availableContactIndx, setAvailableContactIndx] = useState<number>(0)

    const [locationError, setLocationError] = useState<string>("")
    const [navigationError, setNavigationError] = useState<string>("")
    const [contactNameError, setContactNameError] = useState<string>("");
    const [contactPhoneError, setContactPhoneError] = useState<string>("");

    const [ticketMehtodPopup, setTicketMethodPopup] = useState<boolean>(false);

    /**
     * @param e input change event
     * @param name the contact propery to change
     * @param index the changed contact index
     */
    const handleAgencyContactsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: "username" | "name", index: number) => {
        setAgencyContacts(prev => prev.map((contact, ind) =>
            ind === index ?
                ({ ...contact, [name]: e.target.value })
                :
                contact
        ))
    }

    const saveCurrentPageDetails = () => saveSecondPageDetails({ location, navigation, availableContactIndx })

    const handleTicketMethodSelect = (method: TICKETS_METHOD) => {
        setTicketMethod(method)
        saveCurrentPageDetails()
        gotoNextPage()
    }

    const handleContinue = () => {
        if (validateFields()) {
            setTicketMethodPopup(true)
        } else {
            firstInputRef.current?.scrollIntoView({ block: "end", behavior: "smooth" })
        }
    }

    const handlePreviousPageButtonClick = () => {
        saveCurrentPageDetails()
        gotoPrevPage()
    }

    const validateFields = () => {
        const locationVal = dropDownValidation(location);
        setLocationError(locationVal);

        const navigationVal = longTextValidation(navigation)
        setNavigationError(navigationVal);

        const nameVal = nameValidation(agencyContacts[availableContactIndx].name);
        setContactNameError(nameVal)

        const phoneVal = phoneNumValidation(agencyContacts[availableContactIndx].username);
        setContactPhoneError(phoneVal)

        return !(locationVal || navigationVal || nameVal || phoneVal)
    }

    return (
        <div className="add-entrance-tickets">

            <div className="input-container" ref={firstInputRef}>
                <div className="input-title">{AddEntranceTicketsFields.ADDRESS}</div>
                <GoogleLocationAutocomplete value={location} setValue={setLocation} setAddressError={setLocationError} />
                <div className="input-error">
                    {locationError}
                </div>
            </div>

            <GenericInput
                title={AddEntranceTicketsFields.NAVIGATION}
                value={navigation}
                name="navigation"
                characterLimit={_LONG_TEXT_MAX_LENGTH}
                onChange={(e) => setNavigation(e.target.value)}
                error={navigationError}
                textarea
            />

            <div className="input-container">
                <div className="input-title">{AddEntranceTicketsFields.AVAILABLE_CONTACT}</div>
                <FormControl fullWidth>
                    <Select
                        onChange={e => setAvailableContactIndx(Number(e.target.value))}
                        className="input select-mui margin-bottom"
                        value={availableContactIndx}
                    >
                        {agencyContacts.map((contact, index) =>
                            <MenuItem key={index} value={index}>{contact.id !== "" ? contact.name : OTHER}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                {!agencyContacts[availableContactIndx].id && // option "other"
                    <GenericInput title={CONTACT_NAME}
                        type="text"
                        name="name"
                        value={agencyContacts[availableContactIndx].name}
                        onChange={(e) => handleAgencyContactsChange(e, "name", availableContactIndx)}
                        error={contactNameError}
                    />}

                <GenericInput title={PHONE_NUM}
                    type="text"
                    name="username"
                    value={agencyContacts[availableContactIndx].username}
                    onChange={(e) => handleAgencyContactsChange(e, "username", availableContactIndx)}
                    characterLimit={10}
                    error={contactPhoneError}
                />
            </div>

            <div className="add-entrance-buttons">
                <div onClick={handlePreviousPageButtonClick} className="back-btn">{PREVIOUS}</div>
                <GenericButton handleClick={handleContinue} className="yellow continue-btn">{CONTINUE}</GenericButton>
            </div>

            <TicketMethodPopup open={ticketMehtodPopup} handleClose={() => setTicketMethodPopup(false)} handleSelect={handleTicketMethodSelect} />
        </div>
    )
}

export default AddEntranceTicketsSecond;