//takes props - title , src, id?: number | string, handleChange?: () => void, checked: boolean, className?: string.
//it returns a generic checkbox
import { GenericCheckboxProps } from "../consts/interfaces/GenericCheckbox.interface";
import "../style/genericCheckbox.scss";

const GenericCheckbox: React.FC<GenericCheckboxProps> = (props) => {

    //focuses on the button and the press it on key press
    const onKeyPress = (e: any) => {
        const form = e.target.form;
        if (form) {
            const index = [...form].indexOf(e.target);
            switch (e.key) {
                case "Enter":
                    props.handleChange?.()
                    e.preventDefault();
                    break;
                case "ArrowDown":
                    e.preventDefault();
                    form.elements[index + 1] && form.elements[index + 1].focus();
                    break;
                case "ArrowUp":
                    e.preventDefault();
                    form.elements[index - 1] && form.elements[index - 1].focus();
                    break;
            }
        }
    }

    return (
        <div className={props.className}>
            <div className={props.className === '-category-checkbox' ? 'category-checkbox' : `checkbox-item`} >
                <input
                    name={props.name}
                    id={`${props.id}`}
                    onKeyDown={(e) => onKeyPress(e)}
                    className="the-checkbox"
                    type="checkbox"
                    onChange={props.handleChange}
                    checked={props.checked}
                    autoFocus={false}
                />

                {props.terms ?
                    <div>{props.title ? props.title : props.element}</div>
                    :
                    <label className="checkbox-title" htmlFor={`${props.id}`}>
                        {props.src ? <img className={props.id && props.id === 'access3' ? "img mobility" : 'img'} src={props.src} alt="check" /> : ""}
                        {props.title}
                    </label>
                }
            </div>
        </div >
    )
}

export default GenericCheckbox;

