import React, { useState } from "react";
import clsx from "clsx";
import axios from 'axios';
import { useAsyncEffect } from '@hilma/tools';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
    Autocomplete,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";

import { AGENCY_TYPE, NAME_OF_AGENCY_OR_SPORT_BODY, TYPE_AGENCY_TYPE_OR_SPORT_BODY } from "../consts/hebrew";
import { AgencyTypeInputProps } from '../consts/interfaces/AgencyProfile.interface';
import { AGENCY_SIGN_UP } from "../consts/enums/InputsNames";
import { textValidation } from "../functions/validations";

import "../style/agencyTypeInput.scss";

const AgencyTypeInput = React.forwardRef<HTMLInputElement, AgencyTypeInputProps>(({
    agencyName,
    agencyType,
    setAgencyName,
    setAgencyType,
    agencyNameError,
    agencyTypeError,
    setAgencyNameError
}, inputRef) => {

    const [agencies, setAgencies] = useState<{ agencyType: string, agencyNamePossibilities: string[] }[]>([])
    const [agencyTypeDisabled, setAgencyTypeDisabled] = useState<boolean>(false)

    useAsyncEffect(async () => {
        const data: { agencyType: string, agencyNamePossibilities: string[] }[] = (await axios.get('/api/agency/types-data')).data

        setAgencies(data)
    }, [])

    const handleAgencyNameChange = (_: React.SyntheticEvent<Element, Event>, value: string | null) => {
        if (!value) return;
        setAgencyName(value)

        const agency = agencies.find(agency => agency.agencyNamePossibilities.includes(value))

        if (agency) {
            setAgencyType(agency.agencyType)
            setAgencyTypeDisabled(true)
        } else
            setAgencyTypeDisabled(false)
    }

    const handleAgencyNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value
        setAgencyNameError(textValidation(value))
        if (!agencyTypeDisabled) return;
        const agency = agencies.find(agency => agency.agencyNamePossibilities.includes(value))

        if (!agency)
            setAgencyTypeDisabled(false)
    }

    return (
        <div>
            <div className="input-title">
                {NAME_OF_AGENCY_OR_SPORT_BODY}:
            </div>
            <Autocomplete
                popupIcon={<SearchIcon fontSize="inherit" />}
                clearIcon={<ClearIcon fontSize="inherit" />}
                value={agencyName}
                options={agencies?.flatMap(({ agencyNamePossibilities }) => agencyNamePossibilities)}
                onChange={handleAgencyNameChange}
                onBlur={handleAgencyNameBlur}
                onInputChange={(_, value) => setAgencyName(value)}
                inputValue={agencyName}
                renderOption={(props, option) => (
                    <Typography
                        {...props}
                        className={clsx("agency-name-item", { "selected-option": option === agencyName })}
                    >
                        {option}
                    </Typography>
                )}
                renderInput={(params) => <TextField name={AGENCY_SIGN_UP.AGENCY_NAME} {...params} placeholder={TYPE_AGENCY_TYPE_OR_SPORT_BODY} />}
                className='agency-name-select'
                ref={inputRef}
            />
            <div className="agency-sign-up-errors">
                {agencyNameError}
            </div>

            <div className="input-title">{AGENCY_TYPE}:</div>
            <FormControl fullWidth>
                <Select
                    name={AGENCY_SIGN_UP.AGENCY_TYPE}
                    onChange={(event) => setAgencyType(event.target.value)}
                    value={agencyType}
                    className='agency-type-select'
                    disabled={agencyTypeDisabled}
                >
                    {agencies?.map((item, i) =>
                        <MenuItem key={i} value={item.agencyType} className="agency-type-item">
                            {item.agencyType}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <div className="agency-sign-up-errors">{agencyTypeError}</div>
        </div >
    );
})

export default AgencyTypeInput;
