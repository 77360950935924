import { useEffect, useState } from "react";

import { HOW_MANY_ACCESSIBLE_TICKETS, MOBILITY_DIFFICULTIES, WHEELCHAIRS } from "../../../consts/hebrew";
import { SeatgroupAccessibilitiesProps } from "../../../consts/interfaces/seatgroupAccessibilities";
import PlusMinusInput from "../../../generic-components/PlusMinusInput";

import './style/seatgroupAccessibilities.scss';

const SeatgroupAccessibilities: React.FC<SeatgroupAccessibilitiesProps> = ({
    wheelchairAccessibleCount,
    mobilityAccessibleCount,
    setAccessibility,
    numSeats,
    sumOfAccessTicketsError,
    disabled
}) => {

    const [wheelchairAccessible, setWheelchairAccessible] = useState<number>(wheelchairAccessibleCount)
    const [mobilityAccessible, setMobilityAccessible] = useState<number>(mobilityAccessibleCount)

    useEffect(() => {
        setAccessibility(wheelchairAccessible, 'wheelchair')
    }, [wheelchairAccessible])

    useEffect(() => {
        setAccessibility(mobilityAccessible, 'mobility')
    }, [mobilityAccessible])

    return (
        <div className="accessibilities-inputs">
            <div className="title">{HOW_MANY_ACCESSIBLE_TICKETS}</div>
            <div className="accessibility-container">
                <PlusMinusInput disabled={disabled} value={wheelchairAccessible} setValue={setWheelchairAccessible} maxValue={numSeats} />
                <img src='/icons/accessibility/wheelchair-accessibility.svg' alt="wheelchair accessibility" />
                <div>{WHEELCHAIRS}</div>
            </div>

            <div className="accessibility-container">
                <PlusMinusInput disabled={disabled} value={mobilityAccessible} setValue={setMobilityAccessible} maxValue={numSeats} />
                <img src='/icons/accessibility/mobility-accessibility.svg' alt="mobility accessibility" />
                <div>{MOBILITY_DIFFICULTIES}</div>
            </div>
            <div className='input-error'> {sumOfAccessTicketsError}</div>
        </div>
    )
}

export default SeatgroupAccessibilities;