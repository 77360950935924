//this component gets props - open : boolean, handleClose ?: () => void
//it returns a popup that shows the user that we saved him the tickets till the event timer expires

import { PopupSaveTicketsProps } from "../../consts/interfaces/popupSaveTickets.interface";
import { PopupSaveTicketsText } from "../../consts/hebrew";
import GenericPopup from "../../generic-components/GenericPopup";
import GenericButton from "../../generic-components/GenericButton";
import '../../style/genericPopup.scss';

const PopupSaveTickets: React.FC<PopupSaveTicketsProps> = ({ open, timer, handleClose }) => {
    return (
        <GenericPopup
            image='/icons/ticket.svg'
            open={open}
            handleClose={handleClose}
            className="save-tickets-popup-wrapper"
            title={PopupSaveTicketsText.ticketsAreSavedForYou}>
            <div>
                <div className="save-tickets-text">
                    <p className="pay-attention">{PopupSaveTicketsText.payAttentionToLeftTime}</p>
                    <p className="timer">{timer}</p>
                    <p>{PopupSaveTicketsText.canAddTimeIfNeed}</p>
                </div>
                <GenericButton
                    className="small yellow"
                    handleClick={handleClose}>
                    {PopupSaveTicketsText.gotIt}
                </GenericButton>
            </div>
        </GenericPopup>
    );
}

export default PopupSaveTickets;