import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Area } from "react-easy-crop";
import axios from "axios";
import moment from "moment";

import { useFiles } from "@hilma/fileshandler-client";
import { useAsyncEffect } from "@hilma/tools";

import { AgencyContact, ANEContextInterface, FileMangerFile, FilesManager, ThirdPageDetails } from "../consts/interfaces/AddNewEventContext.interface";
import { _ERROR_DATE_TOO_CLOSE, _ERROR_MESSAGE, _SEAT_GROUP, DATE_TOO_CLOSE, TICKETS_METHOD, COUPONS, FORM_FIELDS } from "../consts/variables";
import { AgeRangeArrayItem, AgeRangeServer } from "../consts/interfaces/AgeRange.interface";
import SeatGroup, { MiniSeatGroup } from "../consts/interfaces/SeatGroup.interface";
import { PopulationArray } from '../consts/interfaces/PopulationArray.interface';
import { Accessibility } from "../consts/interfaces/Accessibility.interface";
import { Coupons } from "../consts/interfaces/TicketsCouponMethod.interface";
import { CategoriesArray } from "../consts/interfaces/Categories.interface";
import Hall, { LocationDetails } from "../consts/interfaces/Hall.interface";
import UseParamsInterface from "../consts/interfaces/UseParams.interface";
import { useExitBrowser } from "../consts/hooks/useExitBrowser";
import { Event } from "../consts/interfaces/Event.interface";

import {
    getDetailsForPreview,
    getEventDetailsFromLocalStorage,
} from "../functions/getDetailsFromLocalStorage";
import {
    clearFormDataStorage,
    saveImageInLocalStorage,
    saveInLocalStorage,
    savePdfsInLocalStorage,
} from "../functions/localStorage";
import { convertDurationToMinutes } from "../functions/convertsFunctions";
import { convertToMilliseconds } from "../functions/dateFunctions";
import { base64ToFile } from "../functions/filesFunctions";

import ErrorMessageModal from "../generic-components/ErrorMessageModal";
import { UserContext } from "./UserContext";
import { GoogleAnalyticsContext } from "./GoogleAnalytics";
import PopupEventCreated from "../popups/agency/PopupEventCreated";

export const AddNewEventContext =
    React.createContext<ANEContextInterface | null>(null);

export const AddNewEventProvider: React.FC = ({ children }) => {
    const [pageCx, setPageCx] = useState<number>(0); // which page is active from the pages array
    const filesUploaderCx = useFiles(); // filesUploader instance from @hilma/fileshandler-client

    const [errorPopup, setErrorPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { systemID, role } = useContext(UserContext);
    const { gaEvent, gaException } = useContext(GoogleAnalyticsContext);

    const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false); // is the event is in edit mode, if not its on creating new event mode.
    const [isCatePopuAgeFetch, setIsCatePopuAgeFetch] =
        useState<boolean>(false);
    const [fetchDataFromDB, setFetchDataFromDB] = useState<boolean>(false);

    // first page: event name, date & time,duration, description, category,  activity field and image (in that order)
    const [eventNameCx, setEventNameCx] = useState<string>("");
    const [dateCx, setDateCx] = useState<moment.Moment | null>(null);
    const [timeCx, setTimeCx] = useState<moment.Moment | string | null>(null);
    const [durationCx, setDurationCx] = useState<string>("");
    const [descriptionCx, setDescriptionCx] = useState<string>("");
    const [categoryCx, setCategoryCx] = useState<CategoriesArray[]>([]);
    const [populationCx, setPopulationCx] = useState<PopulationArray[]>([]);
    const [ageRangeCx, setAgeRangeCx] = useState<AgeRangeArrayItem[]>([]);
    const [imageCoordinatesCx, setImageCoordinatesCx] = useState<Area>({
        x: 0,
        y: 0,
        width: 100,
        height: 100,
    });
    const [hearingAidsNumberCx, setHearingAidsNumberCx] = useState<number>(0);
    const [filesManager, setFilesManager] = useState<FilesManager>({
        coverImage: null,
        posterImage: null,
        files: [],
    });
    // const { showError } = useContext(ErrorContext);

    // second page: location object, contact name and phone number
    // the object location contains the location hall, location and navigation and for each hall the seats accessibilty
    const [currentAgencyContactCx, setCurrentAgencyContactCx] =
        useState<number>(0); // index or contact object
    const [agencyContactListCx, setAgencyContactListCx] = useState<
        AgencyContact[]
    >([]);
    const [locationDetailsCx, setLocationDetailsCx] = useState<LocationDetails>(
        {
            halls: [
                {
                    selected: false,
                    id: 0,
                    hallName: "",
                    hallNameError: "",
                },
            ],
            location: "",
            navigation: "",
        }
    );
    // the third page: amount of tickets(numValue) , isSeating, areAdjacent, ticket method, coupon code and weblink (both optional), seatGroups (optional)
    const [isSeatingCx, setIsSeatingCx] = useState<boolean>();
    const [numValueCx, setNumValueCx] = useState<number>();
    const [priceValueCx, setPriceValueCx] = useState<number>();
    const [areAdjacentValueCx, setAreAdjacentValueCx] = useState<boolean>();
    const [isMarkedSeatsCx, setIsMarkedSeatsCx] = useState<boolean>();
    const [ticketMethodValueCx, setTicketMethodValueCx] = useState<
        TICKETS_METHOD | ""
    >("");
    const [webLinkCx, setWebLinkCx] = useState<string>("");
    const [seatGroupsCx, setSeatGroupsCx] = useState<Array<SeatGroup>>([
        { ..._SEAT_GROUP },
    ]);
    const [couponsCx, setCouponsCx] = useState<Array<Coupons>>([
        { ...COUPONS },
    ]);

    const [eventCreatedPopup, setEventCreatedPopup] = useState<boolean>(false);
    const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);
    const createdEventId = useRef<Event["eventId"]>();

    const confirmBrowserExit = useExitBrowser();
    const history = useHistory();
    const { id } = useParams() as UseParamsInterface;
    const paramID = Number(id);
    const [eventID, setEventID] = useState<number>(paramID);
    const [clearCx, setClearCx] = useState<boolean>(false);

    //the forth page
    const [vipDetailsCx, setVipDetailsCx] = useState<string>("");

    useEffect(() => {
        if (!clearCx) return;
        addDetailsFirst("", "", "", null, "", [], [], [], {
            x: 0,
            y: 0,
            width: 100,
            height: 100,
        });

        addDetailsSecond("", "", 0, 0, []);

        backButtonThirdPage(
            undefined,
            undefined,
            undefined,
            undefined,
            "",
            "",
            null,
            0,
            [],
            undefined
        );

        saveDetailsForthPage("");
    }, [clearCx]);

    useAsyncEffect(async () => {
        if (!fetchDataFromDB || role !== "agency") return;

        if (categoryCx.length === 0 || clearCx) {
            const categoriesServer: Partial<CategoriesArray>[] = (
                await axios.get("/api/category/get-all-categories")
            ).data;
            // get some info for the add new event form from the server
            // get info for the categories
            setCategoryCx(
                categoriesServer.map((el) => ({
                    ...el,
                    selected: false,
                })) as CategoriesArray[]
            );
        }

        if (populationCx.length === 0 || clearCx) {
            const populationOptions = [
                {
                    id: 1,
                    title: "כללי",
                },
                {
                    id: 2,
                    title: "דתי",
                },
                {
                    id: 3,
                    title: "ערבי",
                },
                {
                    id: 4,
                    title: "חרדי",
                },
            ];
            setPopulationCx(
                populationOptions.map((el) => ({
                    ...el,
                    selected: false,
                })) as PopulationArray[]
            );
        }

        if (ageRangeCx.length === 0 || clearCx) {
            const ageArrayItems = await axios.get(
                "/api/age-range/get-all-ages"
            );
            setAgeRangeCx(
                ageArrayItems.data.map((el: AgeRangeServer) => ({
                    ...el,
                    selected: false,
                })) as AgeRangeArrayItem[]
            );
        }

        clearCx &&
            setFilesManager({
                coverImage: null,
                posterImage: null,
                files: [],
            });

        setIsCatePopuAgeFetch(true);
        clearCx && setClearCx(false);
    }, [fetchDataFromDB, clearCx, role]);

    useAsyncEffect(async () => {
        if (!systemID || role !== "agency") return;

        const agencyContactsFromServer = await axios.get(
            `/api/agency/agency-contacts?id=${systemID}`
        );
        const agencyContactsToSave = agencyContactsFromServer.data.map(
            (con: AgencyContact) => {
                return {
                    id: con.id,
                    name: con.name,
                    username: con.username,
                };
            }
        );

        !agencyContactListCx?.length &&
            setAgencyContactListCx([
                ...agencyContactsToSave,
                { name: "", username: "", id: "" },
            ]);
    }, [systemID, clearCx, role]);

    useAsyncEffect(async () => {
        if (role !== "agency") return;
        try {
            const { locationDetails } = (
                await axios.get("/api/agency/get-agency-location")
            )?.data as {
                locationDetails: LocationDetails;
                initialAccessibilities: Accessibility[];
            };
            const { location, navigation, status, halls } = locationDetails;

            const hallFixed: Hall[] =
                status === "no location found"
                    ? [
                          {
                              selected: false,
                              id: 0,
                              hallName: "",
                              hallNameError: "",
                          },
                      ]
                    : halls.map((item) => {
                          return {
                              ...item,
                              selected: false,
                          };
                      });

            setLocationDetailsCx({
                status: locationDetails.status,
                location: location,
                navigation: navigation,
                halls: hallFixed,
            });
        } catch (error) {
            throw error;
        }
    }, [role, clearCx]);

    useAsyncEffect(async () => {
        // this useEffect is responsible for the edit mode. fetching the data from the server and insert the information back to the inputs
        if (eventID && (editMode || isDuplicated) && isCatePopuAgeFetch) {
            getEventForEdit();
            isDuplicated && setIsDuplicated(false);
        }
    }, [eventID, editMode, isDuplicated, isCatePopuAgeFetch]);

    const saveDuplicateDetailsInLocalStorage = (
        eventName: string,
        time: string,
        description: string,
        ageRange: AgeRangeArrayItem[],
        categories: CategoriesArray[],
        population: PopulationArray[],
        imageCoordinates: Area,
        locationDetails: LocationDetails,
        agencyContacts: AgencyContact[],
        currentAgencyContact: number,
        coverImage: string,
        poster: string,
        posterName: string
    ) => {
        saveInLocalStorage(FORM_FIELDS.NAME, eventName);
        saveInLocalStorage(FORM_FIELDS.DURATION, time);
        saveInLocalStorage(FORM_FIELDS.DESCRIPTION, description);
        saveInLocalStorage(FORM_FIELDS.AGE_RANGE, ageRange);
        saveInLocalStorage(FORM_FIELDS.CATEGORIES, categories);
        saveInLocalStorage(FORM_FIELDS.POPULATION, population);
        saveInLocalStorage(FORM_FIELDS.IMAGE_COORDINATES, imageCoordinates);
        saveInLocalStorage(FORM_FIELDS.ADDRESS, locationDetails.location);
        saveInLocalStorage(FORM_FIELDS.NAVIGATION, locationDetails.navigation);
        saveInLocalStorage(FORM_FIELDS.AGENCY_CONTACTS, agencyContacts);
        saveInLocalStorage(FORM_FIELDS.CURRENT_CONTACT, currentAgencyContact);
        saveImageInLocalStorage(
            {
                fileName: "coverImage",
                link: coverImage,
                id: 0,
                type: "",
            },
            FORM_FIELDS.IMAGE
        );
        poster &&
            saveImageInLocalStorage(
                {
                    fileName: posterName,
                    link: poster,
                    id: 0,
                    type: "",
                },
                FORM_FIELDS.POSTER
            );
    };

    const getEventForEdit = async () => {
        try {
            const res: any = await axios.get(
                `/api/event/all-event-details?id=${eventID}&agencyId=${systemID}`
            );
            const {
                eventName,
                date,
                duration,
                description,
                ageRange,
                categories,
                populations,
                posterImage,
                coverImage,
                posterImageName,
                imageCoordinates,
                location,
                navigation,
                hall,
                contactName,
                contactPhone,
                tickets,
                ticketPrice,
                isSeating,
                isMarkedSeats,
                seatGroups,
                hearingAid,
            } = res.data;

            const hours = Math.floor(duration / 60);
            const minutes = duration % 60;
            const time = hours + ":" + (minutes < 10 ? "0" + minutes : minutes);

            const updatedAgeRangeCx = ageRangeCx.map((item) => {
                const selectedItem = ageRange.find(
                    (selectedItem: any) => selectedItem.id === item.id
                );
                if (selectedItem) {
                    return { ...item, selected: true };
                }
                return item;
            });
            const updatedCategoryCx = categoryCx.map((item) => {
                const selectedItem = categories.find(
                    (selectedItem: any) => selectedItem.id === item.id
                );
                if (selectedItem) {
                    return { ...item, selected: true };
                }
                return item;
            });
            const updatedPopulationCx = populationCx.map((item) => {
                const selectedItem = populations.find(
                    (selectedItem: any) => selectedItem.id === item.id
                );
                if (selectedItem) {
                    return { ...item, selected: true };
                }
                return item;
            });

            const ticketMethod = seatGroups[0]?.filePath.includes("pdf")
                ? TICKETS_METHOD.PDF
                : TICKETS_METHOD.COUPON;
            const seatGroupsFormat = seatGroups.map((item: any) => {
                if (ticketMethod === TICKETS_METHOD.PDF) {
                    const { fileName, accessibilities, tickets } = item;
                    return {
                        numSeats: tickets.length,
                        ticketFilePDF: fileName,
                        wheelchairAccessible:
                            accessibilities.find(
                                (item: any) =>
                                    item.accessibility.info === "כיסאות גלגלים"
                            )?.count || 0,
                        mobilityDifficultiesAccessible:
                            accessibilities.find(
                                (item: any) =>
                                    item.accessibility.info === "קשיי ניידות"
                            )?.countseatGroups || 0,
                    };
                } else {
                    // coupon method
                    return {
                        numSeats: item.seatNum,
                        code: item.coupon,
                    };
                }
            });

            const updateAgencyContacts = [...agencyContactListCx];
            let currentAgencyContact = 0;
            if (
                agencyContactListCx?.[0]?.name !== contactName &&
                agencyContactListCx?.[0]?.username !== contactPhone
            ) {
                if (agencyContactListCx) {
                    updateAgencyContacts.splice(1, 0, {
                        name: contactName,
                        username: contactPhone,
                        id: "",
                    });
                    updateAgencyContacts.push();
                    currentAgencyContact = 1;
                }
            }

            setEventNameCx(eventName);
            editMode && setDateCx(moment(date));
            editMode && setTimeCx(moment(date));
            setDurationCx(time);
            setDescriptionCx(description);
            setAgeRangeCx(updatedAgeRangeCx);
            setCategoryCx(updatedCategoryCx);
            setPopulationCx(updatedPopulationCx);
            setImageCoordinatesCx(imageCoordinates);

            setLocationDetailsCx((prevState) => ({
                ...prevState,
                halls: [
                    {
                        ...prevState.halls[0],
                        id: hall.id,
                        hallName: hall.hallName,
                    },
                ],
                location: location,
                navigation: navigation,
            }));
            setAgencyContactListCx(updateAgencyContacts);
            setCurrentAgencyContactCx(currentAgencyContact);

            setFilesManager({
                coverImage: {
                    link: coverImage,
                    id: 0,
                    fileName: "",
                    isNew: false,
                },
                posterImage: {
                    link: posterImage,
                    id: 0,
                    fileName: posterImageName,
                    isNew: false,
                },
                files: editMode
                    ? seatGroups.map(
                          (sg: any): FileMangerFile => ({
                              fileName: sg.fileName,
                              id: sg.id,
                              link: "unknown",
                              isNew: false,
                          })
                      )
                    : [],
            });

            if (isDuplicated) {
                saveDuplicateDetailsInLocalStorage(
                    eventName,
                    time,
                    description,
                    updatedAgeRangeCx,
                    updatedCategoryCx,
                    updatedPopulationCx,
                    imageCoordinates,
                    locationDetailsCx,
                    updateAgencyContacts,
                    currentAgencyContact,
                    coverImage,
                    posterImageName || "",
                    posterImage
                );
                return;
            }

            setWebLinkCx(
                ticketMethod === TICKETS_METHOD.COUPON
                    ? seatGroups[0]?.filePath
                    : ""
            );
            if (ticketMethod === TICKETS_METHOD.PDF) {
                setSeatGroupsCx(seatGroupsFormat);
            } else {
                setCouponsCx(seatGroupsFormat);
            }
            setNumValueCx(tickets.length);
            setPriceValueCx(ticketPrice);
            setIsSeatingCx(isSeating);
            setAreAdjacentValueCx(seatGroups.length === 1);
            setIsMarkedSeatsCx(isMarkedSeats);
            setTicketMethodValueCx(ticketMethod);
            setHearingAidsNumberCx(hearingAid);
        } catch (err) {
            // showError(_ERROR_MESSAGE);
        }
    };

    const eventDetailsCx = {
        eventName: eventNameCx,
        duration: durationCx,
        description: descriptionCx,
        date: dateCx,
        time: timeCx,
        categories: categoryCx,
        populations: populationCx,
        ageRange: ageRangeCx,
        imageCoordinates: imageCoordinatesCx,
        coverImage: filesManager.coverImage?.link,
        location: locationDetailsCx.location,
        navigation: locationDetailsCx.navigation,
        currentContact: currentAgencyContactCx,
        agencyContacts: agencyContactListCx,
        isSeating: isSeatingCx,
        ticketCount: numValueCx,
        ticketMethod: ticketMethodValueCx,
        seatGroups: seatGroupsCx,
        hearingAids: hearingAidsNumberCx,
        coupons: couponsCx,
        isMarkedSeats: isMarkedSeatsCx,
        vip: vipDetailsCx,
        posterImage: filesManager.posterImage?.link,
    };
    const getDataForPreview = getDetailsForPreview(eventDetailsCx);

    const loadDraftData = () => {
        const eventDetails = getEventDetailsFromLocalStorage();

        const {
            eventName,
            duration,
            description,
            date,
            time,
            categories,
            populations,
            ageRange,
            imageCoordinates,
            coverImage,
            poster,
            location,
            navigation,
            selectedHallIndex,
            currentContact,
            agencyContacts,
            hallName,
            isSeating,
            ticketCount,
            ticketPrice,
            areAdjacent,
            ticketMethod,
            webLink,
            seatGroups,
            hearingAids,
            coupons,
            isMarkedSeats,
            pdfs,
            vip,
        } = eventDetails;

        addDetailsFirst(
            eventName,
            duration,
            description,
            date,
            time,
            categories,
            populations,
            ageRange,
            imageCoordinates
        );

        addDetailsSecond(
            location,
            navigation,
            selectedHallIndex,
            currentContact,
            agencyContacts
        );
        hallName &&
            setLocationDetailsCx((prev) => ({
                ...prev,
                halls: [{ ...prev.halls[0], hallName }],
            }));

        backButtonThirdPage(
            isSeating,
            ticketCount,
            ticketPrice,
            areAdjacent,
            ticketMethod,
            webLink,
            seatGroups,
            hearingAids,
            coupons,
            isMarkedSeats
        );

        saveDetailsForthPage(vip);

        !filesManager.coverImage &&
            coverImage &&
            base64ToFile(coverImage).then((file) => {
                const uploadedFile = filesUploaderCx.upload(file);
                changeCoverImage({ isNew: true, ...uploadedFile });
            });

        !filesManager.posterImage &&
            poster &&
            base64ToFile(poster).then((file) => {
                const uploadedFile = filesUploaderCx.upload(file);
                changePosterImage({ isNew: true, ...uploadedFile });
            });

        const uploadedFiles = pdfs?.map(async (pdf) => {
            const file = await base64ToFile(pdf);
            const uploadedPdf = filesUploaderCx.upload(file);
            return uploadedPdf;
        });

        filesManager.files.length === 0 &&
            uploadedFiles &&
            Promise.all(uploadedFiles).then((uploadedFiles) => {
                changeFiles(
                    uploadedFiles.map((file) => ({ ...file, isNew: true }))
                );
            });
    };

    const addDetailsFirst = (
        eventName: string,
        duration: string,
        description: string,
        date: moment.Moment | null,
        time: moment.Moment | string | null,
        categories: CategoriesArray[],
        populations: PopulationArray[],
        ageRange: AgeRangeArrayItem[],
        imageCoordinates: Area
    ) => {
        setEventNameCx(eventName);
        setDurationCx(duration);
        setDescriptionCx(description);
        date ? setDateCx(date) : setDateCx(null);
        time ? setTimeCx(time) : setTimeCx(null);
        categories && setCategoryCx(categories);
        populations && setPopulationCx(populations);
        ageRange && setAgeRangeCx(ageRange);
        setImageCoordinatesCx(imageCoordinates);
    };

    const addDetailsSecond = (
        location: string | null,
        navigation: string | null,
        selectedHallIndex: number,
        currentContact: number,
        agencyContacts: AgencyContact[]
    ) => {
        clearCx
            ? setLocationDetailsCx({
                  halls: [
                      {
                          selected: false,
                          id: 0,
                          hallName: "",
                          hallNameError: "",
                      },
                  ],
                  location: "",
                  navigation: "",
                  status: "no location found",
              })
            : setLocationDetailsCx((prev) => ({
                  ...prev,
                  ...(location && { location }),
                  ...(navigation && { navigation }),
                  halls: prev.halls.map((hall, index) => {
                      return {
                          ...hall,
                          selected: index === selectedHallIndex,
                      };
                  }),
              }));

        setCurrentAgencyContactCx(currentContact);
        if (agencyContacts && agencyContacts.length !== 0) {
            setAgencyContactListCx([...agencyContacts]);
        }
    };

    const changeCoverImage = (UploadedFile: FileMangerFile) => {
        setFilesManager((prev) => ({
            ...prev,
            coverImage: UploadedFile,
        }));
    };

    const changePosterImage = (UploadFile: FileMangerFile) => {
        setFilesManager((prev) => ({
            ...prev,
            posterImage: UploadFile,
        }));
    };

    const deletePosterImage = () => {
        if (!filesManager.posterImage) return;
        saveInLocalStorage(FORM_FIELDS.POSTER, null);
        filesUploaderCx.delete(
            filesManager.posterImage.id,
            filesManager.posterImage.link
        );
        setFilesManager((prev) => ({
            ...prev,
            posterImage: null,
        }));
    };

    const deleteCoverImage = () => {
        if (!filesManager.coverImage) return;

        filesUploaderCx.delete(
            filesManager.coverImage.id,
            filesManager.coverImage.link
        );
        setFilesManager((prev) => ({
            ...prev,
            coverImage: null,
        }));
    };

    const changeFiles = (UploadedFiles: FileMangerFile[]) => {
        setFilesManager((prev) => ({
            ...prev,
            files: UploadedFiles,
        }));
        !editMode && savePdfsInLocalStorage(UploadedFiles);
    };

    const changeAgencyContacts = (
        currentContact: number,
        agencyContacts: AgencyContact[] | null
    ) => {
        if (agencyContacts && currentContact < agencyContacts.length) {
            setCurrentAgencyContactCx(currentContact);
            if (currentContact === agencyContacts.length - 1) {
                setAgencyContactListCx([...agencyContacts]);
            }
        }
    };

    const saveDetailsForthPage = (eventVipDetails: string) => {
        setVipDetailsCx(eventVipDetails);
    };

    const publishNewEvent = async () => {
        setIsPublishLoading(true);
        const res = await sendNewEvent();

        createdEventId.current = res?.data.id;
        setEventCreatedPopup(true);
        gaEvent("new_event");
        setIsPublishLoading(false);
    };

    const sendNewEvent = async () => {
        if (agencyContactListCx) {
            const DateTime = convertToMilliseconds(`${dateCx}`, `${timeCx}`);
            const serverCategory = categoryCx
                .filter((item) => item.selected)
                .map((item) => ({
                    id: item.id,
                    categoryName: item.categoryName,
                }));

            const serverPopulation = populationCx
                .filter((item) => item.selected)
                .map(({ selected, ...rest }) => rest);
            const isCoupon = ticketMethodValueCx === TICKETS_METHOD.COUPON;

            let newSeatGroupsByMethod;
            isCoupon
                ? (newSeatGroupsByMethod = couponsCx.map((coupon) => ({
                      ...coupon,
                      numSeats: parseInt(coupon.numSeats),
                      webLink: webLinkCx,
                  })))
                : (newSeatGroupsByMethod = seatGroupsCx.map((seatGroup) => {
                      return {
                          ...seatGroup,
                          numSeats:
                              areAdjacentValueCx || !isSeatingCx
                                  ? numValueCx
                                  : seatGroup.numSeats,
                          ticketFilePDF: ["pdf"],
                      };
                  }));

            const duration = convertDurationToMinutes(durationCx);

            let hall =
                locationDetailsCx.halls.find(
                    (item) => item.selected === true
                ) || null;
            if (!hall) throw new Error("no hall selected");

            try {
                const newAgeR = ageRangeCx
                    .filter((age) => age.selected)
                    .map((age) => age.rangeName);
                const res = await filesUploaderCx.post(`/api/event/new-event`, {
                    agencyId: systemID,
                    filesManager: {
                        posterImage: filesManager.posterImage
                            ? {
                                  id: filesManager.posterImage.id,
                                  fileName: filesManager.posterImage.fileName,
                                  ...(!filesManager.posterImage.isNew &&
                                  filesManager.posterImage.id === 0
                                      ? { link: filesManager.posterImage.link }
                                      : {}),
                              }
                            : undefined,
                        coverImage: filesManager.coverImage
                            ? {
                                  id: filesManager.coverImage.id,
                                  ...(!filesManager.coverImage.isNew &&
                                  filesManager.coverImage.id === 0
                                      ? { link: filesManager.coverImage.link }
                                      : {}),
                              }
                            : undefined,
                        files: filesManager.files?.map((item, index) => ({
                            filesUploaderId: item.id,
                            fileName: item.fileName,
                            seatGroupIndex: index,
                        })),
                    },
                    // these fields are from the first page
                    eventName: eventNameCx,
                    date: new Date(DateTime).toISOString(),
                    description: descriptionCx,
                    ticketNum: numValueCx,
                    ticketPrice: priceValueCx,
                    seatGroups: newSeatGroupsByMethod,
                    duration: duration,
                    categories: serverCategory,
                    populations: serverPopulation,
                    ageRange: newAgeR,
                    hearingAid: hearingAidsNumberCx,
                    imageCoordinates: imageCoordinatesCx,
                    //these fields are from the second page
                    contactName:
                        agencyContactListCx[currentAgencyContactCx].name,
                    contactPhone:
                        agencyContactListCx[currentAgencyContactCx].username,
                    location: locationDetailsCx.location,
                    navigation: locationDetailsCx.navigation,
                    hall,
                    //these parameters are from the third page of add new event
                    isSeating: Boolean(isSeatingCx),
                    isMarkedSeats: Boolean(isMarkedSeatsCx),

                    //this parameter is from the forth page
                    vipDetails: vipDetailsCx,
                });

                clearFormDataStorage();
                return res;
            } catch (error: any) {
                setIsPublishLoading(false);
                if (error?.data?.message.includes(DATE_TOO_CLOSE)) {
                    setPageCx(0)
                    setErrorMessage(_ERROR_DATE_TOO_CLOSE)
                }
                else {
                    setErrorMessage(_ERROR_MESSAGE)
                }
                setErrorPopup(true);

                gaException("new_event", "server", true);
                throw new Error("error trying to add new event");
            }
        }
    };

    const sendEditEvent = async (
        numTickets: number,
        priceTickets: number,
        seatGroups: MiniSeatGroup[],
        isSeating: boolean,
        isMarkedSeats: boolean,
        hearingAidsNumber: number
    ) => {
        if (agencyContactListCx) {
            const newAgeR = ageRangeCx
                .filter((age) => age.selected)
                .map((age) => age.rangeName);

            let hall =
                locationDetailsCx.halls.find(
                    (item) => item.selected === true
                ) || null;

            const duration = convertDurationToMinutes(durationCx);

            const serverCategory = categoryCx
                .filter((item) => item.selected)
                .map((item) => ({
                    id: item.id,
                    categoryName: item.categoryName,
                }));

            const DateTime = convertToMilliseconds(`${dateCx}`, `${timeCx}`);

            const serverPopulation = populationCx
                .filter((item) => item.selected)
                .map(({ selected, ...rest }) => rest);

            try {
                await filesUploaderCx.post(`/api/event/edit-event`, {
                    eventId: eventID,
                    eventName: eventNameCx,
                    date: new Date(DateTime).toISOString(),
                    duration: duration,
                    description: descriptionCx,
                    agencyId: systemID,
                    categories: serverCategory,
                    populations: serverPopulation,
                    ageRange: newAgeR,
                    imageCoordinates: imageCoordinatesCx,

                    location: locationDetailsCx.location,
                    navigation: locationDetailsCx.navigation,
                    hall,
                    contactName:
                        agencyContactListCx[currentAgencyContactCx]?.name,
                    contactPhone:
                        agencyContactListCx[currentAgencyContactCx]?.username,

                    ticketNum: numTickets,
                    ticketPrice: priceTickets,
                    isSeating: isSeating,
                    isMarkedSeats: isMarkedSeats,
                    seatGroups: seatGroups,
                    hearingAid: hearingAidsNumber,

                    filesManager: {
                        coverImage: filesManager.coverImage?.isNew
                            ? filesManager.coverImage?.id
                            : undefined,
                        posterImage: filesManager.posterImage?.isNew
                            ? {
                                  id: filesManager.posterImage?.id,
                                  fileName: filesManager.posterImage?.fileName,
                              }
                            : undefined,
                        files: filesManager.files?.map((item, index) => ({
                            filesUploaderId: item.id,
                            fileName: item.fileName,
                            seatGroupIndex: index,
                        })),
                    },
                });
                history.push(`/event/details/${eventID}`);
            } catch (error) {
                setErrorPopup(true);
                setErrorMessage(_ERROR_MESSAGE);
                throw new Error("error trying to edit event");
            }
        }
    };

    const backButtonThirdPage = (
        isSeating: boolean | undefined,
        amountTickets: number | undefined,
        priceTickets: number | undefined,
        areAdjacent: boolean | undefined,
        ticketMethod: TICKETS_METHOD | "",
        webLink: string,
        seatGroups: SeatGroup[] | null,
        hearingAidsNumber: number,
        coupons: Coupons[],
        isMarkedSeats?: boolean
    ) => {
        setIsSeatingCx(isSeating);
        setNumValueCx(amountTickets);
        setPriceValueCx(priceTickets);
        setAreAdjacentValueCx(areAdjacent);
        setTicketMethodValueCx(ticketMethod);
        setWebLinkCx(webLink);
        seatGroups && setSeatGroupsCx(seatGroups);
        setHearingAidsNumberCx(hearingAidsNumber);
        setCouponsCx(coupons);
        setIsMarkedSeatsCx(isMarkedSeats);
    };

    const getThirdPage = (): ThirdPageDetails => {
        return {
            numValueCx,
            priceValueCx,
            areAdjacentValueCx,
            isMarkedSeatsCx,
            ticketMethodValueCx,
            webLinkCx,
            isSeatingCx,
            seatGroupsCx,
            hearingAidsNumberCx,
            couponsCx,
        };
    };

    return (
        <AddNewEventContext.Provider
            value={{
                eventNameCx,
                durationCx,
                descriptionCx,
                isSeatingCx,
                pageCx,
                filesUploaderCx,
                categoryCx,
                populationCx,
                ageRangeCx,
                timeCx,
                dateCx,
                vipDetailsCx,
                currentAgencyContactCx,
                agencyContactListCx,
                imageCoordinatesCx,
                changeAgencyContacts,
                locationDetailsCx,
                filesManager,
                setPageCx,
                addDetailsFirst,
                addDetailsSecond,
                saveDetailsForthPage,
                sendEditEvent,
                setCategoryCx,
                setPopulationCx,
                setLocationDetailsCx,
                backButtonThirdPage,
                getThirdPage,
                changeCoverImage,
                changePosterImage,
                deletePosterImage,
                changeFiles,
                confirmBrowserExit,
                editMode,
                setEditMode,
                deleteCoverImage,
                loadDraftData,
                getDataForPreview,
                setFetchDataFromDB,
                setEventID,
                setIsDuplicated,
                isDuplicated,
                publishNewEvent,
                isPublishLoading,
                fetchDataFromDB,
                setClearCx,
            }}
        >
            {children}
            <ErrorMessageModal
                open={errorPopup}
                setOpen={setErrorPopup}
                message={errorMessage}
            />
            {/* ! try to use instead showError from the errorContext */}
            <PopupEventCreated
                open={eventCreatedPopup}
                handleClose={() => {
                    setEventCreatedPopup(false);
                    history.push(`/event-added/${createdEventId.current}`);
                }}
            />
        </AddNewEventContext.Provider>
    );
};
