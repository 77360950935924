export const base64ToFile = async (image: { base64: string, fileName: string, type: string }) => {
    const blob = await urlToBlob(image.base64);
    const file = new File([blob], image.fileName, { type: image.type })
    return file;
}

export const blobToBase64 = (blob: Blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export const urlToBlob = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
}