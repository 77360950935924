//this component gets props - OrgProfileContactProps
//it returns one contact that belongs to the organization with its name, phonenum and email (we display it in an array of contacts that belong to a specific org)
import { OrgProfileContactProps } from "../consts/interfaces/Organization.interface";
import GenericInput from "./GenericInput";
import { ORG_SIGN_UP } from "../consts/enums/InputsNames";

const ProfileContact: React.FC<OrgProfileContactProps> = ({ setNameError, setPhoneError, setEmailError, setName, setEmail, setPhone, disabled, name, nameError, phoneDisabled, phone, phoneError, email, emailError, secondaryTitle }) => {
    //function that handles the onBlur of the inputs
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (e.target.name) {
            case "contactName":
                setNameError?.(e);
                break;
            case "contactPhone":
                setPhoneError?.(e);
                break;
            case "contactEmail":
                setEmailError?.(e);
                break;
            default:
                break;
        }
    }

    //function that handles the onChange of the inputs
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault()
        switch (e.target.name) {
            case "contactName":
                setName(e);
                break;
            case "contactPhone":
                setPhone(e);
                break;
            case "contactEmail":
                setEmail(e);
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <GenericInput disabled={disabled} value={name} error={nameError} title="איש קשר:" name={ORG_SIGN_UP.CONTACT_NAME} onChange={handleInputChange} onBlur={handleInputBlur} />
            <GenericInput disabled={disabled || phoneDisabled} value={phone} error={phoneError} secondaryTitle={secondaryTitle} title="טלפון:" name={ORG_SIGN_UP.CONTACT_PHONE} onChange={handleInputChange} onBlur={handleInputBlur} characterLimit={10} type='number' />
            <GenericInput disabled={disabled} value={email} error={emailError} title="מייל:" name={ORG_SIGN_UP.CONTACT_EMAIL} onChange={handleInputChange} onBlur={handleInputBlur} />
        </div>
    )
}

export default ProfileContact