import { ARE_ADJANCENT, IS_MARKED, SEATING_OR_STANDING } from "../consts/variables";

export const checkIsSeating = (value: string) => {
    switch (value) {
        case SEATING_OR_STANDING.SEATING:
            return true;
        case SEATING_OR_STANDING.STANDING:
            return false;
        default:
            return undefined;
    }
}

export const checkIsMarked = (value: string) => {
    switch (value) {
        case IS_MARKED.MARKED:
            return true;
        case IS_MARKED.NOT_MARKED:
            return false;
        default:
            return undefined;
    }
}

export const checkIsAdjacent = (value: string) => {
    switch (value) {
        case ARE_ADJANCENT.ADJACENT:
            return true;
        case ARE_ADJANCENT.NOT_ADJACENT:
            return false;
        default:
            return undefined;
    }
}