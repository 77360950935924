import { createTheme, IconButton, ThemeProvider } from "@mui/material";
import "./style/genericMessage.scss";

export interface MessageRecievedProps {
  showYellowDot: boolean;
  onClick?: () => void;
  isHeader?: boolean;
  numOfMessages: number;
}

const MessageReceived: React.FC<MessageRecievedProps> = (props) => {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FED811",
        contrastText: "#41596B"
      }
    }
  })

  return (
    <>
      <div className="message-icon">
        <ThemeProvider theme={theme}> {/* color provider */}
          <IconButton sx={{ padding: "unset" }} onClick={props.onClick}> {/*padding insure that the yellow dot is on the blue icon of messeges */}
            {props.numOfMessages !== 0 ?
              <img src="/icons/message-dot.svg" alt="message" className="message" />
              : <img src="/icons/message.svg" alt="message" className="message" />}
          </IconButton>
        </ThemeProvider>
      </div>

    </>
  );
}

export default MessageReceived