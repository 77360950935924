import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";
import { FocusEvent, useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { ADD_EVENT_THIRD } from "../../../consts/enums/InputsNames";
import { demoEventData } from "../../../consts/eventDetails";
import { ADD_MORE_TICKETS, COUPON_CODE_FOR_EXTERNAL_WEBSITE, HOW_MANY_TICKET_DO_YOU_WANT_TO_GIVE, HOW_WILL_THE_ORGANIZATION_RECEIVE_THE_TICKETS, MAKE_SURE_COUPONS_NUMBER_EQUALS_TICKETS_NUMBER, MAKE_SURE_SEATS_NUMBER_EQUALS_TICKETS_NUMBER, WHAT_IS_THE_VALUE_OF_TICKET } from "../../../consts/hebrew";
import { ANEContextInterface, FileMangerFile } from "../../../consts/interfaces/AddNewEventContext.interface";
import SeatGroup from "../../../consts/interfaces/SeatGroup.interface";
import { EventDetails } from "../../../consts/interfaces/TicketOrder.interface";
import { Coupons } from "../../../consts/interfaces/TicketsCouponMethod.interface";
import UseParamsInterface from "../../../consts/interfaces/UseParams.interface";
import { ADD_EVENT_LEFT } from "../../../consts/leftSideLayouts";
import { COUPONS, FILL_THIS_FIELD, SEAT_GROUP_SUM_ERROR, TICKETS_METHOD, ERROR_EVENT_TOO_SOON as _ERROR_EVENT_TOO_SOON, _SEAT_GROUP } from "../../../consts/variables";
import { convertStringToNumber, positiveInteger } from "../../../functions/convertsFunctions";
import { eventToDismantledDate } from "../../../functions/dateFunctions";
import { codeValidation, fileValidation, linkValidation, numValidation } from "../../../functions/validations";

import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import RadioButtons from "../../../generic-components/RadioButtons";

import EventSeatingDetailsInputs from "./EventSeatingDetailsInputs";
import TicketsCouponMethod from "./TicketsCouponMethod";
import TicketsPDFMethod from "./TicketsPDFMethod";

import { AddNewEventContext } from "../../../context/AddNewEventContext";

import "./style/addMoreTickets.scss";
import { ErrorContext } from "../../../context/ErrorContext";

const AddMoreTickets = () => {

    const { id } = useParams<UseParamsInterface>()
    const eventId = Number(id)

    const [eventDetails, setEventDetails] = useState<EventDetails>(demoEventData)

    const { date, day, time } = eventToDismantledDate(eventDetails.date)

    const [ticketsNum, setTicketNum] = useState<number>();
    const [ticketMethodValue, setTicketMethodValue] = useState<TICKETS_METHOD | ''>("");
    const [areAdjacentValue, setAreAdjacentValue] = useState<boolean>();

    const [seatGroups, setSeatGroups] = useState<SeatGroup[]>([{ ..._SEAT_GROUP }]);
    const [coupons, setCoupons] = useState<Coupons[]>([{ ...COUPONS }]);
    const [files, setFiles] = useState<FileMangerFile[]>([]);
    const [webLink, setWebLink] = useState<string>("");

    const [numError, setNumError] = useState<string>("");
    const [areAdjacentError, setAreAdjacentError] = useState<string>("");
    const [ticketMethodError, setTicketMethodError] = useState<string>("");
    const [seatGroupsTicketsSumError, setSeatGroupsTicketsSumError] = useState('')
    const [webLinkError, setWebLinkError] = useState<string>("");
    const [fileError, setFileError] = useState<string>("");
    const [isWithin5Hours, setIsWithin5Hours] = useState(false);
    const [toggleError, setToggleError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { showError } = useContext(ErrorContext);
    const { filesUploaderCx } = useContext(AddNewEventContext) as ANEContextInterface;
    const history = useHistory()

    useAsyncEffect(async () => {
        // take from context instead?
        const { data } = await axios.get(`/api/event/all-event-details?id=${eventId}`)
        setEventDetails(data)
        setTicketMethodValue(data.seatGroups[0].coupon ? TICKETS_METHOD.COUPON : TICKETS_METHOD.PDF)
        setIsWithin5Hours(new Date(data.date).getTime() - new Date().getTime() < 18_000_000)
        setToggleError(prev => !prev)
    }, [eventId])

    useEffect(() => {
        if (isWithin5Hours) showError(_ERROR_EVENT_TOO_SOON)
    }, [toggleError])

    const handleInputChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number) => {
        switch (name) {
            case "howManyTickets":
                if (value.includes('.')) break;
                setTicketNum(positiveInteger(value))
                break;
            case "webLink":
                setWebLink(value);
                break;
            case "couponCode":
                if (index === undefined) break;
                const CouponsCopyForCodes = [...coupons];
                CouponsCopyForCodes[index].code = value;
                setCoupons(CouponsCopyForCodes);
                break;
            case "numSeatsCoupon":
                if (index === undefined) break;
                const CouponsCopyForNumSeats = [...coupons];
                CouponsCopyForNumSeats[index].numSeats = value;
                setCoupons(CouponsCopyForNumSeats);
                break;
            case "numSeats":
                if (index === undefined) break;
                const SeatGroupsCopy = [...seatGroups];
                SeatGroupsCopy[index].numSeats = convertStringToNumber(value);
                setSeatGroups(SeatGroupsCopy);
                break;
        }
    }

    const handleAreAdjacentSeatsChange = (areAdjacent?: boolean) => {
        setAreAdjacentValue(areAdjacent);

        if (areAdjacent === false && seatGroups.length < 2) {
            setSeatGroups(prev => [...prev, { ..._SEAT_GROUP }])
        }

        if (areAdjacent) {
            setSeatGroups(prev => [prev[0]])
        }
        setAreAdjacentError('')
    }

    const handleValidation = () => {
        const ticketNumVal = numValidation(ticketsNum)
        const areAdjacentValidation = (eventDetails.isSeating && areAdjacentValue === undefined) ? FILL_THIS_FIELD : '';
        const ticketMethodVal = ticketMethodValue ? '' : FILL_THIS_FIELD;
        const areSeatGroupsValid = validateSeats();

        if (!ticketNumVal && !areAdjacentValidation && areSeatGroupsValid && !ticketMethodVal && !isWithin5Hours) {
            return true
        }

        setNumError(ticketNumVal);
        setAreAdjacentError(areAdjacentValidation)
        setTicketMethodError(ticketMethodVal)
        setIsLoading(false)
        if (isWithin5Hours) setToggleError(prev => !prev)
        return false;
    }

    const handleSubmit = async (e: React.MouseEvent) => {
        e.preventDefault();
        setIsLoading(true)
        if (!handleValidation()) return;
        const couponBody = coupons.map(({ code, numSeats }) => ({ code, numSeats, webLink }))
        const seatGroupBody = seatGroups.length === 1 ? [{ ...seatGroups[0], numSeats: ticketsNum }] : seatGroups;
        const filesManager = files.map((file, i) => ({
            filesUploaderId: file.id,
            fileName: file.fileName,
            seatGroupIndex: i,
        }))
        const body = { seatGroups: ticketMethodValue === TICKETS_METHOD.PDF ? seatGroupBody : couponBody, filesManager }
        try {
            const { data } = await filesUploaderCx.post(`/api/event/add-tickets/${eventId}`, body)
            setIsLoading(false)
            history.goBack()

        } catch (err) {
            setIsLoading(false)
            setToggleError(prev => !prev)
        }
    }

    const validateSeats = () => {
        if (ticketMethodValue === TICKETS_METHOD.COUPON) {
            let valid = true;
            let numSeatsCouponsSum = 0;
            coupons.forEach((coupon, index) => {
                numSeatsCouponsSum += Number(coupon.numSeats);
                const codeVal = codeValidation(coupon.code);
                const numSeatsVal = numValidation(coupon.numSeats);
                coupons[index].codeError = codeVal;
                coupons[index].numSeatsError = numSeatsVal;
                if (codeVal || numSeatsVal) {
                    valid = false;
                }
            });
            const webLinkVal = linkValidation(webLink);
            if (webLinkVal) {
                setWebLinkError(webLinkVal);
                valid = false;
            }
            if (numSeatsCouponsSum !== Number(ticketsNum)) {
                setSeatGroupsTicketsSumError(MAKE_SURE_COUPONS_NUMBER_EQUALS_TICKETS_NUMBER)
                valid = false;
            } else {
                setSeatGroupsTicketsSumError('')
            }
            return valid;
        }

        else if (seatGroups.length === 1) {
            const ticketFilePDFError = fileValidation(fileError, files[0]?.fileName || '')
            setFileError(ticketFilePDFError)
            const isSumOfAccessValid = Number(ticketsNum) - seatGroups[0].wheelchairAccessible >= 0 &&
                Number(ticketsNum) - seatGroups[0].mobilityDifficultiesAccessible >= 0;
            setSeatGroups(prev => [{ ...prev[0], sumOfAccessTicketsError: isSumOfAccessValid ? '' : SEAT_GROUP_SUM_ERROR }])
            return !ticketFilePDFError && isSumOfAccessValid
        }

        let isValid = true;
        let ticketsSum = 0;
        const validatedSeatGroups = seatGroups.map(seatGroup => {
            const ticketFilePDFError = seatGroups.length > 1 ? fileValidation(seatGroup.ticketFilePDFError, seatGroup.ticketFilePDF[0]) : '';
            const numOfSeats = numValidation(seatGroup.numSeats)
            const isSumOfAccessValid = Number(seatGroup.numSeats) - seatGroup.wheelchairAccessible >= 0 &&
                Number(seatGroup.numSeats) - seatGroup.mobilityDifficultiesAccessible >= 0
            const numSeatsError = numOfSeats;
            const sumError = isSumOfAccessValid ? '' : SEAT_GROUP_SUM_ERROR
            if (ticketFilePDFError || numSeatsError || !isSumOfAccessValid) isValid = false;

            ticketsSum += Number(seatGroup.numSeats);

            return {
                ...seatGroup,
                ticketFilePDFError,
                numSeatsError,
                sumOfAccessTicketsError: sumError
            }
        })
        setSeatGroups(validatedSeatGroups);

        if (ticketsSum !== Number(ticketsNum) && seatGroups.length > 1) {
            setSeatGroupsTicketsSumError(MAKE_SURE_SEATS_NUMBER_EQUALS_TICKETS_NUMBER)
            isValid = false;
        } else {
            setSeatGroupsTicketsSumError('')
        }
        return isValid;
    }

    return (
        <GenericSplitPage squares={ADD_EVENT_LEFT} serverLoading={false}>
            <form className="add-more-tickets">
                <h2>
                    {ADD_MORE_TICKETS.ADD_TICKETS}
                </h2>
                <div className="event-details">
                    <b>{eventDetails.eventName}</b> {day} | {date} | {time}
                </div>

                <GenericInput
                    title={HOW_MANY_TICKET_DO_YOU_WANT_TO_GIVE}
                    type="number"
                    value={ticketsNum === undefined ? "" : ticketsNum}
                    name={ADD_EVENT_THIRD.HOW_MANY_TICKETS}
                    characterLimit={3}
                    hideCharacterLimit
                    onChange={handleInputChange}
                    error={numError}
                />

                <div className="ticket-worth-input">
                    <GenericInput
                        disabled
                        title={WHAT_IS_THE_VALUE_OF_TICKET}
                        name={ADD_EVENT_THIRD.TICKET_PRICE}
                        type="number"
                        characterLimit={4}
                        hideCharacterLimit
                        value={eventDetails.ticketPrice}
                    />
                    <p className="shekel-sign">₪</p>
                </div>

                <EventSeatingDetailsInputs
                    disableIsSeating
                    disableIsMarkedSeats
                    isSeating={eventDetails.isSeating}
                    isSeatingError={""}
                    isMarkedSeats={eventDetails.isMarkedSeats}
                    isMarkedSeatsError={""}
                    areAdjacent={areAdjacentValue}
                    areAdjacentError={areAdjacentError}
                    handleIsSeatingChange={() => { }}
                    handleMarkedSeatsChange={() => { }}
                    handleAreAdjacentSeatsChange={handleAreAdjacentSeatsChange}
                />

                <div className="add-event-input-title">{HOW_WILL_THE_ORGANIZATION_RECEIVE_THE_TICKETS}?</div>
                <RadioButtons
                    disabled
                    className="add-new-event"
                    value={ticketMethodValue}
                    dontHaveLabel
                    handleChange={() => { }}
                    options={[
                        {
                            label:
                                <TicketsPDFMethod
                                    isTicketMethodPDF={ticketMethodValue === TICKETS_METHOD.PDF}
                                    isSeating={eventDetails.isSeating}
                                    areAdjacent={areAdjacentValue}
                                    seatGroupsTicketsSumError={seatGroupsTicketsSumError}
                                    seatGroups={seatGroups}
                                    files={files}
                                    fileError={fileError}
                                    setSeatGroups={setSeatGroups}
                                    setFiles={setFiles}
                                    setFileError={setFileError}
                                    handleInputChange={handleInputChange}
                                    handleInputBlur={() => { }}
                                    ticketsNum={Number(ticketsNum)}
                                />,
                            value: TICKETS_METHOD.PDF
                        },
                        {
                            label: <>
                                {COUPON_CODE_FOR_EXTERNAL_WEBSITE}
                                {ticketMethodValue === TICKETS_METHOD.COUPON
                                    ?
                                    <TicketsCouponMethod
                                        coupons={coupons}
                                        webLink={webLink}
                                        webLinkError={webLinkError}
                                        seatGroupsTicketsSumError={seatGroupsTicketsSumError}
                                        setCoupons={setCoupons}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={function (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, index?: number | undefined): void {
                                        }}
                                        editMode={false} />
                                    : null}
                            </>,

                            value: TICKETS_METHOD.COUPON
                        }
                    ]}
                />
                <div className="error">
                    {ticketMethodError}
                </div>


                <div className="buttons-container">
                    <button type="button" className="cancel-btn" onClick={() => history.goBack()} >{ADD_MORE_TICKETS.CANCEL}</button>
                    <GenericButton className="small cyan" handleClick={handleSubmit}>
                        {ADD_MORE_TICKETS.ADD}
                    </GenericButton>
                </div>
            </form>
        </GenericSplitPage>
    )
}

export default AddMoreTickets;