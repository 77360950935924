export enum OrderEvents {
    ordered_events = 'ארועים שהוזמנו',
    soon_events = 'האירועים הקרובים שהזמנת',
    there_are_no_soon_events = 'אין הצעות לאירועים כרגע',
    past_events = 'אירועים שהתקיימו',
    there_are_no_past_events = 'אין אירועים שהתקיימו',
    tickets = "כרטיסים",
    ordered = 'הוזמנו',
    single_ticket_ordered = 'הוזמן כרטיס אחד',
    want_to_thank = 'רוצים להגיד תודה?',
    thanks_for_feedback = 'תודה על הפידבק!',
    amount = 'כמות:',
    events = 'אירועים',
    one_event = 'אירוע אחד',
    show_all_events = 'לצפייה בכל האירועים הקרובים',
    show_soon_events = 'לצפייה באירועים הקרובים'
}