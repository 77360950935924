import { useFiles } from "@hilma/fileshandler-client";
import { useAsyncEffect } from "@hilma/tools";
import axios from 'axios';
import moment, { Moment } from "moment";
import React, { useContext, useMemo, useState } from "react";
import { Area } from 'react-easy-crop';
import { useHistory } from "react-router-dom";
import { BenefitSeatGroup } from "../consts/entranceTicketsTypes";
import { BenefitFilesManager, BenefitFirstPageDetails, BenefitSecondPageDetails, BenefitThirdPageDetails, EntranceTicketsContextInterface } from "../consts/interfaces/AddEntranceTicketsContext.interface";
import { AgencyContact, FileMangerFile } from "../consts/interfaces/AddNewEventContext.interface";
import { AgeRangeArrayItem, AgeRangeServer } from "../consts/interfaces/AgeRange.interface";
import { CategoriesArray } from "../consts/interfaces/Categories.interface";
import { PlaceTypeServer } from '../consts/interfaces/PlaceType.interface';
import { PopulationArray, PopulationArrayServer } from "../consts/interfaces/PopulationArray.interface";
import { EMPTY_BENEFIT_SEATGROUP, TICKETS_METHOD, _ERROR_SEND_MESSAGE_ } from "../consts/variables";
import { filterSelected } from "../functions/arrayUtils";
import { ErrorContext } from "./ErrorContext";
import { UserContext } from "./UserContext";

export const AddEntranceTicketsContext = React.createContext<EntranceTicketsContextInterface | null>(null);

export const AddEntranceTicketsProvider: React.FC = ({ children }) => {

    const { systemID } = useContext(UserContext)
    const { showError } = useContext(ErrorContext);

    const filesUploaderCx = useFiles()
    const history = useHistory();

    const defaultExpirationDate = useMemo(() => {
        const date = new Date()
        date.setFullYear(date.getFullYear() + 1)
        return moment(date)
    }, [])

    const [page, setPage] = useState<number>(0)

    const [filesManager, setFilesManager] = useState<BenefitFilesManager>({ coverImage: null, files: [] })

    const [extraInfoCx, setExtraInfoCx] = useState<string>('')
    const [ageRangesCx, setAgeRangesCx] = useState<AgeRangeArrayItem[]>([])
    const [categoriesCx, setCategoriesCx] = useState<CategoriesArray[]>([])
    const [populationsCx, setPopulationsCx] = useState<PopulationArray[]>([])
    const [imageCoordinatesCx, setImageCoordinatesCx] = useState<Area>({ x: 0, y: 0, width: 100, height: 100 })

    const [locationCx, setLocationCx] = useState<string>('')
    const [navigationCx, setNavigationCx] = useState<string>('')
    const [availableContactIndxCx, setAvailableContactIndxCx] = useState<number>(0)
    const [agencyContacts, setAgencyContacts] = useState<AgencyContact[]>([])

    const [ticketMethod, setTicketMethod] = useState<TICKETS_METHOD>()

    const [seatGroupsCx, setSeatGroupsCx] = useState<BenefitSeatGroup[]>([{ ...EMPTY_BENEFIT_SEATGROUP }])
    const [webLinkCx, setWebLinkCx] = useState<string>("")
    const [ticketNumCx, setTicketNumCx] = useState<string>("")
    const [singleTicketPriceCx, setSingleTicketPriceCx] = useState<string>("")
    const [expirationDateCx, setExpirationDateCx] = useState<Moment | null>(defaultExpirationDate)
    const [hearingAidsNumberCx, setHearingAidsNumberCx] = useState<number>(0)

    useAsyncEffect(async () => {
        if (!systemID) return;

        const ageRanges: AgeRangeServer[] = (await axios.get('/api/age-range/get-all-ages')).data
        setAgeRangesCx(ageRanges.map(agerange => ({ ...agerange, selected: false })))

        const placeTypes: PlaceTypeServer[] = (await axios.get('/api/place-type')).data
        setCategoriesCx(placeTypes.map(({ id, type }) => ({ id, categoryName: type, selected: false })))

        const populations: PopulationArrayServer[] = (await axios.get('/api/population')).data
        setPopulationsCx(populations.map(({ id, populationName }) => ({ id, title: populationName, selected: false })))

        const agencyContacts: AgencyContact[] = (await axios.get(`/api/agency/agency-contacts?id=${systemID}`)).data;
        setAgencyContacts(agencyContacts.concat({ name: "", username: "", id: "" }))
    }, [systemID])

    const saveFirstPageDetails = ({ extraInfo, ageRanges, categories, populations, imageCoordinates }: BenefitFirstPageDetails) => {
        setExtraInfoCx(extraInfo)
        setAgeRangesCx(ageRanges)
        setCategoriesCx(categories)
        setPopulationsCx(populations)
        setImageCoordinatesCx(imageCoordinates)
    }

    const saveSecondPageDetails = ({ location, navigation, availableContactIndx }: BenefitSecondPageDetails) => {
        setLocationCx(location)
        setNavigationCx(navigation)
        setAvailableContactIndxCx(availableContactIndx)
    }

    const saveThirdPageDetails = ({ ticketNum, singleTicketPrice, expirationDate, hearingAidsNumber, seatGroups, webLink }: BenefitThirdPageDetails) => {
        setTicketNumCx(ticketNum)
        setSingleTicketPriceCx(singleTicketPrice)
        setExpirationDateCx(expirationDate)
        setHearingAidsNumberCx(hearingAidsNumber)
        setSeatGroupsCx(seatGroups)
        setWebLinkCx(webLink)
    }

    const getThirdPageDetails = (): BenefitThirdPageDetails => ({
        ticketNum: ticketNumCx,
        singleTicketPrice: singleTicketPriceCx,
        expirationDate: expirationDateCx,
        webLink: webLinkCx,
        seatGroups: seatGroupsCx,
        hearingAidsNumber: hearingAidsNumberCx
    })

    const postBenefitTickets = async ({ webLink, singleTicketPrice, expirationDate, hearingAidsNumber, seatGroups }: BenefitThirdPageDetails) => {
        try {
            const res = await filesUploaderCx.post('api/benefit/add-benefit-tickets', {
                agencyId: systemID,
                information: extraInfoCx,
                ageRanges: filterSelected(ageRangesCx),
                populations: filterSelected(populationsCx),
                placeTypes: filterSelected(categoriesCx).map(item => ({ ...item, type: item.categoryName })),
                location: locationCx,
                navigation: navigationCx,
                contactName: agencyContacts[availableContactIndxCx].name,
                contactPhone: agencyContacts[availableContactIndxCx].username,
                hearingAids: hearingAidsNumber,
                imageCoordinates: imageCoordinatesCx,
                expirationDate,
                coverImage: filesManager.coverImage?.id,
                ticketWorth: Number(singleTicketPrice),
                seatGroups,
                webLink,
            })

            if (res) {
                history.push('/')
            }
        } catch (error) {
            showError(_ERROR_SEND_MESSAGE_)
        }
    }

    const changeCoverImage = (UploadedFile: FileMangerFile) => {
        setFilesManager(prev => ({
            ...prev,
            coverImage: UploadedFile
        }))
    }

    const deleteCoverImage = () => {
        if (!filesManager.coverImage) return;

        filesUploaderCx.delete(filesManager.coverImage.id, filesManager.coverImage.link)
        setFilesManager(prev => ({
            ...prev,
            coverImage: null
        }))
    }

    const gotoNextPage = () => {
        setPage(prev => prev + 1)
    }

    const gotoPrevPage = () => {
        setPage(prev => prev - 1)
    }

    return (
        <AddEntranceTicketsContext.Provider value={{
            filesUploaderCx,
            extraInfoCx,
            ageRangesCx,
            categoriesCx,
            populationsCx,
            imageCoordinatesCx,
            locationCx,
            navigationCx,
            agencyContacts,
            filesManager,
            page,
            ticketMethod,
            setAgencyContacts,
            setTicketMethod,
            changeCoverImage,
            deleteCoverImage,
            saveFirstPageDetails,
            saveSecondPageDetails,
            saveThirdPageDetails,
            postBenefitTickets,
            getThirdPageDetails,
            gotoNextPage,
            gotoPrevPage,
        }}>
            {children}
        </AddEntranceTicketsContext.Provider>
    )
}
