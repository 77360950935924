import React, { useMemo } from 'react';
import clsx from 'clsx';
import { GenericLeftSideLayoutProps, LeftSideLayoutSquare } from '../consts/interfaces/GenericLeftSideLayout.interface';
import '../style/genericLeftSideLayout.scss';

const Square: React.FC<LeftSideLayoutSquare> = ({ color, icon, text, className, flex }) => (
    <div className={clsx("square", className)} style={{ backgroundColor: color, flex }}>
        {icon && <img src={icon} alt='' />}
        {text && <div>
            {Array.isArray(text) ?
                text.map((text, index) =>
                    typeof text === "string" ?
                        text :
                        <div key={index} className={clsx(text.className, { "big-text": text.big }, { "gray-text": text.gray })}>
                            {text.text}
                        </div>
                ) :
                text
            }
        </div>}
    </div>
);

const GenericLeftSideLayout: React.FC<GenericLeftSideLayoutProps> = ({ squares }) => {

    const [squares1, squares2] = useMemo(() => {
        if (!squares) return [];

        const firstSquaresNum = Math.floor(squares.length / 2)
        const arr1 = squares.slice(0, firstSquaresNum)
        const arr2 = squares.slice(firstSquaresNum)

        return [arr1, arr2]
    }, [squares])

    return (
        <div className="left-side-layout">
            <div className="squares-container">
                {squares1?.map((square, index) =>
                    <Square key={index} {...square} />
                )}
            </div>

            <div className="squares-container">
                {squares2?.map((square, index) =>
                    <Square key={index} {...square} />
                )}
            </div>
        </div>
    )
}

export default GenericLeftSideLayout;