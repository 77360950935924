import React from "react"
import { Typography } from "@mui/material"
import { MY_DONATIONS, SHEKEL_SIGN } from "../../../consts/hebrew"
import './style/myDonationsMobile.scss'

const MyDonationsMobile: React.FC<{ donationValue?: number }> = ({ donationValue }) => {

    return (
        <div className="my-donations-mobile">
            <div className="img-container"><img src="/icons/present.svg" alt="present" /></div>
            <Typography variant="h3" component='div' children={`${donationValue || 0} ${SHEKEL_SIGN}`} />
            <Typography variant="caption" component='div' children={MY_DONATIONS} />
        </div>
    )
}

export default MyDonationsMobile