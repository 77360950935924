//404 page - when a page isn't accessible for some reason
//(like an event that isn't supposed to be shown to this organization and so on)

import { useHistory } from "react-router-dom";
import "./style/pageNotFound.scss";

const PageNotFound: React.FC = () => {

    document.title = 'דף לא נמצא'
    const history = useHistory();

    const handleClick = () => {
        history.push("/")
    }

    return (
        <>
            <div className='page-not-found-page'>
                <div className="four-o-four">
                    <div className="four">4</div>
                    <img src="/icons/tickets.svg" className="tickets" alt="tickets" />
                    <div className="four">4</div >
                </div>
                <div className="text">
                    Page Not Found
                </div>
                <div className='text return-button' onClick={handleClick}>
                    חזור לדף הראשי
                </div>
            </div >
        </>
    )
}
export default PageNotFound;