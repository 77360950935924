export enum NOT_RELEVANT {
    FEEDBACK_NOT_RELEVANT = 'משוב לא רלוונטי',
    TIME_NOT_GOOD="מועד זה לא מתאפשר לנו",
    TOO_SOON = 'התראה קצרה מידי',
    NOT_MATCH = 'האירוע לא מתאים באופי שלו',
    PASS_TO_NEXT_ORG = 'נעביר את ההצעה לעמותה אחרת',
    DONT_WORRY_WE_WILL_OFFER_AGAIN = 'אל דאגה, נמשיך להציע אירועים נוספים',
    WHY_NOT_RELEVANT = 'נשמח לדעת מדוע לא רלוונטי?',
    MAKE_BETTER_MATCH = 'כך נוכל לדייק את הצעות האירועים',
    ENTER_TEXT = 'הקלד טקסט',
    WHICH_CATEGORIES_ARE_NOT_RELEVANT = 'אנא סמנו אילו מבין הקטגוריות הבאות הן אינן מתאימות לעמותה שלכם:',
    HAVE_TO_FILL = 'יש למלא שדה זה',
    THANKS_FOR_FEEDBACK = "תודה על הפידבק!",
    EVENT_WAS_PASSED = "האירוע הועבר לעמותה נוספת, אל דאגה נמשיך להעביר לכם אירועים נוספים",
    OTHER = "אחר"
}