//this component gets props - PopupJoiningConditionsMobileProps
//it returns a popup that shows the user the left side of the registration for the org

import React from "react";

import { PopupJoiningConditionsMobileProps, ReturnArray } from "../../consts/interfaces/PopupJoiningConditionsMobile.interface";
import { TITLES } from "../../consts/hebrew";
import GenericPopup from "../../generic-components/GenericPopup";
import "../../style/genericPopup.scss";

const PopupJoiningConditionsMobile: React.FC<PopupJoiningConditionsMobileProps> = ({ open, handleClose }) => {

    const myArr: Array<ReturnArray> = [
        { src: "/icons/hands.svg", text: "עמותה חברתית" },
        { src: "/icons/check-circle.svg", text: "אישור לניהול תקין" },
    ]

    return (
        <div>
            <GenericPopup
                title={TITLES.CONDITIONALS_FOR_JOINING}
                open={open}
                handleClose={handleClose}
                className="joining-conditions"
            >
                <div className="joining-conditions">

                    {myArr.map((object, i) =>
                        <div key={i}>
                            <img src={object.src} alt='' />
                            <div className="icon-text">{object.text}</div>
                        </div>)}

                </div>
            </GenericPopup>
        </div>
    )
}

export default PopupJoiningConditionsMobile