import React from "react";
import GenericToolTip from "../GenericToolTip";
import './style/genericUserForContact.scss';
interface ContactChooseProps {
    username: string;
    name: string;
    email: string;
    selectedId?: number | null;
    id: number;
    handleChange?: () => void;
}
const ContactChoose: React.FC<ContactChooseProps> = ({ handleChange, username, name, email }) => {

    return (
        <div className="user-row">
            <div className="user-details">
                <div className="name-user-row">{name}</div>
                <div className="phone-details">{username}</div>
                <GenericToolTip name="email-tool-tip" title={email}>
                    <div className="email-details">{email}</div>
                </GenericToolTip>
            </div>
        </div >


    )
}
export default ContactChoose;