import { useState } from "react";
import clsx from "clsx";

import { LoginText } from "../../../consts/enums/login.enum";
import { TITLES } from "../../../consts/hebrew";

import LoginRightSecond from "./LoginRightSecond";
import LoginRightFirst from "./LoginRightFirst";
import LoginLeft from "./LoginLeft";

import "./style/login.scss";

const Login: React.FC = () => {
    document.title = LoginText.connecting

    const [page, setPage] = useState<"first" | "second">("first");
    const [phoneNum, setPhoneNum] = useState<string>("");

    return (
        <div className='whole-login'>
            <div className="login-container">
                <div className="login-right-container">
                    <div className="login-right-content">
                        <div>
                            <div className="login-logo">
                                <img src="/icons/new-contact.svg" alt="user logo" />
                            </div>
                            <div className="login-title">{TITLES.LOGIN_USERS}</div>
                        </div>

                        {page === "first" ?
                            <LoginRightFirst setPage={setPage} setPhoneNum={setPhoneNum} />
                            :
                            <LoginRightSecond phone={phoneNum} />
                        }
                    </div>
                </div>

                <div className={clsx("login-left-container", { "hidden-content": page === "second" })} >
                    <LoginLeft />
                </div>
            </div>
        </div >
    )
}

export default Login;