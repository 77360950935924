import { useContext } from "react";
import { GenericDropDownProps } from "../consts/interfaces/GenericDropDown.interface";
import { UserContext } from "../context/UserContext";
import FocusAble from "./FocusAble";

import "../style/genericDropDown.scss";

const GenericDropDown: React.FC<GenericDropDownProps> = ({ isOpen, elements, className }) => {
    const { role } = useContext(UserContext)

    return (
        <div className={`dropdown-container ${className ?? ''} ${isOpen ? "shown" : "hidden"}`}>
            {elements.map(element => {
                return (
                    <FocusAble key={element.label} className={`dropdown-element ${element.className ?? ''}`} onClick={element.onClick}>
                        <div className={`element-text  ${role === 'agency' && role}`}>
                            {element.icon ?? null}
                            {element.label}
                        </div>
                        {element.badgeCount && element.badgeCount !== 0 &&
                            <div className="messages-count-badge">
                                {element.badgeCount}
                            </div>
                        }
                    </FocusAble>
                )
            })}
        </div>
    )
}

export default GenericDropDown


