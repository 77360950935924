import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLogout } from "@hilma/auth";
import { counterProvider } from "../../context/CountMessagesContext";
import { UserContext } from "../../context/UserContext";
import { header } from "../enums/header.enum";
import { useConfig } from "../../context/ConfigContext";
import { useMediaQuery } from "@mui/material";

const useMenuItems = (unauthenticated = false, role = "") => {
    const history = useHistory();
    const logout = useLogout();
    const { loggingIn } = useContext(UserContext);
    const { count } = useContext(counterProvider);
    const { featureFlags } = useConfig();
    const featureIsEnabled = featureFlags['update_agency_profile'];
    const isMobile = useMediaQuery('(max-width: 768px)');

    const isAgency = role === "agency"

    let dropDownItems = [];

    if (unauthenticated) {
        dropDownItems = [
            {
                label: header.login,
                onClick: () => history.push('/login'),
                iconSrc: ""
            },
        ];
    } else if (isAgency) {
        dropDownItems = [
            {
                label: header.agency_profile,
                onClick: () => history.push('/agency-profile'),
                iconSrc: "/icons/profile-user-nav.svg"
            },
            {
                label: header.events,
                onClick: () => history.push('/archive'),
                badgeCount: count !== 0 ? count : undefined,
                iconSrc: "/icons/archive-nav.svg"

            },
            {
                label: header.logout,
                onClick: () => {
                    loggingIn("", "", "", 0, '', "");
                    history.push('/');
                    logout();
                },
                iconSrc: "/icons/logout.svg"

            },
        ];

    } else {
        dropDownItems = [
            {
                label: header.org_profile,
                onClick: () => { history.push('/org-profile') },
                iconSrc: "/icons/profile-user-nav.svg"
            },
            {
                label: header.soon_events,
                onClick: () => { history.push('/future-ordered-events') },
                iconSrc: "/icons/archive-nav.svg"
            },
            {
                label: header.feedbacks,
                onClick: () => { history.push('/past-ordered-events') },
                iconSrc: "/icons/tickets-nav.svg"
            }
        ];


        if (featureIsEnabled) {
            dropDownItems.push({
                label: header.benefits,
                onClick: () => history.push('/benefits'),
                iconSrc: "/icons/heart.svg"
            });
        }

        dropDownItems.push({
            label: header.logout,
            onClick: () => {
                loggingIn("", "", "", 0, '', "");
                history.push('/');
                logout();
            },
            iconSrc: "/icons/logout.svg"
        });
    }

    if (isMobile) {
        dropDownItems.push({
            label: header.make_contact,
            onClick: () => history.push('/contact-us'),
            iconSrc: "/icons/contact-nav.svg"
        });
    }

    return dropDownItems;
};

export default useMenuItems;
