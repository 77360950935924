//this component gets props - PopupCategoryProps
//it returns a popup of categories for an event

import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { DemoInterface } from '../../consts/interfaces/Demographic.interface';
import { PopupDemoProps } from '../../consts/interfaces/PopupDemo.interface';
import { selectAtLeastOneValidator } from '../../functions/validations';

import GenericCheckbox from '../../generic-components/GenericCheckbox';
import GenericButton from '../../generic-components/GenericButton';
import GenericPopup from '../../generic-components/GenericPopup';

import '../style/popupDemo.scss';

const PopupDemo: React.FC<PopupDemoProps> = ({ open, demo, setDemo, handleClose, setDemoError }) => {
    const [editCategories, setEditcategories] = useState<DemoInterface[]>([])

    useEffect(() => {
        if (open) {
            const coppiedDemo = demo.map((demo) => {
                return {
                    id: demo.id,
                    selected: demo.selected,
                    demographicName: demo.demographicName
                }
            })
            setEditcategories(coppiedDemo)
        }
    }, [open])

    //adds a v next to a category that has been click
    const handleChange = (id: number) => {
        const newCategories = [...editCategories];
        newCategories[newCategories.findIndex(item => item.id === id)].selected = !newCategories[newCategories.findIndex(item => item.id === id)].selected;
        setEditcategories(newCategories)
    }

    const saveNewCategories = () => {
        setDemo([...editCategories])
        const demogVal = selectAtLeastOneValidator([...editCategories])
        setDemoError(demogVal)
        handleClose && handleClose()
    }

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)'); // device status (if true its phone mode otherwise its desktop mode)

    return (
        <GenericPopup
            open={open}
            className='demographic'
            handleClose={handleClose}
        >
            <div className='category-container'>
                <div className='title'>בחר את קהלי היעד הרלוונטיות לעמותה שלך</div>

                {/* <SearchBarCategories arr={props.categories} setArr={props.setCategories} initial={props.initialCategory} /> */}

                <form>
                    <div className='demog-box'>
                        {
                            editCategories.length ?
                                editCategories.map((item, index) => {

                                    return (
                                        <div key={index} className='category-item'>
                                            <GenericCheckbox className='-category-checkbox' title={item.demographicName} id={index} checked={item.selected} handleChange={() => handleChange(item.id)} />
                                            {/* <div className='category-name'>{item.demographicName}</div> */}
                                        </div>
                                    )
                                }) :
                                <div className='no-found-type'>אין סוגי אירוע לפי ערך החיפוש</div>
                        }
                    </div>
                </form>
                <GenericButton className={`yellow  ${tabletMediaQuery ? "big" : "small"}`} handleClick={saveNewCategories}>אישור</GenericButton>
            </div>
        </GenericPopup >
    )
}
export default PopupDemo;