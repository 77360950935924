import useImageCoordinates from '../consts/hooks/useImageCoordinates';
import GenericProfilePicProps from '../consts/interfaces/GenericProfilePic.interface';
import '../style/genericProfilePic.scss';

const GenericProfilePic: React.FC<GenericProfilePicProps> = ({ src, imageCoordinates, className, style }) => {

    const imageCoordinatesStyle = useImageCoordinates(imageCoordinates);

    return (
        <div className={"profile-pic-wrapper " + (className || "")} style={style}>
            <img
                style={src ? imageCoordinatesStyle : undefined}
                src={src}
                alt="profile"
                onError={(e) => {
                    e.currentTarget.src = "/icons/new-contact.svg";
                    e.currentTarget.setAttribute("style", "")
                }}
            />
        </div>
    )
}
export default GenericProfilePic;