//events ordered shows all the events an organization has ordered in two sections 
// - the events that r coming up and the events that have already passed
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useAsyncEffect } from "@hilma/tools";
import { Skeleton } from "@mui/material";

import { EventInfoInterface, EventInfoInterfaceFromServer } from "../../../consts/interfaces/Event.interface";
import { OrderEvents } from '../../../consts/enums/events.enum';
import { _ERROR_MESSAGE } from "../../../consts/variables";

import { SocketContext } from "../../../context/SocketContext";
import { ErrorContext } from "../../../context/ErrorContext";
import { UserContext } from "../../../context/UserContext";

import EventsHappened from "./PastEvents";

import "./style/eventsOrdered.scss";

const EventsOrdered: React.FC = () => {
    document.title = OrderEvents.ordered_events

    const { systemID } = useContext(UserContext);
    const { showError } = useContext(ErrorContext)
    const { eventEdited, removeEvent } = useContext(SocketContext)
    const eventTimeouts = useRef<{ [id: number]: NodeJS.Timeout }>({});
    const firstTry = useRef(true)

    const [eventsArray, setEventsArray] = useState<EventInfoInterfaceFromServer[] | undefined>()
    const [eventsFutureArray, setEventsFutureArray] = useState<EventInfoInterface[]>([])
    const [eventsPastArray, setEventsPastArray] = useState<EventInfoInterface[]>([])
    const [loading, setLoading] = useState(true) //for socket

    //gets from database all events that the organization has purchased
    useAsyncEffect(async () => {
        try {
            if (systemID) {
                const events = await axios.get(`/api/ticket/getEventsPurchased?organizationId=${systemID}`)
                setEventsArray(events.data)
            }
        } catch (error) {
            showError(_ERROR_MESSAGE)
            throw error
        }
    }, [systemID])

    useEffect(() => { // this sort the events by date to two arrays - past and future
        if (eventsArray) {
            if (eventsArray.length) {
                const { past, future } = eventsArray.reduce((allEvents, event) => {
                    const array = isPast(new Date(event.date)) ? allEvents.past : allEvents.future;
                    array.push({
                        coverImage: event.coverImage,
                        posterImage: event.posterImage,
                        eventName: event.eventName,
                        date: event.date,
                        ticketsPurchased: event.ticketsNumOrdered,
                        eventId: event.id,
                        description: "",
                        address: "",
                        wholeDate: "",
                        demographics: 0,
                        imageCoordinates: event?.imageCoordinates,
                        vipDetails: event?.vipDetails,
                        seatGroups: event?.seatGroups,
                        ticketsPurchasedByOrg: event.ticketsPurchasedByOrg
                    });
                    return allEvents;
                }, {
                    past: [] as EventInfoInterface[],
                    future: [] as EventInfoInterface[]
                });
                setEventsFutureArray(future);
                setEventsPastArray(past);
            }
            setLoading(false);
        }
    }, [eventsArray, systemID])

    useEffect(() => { // when event is deleted completely, it removes it from the future array
        if (removeEvent) {
            setEventsFutureArray(prev => {
                return prev.filter(event => event.eventId !== removeEvent)
            })
        }
    }, [removeEvent])

    useEffect(() => {
        if (firstTry.current && eventsFutureArray.length) {
            eventsFutureArray.forEach(originalEvent => {
                const eventDate = new Date(originalEvent.date);
                const delay = eventDate.getTime() - new Date().getTime();
                // runs a function after the timeout that removes the event from the future array and adds it to the past array only if the event is on the next hour
                if (delay < 3600000) { // if the event is on the next hour
                    eventTimeouts.current[originalEvent.eventId] = setTimeout(() => {
                        const theEvent = eventsFutureArray.find(event => event.eventId === originalEvent.eventId)
                        if (theEvent) {
                            const newFuture = eventsFutureArray.filter(event => event.eventId !== originalEvent.eventId)
                            // set future and past arrays
                            setEventsFutureArray(newFuture)
                            setEventsPastArray(prev => [...prev, theEvent])
                        }
                    }, delay)
                }
            })
            firstTry.current = false;
        }
    }, [eventsFutureArray])

    //if event is edited it should update the info
    useEffect(() => {
        if (eventEdited) {
            setEventsFutureArray(prev => {
                const copyPrev = prev.map(event => {
                    if (event.eventId !== eventEdited.id) return event
                    return {
                        ...event,
                        ...(eventEdited.coverImage && { coverImage: eventEdited.coverImage }),
                        ...(eventEdited.posterImage && { posterImage: eventEdited.posterImage }),
                        eventName: eventEdited.eventName
                    }
                })
                return copyPrev
            })
        }
    }, [eventEdited])

    //checks if event date has past if so it moves it to events passed 
    const isPast = (date: Date) => {
        return date <= new Date();
    }

    return (
        <>
            {!loading ?
                <div className="events-ordered">
                    <div className="title">{OrderEvents.past_events}</div>
                    {eventsPastArray.length === 0 ?
                        <div className="no-upcoming-events-container">
                            <img className="no-upcoming-events-img" src=".././icons/theater.svg" alt='' />
                            <div className="no-upcoming-events">{OrderEvents.there_are_no_past_events}</div>
                        </div>
                        :
                        <EventsHappened eventsArray={eventsPastArray} />
                    }
                </div>
                :
                <div className="skeleton-container" >
                    <Skeleton variant="text" className="skeleton-main-title" />
                    <div className="skeleton-slide-container2">
                        <Skeleton className="skeleton-slide" variant="rectangular" />
                        <Skeleton className="skeleton-slide" variant="rectangular" />
                        <Skeleton className="skeleton-slide" variant="rectangular" />
                        <Skeleton className="skeleton-slide" variant="rectangular" />

                    </div>
                </div>
            }
        </>
    )
}
export default EventsOrdered;