import { useHistory } from "react-router-dom";
import GenericPopup from "../../generic-components/GenericPopup";
import { BACK_TO_HOME_PAGE } from "../../consts/hebrew";
import '../style/eventCanceledPopup.scss'

const PopupEventCanceled: React.FC<{ open: boolean }> = ({ open }) => {

    const history = useHistory();

    return (
        <GenericPopup
            image='/icons/attention.svg'
            children={<div className="title"><div>אנו מצטערים,</div><div>האירוע בו הנך צופה כעת בוטל</div></div>}
            button={{ text: BACK_TO_HOME_PAGE, handleClick: () => history.replace('/'), className: 'cyan' }}
            open={open}
            removeXButton
            disableBackgroundClick
            className="event-canceled"
        />
    );
}

export default PopupEventCanceled;