import { useEffect } from "react";
import clsx from "clsx";
import { PlusMinusInputProps } from "../consts/interfaces/plusMinusInput";
import '../style/plusMinusInput.scss';

const PlusMinusInput: React.FC<PlusMinusInputProps> = ({ value, setValue, maxValue, className, disabled }) => {

    useEffect(() => {
        if (maxValue && value > maxValue)
            setValue(maxValue)
    }, [maxValue])

    const increase = () => {
        if (disabled) return;
        if (maxValue === 0) return
        if (maxValue && value >= maxValue) return;
        setValue(prev => prev + 1)
    }

    const decrease = () => {
        if (disabled) return;
        if (value === 0) return;
        setValue(prev => prev - 1)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (maxValue && Number(e.target.value) > maxValue) return;
        setValue(Number(e.target.value))
    }
    return (
        <div className={clsx(["number-input-container", className])}>
            <div className={clsx("plus-btn", disabled && "disabled")} onClick={increase}>
                +
            </div>

            <input
                disabled={disabled}
                type='number'
                inputMode="numeric"
                value={value}
                onChange={handleInputChange}
                className="number-display"
                min={0}
                max={maxValue}
            />

            <div className={clsx("minus-btn", disabled && "disabled")} onClick={decrease}>
                -
            </div>
        </div>
    )
}

export default PlusMinusInput;