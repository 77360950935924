import { EventDetails } from "./interfaces/TicketOrder.interface";

export const demoBenefitData: EventDetails = {
    coverImage: '/enterHomePage.jpeg',
    eventType: 'oneGroup',
    categories: [{ id: 1, categoryName: "ספורט" }],
    populations: [{ id: 1, populationName: 'כללי' }],
    description: 'לונה פארק תל אביב הוא פארק שעשועים במתחם גני התערוכה בתל אביב. המקום מנוהל על ידי חברת "עיר השעשועים", אשר נוסדה בשנת 1975, ושבבעלותה גם הסופרלנד בראשון לציון וכן פארק המים מימדיון בתל אביב',
    eventName: 'th f d',
    contactName: '',
    contactPhone: '',
    id: 0,
    location: "הרטום 16, ירושלים",
    navigation: "קו 40, 54, 506\nאו ברגל",
    seatGroups: [{ id: 1, tickets: [], usedSeats: 1, coupon: "", filePath: '/HA', seatNum: 2, accessibilities: [{ count: 2, accessibility: { info: "גלגלים", icon: "/icons/arrow.svg", id: 1 } }] }],
    tickets: [{ id: 180, status: 'unassigned', timeExpired: '2023-04-13 13:34:18,14400', extraTime: false, seatGroup: 1 }],
    accessibilities: [{ count: 1, accessibility: { id: 1, info: 'כיסאות גלגלים', icon: '/image/wheel-chair.png' } }],
    hearingAid: 0,
    vipDetails: '',
    hall: { id: 0, hallName: "cou" },
    imageCoordinates: {
        width: 100,
        height: 100,
        x: 0,
        y: 0
    },
    ageRange: [{ id: 1, rangeName: "גיל הרך" }],
    date: "2015-03-25T12:00:00Z",
    eventContact: { id: 1, email: "acb@gmail.com", name: "hello", username: "0511111111" },
}

export const demoEventData: EventDetails = {
    duration: 0,
    eventType: 'oneGroup',
    coverImage: '',
    date: "",
    posterImage: '',
    contactName: '',
    contactPhone: "",
    categories: [{ id: 0, categoryName: '' }],
    populations: [{
        populationName: '',
        id: 0
    }],
    ageRange: [],
    description: '',
    eventName: '',
    id: 0,
    location: '',
    navigation: '',
    isSeating: false,
    isMarkedSeats: false,
    seatGroups: [
        {
            filePath: '',
            id: 0,
            seatNum: 0,
            coupon: '0',
            accessibilities: [],
            usedSeats: 0,
            tickets: []
        }
    ],
    tickets: [{
        id: 0,
        seatGroup: 0,
        status: '',
        timeExpired: '',
        extraTime: false,
        organization: { id: 0 }
    }],
    hall: {
        id: 0,
        hallName: '',
    },
    imageCoordinates: { x: 0, y: 0, width: 100, height: 100 },
    accessibilities: [],
    hearingAid: 0,
    vipDetails: '',
    purchasedDetails: [],
}