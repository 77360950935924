//this component gets props -  open: boolean, handleClose?: () => void, name?: string, eventId:number
//it returns a popup that asks you if you ant to delete an event 
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { PopupDeleteEventProps } from "../../consts/interfaces/PopupDeleteEvent.interface";
import { DELETE_EVENT } from "../../consts/enums/deleteEvent.enum";
import GenericButton from "../../generic-components/GenericButton";
import GenericPopup from "../../generic-components/GenericPopup";

import "../../style/genericPopup.scss";

const PopupDeleteEvent: React.FC<PopupDeleteEventProps> = ({ eventId, open, handleClose }) => {
    const history = useHistory();
    const [disabled, setDisabled] = useState<boolean>(false)
    const [serverLoading, setServerLoading] = useState(false)


    //function that send to the server the event id and deleltes it if clicked
    const handleDelete = async () => {
        try {
            setServerLoading(true)
            setDisabled(true)
            await axios.delete(`/api/event/delete-event?id=${eventId}`);
            history.push(`/cancel-event-feedback/${eventId}`)
            setServerLoading(false)

        } catch (error) {
            setDisabled(false)
            setServerLoading(false)
            return (error);
        }
    }

    return (
        <div>
            <GenericPopup
                open={open}
                className="the-popup delete-event"
                imageStyles="edit"
            >
                <div className="delete-event">
                    <div className="delete-event-title the-title">
                        {DELETE_EVENT.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
                    </div>
                    <div className="delete-event-buttons">
                        <GenericButton
                            serverLoading={serverLoading}
                            disabled={disabled}
                            className={"yellow delete"}
                            handleClick={() => handleDelete()}
                        > {DELETE_EVENT.YES} </GenericButton>
                        <GenericButton
                            disabled={disabled}
                            className={"blue delete"}
                            handleClick={handleClose}
                        > {DELETE_EVENT.NO} </GenericButton>
                    </div>
                </div>
            </GenericPopup>
        </div>
    )
}

export default PopupDeleteEvent;

