import EventIcon from '@mui/icons-material/Event';
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Moment } from 'moment';
import { useState } from "react";
import { AddEntranceTicketsFields } from '../../../consts/hebrew';
import './style/entranceTicketsExpirationInput.scss';

const EntranceTicketsExpirationInput: React.FC<{
    date: Moment | null,
    setDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    error?: string
}> = ({ date, setDate, error }) => {

    const [openDate, setOpenDate] = useState(false);

    return (
        <div className="input-container">
            <div className="input-title">{AddEntranceTicketsFields.TICKETS_EXPIRATION}:</div>
            <DatePicker
                open={openDate}
                onOpen={() => setOpenDate(true)}
                onClose={() => setOpenDate(false)}
                inputFormat="DD.MM.yyyy"
                disablePast
                value={date}
                onChange={(value) => setDate(value)}
                renderInput={(params) => <TextField {...params} InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setOpenDate(true)}>
                                <EventIcon fontSize="inherit" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                    className="input entrance-expiration-date"
                />}
            />

            <div className='input-error'>
                {error}
            </div>
        </div>
    )
}

export default EntranceTicketsExpirationInput;