import { ADD_COUPON, ENTER_COUPON_CODE, ENTER_LINK_TO_TICKET_ORDER, TICKET_NUMBER } from "../../../consts/hebrew";
import { TicketsCouponMethodProps } from "../../../consts/interfaces/TicketsCouponMethod.interface";
import { COUPONS } from "../../../consts/variables";

import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";

import './style/ticketsCouponMethod.scss'

const TicketsCouponMethod: React.FC<TicketsCouponMethodProps> = ({ coupons, webLink, webLinkError, seatGroupsTicketsSumError, setCoupons, handleInputChange, handleInputBlur, editMode }) => {

    const handleAddSeatGroup = () => {
        setCoupons(prev => prev.concat({ ...COUPONS }));
    }
    const handleRemoveSeatGroup = (index: number) => {
        setCoupons(prev => prev.filter((_, i) => i !== index));
    }

    return (
        <div className="coupons-input">
            <GenericInput
                disabled={editMode}
                value={webLink}
                name="webLink"
                placeholder={ENTER_LINK_TO_TICKET_ORDER}
                onChange={(e) => handleInputChange(e)}
                onBlur={handleInputBlur}
                error={webLinkError}
                className="web-link-input"
            />
            {coupons?.map((coupon, index) => (
                <div key={index} className="coupon-inputs-box">
                    <>
                        <GenericInput
                            disabled={editMode}
                            value={coupon.numSeats}
                            name="numSeatsCoupon"
                            type='number'
                            placeholder={TICKET_NUMBER}
                            onChange={(e) => handleInputChange(e, index)}
                            onBlur={(e) => handleInputBlur(e, index)}
                            className="seatGroup"
                            error={coupon.numSeatsError}
                            characterLimit={3}
                            hideCharacterLimit
                        />
                        <GenericInput
                            disabled={editMode}
                            value={coupon.code}
                            name="couponCode"
                            type='text'
                            placeholder={ENTER_COUPON_CODE}
                            onChange={(e) => handleInputChange(e, index)}
                            onBlur={(e) => handleInputBlur(e, index)}
                            className="seatGroup"
                            error={coupon.codeError}
                            characterLimit={8}
                            hideCharacterLimit
                        />
                    </>
                    {index >= 1 && !editMode
                        ? <div className="trash-can-container">
                            <img className="trash-can-top" src="/icons/trash-con-top.svg" onClick={(e) => { e.preventDefault(); handleRemoveSeatGroup(index) }} alt="top of trash can" />
                            <img className="trash-can-bottom" src="/icons/trash-can-bottom.svg" onClick={(e) => { e.preventDefault(); handleRemoveSeatGroup(index) }} alt="bottom of trash can" />
                        </div>
                        : null}

                </div>
            ))}
            <div className="add-event-errors-third tickets-sum-error">
                {seatGroupsTicketsSumError}
            </div>
            {!editMode && < GenericButton className="seat-group" handleClick={handleAddSeatGroup}>+ {ADD_COUPON}</GenericButton>}
        </div >
    )
}

export default TicketsCouponMethod;