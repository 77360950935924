import { Contact } from "../consts/interfaces/Contact.interface";
import { emailValidation, nameValidation, phoneNumValidation } from "./validations";

const DUPLICATE_PHONE_ERROR = 'לא ניתן להשתמש באותו מספר פלאפון עבור שני אנשי קשר';
const DUPLICATE_EMAIL_ERROR = 'לא ניתן להשתמש באותו כתובת מייל עבור שני אנשי קשר';

export const handleContactsErrors = (contacts: Contact[]) => {
    const checkDuplicatePhone = (contact: Contact) => {
        let flag = 0;
        const phone = contact.phone;
        contacts.forEach((user) => {
            if (user.phone === phone) flag++
        })
        if (flag > 1) return true
    }

    const checkDuplicateEmail = (contact: Contact) => {
        let flag = 0;
        const email = contact.email;
        contacts.forEach((user) => {
            if (user.email === email) flag++
        })
        if (flag > 1) return true
    }

    return contacts.map(contact => {

        const nameVal = nameValidation(contact.name);

        const phoneVal = phoneNumValidation(contact.phone) ? phoneNumValidation(contact.phone) : checkDuplicatePhone(contact) ? DUPLICATE_PHONE_ERROR : "";

        const emailVal = emailValidation(contact.email) ? emailValidation(contact.email) : checkDuplicateEmail(contact) ? DUPLICATE_EMAIL_ERROR : "";

        return { ...contact, nameError: nameVal, phoneError: phoneVal, emailError: emailVal }
    })
}

