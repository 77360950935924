import { useHistory } from "react-router-dom";
import { EVENT_PREVIEW_POP_UP } from "../../consts/hebrew"
import GenericButton from "../../generic-components/GenericButton"
import GenericPopup from "../../generic-components/GenericPopup"

import '../style/popupEventPreview.scss'

interface PopupEventPreviewProps {
    open: boolean;
    serverLoading: boolean;
    handleClose: () => void;
    handlePublishEvent: () => void;
}

const PopupEventPreview: React.FC<PopupEventPreviewProps> = ({ open, serverLoading, handleClose, handlePublishEvent }) => {
    const history = useHistory()

    const showPreview = () => {
        handleClose()
        history.push('/event/preview')
    }

    return (
        <GenericPopup
            open={open}
            title={EVENT_PREVIEW_POP_UP.EVENT_DETAILS_ARE_SAVED}
            handleClose={handleClose}
            className="popup-event-preview"
        >
            <>
                <div className="text-wrapper">
                    <p>{EVENT_PREVIEW_POP_UP.BEFORE_PUBLISH_EVENT}</p>
                    <p>{EVENT_PREVIEW_POP_UP.RECOMMEND_TO_GO_OVER}</p>
                </div>
                <div className="buttons-wrapper">
                    <GenericButton className="blue big" handleClick={showPreview}>{EVENT_PREVIEW_POP_UP.PREVIEW}</GenericButton>
                    <GenericButton className="yellow big" serverLoading={serverLoading} handleClick={handlePublishEvent}>{EVENT_PREVIEW_POP_UP.PUBLISH_EVENT}</GenericButton>
                </div>
            </>
        </GenericPopup>
    )
}
export default PopupEventPreview