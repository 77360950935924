import React, { useContext } from "react";
import { ADDRESS, ADD_HALL, EVENT_DESCRIPTION, FILL_HALL_AND_PLACE_DETAILS, MARK_IF_EVENT_AUDIENCE_IS_FOLLOWING_POPULATIONS, PUBLIC_TRANSPORT_NAVIGATION } from "../../../consts/hebrew";
import GoogleLocationAutocomplete from "../../../generic-components/google-location-autocomplete/GoogleLocationAutocomplete";
import GenericInput from "../../../generic-components/GenericInput";
import { HALL_USED_ERROR, _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import GenericTrashCan from "../../../generic-components/GenericTrashCan";
import HallInputs from "../../../generic-components/HallInputs";
import GenericButton from "../../../generic-components/GenericButton";
import { DetailsTabProps } from "../../../consts/interfaces/AgencyProfile.interface";
import { useAgencyContext } from "../../../context/AgencyContext";
import { ErrorContext } from "../../../context/ErrorContext";
import AgeRangeCheckboxes from "../../../generic-components/AgeRangeCheckboxes";
import { FileMangerFile } from "../../../consts/interfaces/AddNewEventContext.interface";
import CategoryInput from "../../../generic-components/CategoryInput";
import GenericOvalClick from "../../../generic-components/GenericOvalClick";
import UploadEventImage from "../../../generic-components/UploadEventImage";
import UploadEventPosterImage from "../../../generic-components/UploadEventPosterImage";

const DetailsTab: React.FC<DetailsTabProps> = (props) => {

    const { eventDetailsValue, ageRangeArray, categories, populations, imgCoordinates, filesManager, filesUploader, hasVisits, hasEvents, isFixedLocation, eventDetailsError, ageRangeError, categoryError, populationError, fileError, addressError, howToArriveError, setAgeRangeArray, setCategories, setPopulations, setImgCoordinates, setFilesManager, setAddressError, setAgeRangeError, setCategoryError, setFileError, handleInputChange, handleInputBlur } = props
    const { address, howToArrive, halls, setAddress, setHalls } = useAgencyContext()
    const { showError } = useContext(ErrorContext);

    const handleAddHall = () => {
        setHalls(prev => [...prev, { hallName: "", hallNameError: "" }])
    }
    const hallTrash = (index: number) => {
        if (halls[index].isUsed)
            return showError(HALL_USED_ERROR + ' ' + halls[index].isUsed); // if the hall is used in an event, you can't delete it
        setHalls(prev => prev.filter((_, ind) => ind !== index))
    }
    const changeCoverImage = (UploadFile: FileMangerFile) => {
        setFilesManager(prev => ({ ...prev, coverImage: UploadFile }))
    }
    const deleteCoverImage = () => {
        setFilesManager(prev => ({ ...prev, coverImage: null }))
    }
    const changePosterImage = (UploadFile: FileMangerFile) => {
        setFilesManager(prev => ({ ...prev, posterImage: UploadFile }))
    }
    const deletePosterImage = () => {
        setFilesManager(prev => ({ ...prev, posterImage: null }))
    }

    return (
        <div className="details">
            {hasVisits && <>
                <GenericInput
                    title={`${EVENT_DESCRIPTION}:`}
                    type="textarea"
                    value={eventDetailsValue}
                    name="eventDetails"
                    error={eventDetailsError}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    characterLimit={_LONG_TEXT_MAX_LENGTH}
                />

                <div className="input-container">
                    <AgeRangeCheckboxes ageRange={ageRangeArray} setAgeRange={setAgeRangeArray} saveDraft setAgeRangeError={setAgeRangeError} />
                    <div className="bottom-text-container">
                        <div className="input-error">
                            {ageRangeError}
                        </div>
                    </div>
                </div>

                <CategoryInput categories={categories} setCategories={setCategories} categoryError={categoryError} setCategoryError={setCategoryError} saveDraft />

                <div className="input-container margin-top">
                    <div style={{ marginBottom: '3vh' }} className="input-title">{MARK_IF_EVENT_AUDIENCE_IS_FOLLOWING_POPULATIONS}:</div>
                    <GenericOvalClick options={populations} setOptions={setPopulations} saveDraft />

                    <div className="input-error">
                        {populationError}
                    </div>
                </div>

                <UploadEventImage
                    filesUploader={filesUploader}
                    src={filesManager.coverImage?.link || ""}
                    imageCoordinates={imgCoordinates}
                    setImageCoordinates={setImgCoordinates}
                    fileError={fileError}
                    setFileError={setFileError}
                    onChange={({ fileName, link, id }) => changeCoverImage({ fileName, link, id, isNew: true })}
                    // onChange={(value) => changeCoverImage(value.id)}
                    // onDelete={deleteCoverImage}
                    deleteCoverImage={deleteCoverImage}
                />
                <UploadEventPosterImage
                    filesUploader={filesUploader}
                    fileName={filesManager.posterImage?.fileName || ""}
                    // imageCoordinates={imgCoordinates}
                    // setImageCoordinates={setImgCoordinates}
                    fileError={fileError}
                    setFileError={setFileError}
                    onChange={({ fileName, link, id }) => changePosterImage({ fileName, link, id, isNew: true })}
                    onDelete={deletePosterImage}
                />
            </>}
            {(hasVisits || isFixedLocation) && <>
                {!hasEvents && <div className="input-title">{FILL_HALL_AND_PLACE_DETAILS}</div>}
                <div className="input-container">
                    <div className="input-title">{ADDRESS}</div>
                    <GoogleLocationAutocomplete
                        value={address}
                        setValue={setAddress}
                        setAddressError={setAddressError}
                    />
                    <div className="bottom-text-container">
                        <div className="input-error">
                            {addressError}
                        </div>
                    </div>
                </div>

                <GenericInput
                    type="textarea"
                    name="howToArrive"
                    title={PUBLIC_TRANSPORT_NAVIGATION}
                    value={howToArrive}
                    error={howToArriveError}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    characterLimit={_LONG_TEXT_MAX_LENGTH}
                />
            </>}

            {(hasEvents && isFixedLocation) && <>
                {halls.map((hall, index) =>
                    <div key={index}>
                        {halls.length !== 1 && <GenericTrashCan onClick={() => hallTrash(index)} />}
                        <HallInputs
                            {...hall}
                            setHallName={(e) => handleInputChange(e, index)}
                            setHallNameError={(e) => handleInputBlur(e, index)}
                            key={index}
                            name={`hall${index}`}// this is for error navigation
                        // name={'hallName'}
                        />
                        {index !== halls.length - 1 &&
                            <div className="line-break" />}
                    </div>
                )}
                {(halls.length < 4) && <GenericButton className="add-hall-btn turquise" handleClick={handleAddHall}>+ {ADD_HALL}</GenericButton>}
            </>}
        </div>
    )
}
export default DetailsTab;