import React, { useContext, useEffect, useRef, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';

import { PREVIEW } from '../../../consts/hebrew';
import { useExitBrowser } from '../../../consts/hooks/useExitBrowser';
import { ANEContextInterface } from '../../../consts/interfaces/AddNewEventContext.interface';

import { AddNewEventContext } from '../../../context/AddNewEventContext';

import TicketOrder from '../../../generic-components/ticket-order/TicketOrder';
import ForgotPublishEventPopup from '../../../popups/agency/ForgotPublishEventPopup';

import "./style/eventPreview.scss";

const EventPreview: React.FC = () => {
    const { getDataForPreview, loadDraftData, publishNewEvent, isPublishLoading } = useContext(AddNewEventContext) as ANEContextInterface
    const history = useHistory();
    useExitBrowser()
    const [forgotPublishPopup, setForgotPublishPopup] = useState<boolean>(false)

    const unblockRef = useRef<() => void>();

    useEffect(() => {
        loadDraftData()
    }, [])

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            const eventAddedPath = matchPath(location.pathname, { path: "/event-added/:id" })
            if (location.pathname === "/event/add" || eventAddedPath?.isExact) {
                return;
            }

            setForgotPublishPopup(true)
            return false;
        })

        return () => {
            unblockRef.current?.();
        }
    }, [])

    const unblock = () => unblockRef.current?.();

    const onPublishClick = async () => {
        unblock()
        setForgotPublishPopup(false)

        publishNewEvent()
    }

    return (
        <div className='event-page'>
            <div className="preview-banner">{PREVIEW}</div>
            <TicketOrder type='preview' eventInfo={getDataForPreview} />

            <ForgotPublishEventPopup
                open={forgotPublishPopup}
                handleClose={() => setForgotPublishPopup(false)}
                onPublishEventClick={onPublishClick}
                publishLoading={isPublishLoading}
            />
        </div>
    )
}
export default EventPreview;