//All validation funcs for text, number, phone number, email and so on

// import AdapterMoment from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import { HallsProfileAgency } from "../consts/interfaces/AgencyProfile.interface";
import { VALID_CODE, VALID_DURATION, VALID_EMAIL, VALID_LINK, VALID_NUM_PEOPLE, VALID_PHONE_NUMBER, VALID_TEXT } from "../consts/regexes";
import { _LONG_TEXT_MAX_LENGTH, _NORMAL_TEXT_MAX_LENGTH } from "../consts/variables";
import { convertStringToNumber } from "./convertsFunctions";

export const textValidation = (value: string) => {
    if (!value) {
        return "יש למלא שדה זה";
    } else if (value.length > _NORMAL_TEXT_MAX_LENGTH) {
        return `טקסט צריך להיות באורך של עד ${_NORMAL_TEXT_MAX_LENGTH} תווים`;
    } else if (!VALID_TEXT.test(value)) {
        return "לא תקין";
    }
    return "";
}

export const longTextValidation = (value: string) => {
    if (!value || value.length < 3) {
        return "יש למלא את שדה זה עם לפחות 3 תווים";
    } else if (value.length > _LONG_TEXT_MAX_LENGTH) {
        return `טקסט צריך להיות באורך של עד ${_LONG_TEXT_MAX_LENGTH} תווים`;
    }
    return "";
}

export const dropDownValidation = (value: string | undefined | null) => {
    if (!value || value === "") {
        return "יש לבחור אפשרות מהרשימה";
    }
    return "";
}
export const nameValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (value.length > _LONG_TEXT_MAX_LENGTH) {
        return `טקסט צריך להיות באורך של עד ${_LONG_TEXT_MAX_LENGTH} תווים`;
    } else if (!VALID_TEXT.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const phoneNumValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_PHONE_NUMBER.test(value)) {
        return "לא תקין";
    }
    return "";

}

export const eventNameValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    }
    return "";
}

export const numValidation = (value: number | undefined | string, zeroValid?: boolean) => {
    const checkedValue = typeof value === "string" ? convertStringToNumber(value) : value

    if (checkedValue === undefined || checkedValue === null) {
        return "יש למלא שדה זה";
    } else if (!zeroValid && checkedValue === 0) {
        return "מספר כרטיסים חייב להיות גדול מאפס";
    } else if (isNaN(checkedValue)) {
        return "לא תקין, אנא הכנס ספרות בלבד";
    }
    return "";
}

export const numOfPeopleValidation = (value: string, zeroValid = true) => {

    if (!value) return "יש למלא שדה זה";
    if (value.length > 5) return `טקסט צריך להיות באורך של עד 5 תווים`;
    if (!VALID_NUM_PEOPLE.test(value)) return "לא תקין";
    if (!zeroValid && value === "0") return "יש למלא שדה זה";

    return "";
}

export const emailValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_EMAIL.test(value)) {
        return "לא תקין";
    }
    return "";

}
export const dateValidation = (value: Moment | null, futureLimit?: Moment) => {
    // the value is a moment object from the library of moment
    if (!value) {
        return "יש למלא שדה זה";
    } else if (value.diff(moment(), 'day') < 0) {
        return 'אין אפשרות להכניס תאריך בעבר'
    } else if (futureLimit?.isBefore(value)) {
        return 'ניתן להכניס תאריך עד שלוש שנים מהתאריך הנוכחי'
    } else if (!value.isValid()) {
        return "לא תקין";
    }
    return "";
}

export const timeValidation = (value: Moment | string | null, isToday: boolean) => {
    // the value is a moment object from the library of moment
    if (!value) return "יש למלא שדה זה";
    if (typeof value === "string") return "לא תקין";
    if (!value.isValid()) return "לא תקין";
    if (isToday && value?.isBefore(moment())) return "שעה כבר עברה";
    if (isToday && value?.isBefore(moment({ h: new Date().getHours() + 5 }))) return "לא ניתן לקבוע אירוע לזמן זה";
    return "";
}

export const hallValidation = (value: HallsProfileAgency) => {
    const val = !textValidation(value.hallName)
    return val
}

export const codeValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_CODE.test(value)) {
        return "לא תקין";
    }
    return "";
}

/**
 * 
 * @param connectedError the first argument is connected error, if its exist it will be returned and should be displayed
 * @param flag the second argument is the flag (it will flag if the file is connected or not), if not it will return the appropriate error
 * @returns it return the error message according to conditions, if the error conditions is not met it will return an empty string (valid file)
 */
export const fileValidation = (connectedError: string, link: string | undefined) => {
    if (connectedError && connectedError !== "") {
        return connectedError
    }
    if (!link) {
        return "יש למלא שדה זה ";  // no files in the array
    }
    return ""; //otherwise it is valid (empty string)
}

export const numberValidtion = (value: number) => {
    if (value === 0) {
        return "יש למלא שדה זה";
    }
    return "";
}

export const demographicsValidation = (demoArr: string[]) => {
    return demoArr.length === 0 ? "יש לבחור לפחות קהל יעד אחד" : ""
}

export const linkValidation = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    } else if (!VALID_LINK.test(value)) {
        return "לא תקין";
    }
    return "";
}

export const selectAtLeastOneValidator = (optionsArray: { selected?: boolean }[]) => {
    const error = optionsArray.some(option => option.selected) ?
        "" :
        "יש לבחור לפחות אופצייה אחת";
    return error;
}

export const durationValidator = (value: string) => {
    if (value === "") {
        return "יש למלא שדה זה";
    }
    else if (value === "0:00") {
        return "לא תקין";
    }
    else if (!VALID_DURATION.test(value)) {
        return "לא תקין";
    }
    return "";
}


