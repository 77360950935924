const defaultColor = '#0C414F'

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        cardBio: true;
    }
}

export const defaultTheme = {
    typography: {
        fontFamily: 'Assistant',
        h1: {
            color: defaultColor,
            fontSize: "2rem",
            fontWeight: "700",
            lineHeight: 2,
        },
        h2: {
            color: defaultColor,
            fontSize: '1.75rem',
            lineHeight: 1.75,
        },
        h3: {
            color: defaultColor,
            fontSize: '1.5rem',
            fontWeight: '700',
            lineHeight: 1.5,
        },
        h4: {
            color: defaultColor,
            fontSize: '1.25rem',
            lineHeight: 1.25,
        },
        h5: {
            color: defaultColor,
            fontSize: '1.125rem',
            fontWeight: '700',
            lineHeight: 1.125
        },
        h6: {
            color: defaultColor,
            fontSize: '1.0625rem',
            lineHeight: 1.0625,
        },
        body1: {
            color: defaultColor,
            fontSize: '1rem',
        },
        body2: {
            color: defaultColor,
            fontSize: '.75rem',
        },
        caption: {
            color: defaultColor,
        },
        cardBio: {
            color: defaultColor,
            fontSize: '.75rem',
        },
    },
    palette: {
        primary: {
            main: defaultColor,
        },
    },
}