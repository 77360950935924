import { useEffect, useState, useMemo, useContext, useCallback } from "react";
import axios from "axios";
import clsx from "clsx";

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

import { StatusOrderProps, profileOrganizations } from "../../consts/interfaces/StatusOrder.interface";
import { _ERROR_MESSAGE } from "../../consts/variables";
import { EventStatus } from "../../consts/eventTypes";
import { EVENT_IN_INACTIVITY_HOURS, StatusOrderAgency, StatusOrderOrganizationInfo, orgsPurchased, passedThroughOrgs, ticketSummary } from "../../consts/hebrew";
import { ErrorContext } from "../../context/ErrorContext";

import GenericInfoToolTip from "../GenericInfoToolTip";
import GenericProfilePic from "../GenericProfilePic";
import OrganizationInfoForStatusOrder from "./OrganizationInfoForStatusOrder";

import './style/statusOrder.scss';

const role = 'organization'

const StatusOrder: React.FC<StatusOrderProps> = ({ eventId, offeredTo, tickets }) => {
    const [profileOrganizations, setProfileOrganizations] = useState<profileOrganizations[]>([]);
    const [eventStatus, setEventStatus] = useState<EventStatus>()
    const { showError } = useContext(ErrorContext);

    const previousOrganizations = eventStatus === "INACTIVE-SYSTEM"
        ? profileOrganizations.reverse()
        : profileOrganizations.slice(0, -1).reverse()

    const fetchAllOrganizationProfiles = useCallback(async () => {
        try {
            const promises = offeredTo.map(async (organizationId: number) => {
                const { data } = await axios.get(`/api/contact/get-system-profile?role=${role}&systemId=${organizationId}`);
                const countPurchased = tickets.filter(ticket => ticket.status === 'purchased' && ticket.organization.id === organizationId).length

                return { ...data, organizationId, countPurchased };
            });

            const results = await Promise.all(promises);
            setProfileOrganizations(results);
        } catch (err) {
            showError(_ERROR_MESSAGE);
            console.error(err)
        }
    }, [offeredTo, tickets, showError])

    const fetchEventStatus = async () => {
        const { data } = await axios.get(`/api/event/status/${eventId}`)
        setEventStatus(data)
    }

    useEffect(() => {
        fetchAllOrganizationProfiles()
        fetchEventStatus()
    }, [fetchAllOrganizationProfiles])

    const totalPurchasedTickets = useMemo(() => {
        return tickets.filter(obj => obj.status === 'purchased').length;
    }, [tickets]);

    const totalOrganizationsPurchased = useMemo(() => {
        const organizations = new Set();

        tickets.forEach(item => {
            if (item.status === 'purchased') {
                organizations.add(item.organization.id);
            }
        });
        return organizations.size
    }, [tickets])

    const checkIfNextOrgExists = useMemo(() => {
        return (tickets.some(ticket => ticket.status === 'unassigned') || tickets.every(ticket => ticket.status === 'purchased'))
    }, [tickets])

    return (
        <div className='order-status'>
            <div className='status-order-title'>{StatusOrderAgency.STATUS_ORDER}</div>

            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}>

                <TimelineItem>
                    {eventStatus === "INACTIVE-SYSTEM" ?
                        EVENT_IN_INACTIVITY_HOURS
                        :
                        <>
                            <TimelineSeparator>
                                <GenericProfilePic className="timeline-img-first" src={profileOrganizations.at(-1)?.profilePhoto || ''} imageCoordinates={profileOrganizations.at(-1)?.imageCoordinates} />
                                {profileOrganizations.length !== 1 ? <TimelineConnector /> : null}
                            </TimelineSeparator>

                            <TimelineContent sx={{ py: '12px', px: 2, textAlign: 'right' }}>
                                <div className="timeline-item-content">
                                    <div className="name-and-info-row">
                                        <p className="bold">{profileOrganizations.at(-1)?.organizationName}</p>
                                        <p className="organization-info-icon">
                                            <GenericInfoToolTip>
                                                <div>
                                                    <OrganizationInfoForStatusOrder title={'תיאור עמותה:'} value={profileOrganizations.at(-1)?.organizationDescription} />
                                                    <OrganizationInfoForStatusOrder title={'טווח גילאים:'} value={profileOrganizations.at(-1)?.ageRange} />
                                                    <OrganizationInfoForStatusOrder title={'קהלי היעד:'} value={profileOrganizations.at(-1)?.demographics} />
                                                </div>
                                            </GenericInfoToolTip>
                                        </p>
                                    </div>
                                    {checkIfNextOrgExists
                                        ? <span>
                                            <img className='ticket-status-order-icon' src='/icons/ticket.svg' alt="" />
                                            {profileOrganizations.at(-1)?.countPurchased
                                                ? `הוזמנו ${profileOrganizations.at(-1)?.countPurchased} כרטיסים `
                                                : `${StatusOrderAgency.NO_TICKETS_PURCHASED}`
                                            }
                                        </span>
                                        : <span>
                                            {StatusOrderAgency.ORG_WAITING_RESPOND}
                                        </span>
                                    }
                                </div>
                            </TimelineContent>
                        </>
                    }
                </TimelineItem>

                {previousOrganizations.map((organization) => {

                    return (
                        <TimelineItem key={organization.organizationId}>
                            <TimelineSeparator>
                                <GenericProfilePic className="timeline-img" src={organization.profilePhoto} imageCoordinates={organization.imageCoordinates} />
                                {profileOrganizations.at(0) !== organization ? <TimelineConnector /> : null}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2, textAlign: 'right' }}>
                                <div className={clsx('timeline-item-content', !organization.countPurchased && 'disabled')}>
                                    <div className="name-and-info-row">
                                        <p className="bold">{organization.organizationName}</p>
                                        {organization.countPurchased ? <GenericInfoToolTip>
                                            <div>
                                                <OrganizationInfoForStatusOrder title={StatusOrderOrganizationInfo.ORGANIZATION_DESCRIPTION} value={organization.organizationDescription} />
                                                <OrganizationInfoForStatusOrder title={StatusOrderOrganizationInfo.AGE_RANGE} value={organization.ageRange} />
                                                <OrganizationInfoForStatusOrder title={StatusOrderOrganizationInfo.AUDIENCE} value={organization.demographics} />
                                            </div>
                                        </GenericInfoToolTip> : undefined}
                                    </div>
                                    <span>
                                        <img className='ticket-status-order-icon' src='/icons/ticket.svg' alt="ticket-icon" />
                                        {organization.countPurchased
                                            ? `הוזמנו ${organization.countPurchased} כרטיסים `
                                            : `${StatusOrderAgency.NO_TICKETS_PURCHASED}`}
                                    </span>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
            </Timeline>
            <div className='status-order-summary'>
                {StatusOrderAgency.SUMMARY}:
                <span>{passedThroughOrgs(offeredTo.length)}</span>
                <span>{orgsPurchased(totalOrganizationsPurchased)}</span>
                <span>{ticketSummary(totalPurchasedTickets, tickets.length)}</span>
            </div>
        </div >
    )
}

export default StatusOrder;