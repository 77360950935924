//this component returns a component that toggles between the add new event pages 
import { FC, useContext, useEffect, useState } from "react";

import { ANEContextInterface } from "../../../consts/interfaces/AddNewEventContext.interface";
import { AddNewEventProps } from "../../../consts/interfaces/AddNewEvent.interface";
import { ADD_EVENT_LEFT } from "../../../consts/leftSideLayouts";
import { ButtonsText, TITLES } from "../../../consts/hebrew";
import { FORM_FIELDS } from "../../../consts/variables";

import { clearFormDataStorage } from "../../../functions/localStorage";

import AddEventRightSecond from "./AddEventRightSecond";
import AddEventRightThird from "./AddEventRightThird";
import AddEventRightFirst from "./AddEventRightFirst";
import FormStepper from "../../../generic-components/FormStepper";

import PopupLoadDraftEvent from "../../../popups/agency/PopupLoadDraftEvent";
import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import { AddNewEventContext } from "../../../context/AddNewEventContext";

import AddEventRightForth from "./AddEventRightForth";
import './style/addNewEvent.scss';

const AddNewEvent: FC<AddNewEventProps> = ({ type }) => {

    const documentTitleByType = type === 'edit' ? "עריכת אירוע" : "יצירת אירוע"
    document.title = documentTitleByType;

    const { pageCx, setPageCx, setEditMode, loadDraftData, setFetchDataFromDB, isDuplicated, setIsDuplicated, setClearCx } = useContext(AddNewEventContext) as ANEContextInterface

    const isSavedDraftData = Object.values(FORM_FIELDS).some(value => localStorage.getItem(value) !== null)
    const isEventSaved = localStorage.getItem(FORM_FIELDS.IS_SAVED)
    const [serverLoading, setServerLoading] = useState(false)
    const [draftPopup, setDraftPopup] = useState<boolean>(false)

    const pages = [
        <AddEventRightFirst />,
        <AddEventRightSecond />,
        <AddEventRightThird serverLoading={serverLoading} setServerLoading={setServerLoading} />,
        type !== 'edit' ? <AddEventRightForth /> : null
    ].filter(page => page !== null);

    useEffect(() => {
        setPageCx(0)
        if (isDuplicated) {
            clearFormDataStorage()
        }
        else if (isEventSaved && type !== 'edit') {
            loadDraftData()
            return
        }
        else if (isSavedDraftData && type !== 'edit') {
            setDraftPopup(true)
        }
        setFetchDataFromDB(true)
    }, [])

    useEffect(() => {
        if (type === 'edit') {
            setEditMode(true)
            setFetchDataFromDB(true)
        }
    }, [type])

    const clearDraft = () => {
        clearFormDataStorage()
        setFetchDataFromDB(true)
        setClearCx(true)
        loadDraftData()
    }

    return (
        <>
            <GenericSplitPage serverLoading={serverLoading} isAgency squares={ADD_EVENT_LEFT}        >
                <div className="add-new-event-right">

                    <PopupLoadDraftEvent open={draftPopup} handleClose={() => setDraftPopup(false)} loadData={loadDraftData} discardDraft={clearDraft} />
                    <div className="add-event-title">{type === 'edit' ? ButtonsText.EditEvent : TITLES.ADD_NEW_EVENT}</div>

                    <FormStepper activeStep={pageCx} steps={pages} />

                    {pages[pageCx]}

                </div>
            </GenericSplitPage>
        </>
    )
}

export default AddNewEvent;