//this component gets props - open : boolean, handleClose ?: () => void
//it returns a popup that shows the user that we saved him the tickets till the event timer expires

import { AccompanyingGuidePopupProps } from "../../consts/interfaces/AccompanyingGuidePopup.interface";
import GenericPopup from "../../generic-components/GenericPopup";
import '../../style/genericPopup.scss';

const PopupAccompanyingGuide: React.FC<AccompanyingGuidePopupProps> = ({ open, handleClose }) => {
    return (
        <GenericPopup
            image='/icons/hands.svg'
            open={open}
            handleClose={handleClose}
            className="guide-popup"
            imageStyles="popup-save-tickets-img"
        >

            <div className="event-created-popup">
                <div className="event-created-title">
                    <p className="pay-attention">לתשומת ליבכם:</p>
                    <p>לאוכלוסיות בסיכון, נדרש ליווי של איש צוות</p>
                    <br />
                    <p>כגון: ילדים בסיכון, נוער בסיכון, צעירים בסיכון, דרי רחוב, אסירים משוחררים ומכורים</p>
                </div>
            </div>
        </GenericPopup>
    );
}

export default PopupAccompanyingGuide;