import React from "react"
import { EventCreatedPopupProps } from "../../consts/interfaces/EventCreatedPopup.interface"
import GenericPopup from "../../generic-components/GenericPopup"

import '../style/eventCreatedPopup.scss'
import "../../style/genericPopup.scss"

const PopupEventCreated: React.FC<EventCreatedPopupProps> = ({ open, handleClose }) => {

    return (
        <GenericPopup
            open={open}
            handleClose={handleClose}
            imageStyles="event-created-icon"
            image={"/icons/blue-light.svg"}
            button={{ text: 'אני מאשר/ת', handleClick: handleClose, className: 'yellow' }}
            isSmall={true}
            className="popup-event-created"
            removeXButton
            disableBackgroundClick
        >
            <div className="event-created-popup">
                <div className="event-created-title">
                    <p className="pay-attention"> שימו לב: </p>
                    <p> בפרסום האירוע ומתן הכרטיסים </p>
                    <p> המערכת של אנטר מתחילה לעבוד</p>
                </div>
                <div className="event-created-text">
                    <p>
                        אנחנו נעשה הכל כדי לאתר את האנשים שהאירוע ישמח את ליבם
                    </p>
                    <p>
                        אנא התחייבו לשמור את המקומות הפנויים למוזמנים מטעם אנטר
                    </p>
                </div>
            </div>
        </GenericPopup >
    )
}

export default PopupEventCreated;