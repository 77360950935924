import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { OptionsForCancelArr } from "../../../consts/interfaces/DeleteEvent.interface";
import UseParamsInterface from "../../../consts/interfaces/UseParams.interface";
import { FeedbackType } from "../../../consts/interfaces/Feedback.interface";
import { DELETE_EVENT } from "../../../consts/enums/deleteEvent.enum";
import { CANCEL_EVENT_LEFT } from "../../../consts/leftSideLayouts";
import { _LONG_TEXT_MAX_LENGTH } from "../../../consts/variables";
import { TITLES } from "../../../consts/hebrew";

import { UserContext } from "../../../context/UserContext";
import { textValidation } from "../../../functions/validations";

import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import GenericButton from "../../../generic-components/GenericButton";
import GenericInput from "../../../generic-components/GenericInput";
import RadioButtons from "../../../generic-components/RadioButtons";

import '../../../style/notInterested.scss';

const CancelEventPage: React.FC = () => {
    const history = useHistory()
    const { systemID } = useContext(UserContext);
    const { id: eventId } = useParams() as UseParamsInterface;

    const [radioValue, setRadioValue] = useState<string>("")
    const [radioValueError, setRadioValueError] = useState<string>("")
    const [otherReason, setOtherReason] = useState<string>("")
    const [otherReasonError, setOtherReasonError] = useState<string>("")
    const [serverLoading, setServerLoading] = useState(false)
    const [disable, setDisable] = useState<boolean>(false)
    const optionsForCancel = OptionsForCancelArr
    const isIos = /iPhone|iPad|iPod|/i.test(navigator.userAgent);

    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOtherReasonError(textValidation(e.target.value));
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOtherReason(e.target.value)
    }

    const handleSubmit = async () => {
        if (radioValue !== "") {
            setRadioValueError("")
            if (radioValue === DELETE_EVENT.OTHER && otherReason === "") {
                setOtherReasonError(DELETE_EVENT.HAVE_TO_FILL_THIS_FIELD)
            }
            else {
                try {
                    setDisable(true)
                    setServerLoading(true)
                    const feedbackText = radioValue === DELETE_EVENT.OTHER ? otherReason : radioValue

                    axios.post('/api/feedback/save-feedback', {
                        feedbackText,
                        feedbackType: FeedbackType.DELETE_EVENT,
                        agencyId: systemID,
                        eventId: Number(eventId)
                    })
                    history.push('/')
                    setServerLoading(false)

                } catch (error) {
                    setServerLoading(false)
                    return (error);
                }
            }
        }
        else {
            setRadioValueError(DELETE_EVENT.HAVE_TO_FILL_THIS_FIELD)
        }
    }

    return (
        <div className="whole-page-cancle">
            <GenericSplitPage serverLoading={false} squares={CANCEL_EVENT_LEFT}>
                <div className="right-side-in">
                    <div className="no-title">{TITLES.CANCELED_SUCCSESFULLY}</div>
                    <div className="paragraph-in">
                        <p> {DELETE_EVENT.WE_WILL_UPDATE_ALL_ORGANIZATIONS_ABOUT_THE_CANCEL} </p>
                    </div>
                    <div style={{ marginBottom: "4vh" }} className="wrapper-titles-div"  >
                        <div className="title-text">{DELETE_EVENT.WHY_DID_YOE_DECIDE_TO_CANCEL_TICKETS_FOR_EVENT}</div>
                    </div>
                    <div className="radio-buttons-not-relevant">
                        <RadioButtons
                            value={radioValue}
                            handleChange={(e: React.FormEvent<HTMLInputElement>) => setRadioValue(e.currentTarget.value)}
                            options={optionsForCancel} />

                        {radioValue === DELETE_EVENT.OTHER &&
                            <GenericInput
                                characterLimit={_LONG_TEXT_MAX_LENGTH}
                                className="text-area"
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                                placeholder={DELETE_EVENT.TYPE_TEXT}
                                name="otherReason"
                                value={otherReason}
                                error={otherReasonError}
                                textarea
                            />}
                    </div>
                    <div className="error-empty">{radioValueError}</div>
                    <GenericButton disabled={disable} className={isIos && radioValue === "other" ? "yellow button-in-IOS" : "yellow button-in"} handleClick={handleSubmit} serverLoading={serverLoading}>{DELETE_EVENT.SEND}</GenericButton>
                </div>
            </GenericSplitPage>
        </div>
    )
}

export default CancelEventPage