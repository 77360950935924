import React from "react"
import { RecentFeedbacks } from "../../../consts/interfaces/Feedback"
import { formatDate } from "../../../functions/dateFunctions"
import GenericProfilePic from "../../../generic-components/GenericProfilePic"
import './style/thankYouCard.scss'

const ThankYouCard: React.FC<{ feedback: RecentFeedbacks }> = ({ feedback }) => {

    const { event: { date, eventName }, feedbackText, organization: { organizationName, profilePhoto, imageCoordinates } } = feedback

    return (
        <div className="thank-you-card">
            <GenericProfilePic className="profile-pic" src={profilePhoto} imageCoordinates={imageCoordinates} />
            <div className="card-title" children={organizationName} />
            <div className="card-content main" children={feedbackText} />
            <div className="horizontal-split"></div>
            <div className="flex-center event-details" children={`${formatDate(date)} | ${eventName}`} />
        </div>
    )
}

export default ThankYouCard