import { GREETING_ORGANIZATION } from "../../../consts/hebrew"
import './style/greetingOrganization.scss'

interface GreetingOrganizationProps {
    organizationName: string;
}

const GreetingOrganization: React.FC<GreetingOrganizationProps> = ({ organizationName }) => {

    return (
        <div className="greeting-wrapper">
            <div className="greeting-text">
                {GREETING_ORGANIZATION.HELLO}
                <span className="name-of-organization">{`${GREETING_ORGANIZATION.ORGANIZATION} ${organizationName}`}</span>
                👋
            </div>
        </div>
    )
}
export default GreetingOrganization