import { useEffect, useState } from 'react';


/**
 * Confirm browser exit.
 * @param defaultEnabled Start as enabled?
 */
export const useExitBrowser = (
  defaultEnabled = true,
) => {
  const [enabled, setEnabled] = useState<boolean>(defaultEnabled);

  useEffect(() => {
/**
 * function that open a confirm dialog (if enabled) when the user tries to close the browser or reload the tab.
 * @param e browser event that triggers when user try to refresh or exit the page
 */
    function ConfirmBrowserExit(e: BeforeUnloadEvent) { 
      
      if (enabled) {
        e.returnValue = 'האם אתה בטוח שברצונך לצאת מהאתר?';
      }
    }

    window.addEventListener('beforeunload', ConfirmBrowserExit);

    return () => {
      window.removeEventListener('beforeunload', ConfirmBrowserExit);
    };
  }, [enabled]);

  return {

    setEnabled(status: boolean): void {
      setEnabled(status);
    },

    getEnabled(): boolean {
      return enabled;
    },
  };
};