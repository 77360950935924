//this component takes props - isHidden , type
//it returns an event page

import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/he';
import { Skeleton } from "@mui/material";

import RequestExtraTickets from './RequestExtraTickets';
import PurchaseTickets from './PurchaseTickets';
import YellowBanner from '../YellowBanner';
import StatusOrder from './StatusOrder';
import InfoBar from '../info-bar/InfoBar';
import Footer from './Footer';
import GiveBy from './GiveBy';

import { AVAILABLE_HEARING_AIDS, CONTACT, NOT_INTERESTED_MAYBE_NEXT_TIME, TICKET_CAN_BE_USED_TO } from '../../consts/hebrew';
import { EventDetails, TicketOrderProps } from '../../consts/interfaces/TicketOrder.interface';
import { PurchaseSeatGroup } from '../../consts/interfaces/SeatGroup.interface';
import { TicketOrderTickets } from '../../consts/interfaces/Tickets.interface';
import UseParamsInterface from '../../consts/interfaces/UseParams.interface';
import { TICKETS_METHOD } from "../../consts/variables";
import { demoEventData } from '../../consts/eventDetails';

import { SocketContext } from '../../context/SocketContext';
import { UserContext } from '../../context/UserContext';

import GenericFullScreenImagePopup from '../GenericFullScreenImagePopup';
import GenericSeatGroup from '../generic-seat-group/GenericSeatGroup';
import ContactChoose from './ContactChoose';
import GenericTagsEnum from '../GenericTags';
import GenericPopup from '../GenericPopup';

import './style/ticketOrder.scss';
import '../../style/vipBanner.scss';

export interface User {
    id: number;
    username: string;
    email: string;
    name: string;
}

const TicketOrder: React.FC<TicketOrderProps> = ({ eventInfo, serverLoading, type }) => {

    document.title = "דף אירוע"
    const [selectedUser, setSelectedUser] = useState<User>();
    const [purchaseSeatGroups, setPurchaseSeatGroups] = useState<PurchaseSeatGroup[]>([]);
    const [purchaseError, setPurchaseError] = useState<string>("")
    const [showFullPoster, setShowFullPoster] = useState<boolean>(false);
    const { systemID, role } = useContext(UserContext);
    const { eventEdited, ticketPurchased } = useContext(SocketContext)
    const [purchasedTicketNum, setPurchasedTicketNum] = useState(0)
    const [detailsWereChanged, setDetailsWereChanged] = useState(false)

    const { id } = useParams() as UseParamsInterface;
    const eID = Number(id)

    const [statusRead, setStatusRead] = useState("קרא עוד")
    const [linkCouponOrPdf, setlinkCouponOrPdf] = useState<{
        filePath: string,
        coupon: string,
        seatNum: number
    }[]>();

    const [eventType, setEventType] = useState<TicketOrderProps["type"] | "purchase">(type)
    const [ticketDes, setTicketDes] = useState('')
    const [longTicketDes, setLongTicketDes] = useState('')
    const [offeredTo, setOfferedTo] = useState([])
    const [eventDetails, setEventDetails] = useState<EventDetails>({ ...demoEventData })
    const [selectedHearingAid, setSelectedHearingAid] = useState<number>(0)
    const usedHearingAids = eventDetails.purchasedDetails?.reduce((sum, purchase) => sum + purchase.hearingAids, 0) || 0;

    const seatGroupsNum = useMemo(() => eventDetails.seatGroups.reduce((accumulator, currentValue) => accumulator + currentValue.tickets.filter((ticket: any) => ticket.isUsed === true).length, 0), [eventDetails.seatGroups])

    const eventDateInfo = useMemo(() => {
        const momentDate = moment(new Date(eventDetails.date))
        const fullDate = momentDate.format('DD.MM.YYYY')

        if (type === "benefit") {
            return `${TICKET_CAN_BE_USED_TO} ${fullDate}`
        } else {
            const time = momentDate.format('HH:mm');
            const dayName = `יום ${momentDate.format('dddd')}`

            return `${dayName} | ${fullDate} | ${time}`
        }

    }, [type, eventDetails.date])


    useEffect(() => {
        let short = shortDescription(eventInfo.description)
        setTicketDes(`${short}`)
        setLongTicketDes(eventInfo.description)
        setEventDetails(eventInfo)

        if (role === 'agency' && type !== 'preview') {
            setPurchasedTicketNum(eventInfo.tickets.filter((e: any) => e.status === 'purchased').length)
            eventInfo.offeredTo && setOfferedTo(JSON.parse(eventInfo.offeredTo))
        }

        else if (role === 'organization') {
            const pdfOrLink = eventInfo.seatGroups.map(({ filePath, coupon, seatNum }) => ({
                filePath,
                coupon,
                seatNum
            }));

            setlinkCouponOrPdf(pdfOrLink);
        }
    }, [eventInfo])

    //function that changes on socket edits an event
    useEffect(() => {
        if (eventEdited && eventEdited.id === eID) {
            setDetailsWereChanged(true)
            setEventDetails(prev => {
                return {
                    ...prev,
                    ...(eventEdited.coverImage ? { coverImage: eventEdited.coverImage } : {}), // only if the socket return the cover image apply it to the state
                    ...(eventEdited.posterImage ? { posterImage: eventEdited.posterImage } : {}),
                    eventName: eventEdited.eventName,
                }
            })

            let short = shortDescription(eventEdited.description)
            setLongTicketDes(eventEdited.description)
            setTicketDes(`${short}`)
        }

    }, [eventEdited, eID])


    //function that changes on socket and changes number of tickets that have been purchased
    useEffect(() => {
        if (ticketPurchased && ticketPurchased.eventId === eID) {
            setPurchasedTicketNum(prev => prev + ticketPurchased.amountPurchased)
        }
    }, [ticketPurchased, eID])

    const shortDescription = (value: string) => {
        if (value && value.length > 79) {
            return value.substring(0, 79) + '...';
        } else {
            return value;
        }
    }

    //function that handles the read more button and shows the whole text or just the start of it
    const readMore = () => {
        if (statusRead === 'קרא עוד') {
            setTicketDes(longTicketDes);
            setStatusRead('קרא פחות');
        }
        else {
            let short = shortDescription(longTicketDes)
            setTicketDes(short);
            setStatusRead('קרא עוד');
        }
    }

    const updateTickets = (tickets: TicketOrderTickets[]) => {
        setEventDetails((prev) => ({
            ...prev,
            tickets
        }));
    }

    const orderTickets = async () => {
        let isPdfOrCoupon = linkCouponOrPdf && linkCouponOrPdf[0]?.filePath?.includes('pdf') ? TICKETS_METHOD.PDF : TICKETS_METHOD.COUPON

        if (purchaseSeatGroups.length === 0) {
            setPurchaseError("יש לבחור לפחות קבוצת מושבים אחת")
            return false;
        }
        if (purchaseSeatGroups.some(({ usedSeats }) => usedSeats === 0)) {
            setPurchaseError("יש לבחור לפחות כיסא אחד מתוך קבוצת המושבים")
            return false;
        }

        const selectedSeatsAmount = purchaseSeatGroups.reduce((amount, seatGroup) => amount + seatGroup.usedSeats, 0)
        if (selectedHearingAid > selectedSeatsAmount) {
            setPurchaseError("אין אפשרות לבחור כמות אביזרי שמע שאינה תואמת את כמות הכרטיסים")
            return false;
        }

        setPurchaseError("")

        try {
            await axios.post('/api/ticket/order-ticket-seat-groups', {
                seatGroupArr: purchaseSeatGroups,
                eventId: eID,
                organizationId: systemID,
                isPdfOrCoupon: isPdfOrCoupon,
                hearingAids: selectedHearingAid
            })

            return true;
        } catch (error) {
            throw error;
        }
    }

    return (
        <div className='details' >
            {!serverLoading ?
                <div>
                    <div className='agency-changed-details-popup'>
                        {eventType === "time" &&
                            <div>
                                <GenericPopup
                                    open={detailsWereChanged}
                                    handleClose={() => { setDetailsWereChanged(false) }}
                                    image={"/icons/docs.svg"}
                                    imageStyles="clipboard-note-icon"
                                    className='details-popup'
                                >
                                    <div className="agency-changed-details-text">
                                        <p className="pay-attention"> שימו לב! </p>
                                        <p> בוצעו מספר עדכונים באירוע שהוצע לכם</p>
                                    </div>
                                </GenericPopup>
                            </div>
                        }
                    </div>
                    <div className="big-image">
                        {eventType === "edit" &&
                            <YellowBanner isEvent tickets={purchasedTicketNum} />
                        }
                        {eventType !== 'edit' && eventDetails.agency &&
                            <GiveBy
                                agencyImageCoordinates={eventDetails.agency.imageCoordinates}
                                agencyName={eventDetails.agency.agencyName}
                                agencyProfilePhoto={eventDetails.agency.profilePhoto}
                            />}
                        <img className='sponge-bob' src={eventDetails.coverImage} alt={eventDetails.eventName}
                            style={eventDetails.imageCoordinates && {
                                width: `${100 * 100 / eventDetails.imageCoordinates.width}%`, // makes the img bigger so the visible part is smaller
                                left: `${-eventDetails.imageCoordinates.x * 100 / eventDetails.imageCoordinates.width}%`, // moves the img left so the cropped part moves right, proportionate to the img width
                                top: `${-eventDetails.imageCoordinates.y * 100 / eventDetails.imageCoordinates.height}%`  // moves the img up so the cropped part moves down, proportionate to the img height
                            }}
                        />

                        {eventDetails?.vipDetails !== NOT_INTERESTED_MAYBE_NEXT_TIME ? <img className={`vip-banner ${eventType === 'edit' ? 'vip-banner-agency' : ''}`} src='/icons/vip-banner-new.svg' alt="vip-banner" /> : null}
                    </div>

                    <p className='the-name'>{eventDetails.eventName}</p>
                    <p className='the-info'>{eventDateInfo}</p>
                    <InfoBar barType={eventType} eventId={eID} usedSeats={seatGroupsNum} seatGroups={eventDetails.seatGroups} />
                    <div className='event-info-box'>
                        <div className='event-details'>
                            {eventType !== "purchase" &&
                                <p className='limitPara'>{ticketDes}</p>
                            }
                            {longTicketDes.length <= 79 ? "" : <button className="read_button" onClick={readMore}>{statusRead}</button>}
                            <br></br>
                            <br></br>

                            {eventType === "purchase" ?
                                <PurchaseTickets
                                    seatGroups={eventDetails.seatGroups}
                                    leftHearingAids={eventDetails.hearingAid - usedHearingAids}
                                    selectedHearingAid={selectedHearingAid}
                                    setSelectedHearingAid={setSelectedHearingAid}
                                    systemID={systemID}
                                    purchaseSeatGroups={purchaseSeatGroups}
                                    setPurchaseSeatGroups={setPurchaseSeatGroups}
                                    setSelectedUser={setSelectedUser}
                                    purchaseError={purchaseError} />
                                :
                                <div>
                                    <GenericTagsEnum
                                        {...eventDetails}
                                        ageRanges={eventDetails.ageRange}
                                        role={role}
                                        price={eventDetails.ticketPrice}
                                        disableReadMore={type === "benefit"}
                                    />

                                    {eventType === "benefit" && eventDetails.eventContact &&
                                        <div className='benefit-event-extras'>
                                            <div className="tickets-title">{CONTACT}</div>
                                            <ContactChoose {...eventDetails.eventContact} />
                                        </div>}

                                    <div>
                                        {eventDetails.posterImage && <>
                                            <p onClick={() => setShowFullPoster(!showFullPoster)} className='limitPara-poster'>לצפייה בפוסטר המלא</p>
                                            <GenericFullScreenImagePopup
                                                open={showFullPoster}
                                                handleClose={() => setShowFullPoster(false)}
                                                image={eventDetails.posterImage}
                                            // fullScreen
                                            />
                                        </>
                                        }

                                        <div className="tickets-title">כרטיסים</div>
                                        {eventDetails.hearingAid !== 0 &&
                                            <div className='hearing-aids-text'>
                                                <img src="/icons/accessibility/hearing-accessibility.svg" alt='hearing accessibility' />
                                                {eventDetails.hearingAid - usedHearingAids} {AVAILABLE_HEARING_AIDS}
                                            </div>}

                                        <div className="seat-groups-container">

                                            {eventDetails.seatGroups.map((seatgroup, index) =>
                                                <GenericSeatGroup
                                                    key={seatgroup.id}
                                                    index={index}
                                                    numOfTickets={eventType === 'edit' || eventType === 'preview' ? seatgroup.seatNum : eventType === "time" ? seatgroup.tickets.length : seatgroup.tickets.filter((ticket: any) => ticket.isUsed === true).length}
                                                    accessibilities={seatgroup.accessibilities}
                                                    id={seatgroup.id}
                                                />
                                            )
                                            }
                                            <div className="contact-name">איש קשר זמין</div>
                                            <div className='all-contact-details'>
                                                <div className="contact-details">{eventDetails.contactName} </div>
                                                <div className="contact-details"> {eventDetails.contactPhone}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {eventType === "benefit" &&
                                        <RequestExtraTickets />
                                    }
                                </div>

                            }
                        </div>
                        {eventType === 'edit' ? <StatusOrder eventId={eID} offeredTo={offeredTo} tickets={eventDetails.tickets} /> : null}
                    </div>
                    <Footer
                        eventType={eventDetails.eventType}
                        eventId={eID}
                        footerType={eventType}
                        setEventType={setEventType}
                        popup='orderTickets'
                        address={eventDetails.location}
                        ticketLimit={7}
                        name={eventDetails.eventName}
                        seatGroups={eventDetails.seatGroups}
                        tickets={eventDetails.tickets}
                        linkCouponOrPdf={linkCouponOrPdf}
                        updateTickets={updateTickets}
                        purchaseTickets={orderTickets}
                        selectedUser={selectedUser}
                    />
                </div>
                :
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton className='sponge-bob' variant="rectangular" width={"100vw"} height={"55vh"} />
                    <Skeleton variant="text" width={"15vh"} height={'7vh'} sx={{ mt: "4vh", mr: "10.8vw" }} />
                    <Skeleton variant="text" width={"30vh"} height={"4vh"} sx={{ mr: "10.8vw", mb: "2vh" }} />

                    <Skeleton variant="rectangular" width={"100vw"} height={'100vh'} sx={{ mt: '3vh' }} />
                </div>
            }
        </div >
    )
}
export default TicketOrder;