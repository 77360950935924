import { useMediaQuery } from "@mui/material";
import { SwiperSlide } from 'swiper/react';

import SwipeBoard from "../SwipeBoard";
import EventSwiperElement, { EventSwiperElementProps } from "./EventSwiperElement";
import SingleComingEvent from "../SingleComingEvent";

interface SwipeBoardProps {
    array: EventSwiperElementProps["element"][];
    isAgency?: boolean;
    className?: string;
    cover?: EventSwiperElementProps["cover"];
    navigateToEventPage: (eventId: number) => void;
}
const EventSwiper: React.FC<SwipeBoardProps> = ({ array, isAgency, className, cover, navigateToEventPage }) => {

    const tabletMediaQuery = useMediaQuery('(max-width: 768px)');

    const onSlideEnterKeyDown = (slideIndex: number) => {
        navigateToEventPage(array[slideIndex].eventId)
    }

    return (
        <SwipeBoard onSlideEnterKeyDown={onSlideEnterKeyDown} className={className}>
            {array.map(element =>
                <SwiperSlide key={element.eventId}>
                    {tabletMediaQuery ?
                        <SingleComingEvent
                            element={element}
                            navigateToEventPage={navigateToEventPage}
                        />
                        :
                        <EventSwiperElement
                            element={element}
                            eventId={element.eventId}
                            isAgency={isAgency}
                            cover={cover}
                            navigateToEventPage={navigateToEventPage}
                        />
                    }
                </SwiperSlide>
            )}
        </SwipeBoard>
    );
}

export default EventSwiper
