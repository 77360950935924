import { REQUEST_EXTRA_TICKETS_DETAILS, REQUEST_EXTRA_TICKETS_FROM_AGENCY, WANT_EXTRA__TICKETS } from "../../consts/hebrew";
import GenericButton from "../GenericButton";
import GenericToolTip from "../GenericToolTip";
import './style/requestExtraTickets.scss';

const RequestExtraTickets = () => {

    return (
        <div className="request-extra-tickets">
            {WANT_EXTRA__TICKETS}
            <GenericToolTip title={REQUEST_EXTRA_TICKETS_DETAILS} name="request-extra-tickets">
                <img className='info-icon' src='/icons/info.png' alt="info" />
            </GenericToolTip>
            <GenericButton className="extra-tickets-btn blue">
                {REQUEST_EXTRA_TICKETS_FROM_AGENCY}
            </GenericButton>
        </div>
    )
}

export default RequestExtraTickets;