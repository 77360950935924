import CloseIcon from '@mui/icons-material/Close';
import { Grow, IconButton, Modal } from '@mui/material';
import { ErrorMessageProps } from '../consts/interfaces/ErrorMessage.interface';

import '../style/errorMessageModal.scss';

const ErrorMessageModal: React.FC<ErrorMessageProps> = (props) => {
    const closePopup = () => props.setOpen(false)
    const isCloseButtonShown = !props.disableExitButton

    return (
        <Modal
            open={props.open}
            onClose={closePopup}
            disableAutoFocus
            className='error-modal'>
            <Grow in={props.open}>
                <div className="error-container">
                    {isCloseButtonShown &&
                        <IconButton onClick={closePopup} size='large' style={{ color: 'white' }} >
                            <CloseIcon />
                        </IconButton>
                    }
                    <p className={"error-text"}>
                        {props.message}
                    </p>
                </div>
            </Grow>
        </Modal>
    )
}

export default ErrorMessageModal