//this component gets props - open: boolean, handleClose?: () => void, name?: string, handleDelete: () => void
//it returns a popup that asks you if you want to delete a your contact from the org side 

import React from "react";
import { PopupDeleteContactProps } from "../../consts/interfaces/PopupDeleteContact.interface";
import GenericButton from "../../generic-components/GenericButton";
import GenericPopup from "../../generic-components/GenericPopup";
import "../../style/genericPopup.scss";

const PopupDeleteContact: React.FC<PopupDeleteContactProps> = ({ open, handleDelete, handleClose }) => {

    return (
        <div>
            <GenericPopup
                open={open}
                className="the-popup delete-event"
                imageStyles="edit"
            >
                <div>
                    <div className="delete-event-title the-title">
                        בטוח שברצונך למחוק את עצמך בתור איש הקשר?
                    </div>
                    <div className="delete-event-second-title">
                        על ידי מחיקת איש הקשר לא תוכל להיכנס לאתר ולקבל הצעות לאירועים.
                    </div>
                    <div className="delete-event-buttons">
                        <GenericButton
                            className={"blue delete"}
                            handleClick={() => handleDelete()}
                        >כן</GenericButton>
                        <GenericButton
                            className={"red delete"}
                            handleClick={handleClose}
                        >לא</GenericButton>
                    </div>
                </div>
            </GenericPopup>
        </div>
    )
}

export default PopupDeleteContact;