import axios from "axios";
import { useIsAuthenticated, useLogout } from "@hilma/auth";
import { useAsyncEffect } from "@hilma/tools";
import { useError } from "../../context/ErrorContext";
import { useUserInfo } from "../../context/UserContext";
import { _NOT_AUTHENTICATED } from "../variables";

export function useLogIn() {
  const isAuthenticated = useIsAuthenticated()
  const { loggingIn } = useUserInfo()
  const { showError } = useError()
  const logOutAuth = useLogout()


  useAsyncEffect(async () => {
    if (isAuthenticated) {
      try {
        const details = await axios.get('/api/contact/get-current-user')
        if (details.data.agency) {
          loggingIn(details.data.id, details.data.name, "agency", details.data.agency, details.data.email, details.data.username)
          localStorage.setItem("agencyId", JSON.stringify(details.data.agency))

        } else if (details.data.organization) {
          loggingIn(details.data.id, details.data.name, "organization", details.data.organization, details.data.email, details.data.username)
        }
        else {
          console.error('cookies are not correct')
          throw Error('cookies are not correct')
        }

      } catch (error) {
        showError(_NOT_AUTHENTICATED);
        logOutAuth();
        console.error(error);
      }
    }
  }, [isAuthenticated]);
}

export default useLogIn