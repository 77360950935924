//when review is sent it shows this page saying the review has successfully been sent

import { MEET_IN_NEXT_EVENTS, TITLES } from "../../../consts/hebrew";
import FeedBack from "../../../generic-components/GenericFeedback";

const ReviewSent: React.FC = () => {

    document.title = 'משוב נשלח בהצלחה'

    return (
        <FeedBack
            image={"/icons/orange-light.svg"}
            firstLine={<div>{TITLES.FEEDBACK_WAS_SENT}</div>}
            secondLine={""}
            lastLine={<p>{MEET_IN_NEXT_EVENTS}</p>}
        />
    )

}

export default ReviewSent;