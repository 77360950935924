//this component returns an event page on agency side
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncEffect } from '@hilma/tools';
import axios from 'axios';

import { UserContext } from '../../../context/UserContext';
import TicketOrder from '../../../generic-components/ticket-order/TicketOrder';
import PageNotFound from '../../general/page-not-found/PageNotFound';

import UseParamsInterface from '../../../consts/interfaces/UseParams.interface';
import { EventDetails } from '../../../consts/interfaces/TicketOrder.interface';
import { demoEventData } from '../../../consts/eventDetails';

const EventPage: React.FC = () => {
    const { systemID } = useContext(UserContext);
    const { id } = useParams() as UseParamsInterface;
    const eID = Number(id)
    const [eventInfo, setEventInfo] = useState<EventDetails>({ ...demoEventData })
    const [serverLoading, setServerLoading] = useState<boolean>(false)
    const [pageNotFound, setPageNotFound] = useState<boolean>(false)

    useAsyncEffect(async () => {
        if (!eID || !systemID) return

        setServerLoading(true)
        try {
            const res = (await axios.get(`/api/event/all-event-details?id=${eID}&agencyId=${systemID}`)).data;
            setEventInfo(res)

        } catch (error) {
            setPageNotFound(true);
            throw error;
        }
        setServerLoading(false)
    }, [eID, systemID])

    return (
        (!pageNotFound) ?
            <div className='event-page'>
                <TicketOrder type='edit' eventInfo={eventInfo} serverLoading={serverLoading} />
            </div> :
            <PageNotFound />
    )
}
export default EventPage;