//* COMPONENT TO REFACTOR
//Agency registration page - saves all the info in the server and awaits approval
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Area } from 'react-easy-crop';

import { useFiles } from "@hilma/fileshandler-client";
import { useMediaQuery } from '@mui/material';

import ProfileContact from "../../../generic-components/ProfileContact";
import { TermsForm, TermsFormRef } from '../../../generic-components/TermForm';
import AgencyTypeInput from '../../../generic-components/AgencyTypeInput';
import PopupJoinAgency from '../../../popups/agency/PopupJoinAgency';

import { AGENCY_LOGO, AGENCY_SIGNUP, IN_NUMBER_ENTER_YOU_CONFIRM_RECEIVING_ADVERTISEMENT_AND_UPDATE_MESSAGES, SEND_REQUEST, TITLES } from '../../../consts/hebrew';
import { EMAIL_EXISTS_ERROR, EMPTY_CONTACT, INVALID_EMAIL_ERROR, PHONE_NUMBER_EXISTS_ERROR, _ERROR_SEND_MESSAGE_ } from "../../../consts/variables";
import { agencySignUpErrors, contactErrors } from '../../../consts/interfaces/formErrors.interface'
import useIsFirstRender from '../../../consts/hooks/useIsFirstRender';
import { Contact } from '../../../consts/interfaces/Contact.interface';
import { AGENCY_SIGNUP_LEFT } from '../../../consts/leftSideLayouts';

import { emailValidation, nameValidation, phoneNumValidation, textValidation } from '../../../functions/validations';
import { handleContactsErrors } from '../../../functions/contactsValidations';
import { scrollToFirstError } from '../../../functions/scrollToError';

import { GoogleAnalyticsContext } from "../../../context/GoogleAnalytics";
import { ErrorContext } from "../../../context/ErrorContext";

import GenericProfilePicInput from '../../../generic-components/GenericProfilePicInput';
import GenericSplitPage from "../../../generic-components/GenericSplitPage";
import GenericTrashCan from "../../../generic-components/GenericTrashCan";
import GenericButton from "../../../generic-components/GenericButton";

import "../../../style/agencyProfile.scss";

const AgencySignUpPage: React.FC = () => {
  document.title = AGENCY_SIGNUP
  const isFirstRender = useIsFirstRender()
  const tabletMediaQuery = useMediaQuery('(max-width: 770px)');
  const history = useHistory();
  const filesUploader = useFiles();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const termsFormRef = useRef<TermsFormRef>(null);
  const formRef = useRef(null);

  const { gaEvent, gaException } = useContext(GoogleAnalyticsContext)
  const { showError } = useContext(ErrorContext)

  const [serverLoading, setServerLoading] = useState<boolean>(false)
  const [open, setOpen] = useState(true); // popup open for mobile
  const [imageCoordinates, setImageCoordinates] = useState<Area>({ x: 0, y: 0, width: 100, height: 100 })

  const [imageLink, setImageLink] = useState<string>(""); // the link of the uploaded image
  const [agencyType, setAgencyType] = useState<string>("")
  const [agencyName, setAgencyName] = useState<string>(""); // agency name value
  const [contacts, setContacts] = useState<Contact[]>([EMPTY_CONTACT()])

  const [agencyTypeError, setAgencyTypeError] = useState('')
  const [agencyNameError, setAgencyNameError] = useState<string>('')
  const [errors, setErrors] = useState({});

  useEffect(() => {
    scrollToFirstError(formRef, firstInputRef, errors)
  }, [errors])

  const validateContacts = () => {
    const validatedContacts = handleContactsErrors(contacts)
    setContacts(validatedContacts)

    const contactsErrors: contactErrors[] = validatedContacts.map((contact) => {
      const { nameError, phoneError, emailError } = contact
      const contactErrors: contactErrors = {}
      if (nameError) contactErrors.contactName = nameError
      if (phoneError) contactErrors.contactPhone = phoneError
      if (emailError) contactErrors.contactEmail = emailError
      return contactErrors
    });
    return contactsErrors
  }

  const validateFields = () => {
    let newErrors: agencySignUpErrors = {}
    const agencyTypeVal = textValidation(agencyType);
    const agencyNameVal = textValidation(agencyName);
    const contactsErrors = validateContacts()[0]
    const contactsVal = Object.keys(contactsErrors).length === 0
    const termsVal = termsFormRef.current?.validate()

    if (agencyNameVal) newErrors.agencyName = agencyNameVal
    if (agencyTypeVal) newErrors.agencyType = agencyTypeVal
    if (!contactsVal) {
      newErrors = { ...newErrors, ...contactsErrors }
    }
    if (!termsVal) newErrors.termName = false
    setAgencyTypeError(agencyTypeVal)
    setAgencyNameError(agencyNameVal)

    const isError = !contactsVal || !!agencyTypeVal || !!agencyNameVal || !termsVal

    setErrors(newErrors)
    return !isError
  }

  const handleSignup = async () => {
    if (!validateFields()) return
    setServerLoading(true)
    const terms = termsFormRef.current?.getTermData()
    try {
      await filesUploader.post('/api/agency/new-agency', {
        agencyName,
        agencyType,
        contacts,
        imageCoordinates,
        termsName: terms?.termsName,
        termsDate: terms?.termsDate
      })
      setServerLoading(false)
      history.push("/welcome-agency");
      gaEvent('sign_up', {
        sign_up_label: 'organization',
        event_category: 'agency',
        event_label: 'adding_new_agency',
      })
    } catch (error: any) {
      setServerLoading(false)
      if (error.data.message.includes('phone number already exists')) {
        setContacts(prev => {
          return prev.map(user => {
            if (user.phone === error.data.message.replace(/\D/g, '')) {
              return { ...user, phoneError: PHONE_NUMBER_EXISTS_ERROR }
            } else return user
          })
        })
        setErrors({ contactPhone: '' })
      }
      else if (error.data.message.includes('email already exists')) {
        setContacts(prev => {
          return prev.map(user => {
            if (user.email === error.data.message.split(' ')[0]) {
              return { ...user, emailError: EMAIL_EXISTS_ERROR }
            }
            else return user
          })
        })
        setErrors({ contactEmail: '' })
      }
      else if (error.data.message?.find((item: string) => item.includes("must be an email"))) {
        const invalidEmailError = error.data.message?.find((item: string) => item.includes("must be an email"))
        const index = Number(invalidEmailError.split('.')[1])
        setContacts(prev => prev.map((user, indx) =>
          index === indx ? { ...user, emailError: INVALID_EMAIL_ERROR } : user
        ))
        setErrors({ contactEmail: '' })
      }
      else {
        gaException('new_agency', error, true)
        showError(_ERROR_SEND_MESSAGE_)
      }
    }
  }

  //function that handles the onBlur
  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, name: string, index?: number) => {
    if (isFirstRender) return;
    switch (name) {
      case "contactName":
        if (contacts && typeof index === 'number') {
          const coppiedContacts = [...contacts]
          coppiedContacts[index].nameError = nameValidation(e.target.value)
          setContacts(coppiedContacts)
        }
        break;
      case "phone":
        if (contacts && typeof index === 'number') {
          const coppiedContacts = [...contacts]
          coppiedContacts[index].phoneError = phoneNumValidation(e.target.value)
          setContacts(coppiedContacts)
        }
        break;
      case "email":
        if (contacts && typeof index === 'number') {
          const coppiedContacts = [...contacts]
          coppiedContacts[index].emailError = emailValidation(e.target.value)
          setContacts(coppiedContacts)
        }
        break;
      default:
        break;
    }
  }

  //function that handles the onChange
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string, index?: number) => {
    switch (name) {
      case "contactName":
        const contactCopyName = [...contacts];
        if (typeof index === 'number' && contactCopyName[index]) {
          contactCopyName[index].name = e.target.value;
        }
        setContacts(contactCopyName);
        break;
      case "phone":
        const contactCopyPhone = [...contacts];
        if (typeof index === 'number' && contactCopyPhone[index]) {
          contactCopyPhone[index].phone = e.target.value;
        }
        setContacts(contactCopyPhone);
        break;
      case "email":
        const contactCopyEmail = [...contacts];
        if (typeof index === 'number' && contactCopyEmail[index]) {
          contactCopyEmail[index].email = e.target.value;
        }
        setContacts(contactCopyEmail);
        break;
      default:
        break;
    }
  }

  const handleAddContact = (index: number, nameError: string, phoneError: string, emailError: string, name: string, phone: string, email: string) => {

    if (contacts[index].name === '' || contacts[index].phone === '' || contacts[index].email === '') {
      if (name === '') {
        const contactCopyName = [...contacts];
        if (typeof index === 'number' && contactCopyName[index]) {
          contactCopyName[index].nameError = nameValidation(name);
        }
        setContacts(contactCopyName);
      } if (phone === '') {
        const contactCopyNumber = [...contacts];
        if (typeof index === 'number' && contactCopyNumber[index]) {
          contactCopyNumber[index].phoneError = phoneNumValidation(name);
        }
        setContacts(contactCopyNumber);
      } if (email === '') {
        const contactCopyEmail = [...contacts];
        if (typeof index === 'number' && contactCopyEmail[index]) {
          contactCopyEmail[index].emailError = nameValidation(email);
        }
        setContacts(contactCopyEmail);
      }
      return
    }
    if (nameError === '' && phoneError === '' && emailError === '') {

      if (contacts.length < 4) {
        setContacts(prev => {
          return [...prev, { id: "", name: "", phone: "", email: "", nameError: "", phoneError: "", emailError: "" }]
        })
      }
    }
  }

  const deleteContact = (index: number) => {
    let newContactArray: Contact[];
    if (index === 0 && contacts.length === 1) {
      newContactArray = [EMPTY_CONTACT()]
    } else {
      newContactArray = [...contacts]
      newContactArray.splice(index, 1);
    }
    setContacts(newContactArray)
  }

  return (
    <div className="agency-profile">
      {tabletMediaQuery &&
        <PopupJoinAgency
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        />}
      <div>
        <GenericSplitPage
          serverLoading={serverLoading}
          unauthenticated
          squares={AGENCY_SIGNUP_LEFT}
        >

          <form ref={formRef}>

            <div className="inputs-container">
              <div className="agency-sign-up-right-title" ref={firstInputRef}>
                {TITLES.AGENCIES_AND_SPORTS_BODIES_SIGNUP}
              </div>

              <GenericProfilePicInput
                title={AGENCY_LOGO}
                imageCoordinates={imageCoordinates}
                setImageCoordinates={setImageCoordinates}
                filesUploader={filesUploader}
                imageLink={imageLink}
                setImageLink={setImageLink}
              />
              <AgencyTypeInput
                agencyType={agencyType}
                agencyName={agencyName}
                setAgencyType={setAgencyType}
                setAgencyName={setAgencyName}
                agencyTypeError={agencyTypeError}
                agencyNameError={agencyNameError}
                setAgencyNameError={setAgencyNameError}
                ref={firstInputRef}
              />
              <div className="contacts-wrapper" >
                {contacts.map((contact, index) => {
                  return (
                    <div key={index}>
                      {contacts.length > 1 &&
                        <GenericTrashCan onClick={() => deleteContact(index)} />
                      }
                      <ProfileContact
                        secondaryTitle={IN_NUMBER_ENTER_YOU_CONFIRM_RECEIVING_ADVERTISEMENT_AND_UPDATE_MESSAGES}
                        name={contact.name}
                        phone={contact.phone}
                        email={contact.email}
                        {...contacts}
                        // disabled={index < contacts.length && index !== 0 ? true : false} -> commented so it can allow change after errors
                        // phoneDisabled={index < organizationInfo.length ? true : false}
                        setName={(e) =>
                          handleInputChange(e, "contactName", index)
                        }
                        setPhone={(e) =>
                          handleInputChange(e, "phone", index)
                        }
                        setEmail={(e) =>
                          handleInputChange(e, "email", index)
                        }
                        setNameError={(e) =>
                          handleInputBlur(e, "contactName", index)
                        }
                        setPhoneError={(e) =>
                          handleInputBlur(e, "phone", index)
                        }
                        setEmailError={(e) =>
                          handleInputBlur(e, "email", index)
                        }
                        nameError={contact.nameError}
                        phoneError={contact.phoneError}
                        emailError={contact.emailError}
                      />

                      {/* {!isDisabled && index === contacts.length - 1 && (
                          <GenericButton
                            disabled={isDisabled}
                            className="right-blue-btn blue"
                            handleClick={() =>
                              handleAddContact(
                                index,
                                contact.nameError,
                                contact.phoneError,
                                contact.emailError,
                                contact.name,
                                contact.phone,
                                contact.email,
                              )
                            }
                          >
                            {ADD_CONTACT} +
                          </GenericButton>
                        )} */}

                      {index !== contacts.length - 1 && (<div style={{ height: "4vh" }}></div>)}
                    </div>
                  );
                })}
              </div>

              <TermsForm ref={termsFormRef} pdf='policyAgency' />

              <GenericButton
                className="agency-sign-up-right-button yellow left"
                handleClick={handleSignup}
                serverLoading={serverLoading}
              >
                {SEND_REQUEST}
              </GenericButton>
            </div>
          </form>
        </GenericSplitPage>
      </div>
    </div>
  );
}

export default AgencySignUpPage;

