import FuturePurchasedEvents from "../../../generic-components/FuturePurchasedEvents"

import { useContext, useState } from "react";
import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";
import { EventSwiperElementType } from "../../../consts/interfaces/SwipeBoard.interface";
import { EventInfoInterfaceFromServer } from "../../../consts/interfaces/Event.interface";
import { _ERROR_MESSAGE } from "../../../consts/variables";
import { filterFuturePurchasedEvents } from "../../../functions/filterFuturePurchasedEvents";

import SkeletonsFuturePurchasedEvents from "../../../generic-components/SkeletonsFuturePurchasedEvents";

import { ErrorContext } from '../../../context/ErrorContext';
import { UserContext } from '../../../context/UserContext';

import './style/futureEventsPage.scss'

const FutureEventsPage: React.FC = () => {
    const { systemID } = useContext(UserContext);
    const { showError } = useContext(ErrorContext);

    const [isLoading, setIsLoading] = useState(true); // boolean flag that indicates whether the page is loading
    const [purchasedFutureEventsArray, setPurchasedFutureEventsArray] = useState<EventSwiperElementType[]>([])


    useAsyncEffect(async () => {
        if (!systemID) return
        try {
            const allPurchasedEvents: EventInfoInterfaceFromServer[] = (await axios.get(`/api/ticket/getEventsPurchased?organizationId=${systemID}`)).data
            setPurchasedFutureEventsArray(filterFuturePurchasedEvents(allPurchasedEvents))
            setIsLoading(false);
        }

        catch (error) {
            showError(_ERROR_MESSAGE);
            throw error;
        }
    }, [systemID, showError])

    return (
        <div className="future-purchased-events-list">
            {
                isLoading ? <SkeletonsFuturePurchasedEvents /> :
                    <FuturePurchasedEvents
                        futurePurchasedArray={purchasedFutureEventsArray}
                        isFutureEventsPage />
            }
        </div>
    )
}

export default FutureEventsPage