//this component gets props - tickets: number, border?: boolean, isEvent?: boolean.
//it returns the yellow banner for an event

import { YellowBannerProps } from "../consts/interfaces/YellowBannerAndSticker.interface"
import "../style/yellowBanner.scss"

const YellowBanner: React.FC<YellowBannerProps> = ({ isEvent, tickets }) => {

    return (
        <div className={`yellow-banner-wrapper  ${isEvent ? 'isEvent' : ""}`}>
            <div className="yellow-banner-container">
                <div className={tickets > 99 ? 'yellow-banner-number-small' : 'yellow-banner-number'} >{tickets}</div>
                <div className="yellow-banner-text">כרטיסים הוזמנו</div>
            </div>
            <div className="triangle"></div>
        </div>
    )
}

export default YellowBanner