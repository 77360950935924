import { FC } from 'react';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

import '../style/stepper.scss';

// styles the stepper line connector
const StyledStepperConnector = styled(StepConnector)(() => ({
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#41596b',
        borderTopWidth: 2,
        borderRadius: 1,
    },
}));

// step icon that returns colored circle if step is active, colored circle with a checkmark if step completed
function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <div className={className}>
            <div className={`stepper-circle ${(completed || active) && 'colored'}`}>
                {completed &&
                    <img src='/icons/Check.svg' className='stepper-checkmark' alt='' />
                }
            </div>
        </div>
    );
}


const FormStepper: FC<{ steps: Array<any>, activeStep: number }> = ({ steps, activeStep }) => {
    return (
        <Stepper dir='rtl' activeStep={activeStep} connector={<StyledStepperConnector />}>
            {steps.map((step, i) => (
                <Step key={i}>
                    <StepLabel StepIconComponent={StepIcon}></StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

export default FormStepper;